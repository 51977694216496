import React, { useState } from 'react';
import { Button,  Form, Input, message, notification } from "antd";
import {useHistory} from 'react-router-dom';
import { useStores } from "../../store";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import PhoneMask from "../../components/mask";

const Auth = () => {
  const [loading, setLoading] = useState(false)
  const {app, profile, Modals} = useStores();
  const {i18n, t} = useTranslation()
  const history = useHistory();

  const onFinish = async values => {
    values.phone = values.phone.replace(/ /g, '');
    setLoading(true);
    const error = await app.auth(values);
    if(error){
      message.error(error);
      setLoading(false);
    }else {
      const error = await profile.getProfile();
      if(!error && (profile.profile.role === 'central_office' || profile.profile.role === 'regional')){
        history.push(`/${i18n.language}/pm/processing`)
        notification.success({
          message: t('Добро пожаловать в Портал молодежи'),
          description:t('Поздравляем вы успешно зашли в кабинет исполнителя'),
        });
        Modals.hide()
      }else{
        Modals.hide()
        notification.success({
          message: t('Добро пожаловать в Портал молодежи'),
          description:t('Поздравляем вы успешно зашли в свой профиль!'),
        });

      }
    }
  };

  const onFinishFailed = values => {
    setLoading(false);
  };
  return (
    <div className={`auth-wrapper auth-box animate__animated animate__fadeIn`}>
        <h3 className={'reg'}><Trans>Авторизация</Trans></h3>
        <p className={'small mb-56'}><Trans>Для авторизации введите номер телефона и пароль,<br/> указанные вами ранеепри регистрации</Trans></p>
        <div className={'close-popup'} onClick={()=>Modals.hide()}/>
        <Form
          layout={'vertical'}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <PhoneMask label={<Trans>Введите номер телефона</Trans>}/>
          <Form.Item
            label={<Trans>Введите пароль</Trans>}
            name="password"
            rules={[{ required: true, message: <Trans>Введите пароль</Trans> }]}
          >
            <Input.Password  placeholder={t('Пароль')} />
          </Form.Item>

          <Form.Item className={'d-flex justify-end'}>
            <Button type={'text'} className="login-form-forgot" onClick={()=>Modals.show('recoverPassword')}>
              <Trans>Забыли пароль</Trans> ?
            </Button>
          </Form.Item>
          <Button type="primary" loading={loading} htmlType="submit" className={'btn success'}>
            <Trans>Войти</Trans>
          </Button>
          <button type={'button'} onClick={()=>Modals.show('howReg')} className={'btn default'}><Trans>Регистрация</Trans></button>
        </Form>
    </div>
  )
};

export default observer(Auth);