import { observable, flow, makeObservable, action } from "mobx";
import api from '../../api';
class News {
  @observable loading = true;
  @observable error = false;
  @observable hot = null;
  @observable start = 1;
  @observable form = {};
  @observable list = {
    count:0,
    results:[]
  };
  @observable category = {
    results:[]
  };
  @observable newsDetail = {
    object:null,
    similar:[]
  };
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable currentCategory = null;

  constructor() {
    makeObservable(this)
  }
  @action setCurrentCategory = (id) => {
    this.loading = true;
    this.list.results = [];
    this.start = 1;
    this.pagination = {limit:20, offset: 0};
    this.currentCategory = id ? {category_id:id} : null;
  };
  @action setFromValue = (values) => {
    this.hot = null;
    this.loading = true;
    this.start = 1;
    this.list.results = [];
    this.pagination = {limit:20, offset: 0};
    this.form = values;
  }
  @action reset = () => {
    this.list.results = [];
    this.start = 1;
    this.pagination = {
      limit:20,
      offset: 0
    }
    this.newsDetail = {
      object:null,
      similar:[]
    };
    this.loading = true;
    this.error = false;
  };
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @flow *
  getData(){
    const response = yield api.news.list({...this.pagination, ...this.currentCategory, ...this.form});
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.results = [...this.list.results, ...response.data.results];
        this.start++;
      }else{
        this.list = response.data;
      }
    }
    this.loading = false
  }
  @flow *
  getCategory(){
    const response = yield api.news.category();
    if(!response.detail){
      this.category = response.data;
    }
  }
  @flow *
  getHot(){
    const response = yield api.news.list({hot:true});
    if(!response.detail){
      this.hot = response.data.results[0];
      if(this.hot){
        this.hot.categoryName = this.category?.results?.find(item => item.id === this.hot?.category);
      }
    }
  }
  @flow *
  detail(id){
    const response = yield api.news.detail(id);
    if(!response.detail){
      this.newsDetail = response.data;
      const id = this.newsDetail.object.category;
      this.newsDetail.object['categoryName'] = this.category.results.find((category) => category.id === id)
    }else{
      this.error = true;
    }
    this.loading = false;
  }
}

export default new News()
