import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../../store";
import { Form, Modal,  Input,  Button, notification, message, InputNumber } from "antd";
import { observer } from "mobx-react";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import { currentLang, formatDateClient } from "../../../config";

const ExecutorPMChangePeriod = (props) => {
  const {id} = props;
  const {executor} = useStores()
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const {t} = useTranslation()
  const [visible, setVisible] = useState(false)
  const [visibleReject, setVisibleReject] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    executor.changePeriod({request_id:id.id})
    return () => {
      executor.reset()
    }
  }, [executor, id]);

  const onFinish = async values => {
    setLoading(true);
    executor.setValues({...values, url:'/set-depth-level-task'});
    const error = await executor.acceptedItem();

    if(!error){
      notification.success({
        message: t('Заявка отправлено'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
      });
      form.resetFields()
      setLoading(false);
      setVisible(false)
      executor.changePeriod({request_id:id.id})
    }else{
      setLoading(false);
      error.map(item => message.error(item))
    }
  }
  const visibleHandler = (item) => {
    executor.setValues(
      {
        action_id:item.id,
        request_pm_gov_id:item.request_pm_gov_id,
        task_pm_gov_id:item.task.pm_gov_id,
        status:'ACCEPTED'
      }
    );
    setVisible(true)
  }
  const visibleRejectHandler = (item) => {
    executor.setValues(
      {
        action_id:item.id,
        url:'/set-depth-level-task',
        request_pm_gov_id:item.request_pm_gov_id,
        task_pm_gov_id:item.task_pm_gov_id,
        status:'REJECTED'
      }
    );
    setVisibleReject(true)
  }
  const rejectedHandler = async (values) => {
    setLoading(true);
    executor.setValues({...values});
    const error = await executor.acceptedItem();
    if(!error){
      notification.success({
        message: t('Заявка отправлено'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
      });
      form.resetFields()
      setLoading(false);
      setVisibleReject(false)
      executor.changePeriod({request_id:id.id})
    }else{
      error.map(item =>  message.error(item));
      setLoading(false);
    }
  }
  return(
    <div>
      {
        executor.period.results.length > 0 &&
          <>
            <h3><Trans>Изменение ограничения по задач</Trans></h3>
            {
              executor.period.results.map(item => {
                return <div key={item.id}>
                  <ul  className={'animate__animated animate__fadeIn profile-list'}>
                    <li>
                      <strong><Trans>Номер обращений</Trans>:</strong>
                      <span>{item.request.request_no}</span>
                    </li>
                    <li>
                      <strong><Trans>Дата обращения</Trans>:</strong>
                      <span>{formatDateClient(item.created_at)}</span>
                    </li>
                    <li>
                      <strong><Trans>Организация</Trans>:</strong>
                      <span>{item.task.dst_authority[`title_${currentLang()}`]}</span>
                    </li>
                    <li>
                      <strong><Trans>Категории</Trans>:</strong>
                      <span>{item.task.classification[`title_${currentLang()}`]}</span>
                    </li>
                    <li>
                      <strong><Trans>Уровень подведомственной организации</Trans>:</strong>
                      <span>{item.task.depth_level}</span>
                    </li>

                    {
                      (item.status === 'ACCEPTED' || item.status === 'REJECTED') &&
                      <>
                        <li>
                          <strong><Trans>Статус</Trans></strong>
                          <span className={item.status === 'ACCEPTED' ? 'text-success' : 'text-error'}>{item.status === 'ACCEPTED' ? 'Сиз мурожатни кабул килдингиз' : item.status === 'REJECTED' && 'Сиз мурожатни рад килдингиз'}</span>
                        </li>
                      </>
                    }
                    <li className={'full'}>
                      <strong>Коментарии</strong>
                      <p>{item.comment}</p>
                    </li>

                  </ul>
                  <div className={'btn-panel'}>
                    {
                      item.status === 'PROCESSING' &&
                      <>
                        <button className={'btn success'} onClick={()=>visibleHandler(item)}><span><Trans>Принять</Trans></span></button>
                        <button className={'btn error'} onClick={()=>visibleRejectHandler(item)}><span><Trans>Отказать</Trans></span></button>
                      </>
                    }
                  </div>
                </div>
              })
            }
          </>
      }
      <Modal maskClosable={true} onCancel={()=>setVisible(false)} title="Изменение ограничения по задач" visible={visible} footer={false}>
        <Form  form={form} layout={'vertical'} onFinish={onFinish}>
          <Form.Item name={'depth_level'} label={'Уровень подведомственной организации'}>
            <InputNumber/>
          </Form.Item>
          <Form.Item rules={[{required:true, message:t('Обьязательное поля')}]} name={'comment'} label={t('Причина')}>
            <TextArea/>
          </Form.Item>
          <Button loading={loading} htmlType={'submit'} className={'btn success'}><Trans>Отправить</Trans></Button>
          <Button className={'btn close'} onClick={()=>setVisible(false)}><Trans>Закрыть</Trans></Button>
        </Form>
      </Modal>
      <Modal maskClosable={true} onCancel={()=>setVisibleReject(false)} title="Изменение ограничения по задач" visible={visibleReject} footer={false}>
        <Form form={form} layout={'vertical'} onFinish={rejectedHandler}>
          <Form.Item rules={[{required:true, message:t('Обьязательное поля')}]} name={'comment'} label={t('Причина')}>
            <TextArea/>
          </Form.Item>
          <Button loading={loading} htmlType={'submit'} className={'btn success'}><Trans>Отправить</Trans></Button>
          <Button className={'btn close'} onClick={()=>setVisible(false)}><Trans>Закрыть</Trans></Button>
        </Form>
      </Modal>
    </div>
  )
};

export default observer(ExecutorPMChangePeriod)