import React, { useState } from 'react';
import { Button, Form, Select } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { currentLang } from "../../config";

const VacancyModal = () => {
  const {location, Modals, vacancy} = useStores();
  const [title, setTitle] = useState(null)
  const Option = Select;
  const {t} = useTranslation();
  const onFinish = async () => {
    vacancy.filterRegion(title);
    const error = await vacancy.getData();
    if(!error){
      Modals.hide()
    }
  }
  const reset = async () => {
    setTitle(null)
    vacancy.filterRegion(title);
    const error = await vacancy.getData();
    if(!error){
      Modals.hide()
    }
  }
  const getTtitleRegion = (id, key) => {
    setTitle(key);
  }
  return (
    <>
      <div className={'popup-header'}>
        <h3><Trans>Место расположения</Trans></h3>
        <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      </div>
      <Form layout={'vertical'} onFinish={onFinish}>
        <Form.Item name={'region_id'} label={t('Выбор региона')}>
          <Select placeholder={t('Введите Регион')} onChange={getTtitleRegion} onDropdownVisibleChange={() => location.getPMRegion()} >
            {
              location.region.map((item, idx) => {
                return item.title !== '' && <Option key={idx} value={item.id}>{item[`title_${currentLang()}`]}</Option>
              })
            }
          </Select>
        </Form.Item>
        <div className={'buttons d-flex justify-center align-center'}>
          <Button className={'reset'} onClick={()=>reset()}><Trans>Сбросить</Trans></Button>
          <Button htmlType={'submit'} className={'find'} type={'primary'}><Trans>Найти</Trans></Button>
        </div>
      </Form>
    </>
  )
};

export default observer(VacancyModal)