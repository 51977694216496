import { observable, makeObservable, flow, action } from "mobx";
import api from '../../api';
class Achievements {
  @observable person = {
    results:[],
  };
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable start = 1;
  @observable currentCategory = null;
  @observable loading = true;
  @observable category = [];
  @observable single = {
    result:{},
    random:[]
  };

  constructor() {
    makeObservable(this);
  }
  @action reset = () => {
    this.person.results = [];
    this.category = [];
    this.loading = true
  }

  @action loaded = () => {
    this.loading = false
  }

  @action setCurrentCategory = (id) => {
    this.loading = true;
    this.start = 1;
    this.person.results = [];
    this.pagination = {limit:20, offset: 0};
    this.currentCategory = id ? {category:id} : null;
  };
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @flow *
  getPerson(){
    const response = yield api.achievements.person({...this.pagination, ...this.currentCategory});
    if(!response.detail){
      if(this.person.results.length > 0) {
        this.person.results = [...this.person.results, ...response.data.results];
      }else{
        this.person =response.data;
      }
    }
  }
  @flow *
  getCategory(){
    const res = yield api.achievements.category();
    if(!res.detail){
      this.category = res.data.results;
    }
  }
  @flow *
  detail(id){
    const response = yield api.achievements.detail(id);
    if(!response.detail){
      this.single = response.data
    }
  }

};

export default new Achievements()