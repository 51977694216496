import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import opportunities from './modules/youth_opportunities';
import Modals from './modules/modals'
import Home from './modules/home'
import Registration from './modules/Registration'
import RecoverPassword from './modules/recover-passwrod';
import News from './modules/news';
import Contests from './modules/contests';
import Events from './modules/events';
import Grants from './modules/grants';
import Courses from './modules/courses';
import achievements from './modules/achievements';
import vacancy from './modules/vacancy';
import projects from './modules/project';
import profile from './modules/profile';
import location from './modules/location';
import appeals from './modules/appeal';
import ideas from './modules/ideas';
import about from './modules/about';
import executor from './modules/executor';
import magazine from './modules/magazine';
import indices from './modules/indices';
import app from './app'
const stores = {
  opportunities,
  appeals,
  ideas,
  executor,
  magazine,
  about,
  RecoverPassword,
  app,
  location,
  indices,
  Home,
  Contests,
  News,
  Events,
  Grants,
  Courses,
  achievements,
  vacancy,
  projects,
  profile,
  Registration,
  Modals
}
export default stores
export function useStores() {
  return React.useContext(MobXProviderContext);
}