import { observable, flow, makeObservable, action } from "mobx";
import api from '../../api';
import i18n from '../../i18n';
import profile from './profile'
import { currentLang } from "../../config";
class Location {
  @observable region = [];
  @observable district2List = [];
  @observable district = [];
  @observable community = [];
  @observable districtRegistry = [];
  @observable streets = [];
  @observable houses = [];
  @observable district_id = null;
  @observable street_id = null;

  constructor() {
    makeObservable(this)
  }
  @action resetFields = () => {
    this.community = []
    this.streets = []
    this.houses = []
    this.district_id = null
    this.street_id = null

  };
  @flow * getDistrictRegistry(){
    const response = yield api.location.districtRegistry()
    if(!response.detail){
      this.districtRegistry = response.data.results;
      this.street_id = null;
      this.streets = [];
      this.houses = [];
    }
  }
  @flow * getCommunityRegistry(q){
    const response = yield api.location.communityRegistry({district_id:this.district_id, street_id:this.street_id, ...q, lang:currentLang() === 'en' ? 'uz' : currentLang()})
    if(!response.detail){
      this.community = response.data.results;
    }
  }
  @flow * getCommunityPM2(q, id){
    const response = yield api.location.getCommunityPM2(q, id)
    if(!response.detail){
      this.community = response.data;
    }
  }
  @flow * getStreets(id){
    const response = yield api.location.streets(id)
    if(!response.detail){
      this.streets = [];
      this.houses = [];
      this.district_id = id;
      this.street_id = null;
    }
  }
  @flow * suggestion(q){
    const district_id = this.district_id ? this.district_id : profile.profile.district.addr_register_id;
    const response = yield api.location.suggestion({district_id, q, lang:currentLang() === 'en' ? 'uz' : currentLang()})
    if(!response.detail){
      this.streets = response.data.results;
    }
  }
  @flow * getHouses(q){
    const  street_id = this.street_id ? this.street_id : profile.profile.street_id;
    const response = yield api.location.houses({street_id, q, lang:currentLang() === 'en' ? 'uz' : currentLang()});
    if(!response.detail){
      if(q){
        this.houses = response.data.results;
      }
    }
  }
  @flow * getRegion(values){
    const response = yield api.location.region(values)
    if(!response.detail){
      const karakalpak = response.data.find(item =>item.pm_gov_id === 1735)
      const other = response.data.find(item =>item.pm_gov_id === 9999)
      const region = response.data.filter(item => (item.pm_gov_id !== 1735 && item.pm_gov_id !== 9999)).sort((a,b) => {
        if(a[`title_${i18n.language}`] < b[`title_${i18n.language}`]) { return -1; }
        if(a[`title_${i18n.language}`] > b[`title_${i18n.language}`]) { return 1; }
        return 0;
      })
      this.region = [karakalpak, ...region, other];
    }
  }

  @flow * getPMRegion(){
    const response = yield api.location.regionPM()
    if(!response.detail){
      const karakalpak = response.data.find(item =>item.pm_gov_id === 1735)
      const other = response.data.find(item =>item.pm_gov_id === 9999)
      const region = response.data.filter(item => (item.pm_gov_id !== 1735 && item.pm_gov_id !== 9999)).sort((a,b) => {
        if(a[`title_${i18n.language}`] < b[`title_${i18n.language}`]) { return -1; }
        if(a[`title_${i18n.language}`] > b[`title_${i18n.language}`]) { return 1; }
        return 0;
      })
      this.region = [karakalpak, ...region, other];
    }
  }
  @flow * getDistrict(id){
    const response = yield api.location.district(id)
    if(!response.detail){
      this.district = response.data
    }
  }
  @flow * getPMDistrict2(values){
    const response = yield api.location.regionALl(values)
    if(!response.detail){
      this.district2List = response.data;
    }
  }
  @flow * getPMDistrict(id){
    const response = yield api.location.districtPM(id)
    if(!response.detail){
      this.district = response.data
    }
  }
  @flow * district2(values){
    const response = yield api.location.district2(values)
    if(!response.detail){
      this.district = response.data
    }
  }
  @flow * getCommunityPM(values){
    const response = yield api.location.communityPM(values.regionID);
    if(!response.detail){
      this.community = response.data.filter((item)=> item.district_id === values.mahallaID).sort((a,b) => a.title - b.title);
    }
  }
  @action setStreetID = (id) => {
    this.street_id = id;
    this.houses = []
}
}

export default new Location();