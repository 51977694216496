import React from 'react';
import { useStores } from "../../store";
import { Trans } from "react-i18next";

const FilesDownload = (props) => {
  const {app} = useStores()
  const downloadFile = (e, file) => {
    e.preventDefault()
    fetch(file, {
      headers:{
        Authorization:`token ${app.token}`
      }
    }).then(res => res.blob()).then(blob => {
      const fileName = file.split('/').slice(-1);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', fileName)
      a.setAttribute('target', '_blank')
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
  }
  return (
    <div className={'file-download-panel'}>
      {
        props.files.filter(item => item).map((item, index) => (
          <div key={index} className="ant-upload-list-text-container align-center">
            <strong>{item.title}</strong>
            {
              item.file && <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
                <div className="ant-upload-list-item-info">
            <span className="ant-upload-span">
              <div className="ant-upload-text-icon">
                <span role="img" aria-label="paper-clip" className="anticon anticon-paper-clip">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"/>
                  </svg>
                </span>
              </div>
              <a onClick={(e)=>downloadFile(e, item.file)} rel="noopener noreferrer" className="ant-upload-list-item-name" title={item.file.split('/').slice(-1)} href={item.file}><Trans>Скачать файл</Trans></a>
            </span>
                </div>
              </div>
            }
          </div>
        ))
      }
    </div>
  )
};

export default FilesDownload