import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)

  .init({
    lngs: [ 'ru', 'oz', 'kp', 'en'],
    initImmediate: false,
    fallbackLng:'oz',
    lowerCaseLng: true,
    whitelist: ['ru', 'oz', 'kp', 'en'],
    backend: {
      crossDomain: true,
      loadPath:'/static/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      caches: [],
      order: ['path', 'queryString'],
    },
    debug: process.env.NODE_ENV === "development",
    interpolation:{ escapeValue: false },
    react: {
      useSuspense: true,
    }
  });


export default i18n;
