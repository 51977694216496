import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { Button,  Form, Select } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { currentLang, formatDateServer } from "../../config";
import DatePickerPortal from "../../components/datepicker";

const FilterContestPopup = () => {
  const {t, i18n} = useTranslation()
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const {Option} = Select;
  const {location, Contests, Modals} = useStores();

  useEffect(()=>{
    forceUpdate({});
  }, []);

  const getDirection = async () => {
      await Contests.getDirection()
  }
  const getCountry = async () => {
      await location.getPMRegion()
  }
  const onFinish = async values => {
    values.date = formatDateServer(values.date)
    await Contests.setFromValue(values)
    Contests.getData()
    Modals.hide()
  }
  return (
    <div className={'popup-filter'}>
      <h3><Trans>Фильтр</Trans></h3>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      <Form form={form} layout={'vertical'} onFinish={onFinish} >
        <Form.Item  label={t('Регион')} name={'region'}  >
          <Select placeholder={t('Регион')} onDropdownVisibleChange={(open)=> open && getCountry()}>
            {
              location.region.map((item, idx) => {
                return item && <Option key={idx} value={item.id}>{item[`title_${i18n.language}`]}</Option>
              })
            }
          </Select>
        </Form.Item>
        <Form.Item label={t('Направления')} name={'direction'}>
          <Select placeholder={t('Направления')}  onDropdownVisibleChange={(open) => open && getDirection()}>
            {
              Contests.direction.results.map((item, idx) => {
                return <Option key={idx} value={item.id}>{item[`name_${currentLang()}`]}</Option>
              })
            }
          </Select>
        </Form.Item>
        <DatePickerPortal label={t('Дата')} name={'date'}/>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              className={'btn'}
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(false)
              }
            >
              <Trans>Применять фильтр</Trans>
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  )
};

export default observer(FilterContestPopup)