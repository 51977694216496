import React, { useCallback, useEffect, useState } from 'react';
import { observer } from "mobx-react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  Modal,
  DatePicker,
  message, Spin
} from "antd";
import { Trans, useTranslation } from "react-i18next";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";
import { formatDateServer } from "../../config";
import { useStores } from "../../store";
import uz_Latn from "../datepicker/translate/uz";
import ru from "../datepicker/translate/ru";
import en from "antd/lib/locale/en_US";
import o_zOz from "../datepicker/translate/oz";
import InputMask from "react-input-mask";
import CounterDown from "../counter-down";
import { useMediaQuery } from "react-responsive";

const DetailForm = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const {forms, store, setVisible, id} = props;
  const {location, Registration, profile, Modals, app} = useStores()
  const [form] = Form.useForm();
  const {TextArea} = Input;
  const {Option} = Select;
  const {t, i18n} = useTranslation()
  const { Dragger } = Upload;
  const [, setFileList] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [codeVisible, setCodeVisible] = useState(false);
  const [regionID, setRegionId] = useState(null);
  const [mahhalla, setMahalla] = useState(null);
  const [mahhallaPM, setMahallaPM] = useState(null);
  const [disable, setDisable] = useState(true);
  const [phone, ] = useState(null);
  const [, setHouse] = useState(true);
  const [nameField, setNameField] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  let [number, ] = useState('');
  const [, forceUpdate] = useState({});



  useEffect(() => {
    if(app.access && profile.profile.gcp_verified){
      setNameField(true);
      form.setFieldsValue({'full_name':`${profile.profile.last_name} ${profile.profile.last_name} ${profile.profile.patronymic}`})
    }
    forceUpdate({});
  }, [app, profile, form, nameField]);

  const getRegionID = async (value, key) => {
    await location.resetFields();
    const error = await location.getPMDistrict(value);
    setRegionId(key.key)
    if (!error) {
      setDisable(false);
      form.resetFields(['district_id', 'mahalla_id', 'current_address'])
    }
  };
  const getCommynityID = async (id, key) => {
    setMahalla(key.key);
    setMahallaPM(regionID);
    const mahallaID = key.value;
    const error = await location.getCommunityPM({ mahallaID, regionID });

    if (!error) {
      setDisable(false);
    }

  };
  const handleGetCommunity = async q => {
    const error = await location.getCommunityPM2(q, mahhallaPM);
    if (!error) {
      setHouse(false)
    }
  };

  const onFinish = async values => {
    // if(!form.getFieldValue('full_name')) {
    //   return message.error(t('Заполните форму правильно'))
    // };
    setLoading(true);
    Object.keys(values).forEach(k => {
      if(k.includes('file_')){
        const type = k.split('_');
        values[type[1]] = values[k].fileList[0].originFileObj
        delete values[k];
      }
      if(k.includes('date_')){
        const type = k.split('_');
        values[type[1]] = formatDateServer(values[k])
        delete values[k];
      }
      if(k.includes('phone_')){
        const type = k.split('_');
        values[type[1]] = values[k].replace(/ /g, '')
        delete values[k];
      }
    });
    values['form_id'] = id;
    if(forms.mahalla){
      values['district_id'] = mahhalla ? mahhalla : values['district_id']
    }
    if(forms.gcp && !profile.profile.gcp_verified){
      values['passport_sn'] = `${values.series}${values.number}`;
      delete values.series
      delete values.number
    }
    const error = await store.sendForm(values);
    if(!error){
      Modals.show('successForm');

      document.body.classList.remove('show-popup')
      form.resetFields()
      setFileList([])
      setFileArray([])
      setLoading(false);
      setVisible(false);

    }
  }
  const onChange = useCallback(({ fileList: newFileList, file:file}) => {
    setFileList(newFileList.slice(-1));
    if(file.status === 'done'){
      setFileArray(prev=>[...prev, {visible:false, imageSrc:null, id:file.uid}]);
    }
  }, []);
  const onRemove = (file) => {
    setFileArray((prevState) => prevState.filter((d) => d.id !== file.uid));
    setFileList((prevState) => prevState.filter((d) => d.uid !== file.uid));
  }
  const onPreview = async (file) => {
    const index = fileArray.findIndex(item => item.id === file.uid);
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    let arr = [...fileArray];
    arr[index].imageSrc = src;
    arr[index].visible = true;
    setFileArray(arr);
  };
  const languages = {
    uz:uz_Latn,
    ru:ru,
    en:en,
    oz:o_zOz
  };
  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const valueHandler = async (e) => {
    if (e.target.value.length >= 17){
      const error = await Registration.signupCode({ phone: e.target.value.replace(/ /g, ''), dynamic_form:true});
      if(!error){
        setCodeVisible(true);
      }
    }
    // setValue(e.target.value)
  }
  const confirmCode = async (values) => {
    const error = await Registration.confirmCode(values);
    if(error){
      message.error(error.data.detail)
    }else{
      setCodeVisible(false)
    }
  }
  const formatNumber = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  const formatNumber1 = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  }
  const formatLetter = e => {
    e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '').toUpperCase();
  }
  const onChangeVal = async () => {
    if(
      (form.getFieldValue(['number']) && form.getFieldValue(['number']).length >= 7) &&
      (form.getFieldValue(['series']) && form.getFieldValue(['series']).length >= 2) &&
      (form.getFieldValue(['birthdate']) && form.getFieldValue(['birthdate']).length >= 10))
    {
      setLoadingSpin(true);
      const formatDate = form.getFieldValue(['birthdate']).split('.')
      const data = {birthdate:`${formatDate[2]}-${formatDate[1]}-${formatDate[0]}`, document:form.getFieldValue(['series'])+form.getFieldValue(['number'])};
      const error = await profile.getProfileByGCP3(data);
      if(error.status !== 200){
        message.error(error.data.detail)
      }else {
        form.setFieldsValue({'full_name':`${error.data.user_data.last_name} ${error.data.user_data.first_name} ${error.data.user_data.patronymic}`})
        setNameField(true)
      }
      setLoadingSpin(false);
    }
  }
  return (
    <>
      <div className={'formDetail'}>
        <h2><Trans>Стать участником</Trans></h2>
        <Form  onFinish={onFinish} form={form} layout={'vertical'}>
          {
            (forms.gcp && !profile.profile.gcp_verified) && <Spin size={'large'} spinning={loadingSpin}>
              <Input.Group >
                <Form.Item name={'series'} rules={[{ required: true, message: t('Обязательное поле') }]} label={t('Серия паспорта')}>
                  <Input className={'ant-input'} onChange={onChangeVal} onInput={(e)=>formatLetter(e)}  maxLength={2}/>
                </Form.Item>
                <Form.Item name={'number'} rules={[{ required: true, message: t('Обязательное поле') },{ min: 7, message: t("минимум 7 цифр") }]} label={t('Номер паспорта')}>
                  <Input className={'input-number ant-input'} onChange={onChangeVal} type="tel"  onInput={(e)=>formatNumber1(e)}  minLength={7}  maxLength={7}/>
                </Form.Item>
              </Input.Group>
              <Form.Item label={t('Дата рождения')} rules={[
                { required: true, message: t('Обязательное поле') },
                {max: 10, message: t('Минимальное кол-во 8 цифр') }
              ]} name={'birthdate'}>
                <InputMask className={'ant-input'}  type="tel" maskChar={''} onChange={onChangeVal}  mask="99.99.9999"  placeholder="DD.MM.YYYY">
                  {(inputProps) => <Input type="tel"  disabled={props.disabled}  {...inputProps} />}
                </InputMask>
              </Form.Item>

            </Spin>
          }
          {
            nameField && <Form.Item label={t('Ф.И.О')} rules={[
              { required: true, message: t('Обязательное поле') },
            ]} name={'full_name'}>
              <Input readOnly loading={true}/>
            </Form.Item>
          }
          {
            forms.mahalla && <>
              <Form.Item label={t('Местоположения')} name={'region_id'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <Select placeholder={t('Введите Регион')} onDropdownVisibleChange={(open) => open && location.getPMRegion()} onChange={getRegionID}>
                  {
                    location.region.map((item) => {
                      return (item.title !== '' && item.pm_gov_id !== 9999) && <Option  value={item.id} key={item.pm_gov_id}>{item.title}</Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item label={t('Район (Область)')} name={'district_id'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <Select placement={'bottomLeft'} dropdownMatchSelectWidth={false} placeholder={t('Введите Район (город)')} onChange={getCommynityID} disabled={disable}>
                  {
                    location.district.map(item => (
                      <Option value={item.pm_gov_id} key={item.id}>{item.title}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
              <Form.Item label={t('Махалля')} name={'mahalla_id'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <Select
                  showSearch
                  filterOption={false}
                  onSearch={handleGetCommunity}
                  notFoundContent={false}
                  showArrow={false}
                  placement={'bottomLeft'}
                  placeholder={t('Махалля')}
                  disabled={disable}
                >
                  {
                    location.community.map((item, idx) => {
                      return <Option value={item.id} key={idx}>{item.title}</Option>
                    })
                  }
                </Select>
              </Form.Item>
            </>
          }
          {
            forms.fields.map((field, index) => {
              return <div key={index}>
                <Form.Item label={field[`title_${i18n.language}`]} name={field.field_type === 'phone' ? 'phone_'+field.id : field.field_type === 'file' ? 'file_'+field.id : field.field_type === 'date' ? 'date_'+field.id : field.id} rules={[{ required: true, message: t('Обязательное поле') }]}>
                  {
                    (field.field_type === 'line' && field.title === 'Ф.И.О.') ? <Input name={'full_name'}/>  :
                    field.field_type === 'line' ? <Input/>  :
                    field.field_type === 'date' ? <DatePicker locale={languages[i18n.language]} placeholder={t('Выберите дату')}  format={'DD.MM.YYYY'}/>  :
                    field.field_type === 'phone' ? <InputMask maskChar={''} className={'ant-input'} value={phone} onChange={valueHandler}  mask="+\9\98 99 999 99 99"  placeholder="+998">
                            {(inputProps) => <Input type="tel"  disabled={props.disabled}  {...inputProps} />}
                          </InputMask> :
                    field.field_type === 'amount' ? <input className={'ant-input'}  type="tel" value={number} onInput={(e)=>formatNumber(e)}/> :
                    field.field_type === 'text' ? <TextArea/> :
                    field.field_type === 'radio' ?
                        <Radio.Group>
                          {
                            field.choices.map((item, i) => (
                              <Radio key={i} value={item[`title_${i18n.language}`]}>{item[`title_${i18n.language}`]}</Radio>
                            ))
                          }
                        </Radio.Group> :
                    field.field_type === 'checkbox' ?
                        <Checkbox.Group>
                          {
                            field.choices.map((item, i) => (
                              <Checkbox key={i} value={item[`title_${i18n.language}`]}>{item[`title_${i18n.language}`]}</Checkbox>
                            ))
                          }
                        </Checkbox.Group> :
                    field.field_type === 'list' ?
                        <Select>
                          {
                            field.choices.map((item, indx) => (
                              <Option key={indx} value={item[`title_${i18n.language}`]}>{item[`title_${i18n.language}`]}</Option>
                            ))
                          }
                        </Select> :
                    field.field_type === 'file' ?
                        <Dragger onRemove={onRemove}
                                 onPreview={onPreview}
                                 customRequest={dummyRequest}
                                 maxCount={1}
                                 listType="picture"
                                 onChange={onChange}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                          </p>
                          <p className="ant-upload-text"><Trans>Нажмите или перетащите файл в эту область чтобы загрузить</Trans></p>
                        </Dragger> : null
                  }
                </Form.Item>
                {
                  fileArray.map((item, index) => {
                    return <Modal
                      visible={item.visible}
                      key={index}
                      src={item.imageSrc}
                      footer={null}
                      title={null}
                      onCancel={() => {
                        const newArr = [...fileArray];
                        newArr[index].visible = false;
                        setFileArray(newArr);
                      }}
                    >
                      <img alt="example" style={{ width: '100%' }} src={item.imageSrc}/>
                    </Modal>
                  })
                }
              </div>
            })
          }
          <Form.Item shouldUpdate>
            {() => (
              <Button loading={loading} className={'btn-success'} htmlType={'submit'}><Trans>Отправить</Trans></Button>
            )}
          </Form.Item>


        </Form>
      </div>
      <Modal
        footer={null} closable={false} maskClosable={false} header={null} centered={!isMobile}
        visible={codeVisible}
      >
        <Form onFinish={confirmCode} >
          <div
            className={'animate__animated animate__fadeIn'}
            style={{marginTop:25, marginBottom:25}}
          >
            <Form.Item label={<Trans>Введите код</Trans>} name="code"  style={{marginBottom:0}} rules={[
              { required: true, message: <Trans>Введите код</Trans> },
            ]}>
              <Input placeholder={t('Код активации')} maxLength={6}/>
            </Form.Item>
            <CounterDown/>
          </div>
          <Button className={'btn success'} htmlType={'submit'}><Trans>Подтвердить</Trans></Button>
        </Form>
      </Modal>
    </>
  )
};


export default observer(DetailForm)
