import { observable, makeObservable, flow, action } from "mobx";
import api from '../../api';
import { currentLang } from "../../config";
class Appeal {
  @observable step = 1;
  @observable loading = true;
  @observable appealsList = {
    results:[],
    count:0
  };
  @observable title = '';
  @observable filter = {
    status:'processing'
  };
  @observable statistic = {};
  @observable detail = null;
  @observable start = 1;
  @observable paginations = {
    limit:20,
    offset:0
  }
  @observable subcategories = null;
  @observable pm = {
    categories:[],
    classificationsText:[],
    subcategories:[],
    classifications:[],
    classifications2:[],
    classifications3:[],
  };
  @observable scopes = {
    results:[]
  };

  @observable rules = [];

  constructor() {
    makeObservable(this)
  }
  @action setFilters = (val) => {
    this.paginations = {
      limit:20,
      offset:0
    }
    this.start = 1;
    this.appealsList = {
      results:[],
      count:0
    };
    this.loading = true;
    this.filter = {...this.filter, ...val};
  }
  @action reset = () => {
   this.appealsList = {
      results:[],
      count:0
    };
    this.filter = {status:'processing'};
    this.loading = true;
    this.step = 1
    this.start = 1
    this.paginations = {
      limit:20,
      offset:0
    }
    this.pm.subcategories = []
  }
  @action getForm = (id, item) => {
    this.subcategories = item;
    this.title = {
      title_ru:item[`title_ru`] ? item[`title_ru`].toLowerCase() : null,
      title_en:item[`title_en`] ? item[`title_en`].toLowerCase() : null,
      title_uz:item[`title_uz`] ? item[`title_uz`].toLowerCase() : null,
      title_oz:item[`title_oz`] ? item[`title_oz`].toLowerCase() : null
    };
    this.step = 3
  }
  @action setPagination = () => {
    this.paginations.offset = 20 * this.start
  }

  @flow * getPMCategory(){
    const response = yield api.pm.categories();
    if(!response.detail){
      this.pm.categories = response.data?.sort((a, b) => b.is_youth_agency - a.is_youth_agency) ?? [];
    }
  }
  @flow * getClassification(){
    const response = yield api.pm.getClassification();
    if(!response.detail){
      this.pm.classifications = response.data;
    }
  }
  @flow * getClassification2(id){
    const response = yield api.pm.getClassification2(id);
    if(!response.detail){
      this.pm.classifications2 = response.data;
    }
  }
  @flow * getClassification3(id){
    const response = yield api.pm.getClassification3(id);
    if(!response.detail){
      this.pm.classifications3 = response.data;
    }
  }
  @flow * suggestion(q){
    const response = yield api.pm.suggestion({q});
    if(!response.detail){
      this.pm.classificationsText = response.data;
    }
  }

  @flow * submit(value){
    let form = new FormData();
    for(let type in value){
      form.append(type, value[type]);
    }
    const response = yield api.pm.create(form);
    const error = []
    if(response.data.status === 'error'){
      if(response.data){
        for(let key in response.data){
          if(key !== 'status' && response.data[key].detail){
           error.push(response.data[key].detail.error.map(item => item.message));
          }
        }
        return error.map((item) => item);
      }
    }
    else if(response.status === 500){
      if(response.data){
        return ['Что то пошло не так попробуйте позже'];
      }
    }
  }

  @flow * getPMSubcategories(id){
    this.step = 1;
    const response = yield api.pm.subcategories(id);
    if(!response.detail){
      if(!response.data.length){
        this.step = 3;
      }else{
        this.pm.subcategories = response.data;
        this.step = 2;
      }
    }
  }
  @flow * search(values){
    const response = yield api.pm.search(values);
    if(!response.detail){
      this.pm.subcategories = response.data;
      this.step = 2;
    }
  }

  @flow * getData(values){
    const response = yield api.appeals.list({...this.paginations, ...this.filter, ...values});
    if(!response.detail){
      if(this.appealsList.results.length > 0) {
        this.appealsList.count = response.data.count;
        this.appealsList.results = [...this.appealsList.results, ...response.data.results];
        this.start++;

      }else{
        this.appealsList = response.data
      }
      this.loading = false;
    }
  }
  @flow * detailAppeal(id){
    const response = yield api.appeals.detail(id);
    if(!response.detail){
      this.detail = response.data;
    }
  }
  @flow * getStatistic(){
    const response = yield api.appeals.statistic();
    if(!response.detail){
      this.statistic = response.data;
    }
  }
  @flow * benefitAppeal(id){
    const response = yield api.appeals.benefit(id);
    if(!response.detail){
      this.detail = response.data;
      this.rules = [
        {
          title:'Описание льготы',
          active:true,
          description:this.detail[`how_it_works_${currentLang()}`]
        },
        {
          title:'Ответственная организация',
          active:false,
          description:this.detail[`organization_${currentLang()}`]
        },
        {
          title:'Получатель услуг',
          active:false,
          description:this.detail[`recipient_${currentLang()}`]
        },
        {
          active:false,
          title:'Документы, необходимые для получение льгот',
          description:this.detail[`requirements_${currentLang()}`]
        },
        // {
        //   title:'Сроки оказание льгот',
        //   active:false,
        //   description:this.detail[`deadline_${i18n.language}`]
        // },
        {
          title:'Правовое основание услуги',
          active:false,
          description:this.detail.legal_basis
        },
        // {
        //   title:'Форма для обращения по льготам',
        //   active:false,
        //   description:this.detail.contact_form
        // },
        {
          title:'Контактная информация',
          active:false,
          description:this.detail[`contacts_${currentLang()}`]
        },
      ]
    }
  }
  @flow * scopeAppeal(id){
    const response = yield api.appeals.scopes(id);
    if(!response.detail){
      this.scopes = response.data;
    }
  }

}
export default new Appeal()
