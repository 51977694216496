import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../../store";
import { Form, Modal, Input, Button, notification, message } from "antd";
import { observer } from "mobx-react";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import { currentLang, formatDateClient } from "../../../config";

const ExecutorPMResult = (props) => {
  const {id} = props;
  const {executor} = useStores()
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const {t} = useTranslation()
  const [visible, setVisible] = useState(false)
  const [visibleReject, setVisibleReject] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    executor.getResult(id)
    return () => {
      executor.reset()
    }
  }, [executor, id]);

  const onFinish = async values => {
    setLoading(true);
    executor.setValues({...values, url:'/set-status-on-checking-task'});
    const error = await executor.acceptedItem();

    if(!error){
      notification.success({
        message: t('Заявка отправлено'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
      });
      form.resetFields()
      setLoading(false);
      setVisible(false)
      executor.getResult(id)
    }else{
      setLoading(false);
      error.map(item => (
        message.error(item)
      ))
    }
  }
  const visibleHandler = (item) => {
    executor.setValues(
      {
        classification_pm_gov_id:item.classification.pm_gov_id,
        depth_level:item.task.depth_level,
        result_id:item.id,
        request_pm_gov_id:item.request.pm_gov_id,
        task_pm_gov_id:item.task.pm_gov_id,
        result_type_pm_gov_id:item.result_type.pm_gov_id,
        status:'ACCEPTED'
      }
    );
    setVisible(true)
  }
  const visibleRejectHandler = (item) => {
    executor.setValues(
      {
        result_id:item.id,
        url:'/set-status-on-checking-task',
        classification_pm_gov_id:item.classification.pm_gov_id,
        depth_level:item.task.depth_level,
        request_pm_gov_id:item.request.pm_gov_id,
        task_pm_gov_id:item.task.pm_gov_id,
        result_type_pm_gov_id:item.result_type.pm_gov_id,
        status:'REJECTED'
      }
    );
    setVisibleReject(true)
  }
  const rejectedHandler = async (values) => {
    setLoading(true);
    executor.setValues({...values});
    const error = await executor.acceptedItem();
    if(!error){
      notification.success({
        message: t('Заявка отправлено'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
      });
      form.resetFields()
      setLoading(false);
      setVisibleReject(false)
      executor.getResult(id)
    }else{
      error.map(item => (
        message.error(item)
      ));
      setLoading(false);
    }
  }
  return(
    <div>
      {
        executor.result.length > 0 &&
        <>
          <h3><Trans>Результат обращения</Trans></h3>
          {
            executor.result.map(item => {
              return <div key={item.id}>
                <ul  className={'animate__animated animate__fadeIn profile-list'}>
                  <li>
                    <strong>Номер обращений:</strong>
                    <span>{item.request.request_no}</span>
                  </li>
                  <li>
                    <strong>Дата обращения:</strong>
                    <span>{formatDateClient(item.created_at)}</span>
                  </li>
                  <li>
                    <strong>Результат:</strong>
                    <span className={(item.result_type.title === 'Отклонено' ||item.result_type.title === 'Не рассмотрено' || item.result_type.title === 'Рассмотрение прекращено') ? 'text-error' : 'text-success'}>{item.result_type[`title_${currentLang()}`]}</span>
                  </li>
                  {
                    (item.result_type.title === 'Отклонено' || item.result_type.title === 'Не рассмотрено' || item.result_type.title === 'Рассмотрение прекращено') ?
                      <>
                        <li className={'full'}>
                          <strong>Ответ</strong>
                          <p>{item.result_type.title === 'Отклонено' ? 'Нет ответа' : item.reasons_reject_text}</p>
                        </li>
                      </> :
                      <>
                        <li>
                          <strong>Ижро учун масъул ходим:</strong>
                          <span>{item.responsible_person}</span>
                        </li>
                        <li>
                          <strong>Жавоб хатини имзолаган масъул шахс ҳақида маълумотлар:</strong>
                          <span>{item.executor_info}</span>
                        </li>

                        <li className={'full'}>
                          <strong>Ответ</strong>
                          <p>{item.answer_text}</p>
                        </li>
                      </>
                  }
                  <li className={'full'}>
                    <strong>Коментарии</strong>
                    <p>{item.comment}</p>
                  </li>
                  {
                    item.file && <li>
                      <strong><Trans>Файл</Trans>:</strong>
                      <button style={{background:'#44D370', flex:1}} className={'btn text'}>Скачать</button>
                    </li>
                  }
                </ul>
                <div className={'btn-panel'}>
                  {
                    item.status === 'PROCESSING' &&
                    <>
                      <button className={'btn success'} onClick={()=>visibleHandler(item)}><span><Trans>Принять</Trans></span></button>
                      <button className={'btn error'} onClick={()=>visibleRejectHandler(item)}><span><Trans>Отказать</Trans></span></button>
                    </>
                  }
                </div>
              </div>
            })
          }
        </>
      }
      <Modal maskClosable={true} onCancel={()=>setVisible(false)} title="Результат обращения" visible={visible} footer={false}>
        <Form  form={form} layout={'vertical'} onFinish={onFinish}>
          <Form.Item name={'conclusion'} label={'xulosa'}>
            <Input/>
          </Form.Item>
          <Form.Item rules={[{required:true, message:t('Обьязательное поля')}]} name={'comment'} label={t('Причина')}>
            <TextArea/>
          </Form.Item>
          <Button loading={loading} htmlType={'submit'} className={'btn success'}><Trans>Отправить</Trans></Button>
          <Button className={'btn close'} onClick={()=>setVisible(false)}><Trans>Закрыть</Trans></Button>
        </Form>
      </Modal>
      <Modal maskClosable={true} onCancel={()=>setVisibleReject(false)} title="Изменение ограничения по задач" visible={visibleReject} footer={false}>
        <Form form={form} layout={'vertical'} onFinish={rejectedHandler}>
          <Form.Item name={'conclusion'} label={'xulosa'}>
            <Input/>
          </Form.Item>
          <Form.Item rules={[{required:true, message:t('Обьязательное поля')}]} name={'comment'} label={t('Причина')}>
            <TextArea/>
          </Form.Item>
          <Button loading={loading} htmlType={'submit'} className={'btn success'}><Trans>Отправить</Trans></Button>
          <Button className={'btn close'} onClick={()=>setVisible(false)}><Trans>Закрыть</Trans></Button>
        </Form>
      </Modal>
    </div>
  )
};

export default observer(ExecutorPMResult)