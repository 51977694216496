import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Empty, Form, Input, Select, Spin } from "antd";
import { NavLink, useParams } from "react-router-dom";
import Appeal from "../appeal";
import { useStores } from "../../../store";
import ExecutorDetail from "./detail";
import InfiniteScroll from "react-infinite-scroll-component";

const ProfilePM = () => {
  const {appeals} = useStores();
  const {id} = useParams();
  const [form] = Form.useForm();
  const Option = Select.Option;
  const {t, i18n} = useTranslation()

  useEffect(()=>{
    if(id){
      appeals.detailAppeal(id)
    }else{
      appeals.getData()
    }
    return () => {
      appeals.reset()
    }
  }, [id, appeals]);

  const handleChange = async val => {
    await appeals.setFilters(val)
    appeals.getData();
  }
  const changeHandler = async () => {
    await appeals.setFilters(form.getFieldValue());
    appeals.getData()
  }
  const fetchData = () => {
    appeals.setPagination()
    appeals.getData();
  }
  if(id){
    return <ExecutorDetail />
  }
  return (
    <>
      <div>
        <h3><Trans>Обращения  молодежи</Trans></h3>
      </div>
      <div className={'filters d-flex justify-spacebetween'}>
        <ul>
          <li><button className={appeals.filter.status === 'processing' ? 'active' : null} onClick={()=>handleChange({status:'processing'})}><Trans>Актуальные</Trans> ({appeals.statistic.processing_request_count})</button></li>
          <li><button className={appeals.filter.status === 'closed' ? 'active' : null} onClick={()=>handleChange({status:'closed'})}><Trans>Закрытые</Trans> ({appeals.statistic.closed_request_count})</button></li>
        </ul>
        <Form form={form} className={'user-filter-form'} onFinish={changeHandler}>

          <div className={'buttons user-buttons'}>
            <Form.Item name={'created_at'}>
              <Select placeholder={t('Сортировать по дате')} onChange={changeHandler}>
                <Option value={null}><Trans>Старые</Trans></Option>
                <Option value={'y'}><Trans>Свежие</Trans></Option>
              </Select>
            </Form.Item>
            <Form.Item name={'status'}>
              <Select placeholder={t('Статус')}  onChange={changeHandler}>
                <Option value={'is_any_news'}><Trans>Есть новости</Trans></Option>
                <Option value={'expired'}><Trans>Просроченные</Trans></Option>
              </Select>
            </Form.Item>
            <div className={'form user-form'}>
              <Form.Item name={'id'}>
                <Input autoComplete="off" placeholder={t('Поиск по ID')}/>
              </Form.Item>
              <Button htmlType={'submit'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </Button>
            </div>
          </div>
        </Form>
      </div>
      <Spin spinning={appeals.loading} >
        <InfiniteScroll
          dataLength={appeals.appealsList.results.length}
          next={fetchData}
          hasMore={appeals.appealsList.count > appeals.appealsList.results.length}
          pullDownToRefreshThreshold={300}
          style={{overflowAnchor: 'auto', overflow: 'inherit'}}
          useWindow={true}
        >
        <div className={'profile-appeals-box'} style={{display:appeals.appealsList.results.length <= 0 ? 'block': null }}>

          {
            (appeals.appealsList.results.length !== 0) ?

              appeals.appealsList.results.map((item, index) => {
                return  <NavLink  key={index} to={`/${i18n.language}/profile/pm-appeals/${item.id}`}
                                  className={`appeals-item ${item.status}`}>
                  <Appeal key={index} {...item}/>
                </NavLink>
              })
              : appeals.loading ? null :
              <Empty
                style={{ marginLeft: 'auto', marginRight: 'auto', }}
                description={<span><Trans>Тут пока пусто</Trans> </span>}
              />
          }
        </div>
        </InfiniteScroll>
      </Spin>
    </>
  )
};

export default observer(ProfilePM)