import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { currentLang,  truncateString } from "../../config";
import banner from "../../assets/project.jpg";
import { Spin } from "antd";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import InfiniteScroll from "react-infinite-scroll-component";

const Projects = () => {
  const {i18n} = useTranslation()
  const { projects } = useStores();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  useEffect(() => {
    projects.getCategory();
    projects.getData();
    return () => {
      projects.reset();
    }
  }, [projects]);
  const setCurrentCategory = (id) => {
    projects.setCurrentCategory(id);
    projects.getData()
  }
  const fetchData = () => {
    projects.getCategory();
    projects.setPagination()
    projects.getData();
  }

  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>Наши Проекты</Trans>}/>
      }
      <div className={'banner animate__animated animate__fadeIn'} style={{ background: `url(${banner}) no-repeat center center / cover` }}>
        <div className={'center-content'}>
          <h1><Trans>Наши проекты</Trans></h1>
          <p><Trans>Здесь можно узнать про проекты, организованные Агентством по делам молодёжи либо при его сотрудничестве Вы сможете подробно узнать о каждом проекте, а также сможете зарегистрироваться на некоторые из них</Trans> </p>
        </div>
      </div>
      <div className={'center-content project-content  animate__animated animate__fadeIn'}>
        <Helmet>
          <title>Yoshlar Portali - Наши проекты</title>
        </Helmet>
        <div className={'filters'}>
          <ul>
            <li>
              <button
                className={null === projects.category ? 'active' : ''}
                onClick={() => null !== projects.category ? setCurrentCategory(null) : null}>
                <Trans>Все</Trans> ({projects.counter})
              </button>
            </li>
            {
              projects.categoryList.map((item, idx) => {
                return item.projects_count > 0 &&
                  <li key={idx}>
                    <button
                      className={item.id === (projects.category && projects.category.category) ? 'active' : ''}
                      onClick={() => item.id !== (projects.category && projects.category.category) ? setCurrentCategory(item.id) : null}
                    >
                      {item.title} ({item.projects_count})
                    </button>
                  </li>
              })
            }
          </ul>
        </div>
        <InfiniteScroll
          dataLength={projects.all}
          next={fetchData}
          hasMore={projects.list.count > projects.all}
          pullDownToRefreshThreshold={300}
          style={{overflowAnchor: 'auto', overflow: 'inherit'}}
          useWindow={true}
        >
          <Spin spinning={projects.loading} size={'large'}>
            <div className={'events-items'}>
              <div className={'line'}/>
              <div className={'events-block'}>
                {
                  projects.list.results.map((category, index) => {
                    return (category.results.length > 0 && !projects.currentCategory) || category.category === projects.currentCategory ? <div key={index} className={'animate__animated animate__fadeIn'}>
                      <span className={'date'}>{category.title}</span>
                      <div className={'column'}>
                        {
                          category.results.map((item, indx) => {
                            return <div key={indx} className={'event'}>
                              <NavLink to={`/${i18n.language}/projects/${item.id}`} className={'img'}>
                                <img src={item.preview} alt={''}/>
                              </NavLink>
                              <NavLink to={`/${i18n.language}/projects/${item.id}`} className={'description'}>
                                <div className={'top'}>
                                  <strong>{item[`title_${currentLang()}`]}</strong>
                                  <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(item[`content_${currentLang()}`] && item[`content_${currentLang()}`].replace(/<(?!br\s*\/?)[^>]+>/g, ''), 205)}}/>
                                </div>
                              </NavLink>
                            </div>
                          })
                        }
                      </div>
                    </div> : null
                  })
                }
              </div>
            </div>
          </Spin>
        </InfiniteScroll>

      </div>
    </>
  )
};

export default observer(Projects)