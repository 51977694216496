import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import {useParams} from 'react-router-dom';
import { baseUrl, currentLang } from "../../config";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { Button, Image, Spin } from "antd";
import { Trans } from "react-i18next";
import MobileNavigation from "../../components/navigation";
import { useMediaQuery } from "react-responsive";


const MagazineDetail = () => {
  const {magazine} = useStores()
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const {id} = useParams()
  useEffect(()=>{
    magazine.getSingle(id)
    return ()=>{
      magazine.reset()
    }
  }, [magazine, id])
  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>Библиотека</Trans>}/>
      }
      {
        magazine.single ? <>
          <Helmet>
            <title>{magazine.single[`title_${currentLang()}`]}</title>
            <meta property="og:url" content={baseUrl} />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={magazine.single[`title_${currentLang()}`]} />
            <meta property="og:description" content={magazine.single[`description_${currentLang()}`].replace(/<[^>]+>/g, '')} />
            <meta property="og:image" content={magazine.single.cover} />
          </Helmet>
          <div className={'center-content animate__animated animate__fadeIn'}>
            <div className={'article-detail library-detail'}>
              <div className={'detail-content'}>
                <div className={'header'}>
                  <h1>{magazine.single[`title_${currentLang()}`]}</h1>
                  <div className={'d-flex'}>
                    <span className={''}><Trans>Дата выпуска</Trans>: {magazine.single.year}</span>

                  </div>
                </div>
                <Image style={{maxWidth:'100%', height:'auto'}} src={magazine.single.cover}/>
                <div className={'text'} dangerouslySetInnerHTML={{__html:magazine.single[`description_${currentLang()}`]}}/>
                <Button type={'primary'} className={'btn btn-success large'} loading={magazine.single.loading} onClick={()=>magazine.download(magazine.single.file, null)}><span><Trans>Скачать pdf</Trans></span></Button>
              </div>
            </div>
          </div>
        </> : magazine.loading ? <div className={'d-flex justify-center'}><Spin size={'large'} spinning={true}/></div> : null
      }

    </>

  )
};

export default observer(MagazineDetail)