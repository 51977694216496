import {observable, makeObservable, flow} from "mobx";
import api from '../../api';
class About {
  @observable detail = null;
  constructor() {
    makeObservable(this)
  }

  @flow * getData(){
    const response = yield api.about.about();
    if(!response.detail){
      this.detail = response.data[0];
    }
  }
}

export default new About()