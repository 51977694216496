import { observable, makeObservable, flow, action, computed } from "mobx";
import api from '../../api';
class Projects {
  @observable list = {
    results:[]
  };
  @observable project = {
    objects:null,
    next:null,
    prev:null
  };
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable start = 1;
  @observable categoryList = [];
  @observable category = null;
  @observable counter = 0;
  @observable all = 0;
  @observable form = {
    fields:[]
  };
  @observable loading = true;
  @observable error = false;

  constructor() {
    makeObservable(this)
  }
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @action setCurrentCategory = (id) => {
    this.reset()
    if(id) return this.category = {category:id};
    this.category = null;
  };
  @action reset = () => {
    this.pagination = {offset: 0, limit: 20}
    this.start = 1;
    this.project = {
      objects:null,
      next:null,
      prev:null
    };
    this.list = {
      results:[]
    };
    this.currentCategory = null;
    this.loading = true;
  }
  @flow *
  getData(){
    const response = yield api.projects.data({...this.pagination, ...this.category});
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.results = this.categoryList.map((item, index)=> ({
          title:item.title,
          category:item.id,
          results:[...this.list.results[index].results, ...response.data.results.filter(list => list.category === item.id)].sort((a,b) => a.id > b.id ? -1 : a.id < b.id ? 1 : 0)
        }));
        this.start++;
        this.all += response.data.results.length;
      }else{
        this.list = response.data;
        this.all = response.data.results.length;
        this.list.results = this.categoryList.map((item)=> ({
          title:item.title,
          category:item.id,
          results:this.list.results.filter((list) => list.category === item.id).sort((a,b) => a.id > b.id ? -1 : a.id < b.id ? 1 : 0)
        }));
      }
      this.loading = false;
    }
  }
  @computed get sorted(){
    return this.list
  }
  @flow *
  getCategory(){
    const response = yield api.projects.category();
    if(!response.detail){
      this.categoryList = response.data;
      this.counter = this.categoryList.reduce((item,acc)=> {return item += acc.projects_count}, 0)
    }
  }
  @flow *
  detail(id){
    const response = yield api.projects.detail(id);
    if(!response.detail){
      this.project = response.data;
    }else{
      this.error = true;
    }
    this.loading = false;
  }
  @flow *
  membership(values){
    yield api.projects.membership(values);

  }
  @flow * sendForm(values){
    let form = new FormData();
    for(let type in values){
      form.append(type, values[type])
    }
    const response = yield api.projects.sendForm(form);
    if(!response.detail){

    }

  }
  @flow *
  getForm(values){
    const response = yield api.projects.getForm(values);
    if(!response.detail){
      this.form = response.data;
    }

  }
};

export default new Projects();