import React, { useEffect} from 'react';
import { Trans } from "react-i18next";
import { Button,  DatePicker, Form, Input, notification,  Select, Spin } from "antd";
import { NavLink, useParams, useHistory } from "react-router-dom";
import ExecutorDetail from "./detail";
import { useStores } from "../../../store";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "./card";
import { currentLang } from "../../../config";


const ExecutorPending = () => {
  const {id} = useParams();
  const history = useHistory()
  const {executor, profile} = useStores();
  const {Option} = Select;
  useEffect(()=>{
    if(id){
      return executor.detailAppeal(id);
    }
   executor.getBenefitAppeals({benefit:'y'})
    return () => {
      executor.reset('pending')
    }
  }, [profile, executor,  id]);

  const onAppoint = async (Appealid, index) => {
    const error = await executor.onAppoint({index, Appealid});
    if(!error){
      notification.success({
        message: 'Уведомления',
        description:
          'Заявка успешна перешла в "Открытые заявка"',
      });
      if(id){
        history.goBack()
      }
    }

  }
  const fetchData = () => {
    executor.setPagination()
    executor.getBenefitAppeals({benefit:'y'});
  }
  if(id) return <ExecutorDetail onAppoint={onAppoint} {...executor.detail}/>
  return (
    <>
      <h3 className={'bordered'}><Trans>Новые заявки</Trans></h3>
      <div className={'filters  mb-40'}>
        <Form>
          <div className={'form'}>
            <Form.Item>
              <Input placeholder={'Поиск по ID'}/>
            </Form.Item>
            <Button>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </Button>
          </div>
          <div className={'buttons'}>
            <Form.Item>
              <DatePicker placeholder={'Дата'}/>
            </Form.Item>
            <Form.Item>
              <Select placeholder={'Категории'}>
                <Option>1</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Select placeholder={'Сфера'}>
                <Option>1</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
        <InfiniteScroll
          dataLength={executor.appeals.count}
          next={fetchData}
          hasMore={executor.appeals.count > executor.appeals.results.length}
          pullDownToRefreshThreshold={300}
          style={{overflowAnchor: 'auto', overflow: 'inherit'}}
          useWindow={true}
        >
          <Spin spinning={executor.loading}  size="large">
            <div className={'profile-appeals-box'}>
              {
                executor.appeals.results.map((item, index) => {
                  return <div>
                    <NavLink key={index} to={`/${currentLang}/executor/pending/${item.id}`}
                             className={`appeals-item ${item.status}`}>
                      <Card onAppoint={onAppoint} key={index} {...item}/>
                    </NavLink>
                  </div>
                })
              }
            </div>
          </Spin>
        </InfiniteScroll>
    </>
  )
};

export default observer(ExecutorPending)