import { observable, makeObservable, flow, action } from "mobx";
import api from '../../api';
import moment from 'moment'
class Events {
  @observable list = {
    count:0,
    next:null,
    results:[]
  };
  @observable event = {
    object:null
  };
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable form = {};
  @observable forms = {
    fields:[]
  };
  @observable start = 1;
  @observable params = null;
  @observable error = false;
  @observable loading = true;

  constructor() {
    makeObservable(this);
  }
  @action setFromValue = (values) => {
    this.loading = true;
    this.start = 1;
    this.list.results = [];
    this.pagination = {limit:20, offset: 0};
    this.form = values;
  }
  @action buttonsValue = (values) => {
    this.loading = true;
    this.list.results = [];
    this.start = 1;
    this.pagination = {limit:20, offset: 0};
    this.params = values;
  }
  @action reset = () => {
    this.start = 1;
    this.pagination = {limit:20, offset: 0};
    this.list.results = [];
    this.params = null;
    this.form = null;
    this.loading = true
  }
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @flow *
  getData(){
    const response = yield api.events.data({...this.pagination, ...this.form, ...!this.params ? {new:true} : this.params});
    if(!response.detail){
      let data = {};
      response.data.results = response.data.results.sort((a,b) => moment(b.date) - moment(a.date)).forEach(item => {
        const format = moment(item.date).format('DD.MM.YYYY');
        data[format] = response.data.results.filter(items => format === moment(items.date).format('DD.MM.YYYY'));
      });
      if(Object.keys(this.list.results).length > 0) {
        this.list.count = response.data.count;
        this.list.results = {...this.list.results, ...data};
        this.start++;
      }else{
        this.list = response.data;
        this.list.results = data;
      }

      this.loading = false;
    }
  }

  @flow *
  detail(id){
    const response = yield api.events.detail(id);
    if(!response.data.detail && response.status === 200){
      this.event = response.data;
    }else{
      this.error = true;
      this.loading = false;
    }
  }
  @flow * sendForm(values){
    let form = new FormData();
    for(let type in values){
      form.append(type, values[type])
    }
    const response = yield api.events.sendForm(form);
    if(!response.detail){

    }

  }
  @flow *
  getForm(id){
    const response = yield api.events.getForm(id);
    if(!response.detail){
      this.forms = response.data;
    }

  }

};
export default new Events();