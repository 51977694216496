import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { NavLink, useParams } from "react-router-dom";
import { Image, Spin } from "antd";
import { Trans } from "react-i18next";
import {Helmet} from "react-helmet";

import ShareComponent from "../../components/share-buttons";
import i18n from "../../i18n";
import { useStores } from "../../store";
import { baseUrl, currentLang, formatDate, formatNum, truncateString } from "../../config";
import E404 from "../../components/e404/404";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import FilesDownload from "../../components/filesUpload";
const NewsDetail = () => {
  const {News} = useStores();
  const {id} = useParams();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  useEffect(()=>{
    News.getCategory();
    News.detail(id)
    return () => {
      News.reset();
    }
  }, [News, id]);
  const [show, setShow] = useState(false);
  if(News.error){
    return <E404/>
  }
  return (
   <>
     {
       News.newsDetail.object ?
         <>
           <Helmet>
             <meta charSet="utf-8" />
             <meta property="og:url" content={window.location.href} />
             <title>{News.newsDetail.object[`title_${currentLang()}`]}</title>
             <meta property="og:type" content="website" />
             <meta property="og:image" content={baseUrl+News.newsDetail.object.image} />
             <meta property="og:image:width" content="512"/>
             <meta property="og:image:height" content="512"/>
             <meta property="og:title" content={News.newsDetail.object[`title_${currentLang()}`]} />
             <meta property="og:description" content={News.newsDetail.object[`content_${currentLang()}`].replace(/<(?!br\s*\/?)[^>]+>/g, '')}/>
             <meta name="description" content={News.newsDetail.object[`content_${currentLang()}`].replace(/<(?!br\s*\/?)[^>]+>/g, '')}/>
           </Helmet>
           {
             isMobile && <MobileNavigation title={<Trans>Новости</Trans>}/>
           }
           <div className={'center-content  animate__animated animate__fadeIn'}>
             <div className={'article-detail'}>
               <div className={'detail-content'}>
                 <div className={'header'}>
                   <h1>{News.newsDetail.object[`title_${currentLang()}`]}</h1>
                   <div className={'d-flex align-center'}>
                     <span className={'date'}>{formatDate(News.newsDetail.object.created_at)}</span>
                     <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                          stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                          d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                          stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                       {formatNum(News.newsDetail.object.views_count)}
                      </span>
                     {News.newsDetail.object.categoryName && <strong>{News.newsDetail.object.categoryName.title}</strong>}
                   </div>
                 </div>
                 <a href={News.newsDetail.object.image} className={'ant-image'} target={'_blank'} rel="noreferrer">
                   <img className={'ant-image-img'} src={News.newsDetail.object.image} alt={''} />
                 </a>
                 <div className={'text'} dangerouslySetInnerHTML={{__html:News.newsDetail.object[`content_${currentLang()}`]}}/>
                 <FilesDownload files={[{title:News.newsDetail.object[`file_title1_${currentLang()}`],file:News.newsDetail.object.file1},{title:News.newsDetail.object[`file_title2_${currentLang()}`],file:News.newsDetail.object.file2},{title:News.newsDetail.object[`file_title3_${currentLang()}`],file:News.newsDetail.object.file3}]}/>
               </div>
               <div className={'popular'}>
                 <h2><Trans>Похожие новости</Trans></h2>
                 {
                   News.newsDetail.similar.splice(0, 4).map((item, idx) => {
                     return <NavLink key={idx} to={`/${i18n.language}/news/${item.id}`} className={'other-news-link'}>
                       <img src={item.preview} alt={''}/>
                       <div className={'caption'}>
                         <div className={'caption-header'}>
                           <span className={'date'}>{formatDate(item.created_at)}</span>
                           <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                  stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                  stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                             {item.views_count}
              </span>
                         </div>
                         <p>{truncateString(item[`title_${currentLang()}`], 70)}</p>
                       </div>
                     </NavLink>
                   })
                 }
               </div>
             </div>
           </div>
           <div className={'center-content'}>
             <div className={'share-block d-flex justify-start'}>
               <ShareComponent isVisible={show} setVisible={setShow} title={News.newsDetail.object[`title_${i18n.language}`]}/>
             </div>
           </div>
         </> :
       News.loading ? <div className={'center-content d-flex justify-center'}><Spin spinning={true} size={'large'}/></div>  : null
     }
   </>
  )
};

export default observer(NewsDetail)