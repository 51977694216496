import React, { useEffect} from 'react';
import { Button, Col, Row, Spin } from "antd";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import {  currentLang, formatDateClient, truncateString } from "../../config";
import banner from "../../assets/grant.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
const Grants = () => {
  const {i18n} = useTranslation()
  const {Grants, Modals} = useStores();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  useEffect(()=>{
    Grants.getData();
    return () => {
      Grants.reset()
    }
  }, [Grants]);
  const fetchData = () => {
    Grants.setPagination()
    Grants.getData();
  }
  const resetFilter = async () => {
    await Grants.reset()
    Grants.getData();
  }
  const filterButtons = async values => {
    Grants.buttonsValue(values)
    Grants.getData();
  }
  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>Актуальные гранты</Trans>}/>
      }
      <div className={'banner '}>
        <img src={banner} alt=""/>
        <div className={'center-content'}>
          <h1><Trans>Актуальные гранты</Trans></h1>
          <p><Trans>Гранты – вид поддержки студентов на обучение, в работе по основным научным направлениям университетов и повышения эффективности их учебной деятельности не только в нашей стране, но и за её пределами.</Trans> </p>
        </div>
      </div>
      <div className={'center-content grants-box '}>
        <Helmet>
          <title>Yoshlar Portali - Актуальные гранты</title>
        </Helmet>
        <div className={'filters d-flex align-center justify-spacebetween'}>
          <ul>
            <li><button className={Grants.params === null ? 'active' : ''} onClick={() => Grants.params !== null ? filterButtons(null) : null}><Trans>Новые</Trans></button></li>
            <li><button className={Grants.params !== null ? 'active' : ''} onClick={() => Grants.params === null ? filterButtons({hot:true}) : null}><Trans>Горячие</Trans></button></li>
            {
              Grants.form &&  <li>
                <Button onClick={()=>{resetFilter()}} className={'reset-btn'}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.6 8C1.6 6.09305 2.416 4.38253 3.696 3.18762L4.92 4.43982C5.168 4.69354 5.6 4.51348 5.6 4.14519V0.634114C5.6 0.404953 5.424 0.224898 5.2 0.224898H1.768C1.408 0.224898 1.232 0.666851 1.488 0.920565L2.56 2.02545C0.992 3.51499 0 5.63473 0 8C0 11.8876 2.656 15.1449 6.208 15.9797C6.712 16.0943 7.2 15.7096 7.2 15.1776C7.2 14.793 6.936 14.4656 6.568 14.3756C3.728 13.7127 1.6 11.11 1.6 8ZM16 8C16 4.11245 13.344 0.85509 9.792 0.0202898C9.288 -0.0942906 8.8 0.290372 8.8 0.822353C8.8 1.20702 9.064 1.53439 9.432 1.62442C12.272 2.28735 14.4 4.88996 14.4 8C14.4 9.90695 13.584 11.6175 12.304 12.8124L11.08 11.5602C10.832 11.3065 10.4 11.4865 10.4 11.8548V15.3659C10.4 15.595 10.576 15.7751 10.8 15.7751H14.232C14.592 15.7751 14.768 15.3331 14.512 15.0794L13.44 13.9746C15.008 12.485 16 10.3653 16 8Z" fill="#00264D"/>
                  </svg>
                  <Trans>Сбросить</Trans>
                </Button>
              </li>
            }

          </ul>
          <ul>
            <li>
              <button onClick={()=>Modals.show('filterPopup')} className={'d-flex align-center filter-btn'}>
                <Trans>Фильтр</Trans>
                <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.12274 3.6001C6.8563 4.6352 5.91668 5.40006 4.79839 5.40006C3.47292 5.40006 2.39844 4.32558 2.39844 3.00011C2.39844 1.67464 3.47292 0.600159 4.79839 0.600159C5.91668 0.600159 6.8563 1.36502 7.12274 2.40012H23.3984C23.7296 2.40012 23.9984 2.66871 23.9984 3.00011C23.9984 3.33151 23.7296 3.6001 23.3984 3.6001H7.12274ZM5.99836 3.00011C5.99836 3.66291 5.46106 4.20009 4.79839 4.20009C4.13572 4.20009 3.59841 3.66278 3.59841 3.00011C3.59841 2.33744 4.13572 1.80013 4.79839 1.80013C5.46106 1.80013 5.99836 2.33731 5.99836 3.00011Z" fill="white"/>
                  <path d="M0.599987 3.60001H2.47555C2.42616 3.40829 2.39995 3.2072 2.39995 3.00003C2.39995 2.79285 2.42616 2.59176 2.47555 2.40004H0.599987C0.268589 2.40004 0 2.66863 0 3.00003C0 3.33142 0.268589 3.60001 0.599987 3.60001Z" fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.3243 14.4001C14.0579 13.365 13.1182 12.6002 12 12.6002C10.8818 12.6002 9.94196 13.365 9.67565 14.4001H0.599987C0.268589 14.4001 0 14.6688 0 15.0001C0 15.3314 0.268589 15.6001 0.599987 15.6001H9.67552C9.94196 16.6352 10.8817 17.4001 11.9999 17.4001C13.118 17.4001 14.0579 16.6352 14.3242 15.6001H23.3998C23.731 15.6001 23.9997 15.3314 23.9997 15.0001C23.9997 14.6688 23.731 14.4001 23.3998 14.4001H14.3243ZM12 16.2001C12.6628 16.2001 13.2 15.6629 13.2 15.0001C13.2 14.3373 12.6628 13.8001 12 13.8001C11.3372 13.8001 10.8 14.3373 10.8 15.0001C10.8 15.6629 11.3372 16.2001 12 16.2001Z" fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M21.5243 8.40009C21.2579 7.36499 20.3181 6.60013 19.2 6.60013C18.0818 6.60013 17.1419 7.36499 16.8756 8.40009H0.599987C0.268589 8.40009 0 8.66881 0 9.00008C0 9.33135 0.268589 9.60007 0.599987 9.60007H16.8756C17.1421 10.6352 18.0818 11.4 19.2 11.4C20.3181 11.4 21.258 10.6352 21.5243 9.60007H23.3999C23.7312 9.60007 23.9999 9.33135 23.9999 9.00008C23.9999 8.66881 23.7312 8.40009 23.3999 8.40009H21.5243ZM19.2 10.2001C19.8628 10.2001 20.3999 9.66288 20.3999 9.00008C20.3999 8.33728 19.8626 7.8001 19.2 7.8001C18.5372 7.8001 18 8.33728 18 9.00008C18 9.66288 18.5372 10.2001 19.2 10.2001Z" fill="white"/>
                </svg>
              </button>
            </li>
          </ul>
        </div>
        <InfiniteScroll
          dataLength={Grants.list.results.length}
          next={fetchData}
          hasMore={Grants.list.count > Grants.list.results.length}
          pullDownToRefreshThreshold={300}
          style={{overflowAnchor: 'auto', overflow: 'inherit'}}
          useWindow={true}
        >
          <Spin spinning={Grants.loading} size={'large'}>
            <Row gutter={isMobile ? [24, 16] : [24,40]}>
              {
                Grants.list.results.map((item, index) => {
                  return  <Col key={index} span={isMobile ? 24 : 8}>
                    <NavLink to={`/${i18n.language}/grants/${item.id}`} key={index} className={'grant'}>
                      <div className={'img'}>
                        <img src={item.preview} alt={''}/>
                      </div>
                      <span>{formatDateClient(item.deadline)}</span>
                      {
                        item[`address_${currentLang()}`] && <span>{truncateString(item[`address_${currentLang()}`], 20)}</span>
                      }
                      {
                        item.program[`name_${currentLang()}`] && <span>{truncateString(item.program[`name_${currentLang()}`], 20)}</span>
                      }
                      {
                        item.graduation_level[`name_${currentLang()}`] && <span>{item.graduation_level[`name_${currentLang()}`]}</span>
                      }
                      <p>{truncateString(item[`title_${currentLang()}`], 60)}</p>
                    </NavLink>
                  </Col>
                })
              }
            </Row>
          </Spin>
        </InfiniteScroll>
      </div>
    </>
  )
};

export default observer(Grants)