import moment from 'moment';
import i18n from "./i18n";
export const formatDateNewsClient = (date) => {
  return moment(date).format('HH:MM ')
};
export const formatDate = (date) => {
  return moment(date).format('DD.MM.YYYY HH:MM ')
};
export const formatDateClient = (date) => {
  return moment(date).format('DD.MM.YYYY')
};
export const formatDateMonth = (date) => {
  return moment(date).format('DD MMM YYYY')
};
export const formatDateServer = (date) => {
  return moment(date).format('YYYY-MM-DD')
};

export const formatNum = (num) => {
  const format =  i18n.language === 'ru' ? 'тыс' : i18n.language === 'uz' ? 'минг' : i18n.language === 'en' ? 'K' : i18n.language === 'oz' ? 'ming' : i18n.language === 'kp' && 'мың';
  if(num > 1000 && num < 9999){
    return num.toString().substring(0, num.toString().length - 2).split('').join('.') + format
  }
  else if(num > 10000) {
    return num.toString().substring(0, num.toString().length - 3) + format
  }
  return num
};

export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if(str){
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }
  // Return str truncated with '...' concatenated to the end of str.

};
export const dateToFromNowDaily = ( myDate ) => {

  // get from-now for this date
  const fromNow = moment(myDate).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment( myDate ).calendar( null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay:  '[Yesterday]',
    sameDay:  '[Today]',
    nextDay:  '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    }
  });
}
export const currentLang = (lang = i18n.language) => (lang === 'oz' ? 'uz' : lang === 'uz' ? 'oz' : lang);
export const baseUrl = process.env.NODE_ENV === 'development' ? `https://yoshlarportali.uz` : '';
