import React, { useEffect } from 'react';
import { Button, Form, Input, Select } from "antd";
import InputMask from "react-input-mask";
import { useStores } from "../../store";
import { Trans, useTranslation } from "react-i18next";

const MemberForm = (props) => {
  const { profile } = useStores();
  const {t} = useTranslation()
  const { onFinish, handleClickAway, showPopup, loading, classes } = props;
  const { Option } = Select;
  const { TextArea } = Input;
  useEffect(() => {
    profile.getProfile();
  }, [profile])
  const validatePhone = (rule, value, callback) => {
    if (value.match(/\d/g).length < 12) {
      callback(t("Введите корректный номер телефона!"));
    } else {
      callback();
    }
  };
  return (
    <>
      <div className={showPopup ? 'popup-member show' : 'popup-member'}>
        <div className={'overlay'} onClick={handleClickAway}/>
        <div className={`popup-content ${classes}`}>
          <div className={'popup-header'}>
            <h3><Trans>Стать участником</Trans></h3>
            <div className={'close-popup'} onClick={handleClickAway}/>
          </div>
          <Form
            layout={'vertical'}
            onFinish={onFinish}
            fields={profile.profileValues}
          >
            <Form.Item
              label={t('Введите имя')}
              name={'first_name'}
              rules={[{ required: true, message: t('Введите имя') }]}
            >
              <Input placeholeder={'Имя'}/>
            </Form.Item>
            <Form.Item
              label={t('Введите фамилию')}
              name={'last_name'}
              rules={[{ required: true, message: t('Введите фамилию') }]}
            >
              <Input placeholeder={'Фамилия'}/>
            </Form.Item>
            <Form.Item
              label={t('Введите отчество')}
              name={'patronymic'}
              rules={[{ required: true, message: t('Введите отчество') }]}
            >
              <Input placeholeder={'Отчество'}/>
            </Form.Item>
            <Form.Item
              label={t('Статус')}
              name={'status'}
              rules={[{ required: true, message: t('Введите отчество') }]}
            >
              <Select placeholder={t('Статус')}>
                <Option value={'student'}>Студент</Option>
                <Option value={'schoolkid'}>Школьник</Option>
                <Option value={'employed'}>Работающий</Option>
                <Option value={'unemployed'}>Безработный</Option>
                <Option value={'preschooler'}>Дошкольник</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={t('Введите номер телефона')}
              name="phone"
              rules={[{ required: true, message: t('Введите корректный номер телефона') },
                {
                  validator: validatePhone,
                }]}
            >
              <InputMask className={'ant-input'} mask="+\9\98 99 999 99 99" value="+998" name="phone"
                         placeholder="+998"/>
            </Form.Item>

            <Form.Item
              label={t('Описание')}
              name={'description'}
              rules={[{ required: true, message: t('Введите отчество') }]}
            >
              <TextArea placeholeder={t('Описание')}/>
            </Form.Item>
            <Form.Item>
              <Button type={'primary'} loading={loading} className={'btn primary'}
                      htmlType={'submit'}><Trans>Отправить</Trans></Button>
            </Form.Item>
          </Form>
        </div>
      </div>

    </>
  )
};

export default MemberForm