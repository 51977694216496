import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import banner from "../../assets/achiv.jpg";
import { Spin } from "antd";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import ClickAwayListener from "react-click-away-listener";
import { currentLang } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
const Achievements = () => {
  const {achievements} = useStores();
  const {i18n} = useTranslation();
  const [filter, setFilter] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  useEffect(()=>{
    achievements.getCategory();
    achievements.getPerson();
    return () => {
      achievements.reset()
    }
  }, [achievements]);
  const setCurrentCategory = (id) => {
    achievements.setCurrentCategory(id);
    achievements.getPerson()
    setFilter(false)
  }
  const fetchData = async () => {
    await achievements.setPagination()
    achievements.getPerson()
  }
  return (
    <>
      <Helmet>
        <title>Yoshlar Portali - Доска почета</title>
        <meta property="og:type" content={'website'} />
        <meta property="og:title" content={`${achievements.person.result && achievements.person.result.last_name} ${achievements.person.result && achievements.person.result.name}`} />
        <meta property="og:image" content={achievements.person.result && achievements.person.result.image} />
      </Helmet>
      {
        isMobile && <MobileNavigation title={<Trans>Доска почета</Trans>}/>
      }
      <div className={'banner  animate__animated animate__fadeIn'}>
        <img src={banner} alt=""/>
        <div className={'center-content'}>
          <h1><Trans>Доска почета</Trans></h1>
          <p><Trans>В доске почёта приводятся примеры историй успеха наших молодых предпринимателей, общественных деятелей, учёных и спортсменов, которые вносят вклад в развитие нашего общества</Trans></p>
        </div>
      </div>
      <div className={'center-content achievements-box'}>

        <div className={'header-title'}>
          <h1><Trans>Доска почета</Trans></h1>
        </div>
        <div className={'box-half'}>
          <ClickAwayListener onClickAway={()=>setFilter(false)}>
            <div>
              {
                isMobile && <strong onClick={()=>setFilter(!filter)} className={isMobile ? 'mobile-filter' : ''}>
                  <Trans>Рубрики</Trans>
                  {
                    isMobile && <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.6663 1L5.99967 5.66667L1.33301 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  }
                </strong>
              }
              <div className={'category-news'}>
                <strong ><Trans>Рубрики</Trans></strong>
                <ul className={(isMobile && filter) ?  'show' : null}>
                  <li >
                    <button className={null === achievements.currentCategory ? 'active' : ''} onClick={()=>setCurrentCategory(null)}><Trans>Все</Trans></button></li>
                  {
                    achievements.category.map((item, index) => {
                      return item && <li key={index}><button className={achievements.currentCategory && achievements.currentCategory.category === item.id ? 'active' : ''} onClick={()=>setCurrentCategory(item.id)}>{item[`name_${currentLang()}`]}</button></li>
                    })
                  }
                </ul>
              </div>
            </div>
          </ClickAwayListener>
          <InfiniteScroll
            dataLength={achievements.person.results.length}
            next={fetchData}
            hasMore={achievements.person.count > achievements.person.results.length}
            pullDownToRefreshThreshold={300}
            style={{overflowAnchor: 'auto', overflow: 'inherit'}}
            useWindow={true}
          >
            <Spin spinning={achievements.loading} size={'large'}>
              <div className={'achievements-items'}>
                {
                  achievements.person.results.map((item) => {
                    return <NavLink key={item.id} to={`/${i18n.language}/achievements/${item.id}`} className={`achievements-item ${isMobile && 'active'} animate__animated animate__fadeIn`}>
                        <div className={'img'}>
                          <img className={'animate__animated animate__flipInY'} onLoad={achievements.loaded()} src={item.image} alt={''}/>
                        </div>
                        <div className={'description'}>
                          <strong>{item[`last_name_${currentLang()}`]} {item[`name_${currentLang()}`]}</strong>
                          {item.category && <p>{item.category[`name_${currentLang()}`]}</p>}
                        </div>
                      </NavLink>
                  })
                }
              </div>
            </Spin>
          </InfiniteScroll>

        </div>
      </div>
    </>
  )
};

export default observer(Achievements)