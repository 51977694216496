import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import success from '../../assets/success.svg';
import { useStores } from "../../store";

export const SuccessModal = () => {
  const {Modals} = useStores();
  return (
    <div>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      <img tabIndex="1" src={success} alt={''}/>
      <h3><Trans>Поздравляем</Trans>!</h3>
      <p><Trans>Ваша заявка успешно отправлена</Trans></p>
    </div>
  )
};


export const SuccessModalForm = () => {
  const {Modals} = useStores();
  return (
    <div style={{textAlign:'center'}}>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      <img tabIndex="1" src={success} alt={''}/>
      <h3><Trans>Поздравляем</Trans>!</h3>
      <p><Trans>Ваша заявка отправлена в районную (городскую) рабочую группу</Trans></p>
      <p><Trans>Для получения информации о статусе заявки обращайтесь в районные (городские) отделы Агентства по делам молодежи</Trans></p>
    </div>
  )
};
