import { makeObservable, observable, flow, action, computed, toJS } from "mobx";
import api from '../../api';
import profile from './profile'
class Contests {
  @observable error = false;
  @observable loading = true;
  @observable sportList = [];
  @observable start = 1;
  @observable list = {
    count:0,
    results:[]
  };
  @observable direction = {
    results:[]
  };
  @observable params = null;
  @observable form = null;
  @observable forms = {
    fields:[]
  };
  @observable paginations = {
    limit:20,
    offset:0
  }
  @observable contestsDetail = {
    object:null,
    similar:[],
    next:null,
    prev:null
  }

  constructor() {
    makeObservable(this)
  }
  @action setFromValue = (values) => {
    this.start = 1;
    this.list.results = [];
    this.paginations = {limit:20, offset: 0};
    this.form = values;
  }
  @action buttonsValue = (values) => {
    this.loading = true
    this.start = 1;
    this.list.results = [];
    this.paginations = {limit:20, offset: 0};
    this.params = values;
  }
  @action reset = () => {
    this.list.results = [];
    this.start = 1;
    this.paginations = {limit:20, offset: 0};
    this.params = null;
    this.loading = true
  }
  @action setPagination = () => {
    this.paginations.offset = this.start * 20;
  }
  @flow *
  getData(){
    const response = yield api.contests.list({...this.paginations, ...this.form, ...!this.params ? {new:true} : this.params});
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.count = response.data.count;
        this.list.results = [...this.list.results, ...response.data.results];
        this.start++;
      }else{
        this.list = response.data;
      }
      this.loading = false;
    }
  }
  @flow *
  detail(id){
    const response = yield api.contests.detail(id);
    if(!response.detail){
      this.contestsDetail = response.data;
      this.contestsDetail['is_form'] = true;
      this.loading = false;
    }else{
      this.error = true;
      this.loading = false;
    }
  }
  @flow *
  getDirection(){
    const response = yield api.contests.direction();
    if(!response.detail){
      this.direction = response.data
    }
  }
  @flow * membership(values){
    yield api.contests.membership(values);
  }
  @flow * form1(values){
    const response = yield api.contests.form1(values);
    if(response.status === 201){
      return 201;
    }
  }
  @flow * form2(values){
    const response = yield api.contests.form2(values);
    if(response.detail){
      return 201;
    }
  }
  @flow * getSportList(values){
    const response = yield api.contests.sportList(values);
    if(!response.detail){
      this.sportList = response.data;
    }
  }
  @flow * sendForm(values){
    let form = new FormData();
    for(let type in values){
      form.append(type, values[type])
    }
    const response = yield api.contests.sendForm(form);
    if(!response.detail){

    }

  }
  @flow *
  getForm(values){
    const response = yield api.contests.getForm(values);
    if(!response.detail){
      this.forms = response.data;
    }
  }
  @computed get getValues(){
    const person = toJS(profile.profile);
    person.full_name = person.first_name+' '+person.last_name;
    person.age = Math.floor((new Date() - new Date(person.birth_date).getTime()) / 3.15576e+10);
    return Object.keys(person).map((key) => ({
      name: key,
      value: person[key]
    }))
  }
};

export default new Contests()