import React, { useEffect, useState } from 'react';
import { Image, Spin } from "antd";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { Trans, useTranslation } from "react-i18next";
import {
  baseUrl, currentLang,
  formatDateClient,
  formatNum,
} from "../../config";
import { observer } from "mobx-react";
import MapDetail from "../../components/map";
import E404 from "../../components/e404/404";
import MobileNavigation from "../../components/navigation";
import { useMediaQuery } from "react-responsive";
import DetailForm from "../../components/form";
import FormButton from "../../components/form/button";
import FilesDownload from "../../components/filesUpload";

const ContestsDetail = () => {
  const { id } = useParams();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const { Contests} = useStores();
  const {i18n} = useTranslation()
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    Contests.detail(id);
    return () => {
      Contests.reset()
    }
  }, [Contests, id]);

  if(Contests.error){
    return <E404/>
  }
  return (
    <>

      {
        Contests.contestsDetail.object ?
          <>
            <Helmet>
              <title>{Contests.contestsDetail.object[`title_${currentLang()}`]}</title>
              <meta property="og:url" content={baseUrl} />
              <meta property="og:type" content={'website'} />
              <meta property="og:title" content={Contests.contestsDetail.object[`title_${currentLang()}`]} />
              <meta property="og:image" content={Contests.contestsDetail.object.image} />
            </Helmet>
            {
              isMobile && <MobileNavigation title={<Trans>Конкурсы</Trans>}/>
            }
            <div className={'center-content'}>
              <div className={'article-detail'}>
                <div className={'detail-content'}>
                  <div className={'header'}>
                    <h1>{Contests.contestsDetail.object[`title_${currentLang()}`]}</h1>
                    <div className={'d-flex'}>
                      <span className={'date'}>{formatDateClient(Contests.contestsDetail.object.created_at)}</span>
                      <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                          stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                          d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                          stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {formatNum(Contests.contestsDetail.object.views_count)}
                      </span>
                    </div>
                  </div>
                  <Image src={Contests.contestsDetail.object.image}/>
                  <div className={'text'} dangerouslySetInnerHTML={{__html:Contests.contestsDetail.object[`content_${currentLang()}`]}}/>
                  <FilesDownload files={[{title:Contests.contestsDetail.object[`file_title1_${currentLang()}`],file:Contests.contestsDetail.object.file1},{title:Contests.contestsDetail.object[`file_title2_${currentLang()}`],file:Contests.contestsDetail.object.file2},{title:Contests.contestsDetail.object[`file_title3_${currentLang()}`],file:Contests.contestsDetail.object.file3}]}/>
                  <MapDetail title={Contests.contestsDetail.object[`title_${currentLang()}`]} longitude={Contests.contestsDetail.object.longitude} latitude={Contests.contestsDetail.object.latitude}>

                    {
                      Contests.contestsDetail.object.has_form && <FormButton id={id} setVisible={setVisible} store={Contests}/>
                    }

                  </MapDetail>
                  {
                    visible &&  <DetailForm setVisible={setVisible} store={Contests} id={Contests.forms.form} forms={Contests.forms}/>
                  }

                </div>
                <div className={'popular'}>
                  <h3><Trans>В тему</Trans></h3>
                  {
                    Contests.contestsDetail.similar.map((item, idx)=>{
                      return <div key={idx} className={'contest-item'}>
                        <NavLink to={`/${i18n.language}/contests/${item.id}`} className={'contest-link'}>
                          <img src={item.preview} alt={''}/>
                        </NavLink>
                        <div className={'description'}>
                          <div>
                            <div className={'contest-description'}>
                              <span className={'date'}>
                                {formatDateClient(item.created_at)}
                              </span>
                              <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                                    stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                                  <path
                                    d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                                    stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                {formatNum(item.views_count)}
                              </span>
                            </div>
                            <NavLink to={`/${i18n.language}/contests/${item.id}`}>{item[`title_${currentLang()}`]}</NavLink>
                          </div>
                          <ul>
                            {
                              item.start_date && <li>{formatDateClient(item.start_date)}</li>
                            }
                            {
                              item.region[`title_${currentLang()}`] &&  <li>{item.region[`title_${currentLang()}`]}</li>
                            }
                            {
                              item.direction && <li>{item.direction[`name_${currentLang()}`]}</li>
                            }
                          </ul>
                        </div>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          </>
          : Contests.loading ? <div className={'d-flex justify-center'}><Spin spinning={true} size={'large'}/></div> : null
      }
    </>
  )
};
export default observer(ContestsDetail);