import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Empty, Form, Input, Row, Select, Slider, Spin } from "antd";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { currentLang, formatDate, formatNum, truncateString } from "../../config";
import { observer } from "mobx-react";
import MobileNavigation from "../../components/navigation";
import { useMediaQuery } from "react-responsive";
import InfiniteScroll from "react-infinite-scroll-component";
import ClickAwayListener from "react-click-away-listener";

const Vacancy = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [filter, setFilter] = useState(false);
  const { Option } = Select;
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const { t, i18n } = useTranslation()
  const { vacancy, Modals } = useStores();

  useEffect(() => {
    vacancy.getData()
    vacancy.getSchedule()
    vacancy.getEmployment()
    forceUpdate({});
  }, [vacancy]);
  const getSpheres = async () => {
    await vacancy.getSpheres()
  }
  const onFinish = async (values) => {
    await vacancy.setForm(values)
    vacancy.getData()
  }
  const getVal = async () => {
    await vacancy.setForm(form.getFieldValue())
    vacancy.getData()
  }
  const fetchData = () => {
    vacancy.setPagination()
  }
  const resetForm = async () => {
    form.resetFields();
    await vacancy.reset()
    vacancy.getData()
  }
  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>Вакансии для молодёжи</Trans>}/>
      }
      <div className={'banner vacancy  animate__animated animate__fadeIn'} style={{background: 'linear-gradient(96.79deg, #280684 -46.17%, #21BDB8 95.78%)'}}>
        <div className={'center-content'}>
          <h1><Trans>Вакансии для молодёжи</Trans></h1>
          <p><Trans>Компании и организации нуждаются в молодой крови, которая полна идей, сил и готова выполнять задачи в установленные сроки, а молодёжь нуждается в опыте, совершенстве навыков, карьерном росте и самореализации</Trans></p>
          <Form onFinish={onFinish}>
            <Button className={'loc'} onClick={()=>Modals.show('vacancy')}>
              {vacancy.form.title ? vacancy.form.title : <Trans>Выбрать регион</Trans>}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#00264D"/>
              </svg>
            </Button>
            <div className={'form'}>
              <Form.Item  name={'search_text'}>
                <Input placeholder={t('Профессия, должность или компания')}/>
              </Form.Item>
              <Button htmlType={'submit'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className={'center-content  animate__animated animate__fadeIn'}>
        <Helmet>
          <title>Yoshlar Portali - Вакансии</title>
        </Helmet>
        <div className={'vacancy-box'}>
          <div className={'vacancy-filter'}>
            {
              isMobile && <div className={'location-form'}>
                <Form  onFinish={onFinish}>
                <Button className={'loc'} onClick={()=>Modals.show('vacancy')}>
                  {vacancy.form.title ? vacancy.form.title : <Trans>Выберите регион</Trans>}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#00264D"/>
                  </svg>
                </Button>
                <div className={'form'}>
                  <Form.Item name={'search_text'}>
                    <Input  placeholder={'Профессия, должность или компания'}/>
                  </Form.Item>
                  <Button>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </Button>
                </div>
              </Form></div>
            }
            <div className={'buttons'}>
              <Button><Trans>Профессии</Trans></Button>
              {/*<Button>Компании</Button>*/}
            </div>
            {
              !isMobile ?
                <>
                  <strong><Trans>Фильтр</Trans></strong>
                  <Form layout={'vertical'} form={form}>
                    <Form.Item name={'sphere'} label={t('Сфера')}>
                      <Select placeholder={t('Выберите сферу')} onChange={getVal} onDropdownVisibleChange={(open)=>open && getSpheres()}>
                        {
                          vacancy.sphere.map((item, index) => {
                            return <Option key={index} value={item.id}>{item[`name_${currentLang()}`]}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item name={'salary'} label={t('Зарплата')}>
                      <Slider  onAfterChange={getVal} range min={500000} max={20000000} step={500000} defaultValue={[6000000, 15000000]} />
                    </Form.Item>
                    <Form.Item name={'years'} label={t('Опыт работы')}>
                      <Slider  onAfterChange={getVal} range min={0} max={10}/>
                    </Form.Item>
                    <Form.Item name={'employment_type'} label={t('Тип занятости')}>
                      <Checkbox.Group
                        options={vacancy.employment}
                        onChange={getVal}
                      />
                    </Form.Item>
                    <Form.Item name={'work_schedule'} label={t('График работы')}>
                      <Checkbox.Group
                        options={vacancy.schedule}
                        onChange={getVal}
                      />
                    </Form.Item>
                    <Button onClick={()=>{resetForm()}} className={'reset-btn'}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.6 8C1.6 6.09305 2.416 4.38253 3.696 3.18762L4.92 4.43982C5.168 4.69354 5.6 4.51348 5.6 4.14519V0.634114C5.6 0.404953 5.424 0.224898 5.2 0.224898H1.768C1.408 0.224898 1.232 0.666851 1.488 0.920565L2.56 2.02545C0.992 3.51499 0 5.63473 0 8C0 11.8876 2.656 15.1449 6.208 15.9797C6.712 16.0943 7.2 15.7096 7.2 15.1776C7.2 14.793 6.936 14.4656 6.568 14.3756C3.728 13.7127 1.6 11.11 1.6 8ZM16 8C16 4.11245 13.344 0.85509 9.792 0.0202898C9.288 -0.0942906 8.8 0.290372 8.8 0.822353C8.8 1.20702 9.064 1.53439 9.432 1.62442C12.272 2.28735 14.4 4.88996 14.4 8C14.4 9.90695 13.584 11.6175 12.304 12.8124L11.08 11.5602C10.832 11.3065 10.4 11.4865 10.4 11.8548V15.3659C10.4 15.595 10.576 15.7751 10.8 15.7751H14.232C14.592 15.7751 14.768 15.3331 14.512 15.0794L13.44 13.9746C15.008 12.485 16 10.3653 16 8Z" fill="#00264D"/>
                      </svg>
                      <Trans>Сбросить</Trans>
                    </Button>
                  </Form>
                </>
                :
                <div className={'mobile-filter'}>
                  <ClickAwayListener onClickAway={()=>setFilter(false)}>
                    <div>
                      <strong onClick={()=>setFilter(!filter)} className={'filter-btn-mobile'}>
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0.5 1C0.5 0.734784 0.605357 0.48043 0.792893 0.292893C0.98043 0.105357 1.23478 0 1.5 0H13.5C13.7652 0 14.0196 0.105357 14.2071 0.292893C14.3946 0.48043 14.5 0.734784 14.5 1V4C14.4999 4.26519 14.3946 4.51951 14.207 4.707L9.5 9.414V13C9.49994 13.2652 9.39455 13.5195 9.207 13.707L7.207 15.707C7.06715 15.8468 6.88898 15.942 6.69503 15.9806C6.50108 16.0192 6.30005 15.9993 6.11735 15.9237C5.93465 15.848 5.77848 15.7199 5.6686 15.5555C5.55871 15.391 5.50004 15.1978 5.5 15V9.414L0.793 4.707C0.605451 4.51951 0.500057 4.26519 0.5 4V1Z" fill="white"/>
                        </svg>
                        <Trans>Фильтр</Trans>
                      </strong>
                      <Form layout={'vertical'} className={`mobile-form ${filter ? 'show' : ''}`} form={form}>
                        <Form.Item name={'sphere'} label={t('Сфера')}>
                          <Select placeholder={t('Выберите сферу')} onChange={getVal} onDropdownVisibleChange={(open)=>open && getSpheres()}>
                            {
                              vacancy.sphere.map((item, index) => {
                                return <Option key={index} value={item.id}>{item[`name_${currentLang()}`]}</Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                        <Form.Item name={'salary'} label={t('Зарплата')}>
                          <Slider  onChange={getVal} range min={500000} max={20000000} step={500000} defaultValue={[6000000, 15000000]} />
                        </Form.Item>
                        <Form.Item name={'years'} label={t('Опыт работы')}>
                          <Slider  onChange={getVal} range min={0} max={10}/>
                        </Form.Item>
                        <Form.Item name={'employmenttype'} label={t('Тип занятости')}>
                          <Checkbox.Group
                            options={vacancy.employment}
                            onChange={getVal}
                          />
                        </Form.Item>
                        <Form.Item name={'workschedule'} label={t('График работы')}>
                          <Checkbox.Group
                            options={vacancy.schedule}
                            onChange={getVal}
                          />
                        </Form.Item>
                        <Button onClick={()=>{form.resetFields(); vacancy.getData(form.getFieldValue())}} className={'reset-btn'}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.6 8C1.6 6.09305 2.416 4.38253 3.696 3.18762L4.92 4.43982C5.168 4.69354 5.6 4.51348 5.6 4.14519V0.634114C5.6 0.404953 5.424 0.224898 5.2 0.224898H1.768C1.408 0.224898 1.232 0.666851 1.488 0.920565L2.56 2.02545C0.992 3.51499 0 5.63473 0 8C0 11.8876 2.656 15.1449 6.208 15.9797C6.712 16.0943 7.2 15.7096 7.2 15.1776C7.2 14.793 6.936 14.4656 6.568 14.3756C3.728 13.7127 1.6 11.11 1.6 8ZM16 8C16 4.11245 13.344 0.85509 9.792 0.0202898C9.288 -0.0942906 8.8 0.290372 8.8 0.822353C8.8 1.20702 9.064 1.53439 9.432 1.62442C12.272 2.28735 14.4 4.88996 14.4 8C14.4 9.90695 13.584 11.6175 12.304 12.8124L11.08 11.5602C10.832 11.3065 10.4 11.4865 10.4 11.8548V15.3659C10.4 15.595 10.576 15.7751 10.8 15.7751H14.232C14.592 15.7751 14.768 15.3331 14.512 15.0794L13.44 13.9746C15.008 12.485 16 10.3653 16 8Z" fill="#00264D"/>
                          </svg>
                          <Trans>Сбросить</Trans>
                        </Button>
                      </Form>
                    </div>
                  </ClickAwayListener>
                </div>
            }

          </div>
          <div className={'content'}>
            <InfiniteScroll
              dataLength={vacancy.list.results.length}
              next={fetchData}
              hasMore={vacancy.list.count > vacancy.list.results.length}
              pullDownToRefreshThreshold={300}
              style={{overflowAnchor: 'auto', overflow: 'inherit'}}
              useWindow={true}
            >
              <Spin spinning={vacancy.loading}>
                {
                  (vacancy.list.results.length === 0 && !vacancy.loading) ?
                    <Empty
                      style={{ marginLeft: 'auto', marginRight: 'auto', }}
                      description={<span><Trans>Тут пока пусто</Trans> </span>}
                    /> :
                    <Row gutter={isMobile ? [0, 16] : [32, 32]}>
                      {
                        vacancy.list && vacancy.list.results.map((item, idx) => {
                          return <Col span={isMobile ? 24 : 12} key={idx}>
                            <div className={'vacancy-item'}>
                              <NavLink to={`/${i18n.language}/vacancy/${item.id}`}>
                                <strong>{item[`vacancy_name_${currentLang()}`]}</strong>
                                <span className={'salary'}>{item.salary_from} – {item.salary_to} сум</span>
                                <strong>{item.organization.name}</strong>
                                <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(item[`description_${currentLang()}`].replace(/<[^>]+>/g, ''), 130)}}/>
                                <div className={'d-flex address justify-spacebetween'}>
                                  <div className={'location d-flex align-center'}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M14.7148 13.8806L11.179 17.4164C11.0244 17.5712 10.8407 17.694 10.6386 17.7778C10.4365 17.8616 10.2199 17.9047 10.0011 17.9047C9.78228 17.9047 9.56564 17.8616 9.36352 17.7778C9.16141 17.694 8.97779 17.5712 8.82315 17.4164L5.28649 13.8806C4.35417 12.9482 3.71927 11.7603 3.46206 10.4671C3.20485 9.17395 3.3369 7.83352 3.84149 6.61536C4.34608 5.39721 5.20057 4.35604 6.29689 3.62351C7.39321 2.89098 8.68213 2.5 10.0007 2.5C11.3192 2.5 12.6081 2.89098 13.7044 3.62351C14.8007 4.35604 15.6552 5.39721 16.1598 6.61536C16.6644 7.83352 16.7965 9.17395 16.5392 10.4671C16.282 11.7603 15.6471 12.9482 14.7148 13.8806V13.8806Z"
                                        stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path
                                        d="M11.7678 10.9348C12.2366 10.4659 12.5 9.83003 12.5 9.16699C12.5 8.50395 12.2366 7.86807 11.7678 7.39922C11.2989 6.93038 10.663 6.66699 10 6.66699C9.33696 6.66699 8.70107 6.93038 8.23223 7.39922C7.76339 7.86807 7.5 8.50395 7.5 9.16699C7.5 9.83003 7.76339 10.4659 8.23223 10.9348C8.70107 11.4036 9.33696 11.667 10 11.667C10.663 11.667 11.2989 11.4036 11.7678 10.9348Z"
                                        stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <span>{item.region[`title_${i18n.language}`] && item.region[`title_${i18n.language}`]}</span>
                                  </div>
                                  <div className={'d-flex align-center'}>
                            <span className={'d-flex align-center date'}>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M4.66667 3.83333V0.5M11.3333 3.83333V0.5M3.83333 7.16667H12.1667M2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V3.83333C15.5 3.39131 15.3244 2.96738 15.0118 2.65482C14.6993 2.34226 14.2754 2.16667 13.8333 2.16667H2.16667C1.72464 2.16667 1.30072 2.34226 0.988155 2.65482C0.675595 2.96738 0.5 3.39131 0.5 3.83333V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5Z"
                                stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                              {formatDate(item.created_at)}
                            </span>
                                    <span className={'d-flex align-center'}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                                  stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                  d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                                  stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                      {formatNum(item.views_count)}
                             </span>
                                  </div>

                                </div>
                              </NavLink>
                            </div>
                          </Col>
                        })
                      }

                    </Row>
                }
              </Spin>


            </InfiniteScroll>

          </div>
        </div>
      </div>
    </>

  )
};
export default observer(Vacancy)