import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { useStores } from "../../store";

function ScrollToTop({ history, children }) {
  const {Modals} = useStores()
  useEffect(() => {

    const unlisten = history.listen(() => {
      Modals.hide();
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history, Modals]);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);