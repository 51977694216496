import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import Aside from "../../components/aside";
import MobileNavigation from "../../components/navigation";
import { Trans } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useStores } from "../../store";
import { useLocation } from "react-router-dom";

const MainProfile = ({children}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const { profile,appeals } = useStores();
  const location = useLocation();
  useEffect(() => {
    // profile.getProfile();
    if(!profile.profile.role){
      appeals.getStatistic();
    }
    return () => {
      appeals.reset()
    }

  }, [profile, appeals]);
  const path = ['project', 'event', 'course', 'grant', 'contest'].find(item => location.pathname.includes(item));

  return (
   <>
     {
       isMobile && <MobileNavigation title={<Trans>Личный кабинет</Trans>}/>
     }
     <div className={'center-content'}>
       <div className={' profile-box'} style={{display:!path ? 'grid' : 'block'}}>
         {
           !path && <Aside executor={!!profile.profile.role}/>
         }

         <div className={'profile-content profile executor  animate__animated animate__fadeIn'}>
           {children}
         </div>
       </div>

     </div>
   </>
  )
};

export default observer(MainProfile)