import React, { useEffect } from 'react';
import { useStores } from "../../store";
import { Trans } from "react-i18next";
import { observer } from "mobx-react";
import { Empty} from "antd";


const PolicyModal = () => {
  const {app, Modals} = useStores();
  useEffect(()=>{
    app.getPolicy()
    return () => {
      app.reset()
    }
  },[app])
  const closeModal = () => {
    Modals.hide()
  }
  return(
    <>
      <h3 className={'reg'}><Trans>Политика конфиденциальности</Trans></h3>
      <div className={'close-popup'}  tabIndex="1" onClick={()=>closeModal()}/>
      <div className={'rule-content'}>
        {
          app.rules ?  <div className={'text'} dangerouslySetInnerHTML={{__html:app.rules}}/> : <Empty description={<Trans>Тут пока пусто</Trans>}/>
        }
      </div>
    </>
  )
};

export default observer(PolicyModal)