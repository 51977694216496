import { makeObservable, action, observable } from "mobx";
class Indices {
  @observable loading = false;
  @observable list = [
    {
      title_oz:'Turizm va sport vazirligi',
      title_ru:'Министерства туризма и спорта',
      title_uz:'Туризм ва спорт вазирлиги',
      title_en:'Министерства туризма и спорта',
      active:true,
      comp:'turizm',
      height: 1087,
      frame_ru:'https://bi.tashkent.uz/viewer/public?dashboardGuid=a2ce30f55ca342a5a2e8539c79cc57d0&sheetGuid=57befdf9d19e46889d0ba1155be6186d&fit=true',
      frame_en:'https://bi.tashkent.uz/viewer/public?dashboardGuid=83603765783a41c8857c45d3eba6bd48&sheetGuid=57befdf9d19e46889d0ba1155be6186d&fit=true',
      frame_uz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=02806a365e6d4d42824a0ef668072b8c&sheetGuid=57befdf9d19e46889d0ba1155be6186d&fit=true',
      frame_oz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=d044227e22b14afe8e9c6ad624f882e4&sheetGuid=57befdf9d19e46889d0ba1155be6186d&fit=true'
    },
    {
      title_oz:"Xalq ta’limi vazirligi",
      title_ru:"Министерства народного образования",
      title_uz:"Xалқ таълими вазирлиги",
      title_en:"Xalq ta’limi vazirligi",
      active:false,
      comp:'school',
      height: 1054,
      frame_ru:'https://bi.tashkent.uz/viewer/public?dashboardGuid=a2ce30f55ca342a5a2e8539c79cc57d0&sheetGuid=4b390164f0514945b54dd2cc3108f0c9&fit=true',
      frame_en:'https://bi.tashkent.uz/viewer/public?dashboardGuid=83603765783a41c8857c45d3eba6bd48&sheetGuid=4b390164f0514945b54dd2cc3108f0c9&fit=true',
      frame_uz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=02806a365e6d4d42824a0ef668072b8c&sheetGuid=4b390164f0514945b54dd2cc3108f0c9&fit=true',
      frame_oz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=d044227e22b14afe8e9c6ad624f882e4&sheetGuid=4b390164f0514945b54dd2cc3108f0c9&fit=true'
    },
    {
      title_ru:'Министерства высшего образования',
      title_oz:"Oliy ta'lim vazirligi",
      title_uz:"Олий таълим вазирлиги",
      title_en:'',
      active:false,
      comp:'OTV',
      height: 683,
      frame_ru:'https://bi.tashkent.uz/viewer/public?dashboardGuid=a2ce30f55ca342a5a2e8539c79cc57d0&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_en:'https://bi.tashkent.uz/viewer/public?dashboardGuid=83603765783a41c8857c45d3eba6bd48&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_uz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=02806a365e6d4d42824a0ef668072b8c&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_oz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=d044227e22b14afe8e9c6ad624f882e4&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true'
    },
    {
      title_ru:'Министерства финансов',
      title_oz:'Moliya vazirligi',
      title_uz:'Молия вазирлиги',
      title_en:'Moliya vazirligi',
      active:false,
      comp:'MV',
      height: 683,
      frame_ru:'https://bi.tashkent.uz/viewer/public?dashboardGuid=a2ce30f55ca342a5a2e8539c79cc57d0&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_en:'https://bi.tashkent.uz/viewer/public?dashboardGuid=83603765783a41c8857c45d3eba6bd48&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_uz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=02806a365e6d4d42824a0ef668072b8c&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_oz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=d044227e22b14afe8e9c6ad624f882e4&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true'
    },
    {
      title_ru:'Министерства занятости и трудовых отношений',
      title_oz:'Bandlik va mehnat munosabatlari vazirligi',
      title_uz:'Бандлик ва меҳнат муносабатлари вазирлиги ',
      title_en:'Moliya vazirligi',
      active:false,
      comp:'band',
      height: 683,
      frame_ru:'https://bi.tashkent.uz/viewer/public?dashboardGuid=a2ce30f55ca342a5a2e8539c79cc57d0&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_en:'https://bi.tashkent.uz/viewer/public?dashboardGuid=83603765783a41c8857c45d3eba6bd48&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_uz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=02806a365e6d4d42824a0ef668072b8c&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true',
      frame_oz:'https://bi.tashkent.uz/viewer/public?dashboardGuid=d044227e22b14afe8e9c6ad624f882e4&sheetGuid=f6b1ddd411d1460e85d9e49b3a556a9e&fit=true'
    }
  ];
  constructor() {
    makeObservable(this)
  }
  @action loaded = () => {
    this.loading = false;
  }
  @action changeFrame = index => {
    this.loading = false;
    this.list.forEach(item => item.active = false);
    this.list[index].active = true;
    this.loading = false;
  }
}

export default new Indices()