import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, notification } from "antd";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import InputMask from 'react-input-mask';
import CounterDown from "../../components/counter-down";
import { Trans, useTranslation } from "react-i18next";
const RecoverPassword = ({...props}) => {
  const {t, i18n} = useTranslation()
  const [error, setError] = useState({show:false, message:[]})
  const {RecoverPassword, Modals} = useStores();
  useEffect(()=>{
    RecoverPassword.reset()
  }, [RecoverPassword])
  const onFinish = async (values) => {
    setError({show:false, message:[]});
    if(RecoverPassword.step === 1 || RecoverPassword.step === 2){
      values.phone = values.phone.replace(/ /g, '');
    }
    const error = await RecoverPassword.submit(values);
    if(error){
        setError({
          show:true,
          message:error
        })
      }
    if(RecoverPassword.step === 4){
      notification.success({
        message:t('Успешно!'),
        description:t('Вы успешно изменили пароль!')
      });
      Modals.hide()
    }
  };
  const validatePhone = (rule, value, callback) => {
    if(value && value.match(/\d/g).length < 12){
      callback("Error!");
    }
    else {
      callback();
    }
  };
  return (
    <div className={`auth-box auth-wrapper`}>
      <h3><Trans>Восстановление пароля</Trans></h3>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      {
        error.show &&
          error.message.map((item, idx) => {
            return <Alert style={{marginBottom:10}} key={idx} message={item} type="error"/>
          })
      }
      {
      (RecoverPassword.step === 1 || RecoverPassword.step === 2) ?
        <Form
          layout={'vertical'}
          name="basic"
          onFinish={onFinish}
        >
          <Form.Item
            style={{marginTop:10}}
            label={<Trans>Введите номер телефона</Trans>}
            name="phone"
            rules={[{ required: true, message:<Trans>Введите номер телефона чтобы получить код активации</Trans> },
              {
                validator: validatePhone,
              }]}
          >
            <InputMask className={'ant-input'}  mask="+\9\98 99 999 99 99"  value="+998" placeholder="+998"/>
          </Form.Item>

          {
            RecoverPassword.step === 2 &&
            <>
              <Form.Item
                className={'animate__animated animate__fadeInUp'}
                style={{marginTop:15, marginBottom:5}}
                label={<Trans>Введите код</Trans>}
                name="code"
                rules={[
                  { required: true, message:<Trans>Введите код</Trans> }
                ]}
              >
                <Input placeholder={t('Введите код')}/>
              </Form.Item>
              <CounterDown/>
            </>
          }
          {
            (RecoverPassword.step === 1 && error.show && error.message[0].includes('OneID')) ?
              <a href={`/${i18n.language}/api/idgovuz-auth/authorize`} style={{marginTop:32}} className={'btn oneidBtn '}><Trans>Вход по ONE ID</Trans></a> :
              <Form.Item style={{marginTop:32}}>
                <Button loading={RecoverPassword.loading} type="primary" htmlType="submit" className={'btn success'}>
                  <Trans>Получить код активации</Trans>
                </Button>
              </Form.Item>
          }

        </Form>
       : (RecoverPassword.step === 3) &&
        <Form
          className={'animate__animated animate__bounceIn'}
          layout={'vertical'}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            style={{marginTop:10}}
            label={<Trans>Введите новый пароля</Trans>}
            name="new_password1"
            help={<Trans>Пароль должен состоять из цифр, заглавных букв и спец символа</Trans>}
            rules={[{ required: true, message: t('Введите новый пароля')}]}
          >
            <Input.Password placeholder={t('Новый пароль')}/>
          </Form.Item>
          <Form.Item
            style={{marginTop:32}}

            label={<Trans>Введите повторно новый пароль</Trans>}
            name="new_password2"
            rules={[{ required: true, message:<Trans>Введите повторно новый пароль</Trans> }]}
          >
            <Input.Password placeholder={t('Введите повторно новый пароль')}/>
          </Form.Item>

          <Form.Item style={{marginTop:32}}>
            <Button type="primary" htmlType="submit" className={'btn success'}>
              <Trans>Сменить пароль</Trans>
            </Button>
          </Form.Item>
        </Form>
      }
    </div>
  )
}

export default observer(RecoverPassword)