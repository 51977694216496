import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { NavLink, useParams } from "react-router-dom";
import { Image, Spin } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import {
  baseUrl, currentLang,
  formatDate,
  formatDateClient,
  formatNum,
  truncateString
} from "../../config";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import FormButton from "../../components/form/button";
import DetailForm from "../../components/form";
import FilesDownload from "../../components/filesUpload";

const CourDetail = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const { id } = useParams();
  const {i18n} = useTranslation()
  const { Courses } = useStores();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    Courses.detail(id);
    return () => {
      Courses.reset()
    }
  }, [Courses, id]);

  return (
    <>
      {
        Courses.course.object ?
         <>
           <Helmet>
             <title>{Courses.course.object && Courses.course.object[`title_${currentLang()}`]}</title>
             <meta property="og:url" content={baseUrl} />
             <meta property="og:type" content={'website'} />
             <meta property="og:title" content={Courses.course.object && Courses.course.object[`title_${currentLang()}`]} />
             <meta property="og:description" content={Courses.course.object && Courses.course.object[`content_${currentLang()}`]} />
             <meta property="og:image" content={`${baseUrl}${Courses.course.object && Courses.course.object.image}`} />
           </Helmet>
           {
             isMobile && <MobileNavigation title={<Trans>Учебные курсы</Trans>}/>
           }
           <div className={'center-content animate__animated animate__fadeIn course-detail-box'}>
             <div className={'article-detail'}>
               <div className={' detail-content courses'}>
                 <div className={'header'}>
                   <h1>{Courses.course.object[`title_${currentLang()}`]}</h1>
                   <ul className={'course-list'}>
                     <li>{formatDateClient(Courses.course.object.when)}</li>
                     {Courses.course.object.region &&<li>{Courses.course.object.region.title}</li>}
                     {(Courses.course.subcategory && Courses.course.subcategory[`name_${currentLang()}`]) && <li>{Courses.course.object.subcategory[`name_${currentLang()}`]}</li>}
                   </ul>
                 </div>
                 <Image
                   src={`${baseUrl}${Courses.course.object.image}`}
                 />
                 <div className={'header'}>
                   <div className={'d-flex'}>
                     <span className={'date'}>{formatDate(Courses.course.object.created_at)}</span>
                     <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                  stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                  stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                       {formatNum(Courses.course.object.views_count)}
                </span>
                   </div>
                 </div>
                 <strong><Trans>Описание</Trans></strong>
                 <div className={'text'} dangerouslySetInnerHTML={{__html:Courses.course.object[`content_${currentLang()}`]}}/>
                 {/*<div className={'address-box'}>*/}
                 {/*  <strong><Trans>Время и адрес</Trans></strong>*/}
                 {/*  {<p>{Courses.course.object[`address_${currentLang()}`]}</p>}*/}
                 {/*  /!*<span>{formatDateClient(Courses.course.object.when)} в {moment(Courses.course.object.when).format('HH:mm')} </span>*!/*/}
                 {/*  <span>{formatDateClient(Courses.course.object.when)}</span>*/}

                 {/*</div>*/}
                 <FilesDownload files={[{title:Courses.course.object[`file_title1_${currentLang()}`],file:Courses.course.object.file1},{title:Courses.course.object[`file_title2_${currentLang()}`],file:Courses.course.object.file2},{title:Courses.course.object[`file_title3_${currentLang()}`],file:Courses.course.object.file3}]}/>
                 {
                   Courses.course.object.has_form && <FormButton id={id} setVisible={setVisible} store={Courses}/>
                 }
                 {
                   visible && <DetailForm setVisible={setVisible} store={Courses} id={Courses.forms.form} forms={Courses.forms}/>
                 }

               </div>
               <div className={'popular'}>
                 <h3><Trans>В тему</Trans></h3>
                 {
                   Courses.course.similar && Courses.course.similar.map((item, idx) => {
                     return <div key={idx} className={'contest-item'}>
                       <NavLink to={`/${i18n.language}/courses/${item.id}`} className={'contest-link'}>
                         <img src={item.preview} alt={''}/>
                       </NavLink>
                       <div className={'description'}>
                         <NavLink to={`/${i18n.language}/courses/${item.id}`}>{item[`title_${currentLang()}`]}</NavLink>
                         <div dangerouslySetInnerHTML={{__html:truncateString(item[`content_${currentLang()}`].replace(/<[^>]+>/g, ''), 150)}} className={'text'}/>
                         <ul className={'course-list'}>
                           <li>{formatDateClient(item.when)}</li>
                           {
                             item.region && <li>{item.region[`title_${currentLang()}`]}</li>
                           }
                           {
                             item.subcategory[`name_${currentLang()}`] &&  <li>{item.subcategory[`name_${currentLang()}`]}</li>
                           }
                         </ul>
                         <span className={'view'}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                          stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                          d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                          stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                           {formatNum(item.views_count)}
                      </span>
                       </div>
                     </div>
                   })
                 }
               </div>
             </div>
           </div>
         </>
          : Courses.loading ? <div className={'d-flex justify-center'}><Spin spinning={true} size={'large'}/></div> : null
      }
    </>
  )
};

export default observer(CourDetail)