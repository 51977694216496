import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { Col,  Row,  Spin } from 'antd';
import { useStores } from "../../store";
import { Helmet } from "react-helmet";
import { Trans} from "react-i18next";
import banner from "../../assets/idea.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import IdeasVote from "../../components/ideasLike";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";

const Ideas = () => {
  const [, forceUpdate] = useState({});
  const { ideas, app, Modals } = useStores()
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  useEffect(() => {
    ideas.getList();
    forceUpdate({});
    return () =>{
      ideas.reset()
    }
  }, [ideas]);

  const fetchData = () => {
    const data = {limit:6,offset:ideas.start };
    ideas.getList(data)
  }

  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>Идеи</Trans>}/>
      }
      <div className={'banner bottom animate__animated animate__fadeIn'} >
        <img src={banner} alt=""/>
        <div className={'center-content'}>
          <h1><Trans>Идеи</Trans></h1>
          <p><Trans>Публикуйте свои идеи, задумки, желания по усовершенствованию уже существующих проектов, а самые лучшие из них мы реализуем. Ставьте лайк на идеи, которые вы считаете достойными реализации</Trans> </p>
          {
            app.access && <button onClick={() => Modals.show('createIdea')} className={'btn create-idea primary'}><Trans>Отправить идею</Trans></button>
          }
        </div>
      </div>
      <Helmet>
        <title>Yoshlar Portali - Идеи</title>
      </Helmet>
      {
        (app.access && isMobile) && <button onClick={() => Modals.show('createIdea')} className={'btn create-idea primary'}><Trans>Отправить идею</Trans></button>
      }
      <div className={'center-content  animate__animated animate__fadeIn'}>
        <div className={'ideas-items'}>
          <InfiniteScroll
            dataLength={ideas.list.results.length}
            next={fetchData}
            hasMore={ideas.list.count > ideas.list.results.length}
            pullDownToRefreshThreshold={300}
            style={{overflowAnchor: 'auto', overflow: 'inherit'}}
            useWindow={true}
          >
            <Spin spinning={ideas.loading} size={'large'}>
              <Row gutter={isMobile ? [0, 16] : [30, 32]}>
                {
                  ideas.list.results.length > 0 && ideas.list.results.map((item, idx) => {
                    return <Col span={isMobile ? 24 : 8} key={idx}>
                      <IdeasVote {...item} truncate={1}/>
                    </Col>
                  })
                }
              </Row>
            </Spin>
          </InfiniteScroll>
        </div>
      </div>

    </>
  )
};

export default observer(Ideas)