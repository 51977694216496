import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { Button,  Form, Select } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import api from "../../api";
import { currentLang, formatDateServer } from "../../config";
import DatePickerPortal from "../../components/datepicker";

const FilterPopup = () => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [universitys, setUniversity] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [country, setCountry] = useState([]);
  const {Option} = Select;
  const { Modals, Grants} = useStores();

  useEffect(()=>{
    forceUpdate({});

  }, []);

  const getUniversity = async () => {
    const response = await api.grants.university();
    setUniversity(response.data.results)
  }
  const getPrograms = async () => {
    const response = await api.grants.programs();
    setPrograms(response.data.results)
  }
  const getCountry = async () => {
    const response = await api.grants.country();
    setCountry(response.data.results)
  }
  const onFinish = async values => {
    if(values.date){
      values.date_month = formatDateServer(values.date).split('-')[1];
      values.date_year = formatDateServer(values.date).split('-')[0];
      delete values.date;
    }
    const error = await Grants.setFromValue(values);
    if(!error){
      Modals.hide()
      Grants.getData();
    }
  }
  return (
    <div className={'popup-filter'}>
      <h3>Фильтр</h3>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      <Form form={form} layout={'vertical'} onFinish={onFinish} >

        <Form.Item  label={t('Страна')} name={'country'}  >
          <Select placeholder={t('Страна')} onDropdownVisibleChange={(open)=>open && getCountry()}>
            {
              country.map((item, idx) => {
                return <Option key={idx} value={item.id}>{item[`name_${currentLang()}`]}</Option>
              })
            }
          </Select>
        </Form.Item>
        <Form.Item label={t('Университет')} name={'university'}>
          <Select placeholder={t('Университет')}  onDropdownVisibleChange={(open)=>open && getUniversity()}>
            {
              universitys.map((item, idx) => {
                return <Option key={idx} value={item.id}>{item[`name_${currentLang()}`]}</Option>
              })
            }
          </Select>
        </Form.Item>

        <Form.Item  label={t('Степень')} name={'graduation_level'}>
          <Select placeholder={t('Степень')}>
            <Option value={'bachelor'}><Trans>Бакалавр</Trans></Option>
            <Option value={'master'}><Trans>Магистр</Trans></Option>
            <Option value={'doctor'}><Trans>Доктор</Trans></Option>
          </Select>
        </Form.Item>

        <Form.Item  label={t('Программа')} name={'program'}>
          <Select placeholder={t('Программа')}  onDropdownVisibleChange={(open)=>open && getPrograms()}>
            {
              programs.map((item, idx) => {
                return <Option key={idx} value={item.id}>{item[`name_${currentLang()}`]}</Option>
              })
            }
          </Select>
        </Form.Item>
        <DatePickerPortal label={t('Дата')} name={'date'}/>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              className={'btn'}
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(false)
              }
            >
              <Trans>Применять фильтр</Trans>
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  )
};

export default observer(FilterPopup)