import React from 'react';
import { NavLink } from "react-router-dom";
import { currentLang, formatDateClient, truncateString } from "../../../config";
import { observer } from "mobx-react";
import moment from "moment";

const Card = (props) => {
  return (
    <div  className={`appeals-item ${props.status}`}>
      <NavLink to={`/${currentLang}/${props.type}/${props.id}`}>
        <ul>
          <li>
            <div>
              <span>ID и дата</span>
              <strong>№ {props.id} от {formatDateClient(props.created_at)}</strong>
            </div>
            <div>
              <span>Категория и сфера</span>
              <strong>{props.category} / {props.scope}</strong>
            </div>
          </li>
          <li>
            <div>
              <span>Исполнитель</span>
              <strong>Пулатов Мавлонбек</strong>
            </div>
            <div>
              <div
                className={`status ${props.status}`}>{props.status}</div>
            </div>
          </li>
          <li className={'full'}>
            <div>
              <span>Текст обращения</span>
              <p>{truncateString(props.content, 100)}</p>
            </div>
          </li>
        </ul>
      </NavLink>
      <div className={`deadline ${props.status}`}>
        <strong>Срок исполнения до {formatDateClient(props.deadline)}</strong>
        {
          props.status === 'new' ?
            <button className={`appoint-btn ${props.status}`} onClick={() => props.onAppoint(props.id)}>Принять</button> :
            <span>осталось {moment(props.deadline) < moment() ? 0 : moment(props.deadline).diff(moment(), 'days')} дней</span>
        }
      </div>
    </div>
  )
};

export default observer(Card)