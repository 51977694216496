import React, { useEffect, useState } from 'react';
import {  Button,  Col, Form, Input, message, Radio, Row, Select } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { currentLang, formatDateServer } from "../../config";
import { useMediaQuery } from "react-responsive";
import InputMask from "react-input-mask";
import Rules from "../rule";

const AppealForm = () => {
  const { i18n, t } = useTranslation()
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [, forceUpdate] = useState({});
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const { Option } = Select;
  const { profile, appeals, location, app, opportunities,Modals } = useStores();
  const [loading, setLoading] = useState(false);
  const [regionID, setRegionId] = useState(null);
  const [disable, setDisable] = useState(true);
  const [mahhalla, setMahalla] = useState(null);
  const [tashkent, setTashkent] = useState(false);
  const [district, setDistrict] = useState(false);
  const [street, setStreet] = useState(true);
  const [house, setHouse] = useState(true);
  const [file, setFile] = useState(null);
  const { access } = app;
  useEffect(() => {
    profile.getProfile();
    opportunities.reset()
    forceUpdate({});
    return () => {
      appeals.reset()
    }
  }, [opportunities, appeals, profile]);
  const setFileHandler = event => {
    setFile(event.target.files)
  }
  const getRegionID = async (value, key) => {
    if (value === 10) {
      await location.resetFields();
      await location.getPMDistrict2(value);
      const error = await location.getDistrictRegistry();

      if (!error) {
        setDistrict(false)
        setTashkent(true)
        form.resetFields(['district_id', 'street_id', 'house_id', 'mahalla_id', 'apartment'])

      }
    } else {
      await location.resetFields();
      const error = await location.getPMDistrict(value);
      setRegionId(key.key)
      if (!error) {
        setDisable(false);
        setTashkent(false)
        form.resetFields(['district_id', 'mahalla_id', 'current_address'])

      }
    }

  };
  const getCommynityID = async (id, key) => {
    setMahalla(key.key);
    const mahallaID = key.value;
    const error = await location.getCommunityPM({ mahallaID, regionID });
    if (!error) {
      setDisable(false);
      setTashkent(false)
    }
  };
  const getStreets = async id => {
    const error = await location.getStreets(id);
    if (!error) {
      setStreet(false)
      form.resetFields(['street_id', 'mahalla_id', 'house_id'])
    }
  };
  const handleChange = async q => {
    const error = await location.suggestion(q);
    if (!error) {
      setHouse(false)
    }
  };
  const handleGetCommunity = async q => {
    const error = await location.getCommunityRegistry({q});
    if (!error) {
      setHouse(false)
    }
  };
  const handleSelect = value => {
    location.setStreetID(value)
  };
  const handleChangeHouse = async (value) => {
    await location.getHouses(value);
  };
  const onFinish = async (values) => {
    setLoading(true);
    values.recipient_type_id = 1;
    values.phone = values.phone.split('+')[1];
    values.middle_name = values.patronymic;
    values.birthday = formatDateServer(values.birth_date);
    values.authority_id = appeals.subcategories.id;
    if(appeals.subcategories.is_youth_agency){
      values.is_for_youth_agency = true;
    }
    // values.authority_id = 5494;
    if (!values.current_address){
      values.current_address = profile.profile.address
    }
    values.gender = values.gender.substr(0, 1);
    if (tashkent){
      const id = location.district2List.find(item => item.addr_register_id === form.getFieldValue(['district_id']));
      values.district_id = id.id;
    }else{
      values.district_id = mahhalla;
    }
    delete values.classification_id1
    delete values.classification_id2
    delete values.patronymic
    delete values.birth_date
    values.file = file && file[0];
    Object.keys(values).forEach((k) => values[k] == null && delete values[k]);
    const error = await appeals.submit(values);
    if (error) {
      error.map((item)=> {
        return message.error(item)
      })
      setLoading(false);
    }else{
      Modals.show('success')
      setLoading(false);
      form.resetFields()
      appeals.reset()
    }

  };
  const getClassification2 = async (id, key) => {
    await appeals.getClassification2(key.key)
  }
  const getClassification3 = async (id) => {
    await appeals.getClassification3(id)
  }
  return (
    <Form
      layout={'vertical'}
      form={form}
      fields={profile.getProfileValue}
      onFinish={onFinish}
    >
      <Row gutter={isMobile ? [0, 0] : [24, 0]}>
        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Имя')} name={'last_name'} rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Input disabled={!!access && profile.profile.last_name} placeholder={t('Введите Имя')}/>
          </Form.Item>
        </Col>
        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Фамилия')} name={'first_name'}
                     rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Input disabled={!!access && profile.profile.first_name} placeholder={t('Введите Фамилия')}/>
          </Form.Item>
        </Col>
        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Отчество')} name={'patronymic'}
                     rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Input disabled={!!access && profile.profile.patronymic} placeholder={t('Введите Отчество')}/>
          </Form.Item>
        </Col>

        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Дата рождения')} name={'birth_date'}
                     rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Input disabled={!!access && profile.profile.birth_date} placeholder={t('Введите Дата рождения')}/>
          </Form.Item>
        </Col>
        {/*<Col span={isMobile? 24 : 8}>*/}
        {/*  <Form.Item label={t('Email')} name={'email'} rules={[{ required: true, message: t('Обязательное поле') }]}>*/}
        {/*    <Input disabled={!!access && profile.profile.email} placeholder={t('Введите Email')}/>*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}

        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Пол')} name={'gender'} rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Select disabled={!!access && profile.profile.gender} placeholder={t('Введите Пол')}>
              <Option value="female"><Trans>Женский</Trans></Option>
              <Option value="male"><Trans>Мужской</Trans></Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Номер телефона')} name={'phone'}>
            <InputMask disabled={!!access && profile.profile.phone} className={'ant-input'} mask="+\9\98 99 999 99 99" value="+998" name="phone"
                       placeholder="+998"/>
          </Form.Item>
        </Col>
        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Статус')} name={'employment_id'} rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Select placeholder={t('Введите Статус')}>
              <Option value={1}><Trans>Занят-работает</Trans></Option>
              <Option value={2}><Trans>Безработный</Trans></Option>
              <Option value={4}><Trans>Студент</Trans></Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile? 24 : 8}>
          <Form.Item  label={t('Категорий первого уровня')} name={'classification_id1'} rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Select placeholder={t('Категорий первого уровня')}
                    onDropdownVisibleChange={(open) => open && appeals.getClassification()} onChange={getClassification2}>
              {
                appeals.pm.classifications.map((item) => {
                  return item[`title_${i18n.language}`] ? <Option value={item.id} key={item.pm_gov_id}>{item[`title_${i18n.language}`]}</Option> : null
                })
              }
            </Select>
          </Form.Item>
        </Col>

        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Категорий второго уровня')} name={'classification_id2'} rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Select disabled={appeals.pm.classifications2.length === 0} onChange={getClassification3}
                    placeholder={t('Категорий второго уровня')}>
              {
                appeals.pm.classifications2.map((item, idx) => {
                  return item[`title_${i18n.language}`] ? <Option value={item.pm_gov_id} key={idx}>{item[`title_${i18n.language}`]}</Option> : null
                })
              }
            </Select>
          </Form.Item>
        </Col>

        <Col span={isMobile? 24 : 8}>
          <Form.Item name={'classification_id'} label={t('Категорий третьего уровня')}
                     rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Select disabled={appeals.pm.classifications3.length === 0} placeholder={t('Категорий третьего уровня')}>
              {
                appeals.pm.classifications3.map((item, idx) => {
                  return item[`title_${i18n.language}`] ? <Option value={item.id} key={idx}>{item[`title_${i18n.language}`]}</Option> : null
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={isMobile? 24 : 8}>
          <Form.Item label={t('Регион')} name={'region_id'} rules={[{ required: true, message: t('Обязательное поле') }]}>
            <Select placeholder={t('Введите Регион')} onDropdownVisibleChange={(open) => open && location.getPMRegion()}
                    onChange={getRegionID}>
              {
                location.region.map((item) => {

                  return item.title !== '' && <Option  value={item.id} key={item.pm_gov_id}>{item.title}</Option>
                })
              }
            </Select>
          </Form.Item>
        </Col>
        {
          !tashkent
            ?
            <>
              <Col span={isMobile? 24 : 8}>
                <Form.Item label={t('Район (Область)')} name={'district_id'}
                           rules={[{ required: true, message: t('Обязательное поле') }]}>
                  <Select placeholder={t('Введите Район (город)')} onChange={getCommynityID} disabled={disable}>
                    {
                      location.district.map((item, idx) => {
                        return <Option value={item.pm_gov_id} key={item.id}>{item.title}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile? 24 : 8}>
                <Form.Item label={t('Махалля')} name={'mahalla_id'}
                           rules={[{ required: true, message: t('Обязательное поле') }]}>
                  <Select placeholder={t('Махалля')} disabled={disable}>
                    {
                      location.community.map((item, idx) => {
                        return <Option value={item.id} key={idx}>{item.title}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label={t('Адрес')} name={'current_address'}
                           rules={[{ required: true, message: t('Обязательное поле') }]}>
                  <Input/>
                </Form.Item>
              </Col>
            </>
            :
            <>
              <Col  span={isMobile? 24 : 8}>
                <Form.Item label={t('Район (город)')} name={'district_id'} rules={[{ required: true, message: t('Обязательное поле') }]}>
                  <Select placeholder={t('Введите Район (город)')} disabled={district} onChange={getStreets}>
                    {
                      location.districtRegistry.map((item, idx) => {
                        return <Option value={item.id} key={idx}>{item[`name_${currentLang() === 'en' ? 'uz' : currentLang()}`]}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile? 24 : 8}>
                <Form.Item label={t('Улица')} name={'street_id'}>
                  <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    placeholder={t('Введите улицу')}
                    onSearch={handleChange}
                    onChange={handleSelect}
                    disabled={street}
                    notFoundContent={null}
                  >
                    {location.streets.map((item, idx) => (
                      <Option key={idx} value={item.id}>
                        {item[`full_name_${currentLang() === 'en' ? 'uz' : currentLang()}`]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile? 24 : 8}>
                <Form.Item label={t('Махалля')} name={'mahalla_id'}
                           rules={[{ required: true, message: t('Обязательное поле') }]}>
                  <Select
                    placeholder={t('Махалля')}
                    disabled={district}
                    showSearch
                    showArrow={true}
                    filterOption={false}
                    onSearch={handleGetCommunity}
                    notFoundContent={false}
                  >
                    {
                     location.community.map((item, idx) => {
                        return <Option value={item.id} key={idx}>{item[`name_${currentLang() === 'en' ? 'uz' : currentLang()}`]}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={isMobile? 24 : 8}>
                <Form.Item label={t('Введите дом')} name={'house_id'}>
                  <Select showSearch
                          showArrow={true}
                          filterOption={false}
                          onSearch={handleChangeHouse}
                          notFoundContent={false}
                          disabled={house}>
                    {
                      location.houses.map((item, idx) => {
                        return <Option value={item.id} key={idx}>{item.number}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </>
        }
      </Row>
      <Row gutter={[24, isMobile ? 16 : 0]}>
        <Col style={{order:isMobile ? 2 : null}} span={isMobile ? 24 : 12}>
          <Form.Item label={t('Текст обращения')} name={'description'}
                     rules={[{ required: true, message: t('Обязательное поле') }]} style={{ marginBottom: 0 }}>
            <TextArea  placeholder={t('Введите Текст обращения')}/>
          </Form.Item>
          <Rules/>
        </Col>
        <Col span={isMobile ? 24 : 12}>
          <Row gutter={[24, 24]}>
            <Col span={isMobile? 24 : 12}>
              <Form.Item
                rules={[{ required: true, message: t('Обязательное поле') }]} label={t('Открытость обращения')}
                help={t('Открытость информации об обращении и заявителе')}
                name={'is_public'}>
                <Radio.Group>
                  <Radio value={1}><Trans>Да</Trans></Radio>
                  <Radio value={0}><Trans>Нет</Trans></Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={isMobile? 24 : 12}>
              <Form.Item label={t('Загрузить файл')}>
                <div className={'file-content'}>
                  <input type={'file'} required={false} value={file && file.fileName} onChange={setFileHandler}
                         accept="application/pdf, .xls, .doc, .docx, .ppt, .txt"/>
                  <span>{file ? file[0].name : <Trans>Файл не выбран</Trans>}</span>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Col>

      </Row>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            loading={loading}
            type="primary"
            className={'btn primary'}
            htmlType="submit"
            disabled={
              !form.isFieldsTouched(['region_id','district_id','current_address','agreement','is_public','employment_id','mahalla_id'], true)  ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            <Trans>Отправить</Trans>
          </Button>
        )}
      </Form.Item>
    </Form>
  )
};

export default  observer(AppealForm)