import {observable, makeObservable, flow, action} from "mobx";
import api from '../../api';

class Ideas {
  @observable list = {
    count:0,
    results: []
  };
  @observable popular = [];
  @observable idea = {}
  @observable loading = true;
  @observable start = 6;

  constructor() {
    makeObservable(this)
  }
  @action reset = () => {
    this.list.results = [];
    this.loading = true;
  }

  @action detail = (item) => {
    this.idea = item;
  };
  @flow * create(values){
    let form = new FormData();
    for(let type in values){
      form.append(type, values[type])
    }
   const response = yield api.ideas.create(form);
    if(response.status === 400){
      let data = {};
      for(let type in response.data){
        data = response.data[type];
      }
      return data.map((item) => item)
    }
  }
  @flow * getList(values){
    const response = yield api.ideas.list(values);
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.results = this.list.results.concat(response.data.results);
        this.start+= 6;
      }else{
        this.list = response.data;
      }
      this.list.results = this.list.results.sort((a,b) => b.likes - a.likes);
      this.loading = false;
    }
  }
  @flow * getPopular(){
    const response = yield api.ideas.popular();
    if(!response.detail){
      this.popular = response.data
    }
  }
  @flow * votes(value){
    const data = {idea:value.id, type:value.type === 'like' ? 1 : 0};
    const currentItem = this.list.results.find(item => item.id === value.id );

    const response = yield api.ideas.votes(data);

    if(!response.detail){
      if(value.type === 'like'){
        if(currentItem.likes >= 1 && currentItem.user_reaction === 'like') {
          currentItem.likes -= 1;
          this.idea.likes--
          this.idea.user_reaction = null
          currentItem.user_reaction = null;
          return;
        }
        if(currentItem.dislikes !== 0 && currentItem.user_reaction === 'dislike') {
          currentItem.dislikes -= 1;
          currentItem.user_reaction = null;
          this.idea.dislikes--
          this.idea.user_reaction = null
        }
        currentItem.likes += 1;
        currentItem.user_reaction = 'like';
        this.idea.likes++
        this.idea.user_reaction = 'like'
      }
      else {
        if(currentItem.dislikes >= 1  && currentItem.user_reaction === 'dislike') {
          currentItem.dislikes -= 1;
          this.idea.dislikes--
          this.idea.user_reaction = null
          currentItem.user_reaction = null;
          return
        }
        if(currentItem.likes !== 0 && currentItem.user_reaction === 'like') {
          currentItem.likes -= 1;
          this.idea.likes--
          this.idea.user_reaction = null
          currentItem.user_reaction = null
        }
        currentItem.dislikes += 1;
        currentItem.user_reaction = 'dislike'
        this.idea.dislikes++
        this.idea.user_reaction = 'dislike'
      }
    }
  }
}

export default new Ideas()