import React, { useEffect } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Spin } from "antd";
import { useStores } from "../../store";
import { formatDateClient, truncateString } from "../../config";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";

const ExportUsers = () => {
  const {profile} = useStores()
  const {t, i18n} = useTranslation();
  const changeHandler = async (item) => {
    profile.isActiveButton(item)
    profile.setParamsExport({type:item.type})
    await profile.getExportList();
  }
  useEffect(()=>{
    profile.getExportList()
  }, [profile])
  return(
    <>
      <h3 className={'bordered'}><Trans>Запросы на участие в Портал молодёжи</Trans></h3>
      <div className="filters">
        <ul>
          {
            profile.exportTypeButtons.map((item, index) => (
              <li key={index}>
                <button onClick={()=>changeHandler(item)} className={item.active ? 'active' : ''}>{t(item.title)}</button>
              </li>
            ))
          }
        </ul>
      </div>
        <Spin spinning={false}>
          <div className={'profile-appeals-box'}>

          {
            profile.exports.results.map((item, index) => (
              <div className={'appeals-item processing'} key={index}>
                <NavLink to={`/${i18n.language}/export/${profile.params.type}/${item.id}`} >
                  <ul>
                    <li>
                      <div>
                        <span><Trans>ID и дата</Trans></span>
                        <strong>№ {item.id} от {formatDateClient(item.created_at)}</strong>
                      </div>
                      <div>
                        <span><Trans>Категория и сфера</Trans></span>
                        <strong>{item[`title_${i18n.language}`]}</strong>
                      </div>
                    </li>
                    <li className={'full'}>
                      <div>
                        <span><Trans>Описание</Trans></span>
                        <p>{truncateString(item[`content_${i18n.language}`].replace(/<[^>]+>/g, ' '), 100)}</p>
                      </div>
                    </li>
                  </ul>
                </NavLink>
              </div>
            ))
          }
          </div>
        </Spin>
    </>
  )
};

export default observer(ExportUsers)