import {observable, makeObservable, flow, action} from "mobx";
import api from '../../api';
class Executor {
  @observable loading = true;
  @observable detail = null;
  @observable form = null;
  @observable appeals = {
    results:[],
    count:0
  };
  @observable result = {
    results:[],
    count:0
  };
  @observable deadline = {
    results:[],
    count:0
  };
  @observable category = {
    results:[],
    count:0
  };
  @observable task = {
    results:[],
    count:0
  };
  @observable period = {
    results:[],
    count:0
  };
  @observable paginations = {
    offset:0,
    limit:20
  }
  @observable start = 1;
  @observable filter = {}

  constructor() {
    makeObservable(this)
  }
  @action setPagination = () => {
    this.paginations.offset = this.start * 20;
  }
  @action reset = () => {
    this.filter = {}
    this.appeals = {
      count:0,
      results:[]
    }
    this.paginations = {
      offset: 0,
      limit:20
    }
    this.start = 1;
  }
  @action setValues = (values) => {
    this.form = {...this.form, ...values};
  }
  @action setFilter = (values) => {
    this.reset()
    this.filter = {...this.filter, ...values};
  }

  @action resultAppeal = (response) => {
    if(!response.detail){
      if(this.appeals.results.length > 0){
        this.appeals.count = response.data.count;
        this.appeals.results = [...this.appeals.results, ...response.data.results];
        this.start++
      }else{
        this.appeals = response.data;
      }
    }
    this.loading = false;
  }
  @flow * acceptedItem () {
    let data = {};
    data.url = this.form.url;
    delete this.form.url;
    data.values = this.form;
    const response = yield api.executor.republic.accepted(data);
    if(response.data.detail){
      if(response.data.detail.error){
        return response.data.detail.error.map(item=> item.message);
      }else{
        return [response.data.detail.message]
      }
    }
  }
  @flow * detailAppeal (id) {
    const response = yield api.executor.detail(id);
    this.detail = response.data;
  }
  @flow * redirectTask (id) {
    const response = yield api.executor.republic.redirectTask(id);
    this.task = response.data;
    this.loading = false;
  }
  @flow * getResult (id) {
    const response = yield api.executor.republic.result(id);
    this.result = response.data;
    this.loading = false;
  }
  @flow * changeDeadline (id) {
    const response = yield api.executor.republic.changeDeadline(id);
    this.deadline = response.data;
    this.loading = false;
  }
  @flow * changePeriod (id) {
    const response = yield api.executor.republic.changePeriod(id);
    this.period = response.data;
    this.loading = false;
  }
  @flow * changeCategory (id) {
    const response = yield api.executor.republic.changeCategory(id);
    this.category = response.data;
    this.loading = false;
  }

  @flow * getPmAppeals (values) {
    const response = yield api.executor.republic.getPmAppeals({...this.paginations, ...values, ...this.filter});
    this.resultAppeal(response)
  }

  @flow * getBenefitAppeals (value) {
    const response = yield api.executor.executor.getBenefitAppeals({...this.paginations, ...value});
    this.resultAppeal(response)
  }
  @flow * onAppoint (values) {
    const response = yield api.executor.onAppoint({request_id:values.Appealid});
    if(!response.detail){
      this.pending.results.splice(values.index, 1);
    }
  }
  @flow * agencyRequest (values) {
    const response = yield api.executor.agencyRedirect(values);
    if(response.data.status !== 'error'){
      const index = this.appeals.results.findIndex(item => item.id === values.existing_request_id)
      if(index > -1){
        this.appeals.results.splice(index, 1)
      }
    }else{
      let error = []
      for(let type in response.data){
        if(response.data.hasOwnProperty(type) && response.data[type].detail){
          error.push(response.data[type].detail.error.map(item => item.message));
        }
      }
      return error;
    }
  }
};
export default new Executor()