import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { baseUrl, currentLang } from "../../config";
import { Trans} from "react-i18next";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import Gerb from "./icon";
import Slider from "react-slick";
const About = () => {
  const {about} = useStores();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' });
  useEffect(()=>{
    about.getData()
  }, [about]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay:true,
    duration:1500,
    slidesToShow: 5,
    slidesToScroll: 1,
    fade:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          draggable:false,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          draggable:true,
        }
      }
    ]
  };
  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>О нас</Trans>}/>
      }
      <div className={'a-routeFadeIn about-page'}>
        <Helmet>
          <title>Yoshlar Portali - О нас</title>
          <meta property="og:url" content={baseUrl} />
          <meta property="og:type" content={'website'} />
          <meta property="og:title" content="О нас" />
          <meta property="og:description" content={about.detail && about.detail.first_description} />
          <meta property="og:image" content={`${about.detail && about.detail.second_image}`}/>
        </Helmet>
        <div className={'block'}>
          <div className={'center-content'}>
            <div className={'title'}>
              <h3>{about.detail && about.detail[`first_title_${currentLang()}`]}</h3>
              <div className={'text'} dangerouslySetInnerHTML={{__html:about.detail && about.detail[`first_description_${currentLang()}`].replace(/<[^>]+>/g, ' ')}}/>
            </div>
            <div className={'img'}>
              <img src={about.detail && about.detail.first_image} alt={''}/>
            </div>
          </div>
        </div>
        <div className={'block'}>
          <div className={'center-content'}>
            <div className={'title'}>
              <h3>{about.detail && about.detail[`second_title_${currentLang()}`]}</h3>
              <div className={'text'} dangerouslySetInnerHTML={{__html:about.detail && about.detail[`second_description_${currentLang()}`].replace(/<[^>]+>/g, ' ')}}/>
            </div>
            <div className={'img'}>
              <img src={about.detail && about.detail.second_image} alt={''}/>
            </div>
          </div>
        </div>
        <div className={'block'}>
          <div className={'center-content'}>
            <div className={'title'}>
              <h3>{about.detail && about.detail[`third_title_${currentLang()}`]}</h3>
              <div className={'text'} dangerouslySetInnerHTML={{__html:about.detail && about.detail[`third_description_${currentLang()}`].replace(/<[^>]+>/g, ' ')}}/>
            </div>
            <div className={'img'}>
              <img src={about.detail && about.detail.third_image} alt={''}/>
            </div>
          </div>
        </div>
        <div className={'partners'}>
          <div className={'center-content'}>
            <h3><Trans>Наши партнёры</Trans></h3>
            <div className={'icons'}>
              {
                isMobile ? <Slider {...settings}>
                  {
                    about.detail && about.detail.partners.map((item, index) => {
                      return <img key={index} src={item.image} alt={''}/>
                    })
                  }
                </Slider> :
                  about.detail && about.detail.partners.map((item, index) => {
                    return <img key={index} src={item.image} alt={''}/>
                  })
              }

            </div>
          </div>
        </div>
        <div className={'resource'}>
          <div className={'center-content'}>
            <h3><Trans>Полезные ресурсы</Trans></h3>
            <div className={'resource-links'}>
              {
                about.detail && about.detail.useful_links.map((item, index) => {
                  return  <a key={index} rel="noreferrer" href={item.link} target={'_blank'}>
                     <div className={'logo'}>
                       {
                         item.image ? <img src={item.image} alt=""/> : <Gerb/>
                       }
                     </div>
                    <strong>{item.link.split('/')[2]}</strong>
                    <p>{item[`description_${currentLang()}`]}</p>
                  </a>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default observer(About)