import React from 'react';
import { Table } from "antd";
import { useTranslation } from "react-i18next";

const Turizm = () => {
  const {i18n} = useTranslation()
  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index === 0) {
      obj.props.colSpan = 0;
    }
    return obj;
  };
  let columns = [];
  let data = [];
  if(i18n.language === 'ru'){
    columns = [
      {
        title:'п/н',
        dataIndex:'key',
        width:60,
        render:renderContent,
      },
      {
        title:'Название региона',
        width:'15%',
        dataIndex:'region_name',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              colSpan: 2,
            },
          };
        }
      },
      {
        title:"Детско-юношеские спортивные школы",
        children:[
          {
            title:"Количество детско-юношеских спортивных школ",
            fixed: 'left',
            align:'center',
            dataIndex:'sport_schools',
            render: (text, row, index) => {
              if (index > 0) {
                return text;
              }
              return {
                children: <strong>{text}</strong>
              };
            }

          },
          {
            title:"Количество отделов и секций",

            children:[
              {
                title:'количество видов спорта',
                align:'center',
                dataIndex:'sport_type',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'количество групп',
                align:'center',
                dataIndex:'groups_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Количество сотрудников",
            children:[
              {
                title:"общее количество сотрудников",
                align:'center',
                dataIndex:'all_employee_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'из этого: количество тренеров',
                align:'center',
                dataIndex:'coach_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Количество учеников-спортсменов",
            children:[
              {
                title:"занимающиеся",
                dataIndex:'sportsmen',
                align:'center',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }

              },
              {
                title:'из этого: девочки',
                align:'center',
                dataIndex:'sportsmen_girl',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Члены национальной сборной",
            children:[
              {
                title:'основной состав',
                align:'center',
                dataIndex:'nation_team',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'резервный состав',
                align:'center',
                dataIndex:'zax_team',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          }
        ]
      }
    ];
    data = [
      {
        key:'',
        region_name:"По всей республике:",
        sport_schools:'254',
        sport_type:'221',
        groups_count:'21 202',
        all_employee_count:'16 260',
        coach_count:'10 468',
        sportsmen:'328 878',
        sportsmen_girl:'68 563',
        nation_team:'2 356',
        zax_team:'1 238'
      },
      {
        key:1,
        region_name:"Республика Каракалпакстан",
        sport_schools:'20',
        sport_type:'14',
        groups_count:'265',
        all_employee_count:'1 250',
        coach_count:'663',
        sportsmen:'27 417',
        sportsmen_girl:'6 898',
        nation_team:'47',
        zax_team:'50'
      },
      {
        key:2,
        region_name:"Андижанская область",
        sport_schools:'19',
        sport_type:'16',
        groups_count:'2 193',
        all_employee_count:'1 563',
        coach_count:'984',
        sportsmen:'26 771',
        sportsmen_girl:'4 852',
        nation_team:'64',
        zax_team:'70'
      },
      {
        key:3,
        region_name:"Бухарская область",
        sport_schools:'16',
        sport_type:'11',
        groups_count:'2 178',
        all_employee_count:'1 303',
        coach_count:'867',
        sportsmen:'32 717',
        sportsmen_girl:'6 956',
        nation_team:'82',
        zax_team:'61'
      },
      {
        key:4,
        region_name:"Джизакская область",
        sport_schools:'13',
        sport_type:'14',
        groups_count:'943',
        all_employee_count:'744',
        coach_count:'787',
        sportsmen:'11 443',
        sportsmen_girl:'2 459',
        nation_team:'72',
        zax_team:'70'
      },
      {
        key:5,
        region_name:"Кашкадарьинская область",
        sport_schools:'17',
        sport_type:'15',
        groups_count:'1 265',
        all_employee_count:'1 022',
        coach_count:'498',
        sportsmen:'20 208',
        sportsmen_girl:'4 470',
        nation_team:'75',
        zax_team:'63'
      },
      {
        key:6,
        region_name:"Навоийская область",
        sport_schools:'12',
        sport_type:'13',
        groups_count:'1 087',
        all_employee_count:'777',
        coach_count:'338',
        sportsmen:'15 158',
        sportsmen_girl:'3 435',
        nation_team:'80',
        zax_team:'54'
      },
      {
        key:7,
        region_name:"Наманганская область",
        sport_schools:'18',
        sport_type:'17',
        groups_count:'1 502',
        all_employee_count:'1 236',
        coach_count:'680',
        sportsmen:'20 703',
        sportsmen_girl:'4 339',
        nation_team:'103',
        zax_team:'75'
      },
      {
        key:8,
        region_name:"Самаркандская область",
        sport_schools:'22',
        sport_type:'18',
        groups_count:'2 008',
        all_employee_count:'1 377',
        coach_count:'1 331',
        sportsmen:'28 027',
        sportsmen_girl:'6 339',
        nation_team:'74',
        zax_team:'49'
      },
      {
        key:9,
        region_name:"Сурхандарьинская область",
        sport_schools:'17',
        sport_type:'16',
        groups_count:'1 081',
        all_employee_count:'913',
        coach_count:'389',
        sportsmen:'14 298',
        sportsmen_girl:'3 462',
        nation_team:'68',
        zax_team:'21'
      },
      {
        key:10,
        region_name:"Сырдарьинская область",
        sport_schools:'12',
        sport_type:'14',
        groups_count:'1 967',
        all_employee_count:'1 203',
        coach_count:'1 112',
        sportsmen:'31 484',
        sportsmen_girl:'7 697',
        nation_team:'331',
        zax_team:'267'
      },
      {
        key:11,
        region_name:"Ташкентская область",
        sport_schools:'27',
        sport_type:'18',
        groups_count:'1 993',
        all_employee_count:'1 158',
        coach_count:'772',
        sportsmen:'27 589',
        sportsmen_girl:'4 158',
        nation_team:'994',
        zax_team:'107'
      },
      {
        key:12,
        region_name:"Ферганская область",
        sport_schools:'26',
        sport_type:'18',
        groups_count:'2 521',
        all_employee_count:'1 794',
        coach_count:'1 093',
        sportsmen:'37 621',
        sportsmen_girl:'7 689',
        nation_team:'105',
        zax_team:'108'
      },
      {
        key:13,
        region_name:"Хорезмская обл.",
        sport_schools:'15',
        sport_type:'16',
        groups_count:'1 085',
        all_employee_count:'875',
        coach_count:'498',
        sportsmen:'18 663',
        sportsmen_girl:'3 478',
        nation_team:'46',
        zax_team:'68'
      },
      {
        key:14,
        region_name:"Город Ташкент",
        sport_schools:'20',
        sport_type:'21',
        groups_count:'1 114',
        all_employee_count:'1 045',
        coach_count:'456',
        sportsmen:'16 779',
        sportsmen_girl:'2 331',
        nation_team:'215',
        zax_team:'175'
      }
    ];
  }
  if(i18n.language === 'oz'){
    columns = [
      {
        title:'T/r',
        dataIndex:'key',
        width:60,
        render:renderContent,
      },
      {
        title:'Hudud nomi',
        width:'15%',
        dataIndex:'region_name',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              colSpan: 2,
            },
          };
        }
      },
      {
        title:"Bolalar-o'smirlar sport maktablari",
        children:[
          {
            title:"Bolalar-o'smirlari sport maktablari soni",
            fixed: 'left',
            align:'center',
            dataIndex:'sport_schools',
            render: (text, row, index) => {
              if (index > 0) {
                return text;
              }
              return {
                children: <strong>{text}</strong>
              };
            }

          },
          {
            title:"Bo'limlar va seksiyalar soni",

            children:[
              {
                title:'sport turlari soni',
                align:'center',
                dataIndex:'sport_type',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'guruhlar soni',
                align:'center',
                dataIndex:'groups_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Xodimlar soni",
            children:[
              {
                title:"barcha ishchi xodimlar soni",
                align:'center',
                dataIndex:'all_employee_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'shundan trenerlar soni',
                align:'center',
                dataIndex:'coach_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"O'quvchi-sportchilar soni",
            children:[
              {
                title:"shug'ullanuvchilar",
                dataIndex:'sportsmen',
                align:'center',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }

              },
              {
                title:'shundan qizlar',
                align:'center',
                dataIndex:'sportsmen_girl',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Milliy terma jamoa a'zolari",
            children:[
              {
                title:'asosiy tarkib',
                align:'center',
                dataIndex:'nation_team',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'zaxira tarkib',
                align:'center',
                dataIndex:'zax_team',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          }
        ]
      }
    ];
    data = [
      {
        key:'',
        region_name:"Respublika bo'yicha:",
        sport_schools:'254',
        sport_type:'221',
        groups_count:'21 202',
        all_employee_count:'16 260',
        coach_count:'10 468',
        sportsmen:'328 878',
        sportsmen_girl:'68 563',
        nation_team:'2 356',
        zax_team:'1 238'
      },
      {
        key:1,
        region_name:"Qoraqalpog'iston Respublikasi",
        sport_schools:'20',
        sport_type:'14',
        groups_count:'265',
        all_employee_count:'1 250',
        coach_count:'663',
        sportsmen:'27 417',
        sportsmen_girl:'6 898',
        nation_team:'47',
        zax_team:'50'
      },
      {
        key:2,
        region_name:"Andijon viloyati",
        sport_schools:'19',
        sport_type:'16',
        groups_count:'2 193',
        all_employee_count:'1 563',
        coach_count:'984',
        sportsmen:'26 771',
        sportsmen_girl:'4 852',
        nation_team:'64',
        zax_team:'70'
      },
      {
        key:3,
        region_name:"Buxoro viloyati",
        sport_schools:'16',
        sport_type:'11',
        groups_count:'2 178',
        all_employee_count:'1 303',
        coach_count:'867',
        sportsmen:'32 717',
        sportsmen_girl:'6 956',
        nation_team:'82',
        zax_team:'61'
      },
      {
        key:4,
        region_name:"Jizzax viloyati",
        sport_schools:'13',
        sport_type:'14',
        groups_count:'943',
        all_employee_count:'744',
        coach_count:'787',
        sportsmen:'11 443',
        sportsmen_girl:'2 459',
        nation_team:'72',
        zax_team:'70'
      },
      {
        key:5,
        region_name:"Qashqadaryo viloyati",
        sport_schools:'17',
        sport_type:'15',
        groups_count:'1 265',
        all_employee_count:'1 022',
        coach_count:'498',
        sportsmen:'20 208',
        sportsmen_girl:'4 470',
        nation_team:'75',
        zax_team:'63'
      },
      {
        key:6,
        region_name:"Navoiy viloyati",
        sport_schools:'12',
        sport_type:'13',
        groups_count:'1 087',
        all_employee_count:'777',
        coach_count:'338',
        sportsmen:'15 158',
        sportsmen_girl:'3 435',
        nation_team:'80',
        zax_team:'54'
      },
      {
        key:7,
        region_name:"Namangan viloyati",
        sport_schools:'18',
        sport_type:'17',
        groups_count:'1 502',
        all_employee_count:'1 236',
        coach_count:'680',
        sportsmen:'20 703',
        sportsmen_girl:'4 339',
        nation_team:'103',
        zax_team:'75'
      },
      {
        key:8,
        region_name:"Samarqand viloyati",
        sport_schools:'22',
        sport_type:'18',
        groups_count:'2 008',
        all_employee_count:'1 377',
        coach_count:'1 331',
        sportsmen:'28 027',
        sportsmen_girl:'6 339',
        nation_team:'74',
        zax_team:'49'
      },
      {
        key:9,
        region_name:"Surhxondaryo viloyati",
        sport_schools:'17',
        sport_type:'16',
        groups_count:'1 081',
        all_employee_count:'913',
        coach_count:'389',
        sportsmen:'14 298',
        sportsmen_girl:'3 462',
        nation_team:'68',
        zax_team:'21'
      },
      {
        key:10,
        region_name:"Sirdaryo viloyati",
        sport_schools:'12',
        sport_type:'14',
        groups_count:'1 967',
        all_employee_count:'1 203',
        coach_count:'1 112',
        sportsmen:'31 484',
        sportsmen_girl:'7 697',
        nation_team:'331',
        zax_team:'267'
      },
      {
        key:11,
        region_name:"Toshkent viloyati",
        sport_schools:'27',
        sport_type:'18',
        groups_count:'1 993',
        all_employee_count:'1 158',
        coach_count:'772',
        sportsmen:'27 589',
        sportsmen_girl:'4 158',
        nation_team:'994',
        zax_team:'107'
      },
      {
        key:12,
        region_name:"Farg'ona viloyati",
        sport_schools:'26',
        sport_type:'18',
        groups_count:'2 521',
        all_employee_count:'1 794',
        coach_count:'1 093',
        sportsmen:'37 621',
        sportsmen_girl:'7 689',
        nation_team:'105',
        zax_team:'108'
      },
      {
        key:13,
        region_name:"Xorazm viloyati",
        sport_schools:'15',
        sport_type:'16',
        groups_count:'1 085',
        all_employee_count:'875',
        coach_count:'498',
        sportsmen:'18 663',
        sportsmen_girl:'3 478',
        nation_team:'46',
        zax_team:'68'
      },
      {
        key:14,
        region_name:"Toshkent shahri",
        sport_schools:'20',
        sport_type:'21',
        groups_count:'1 114',
        all_employee_count:'1 045',
        coach_count:'456',
        sportsmen:'16 779',
        sportsmen_girl:'2 331',
        nation_team:'215',
        zax_team:'175'
      }
    ];
  }
  if(i18n.language === 'uz'){
    columns = [
      {
        title:'т/р',
        dataIndex:'key',
        width:60,
        render:renderContent,
      },
      {
        title:'Ҳудуд номи',
        width:'15%',
        dataIndex:'region_name',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              colSpan: 2,
            },
          };
        }
      },
      {
        title:"Болалар-ўсмирлар спорт мактаблари",
        children:[
          {
            title:"Болалар-ўсмирлар спорт мактаблари сони",
            fixed: 'left',
            align:'center',
            dataIndex:'sport_schools',
            render: (text, row, index) => {
              if (index > 0) {
                return text;
              }
              return {
                children: <strong>{text}</strong>
              };
            }

          },
          {
            title:"Бўлимлар ва секциялар сони",

            children:[
              {
                title:'спорт турлари сони',
                align:'center',
                dataIndex:'sport_type',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'гуруҳлар сони',
                align:'center',
                dataIndex:'groups_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Ходимлар сони",
            children:[
              {
                title:"барча ишчи ходимлар сони",
                align:'center',
                dataIndex:'all_employee_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'шундан тренерлар сони',
                align:'center',
                dataIndex:'coach_count',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Ўқувчи-спортчилар сони",
            children:[
              {
                title:"шуғулланувчилар",
                dataIndex:'sportsmen',
                align:'center',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }

              },
              {
                title:'шундан қизлар',
                align:'center',
                dataIndex:'sportsmen_girl',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          },
          {
            title:"Миллий терма жамоа аъзолари",
            children:[
              {
                title:'асосий таркиб',
                align:'center',
                dataIndex:'nation_team',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              },
              {
                title:'захира таркиб',
                align:'center',
                dataIndex:'zax_team',
                render: (text, row, index) => {
                  if (index > 0) {
                    return text;
                  }
                  return {
                    children: <strong>{text}</strong>
                  };
                }
              }
            ]
          }
        ]
      }
    ];
    data = [
      {
        key:'',
        region_name:"Respublika bo'yicha:",
        sport_schools:'254',
        sport_type:'221',
        groups_count:'21 202',
        all_employee_count:'16 260',
        coach_count:'10 468',
        sportsmen:'328 878',
        sportsmen_girl:'68 563',
        nation_team:'2 356',
        zax_team:'1 238'
      },
      {
        key:1,
        region_name:"Qoraqalpog'iston Respublikasi",
        sport_schools:'20',
        sport_type:'14',
        groups_count:'265',
        all_employee_count:'1 250',
        coach_count:'663',
        sportsmen:'27 417',
        sportsmen_girl:'6 898',
        nation_team:'47',
        zax_team:'50'
      },
      {
        key:2,
        region_name:"Andijon viloyati",
        sport_schools:'19',
        sport_type:'16',
        groups_count:'2 193',
        all_employee_count:'1 563',
        coach_count:'984',
        sportsmen:'26 771',
        sportsmen_girl:'4 852',
        nation_team:'64',
        zax_team:'70'
      },
      {
        key:3,
        region_name:"Buxoro viloyati",
        sport_schools:'16',
        sport_type:'11',
        groups_count:'2 178',
        all_employee_count:'1 303',
        coach_count:'867',
        sportsmen:'32 717',
        sportsmen_girl:'6 956',
        nation_team:'82',
        zax_team:'61'
      },
      {
        key:4,
        region_name:"Jizzax viloyati",
        sport_schools:'13',
        sport_type:'14',
        groups_count:'943',
        all_employee_count:'744',
        coach_count:'787',
        sportsmen:'11 443',
        sportsmen_girl:'2 459',
        nation_team:'72',
        zax_team:'70'
      },
      {
        key:5,
        region_name:"Qashqadaryo viloyati",
        sport_schools:'17',
        sport_type:'15',
        groups_count:'1 265',
        all_employee_count:'1 022',
        coach_count:'498',
        sportsmen:'20 208',
        sportsmen_girl:'4 470',
        nation_team:'75',
        zax_team:'63'
      },
      {
        key:6,
        region_name:"Navoiy viloyati",
        sport_schools:'12',
        sport_type:'13',
        groups_count:'1 087',
        all_employee_count:'777',
        coach_count:'338',
        sportsmen:'15 158',
        sportsmen_girl:'3 435',
        nation_team:'80',
        zax_team:'54'
      },
      {
        key:7,
        region_name:"Namangan viloyati",
        sport_schools:'18',
        sport_type:'17',
        groups_count:'1 502',
        all_employee_count:'1 236',
        coach_count:'680',
        sportsmen:'20 703',
        sportsmen_girl:'4 339',
        nation_team:'103',
        zax_team:'75'
      },
      {
        key:8,
        region_name:"Samarqand viloyati",
        sport_schools:'22',
        sport_type:'18',
        groups_count:'2 008',
        all_employee_count:'1 377',
        coach_count:'1 331',
        sportsmen:'28 027',
        sportsmen_girl:'6 339',
        nation_team:'74',
        zax_team:'49'
      },
      {
        key:9,
        region_name:"Surhxondaryo viloyati",
        sport_schools:'17',
        sport_type:'16',
        groups_count:'1 081',
        all_employee_count:'913',
        coach_count:'389',
        sportsmen:'14 298',
        sportsmen_girl:'3 462',
        nation_team:'68',
        zax_team:'21'
      },
      {
        key:10,
        region_name:"Sirdaryo viloyati",
        sport_schools:'12',
        sport_type:'14',
        groups_count:'1 967',
        all_employee_count:'1 203',
        coach_count:'1 112',
        sportsmen:'31 484',
        sportsmen_girl:'7 697',
        nation_team:'331',
        zax_team:'267'
      },
      {
        key:11,
        region_name:"Toshkent viloyati",
        sport_schools:'27',
        sport_type:'18',
        groups_count:'1 993',
        all_employee_count:'1 158',
        coach_count:'772',
        sportsmen:'27 589',
        sportsmen_girl:'4 158',
        nation_team:'994',
        zax_team:'107'
      },
      {
        key:12,
        region_name:"Farg'ona viloyati",
        sport_schools:'26',
        sport_type:'18',
        groups_count:'2 521',
        all_employee_count:'1 794',
        coach_count:'1 093',
        sportsmen:'37 621',
        sportsmen_girl:'7 689',
        nation_team:'105',
        zax_team:'108'
      },
      {
        key:13,
        region_name:"Xorazm viloyati",
        sport_schools:'15',
        sport_type:'16',
        groups_count:'1 085',
        all_employee_count:'875',
        coach_count:'498',
        sportsmen:'18 663',
        sportsmen_girl:'3 478',
        nation_team:'46',
        zax_team:'68'
      },
      {
        key:14,
        region_name:"Toshkent shahri",
        sport_schools:'20',
        sport_type:'21',
        groups_count:'1 114',
        all_employee_count:'1 045',
        coach_count:'456',
        sportsmen:'16 779',
        sportsmen_girl:'2 331',
        nation_team:'215',
        zax_team:'175'
      }
    ];
  }
  return (
    <Table
      pagination={false}
      sticky
      columns={columns}
      dataSource={data}
      bordered
      size="large"
    />
  )
};

export default Turizm