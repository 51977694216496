import React, { useEffect, useState } from 'react';
import { useStores } from "../../../store";
import { Trans, useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Form, Input,  message, Modal, notification, Select, Spin } from "antd";
import Card from "./card";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import ExecutorDetail from "../pm/detail";


const ToAgency = () => {
  const {executor, appeals} = useStores()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const {t, i18n} = useTranslation()
  const {id} = useParams()
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const {Option} = Select;
  useEffect(() => {
    if (id) {
      return executor.detailAppeal(id);
    }
    executor.getPmAppeals({youth_agency:'y', sent_to_pm:'n'})
    return () => {
      executor.reset()
    }
  }, [id,  executor]);

  const fetchData = () => {
    executor.setPagination()
    executor.getPmAppeals({youth_agency:'y', sent_to_pm:'n'});
  }
  const onFinish = async values => {
    const data = {...executor.form, ...values};
    setLoading(true);
    const error = await executor.agencyRequest(data);
    if(!error){
      notification.success({
        message: t('Заявка отправлено'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
      });
      form1.resetFields()
      setLoading(false);
      setVisible(false);
    }else{
      setLoading(false);
      error.map(item => message.error(item))
    }
  }
  const changeHandler = async () => {
    await executor.setFilter(form.getFieldValue());
    executor.getPmAppeals({youth_agency:'y', sent_to_pm:'n'})
  }
  const getCategory = async (id) => {
    form1.resetFields(['authority_id'])
    await appeals.getPMSubcategories(id);

  }
  const visibleHandler = (item) => {
    executor.setValues({existing_request_id:item.id, is_for_youth_agency:true});
    setVisible(true)
  }
  const onAppoint = async (item) => {
    const error = await executor.agencyRequest({is_for_youth_agency:true, existing_request_id:item.id});
    if(error){
      return error.map(item => message.error(item))
    }
    notification.success({
      message: t('Заявка отправлено'),
      icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
    });
  }
  if(id){
    return <ExecutorDetail id={id} />
  }
  return (
    <>
      <h3 className={'bordered'}><Trans>Обращения в Портал молодёжи</Trans></h3>
      <div className={'filters  mb-40'}>
        <Form form={form}  onChange={changeHandler}>
          <div className={'form progress'}>
            <Form.Item name={'id'}>
              <Input placeholder={t('Поиск по ID')}/>
            </Form.Item>
            <Button htmlType={'submit'}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </Button>
          </div>
          <div className={'buttons'}>
            <Form.Item name={'created_at'}>
              <Select placeholder={'Сортировать по дате'} onChange={changeHandler}>
                <Option value={'y'}><Trans>Свежие</Trans></Option>
                <Option value={null}><Trans>Старые</Trans></Option>
              </Select>
            </Form.Item>
            <Form.Item name={'status'}>
              <Select placeholder={'Статус'}  onChange={changeHandler}>
                <Option value={'is_any_news'}><Trans>Есть новости</Trans></Option>
                <Option value={'expired'}><Trans>Просроченные</Trans></Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
      <InfiniteScroll
        dataLength={executor.appeals.results.length}
        next={fetchData}
        hasMore={executor.appeals.count > executor.appeals.results.length}
        pullDownToRefreshThreshold={300}
        useWindow={true}
      >
        <Spin spinning={executor.loading}  size="large">
          <div className={'profile-appeals-box'}>
            {
              executor.appeals.results.map((item, index) => {
                return <Card onAppoint={onAppoint} visibleHandler={visibleHandler} type={'processing'} key={index} {...item}/>
              })
            }
          </div>
        </Spin>
      </InfiniteScroll>
      <Modal maskClosable={true} onCancel={()=>setVisible(false)} title={t('Переназначение задач')} visible={visible} footer={false}>
        <Form form={form1} layout={'vertical'} onFinish={onFinish}>
          <Form.Item rules={[{required:true, message:t('Обязательное поле')}]} label={t('Выберите организацию')}>
            <Select
              onDropdownVisibleChange={(open)=>open && appeals.getPMCategory()}
              onChange={getCategory}>
              {
                appeals.pm.categories.map((item) => {
                  return item[`title_${i18n.language}`] && <Option value={item.pm_gov_id}>{item[`title_${i18n.language}`]}</Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item rules={[{required:true, message:'Обязательное поле'}]} name={'authority_id'} label={'Выберите министерства'}>
            <Select disabled={appeals.pm.subcategories.length <= 0}>
              {
                appeals.pm.subcategories.map((item) => {
                  return item[`title_${i18n.language}`] &&<Option value={item.id}>{item[`title_${i18n.language}`]}</Option>
                })
              }
            </Select>
          </Form.Item>
          <Button loading={loading} htmlType={'submit'} className={'btn success'}><Trans>Отправить</Trans></Button>
          <Button className={'btn close'} onClick={()=>setVisible(false)}><Trans>Закрыть</Trans></Button>
        </Form>
      </Modal>
    </>
  )
};

export default observer(ToAgency)