import {observable, action, makeObservable, flow} from "mobx";
import api from '../../api';
import Home from './home'
class Grants {
  @observable list ={
    count:0,
    results:[]
  };
  @observable form = null;
  @observable start = 1;
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable params = null;
  @observable loading = true;
  @observable error = false;
  @observable forms = {
    fields:[]
  };
  @observable grant = {
    object:null,
    similar:[],
    next:null,
    prev:null
  };

  constructor() {
    makeObservable(this)
  }
  @action setFromValue = (values) => {
    this.start = 1;
    this.list.results = [];
    this.pagination = {limit:20, offset: 0};
    this.form = values;
  }
  @action buttonsValue = (values) => {
    this.loading = true
    this.start = 1;
    this.list.results = [];
    this.pagination = {limit:20, offset: 0};
    this.params = values;
  }
  @action reset = () => {
    this.grant = {};
    this.params = null;
    this.form = null;
    this.loading = true
  }
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @flow *
  getData(){
    const response = yield api.grants.data({...this.pagination, ...this.form, ...!this.params ? {new:true} : this.params});
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.results = [...this.list.results, ...response.data.results];
        this.start++;
      }else{
        this.list = response.data;
      }
      this.loading = false;
      Home.data.grants = response.data.results.slice(0, 6);
    }
  }
  @flow *
  detail(id){
    const response = yield api.grants.detail(id);
    if(!response.detail){
      this.grant = response.data;
    }else{
      this.error = true;
      this.loading = false;
    }
  }
  @flow * sendForm(values){
    let form = new FormData();
    for(let type in values){
      form.append(type, values[type])
    }
    const response = yield api.grants.sendForm(form);
    if(!response.detail){

    }

  }
  @flow *
  getForm(values){
    const response = yield api.grants.getForm(values);
    if(!response.detail){
      this.forms = response.data;
    }

  }
};

export default new Grants();