import React, { useEffect} from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Result} from "antd";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import AppealForm from "../../components/appealForm";
import { useStores } from "../../store";
import { Helmet } from "react-helmet";
import pdf from '../../assets/pdf.svg'
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import { currentLang } from "../../config";
const ProfilePrivilegeDetail = () => {
  const {i18n, t} = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const {id} = useParams();
  const {appeals, opportunities} = useStores()
  const {detail, rules} = appeals;

  useEffect(()=>{
    appeals.benefitAppeal(id);
    opportunities.reset()
  }, [appeals, opportunities, id]);
  if(opportunities.step === 2){
    return <Result
      icon={<SmileOutlined />}
      title={t("Ваше обращения успешно отправлено!")}
      extra={<NavLink to={`/${i18n.language}/profile/appeals`} className={'btn primary'}><Trans>Посмотреть</Trans></NavLink>}
    />
  }

  const downloadFile = (file) => {
    const fileName = file.split('/').pop();
    fetch(file, {
      method: 'GET',
      mode: 'no-cors',
    }).then(res => res.blob()).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }
  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>Возможности для молодежи</Trans>}/>
      }
      <Helmet>
        <title>{detail && detail[`title_${currentLang()}`]}</title>
        <meta property="og:description" content={detail && detail[`content_${currentLang()}`]} />
      </Helmet>
      <div className={'center-content profile  animate__animated animate__fadeIn'}>
        <div className={'privilege-detail'}>
          <div className={'d-flex align-center justify-spacebetween'}>
            <strong className={'d-flex title'}>{detail && detail[`title_${currentLang()}`]}</strong>
          </div>
          <ul className={'list-benefit'}>
            {
              rules.map((item, index) => (
                <li key={index}>
                  <strong className={'title'}><Trans>{item.title}</Trans></strong>
                  <div className={'caption'}>
                    {
                      item.title === 'Правовое основание услуги' ?
                        item.description.map((item, index)=>{
                          return <div key={index}>
                            <p>{item[`title_${currentLang()}`]}</p>
                            <a href={item.url}>{item.url}</a>
                          </div>
                        })
                        : item.title === 'Форма для обращения по льготам' ?
                        <div className={'d-flex align-start'}>
                          <button className={'file'} onClick={()=>downloadFile(item.description)}>
                            <img src={pdf} alt="" width={30} height={30}/>
                          </button>
                          <p>Форма по "{detail && detail[`title_${currentLang()}`]}"</p>
                        </div>
                        : <div className={'text'} dangerouslySetInnerHTML={{__html:item.description}}/>

                    }
                  </div>
                </li>
              ))
            }
          </ul>

        </div>

      </div>
      <div className={'form-privilege'}>
        <div className={'benefit-form'}>
          <h2><Trans>Отправка обращении по льготам</Trans></h2>
          <AppealForm/>
        </div>
      </div>
    </>
  )
};

export default observer(ProfilePrivilegeDetail)