import React, { useEffect, useState } from 'react';
import { Image, Modal, Spin } from "antd";
import { NavLink, useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import { useStores } from "../../store";
import {
  baseUrl, currentLang,
  formatDateClient,
  formatNum,
  truncateString
} from "../../config";
import moment from "moment";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import MapDetail from "../../components/map";
import E404 from "../../components/e404/404";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import FormButton from "../../components/form/button";
import DetailForm from "../../components/form";
import FilesDownload from "../../components/filesUpload";

const GrantDetail = () => {
  const {id} = useParams();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const {Grants} = useStores();
  const [visible, setVisible] = useState(false);
  useEffect(()=>{
    Grants.detail(id);
    return ()=>{
      Grants.reset();
    }
  }, [Grants, id]);
  if(Grants.error){
    return <E404/>
  }
  return(
    <>
      {
        Grants.grant.object ?
          <>
            {
              isMobile && <MobileNavigation title={<Trans>Актуальные гранты</Trans>}/>
            }
            <Helmet>
              <title>{Grants.grant.object[`title_${currentLang()}`]}</title>
              <meta property="og:url" content={baseUrl} />
              <meta property="og:type" content={'website'} />
              <meta property="og:title" content={Grants.grant.object[`title_${currentLang()}`]} />
              <meta property="og:description" content={Grants.grant.object[`content_${currentLang()}`].replace(/<[^>]+>/g, '')} />
              <meta property="og:image" content={Grants.grant.object.image} />
            </Helmet>
            <div className={'center-content animate__animated animate__fadeIn'}>
              <div className={'article-detail'}>
                <div className={'detail-content'}>

                  <div className={' header'}>
                    <h1>{Grants.grant.object[`title_${currentLang()}`]}</h1>
                    <div className={'d-flex align-center'}>
                      <span className={'date'}>{formatDateClient(Grants.grant.object.created_at)}</span>
                      <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                  stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                  d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                  stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                        {formatNum(Grants.grant.object.views_count)}
          </span>
                    </div>
                  </div>
                  <Image
                    src={`${baseUrl}${Grants.grant.object.image}`}
                  />
                  <ul className={'grant-description'}>
                    <li className={moment(Grants.grant.object &&Grants.grant.object.deadline) < moment() ? 'error' : ''}>
                      <Trans>Дедлайн</Trans>:<span>{formatDateClient(Grants.grant.object.deadline)}</span>
                    </li>
                    <li>
                      <Trans>Степень</Trans>: <span>{Grants.grant.object.graduation_level[`name_${currentLang()}`]}</span>
                    </li>
                    <li>
                      <Trans>Университет</Trans>:
                      <span>{Grants.grant.object.university[`name_${currentLang()}`]}</span>
                    </li>
                    <li>
                      <Trans>Программа</Trans>:
                      <span>{Grants.grant.object.program[`name_${currentLang()}`]}</span>
                    </li>
                  </ul>
                  <strong><Trans>Описание</Trans></strong>
                  <div className={'text'} dangerouslySetInnerHTML={{__html:Grants.grant.object[`content_${currentLang()}`]}}/>
                  <FilesDownload files={[{title:Grants.grant.object[`file_title1_${currentLang()}`],file:Grants.grant.object.file1},{title:Grants.grant.object[`file_title2_${currentLang()}`],file:Grants.grant.object.file2},{title:Grants.grant.object[`file_title3_${currentLang()}`],file:Grants.grant.object.file3}]}/>
                  <MapDetail title={Grants.grant.object[`title_${currentLang()}`]}>
                    {
                      Grants.grant.object.has_form && <FormButton id={id} setVisible={setVisible} store={Grants}/>
                    }
                  </MapDetail>

                </div>
                <div className={'popular'}>
                  <h3><Trans>В тему</Trans></h3>
                  {
                    Grants.grant.similar.map((item, index) => {
                      return  <NavLink key={index} to={`/${currentLang()}/grants/${item.id}`} className={'grant'}>
                        <div className={'img'}>
                          <img src={item.preview} alt={''}/>
                        </div>
                        <span>{formatDateClient(item.deadline)}</span>
                        {
                          item[`address_${currentLang()}`] && <span>{truncateString(item[`address_${currentLang()}`], 20)}</span>
                        }
                        {
                          item[`address_${currentLang()}`] && <span>{truncateString(item[`address_${currentLang()}`], 20)}</span>
                        }
                        {
                          item.graduation_level[`name_${currentLang()}`] && <span>{item.graduation_level[`name_${currentLang()}`]}</span>
                        }
                        <p>{truncateString(item[`title_${currentLang()}`], isMobile ? 90 : 80)}</p>
                      </NavLink>
                    })
                  }
                </div>
              </div>
            </div>
          </> : Grants.loading ? <div className={'d-flex justify-center'}><Spin size={'large'} spinning={true}/></div> : null
      }
      <Modal visible={visible} footer={null} header={null} onCancel={()=>{setVisible(false); document.body.classList.remove('show-popup')}}>
        <DetailForm setVisible={setVisible} store={Grants} id={Grants.forms.form} forms={Grants.forms}/>
      </Modal>
    </>
  )
};

export default observer(GrantDetail)