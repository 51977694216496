import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import { useStores } from "../../store";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import Loading from "../../components/loading";
import { Trans, useTranslation } from "react-i18next";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import { Result } from "antd";
import E404Token from "../../components/e404/E404Token";

const Token = () => {
  const {app} = useStores();
  const {i18n} = useTranslation()
  const history = useHistory();
  useEffect(()=>{
    app.authIDGov();
  }, [app]);
  if(app.error) {
    return <E404Token/>
  }
  else if (!app.access){
    return <Loading/>;
  }
  else if(history.search !== '' && !!localStorage.getItem('token')){
    return <Redirect from={history.location.pathname} to={`/${i18n.language}`}/>
  }
  return  <Result
    icon={<SmileOutlined />}
    title={<Trans>Вы успешно зарегистрировались!</Trans>}
    extra={<NavLink to={`/${i18n.language}/`} className={'btn primary'}><Trans>На главную</Trans></NavLink>}
  />

};

export default observer(Token)