import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Button, Form, Input, Select, Radio, message, notification, Alert } from "antd";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { currentLang } from "../../config";


const ChangeAddress = () => {
  const [form] = Form.useForm()
  const Option = Select.Option;
  const {t, i18n} = useTranslation()
  const {location, profile} = useStores()
  const [districtDisabled, setDistrictDisabled] = useState(profile.profile.region_id);
  const [type, setType] = useState('all');
  const [, setDistrict] = useState(false);
  useEffect(()=>{
    (async ()=>{
      await profile.getProfile()
      location.getPMRegion();
    })()
  }, [location, profile]);
  const getRegionID = async (value) => {
    if (value === 10) {
      const error = await location.getDistrictRegistry();
      if (!error) {
        setDistrict(false)
        profile.setTashkent(true);
      }
    } else {
      const error = await location.getPMDistrict(value);

      if (!error) {
        profile.setTashkent(false);
      }
    }
    form.resetFields(['district_id', 'address', 'street', 'house', 'apartment'])
    setDistrictDisabled(false)
  };
  const onChange = e => {
    setType(e.target.value)
  };
  const handleSuggestion = async q => {
    await location.suggestion(q, type);
  };
  const handleSelect = (value, key) => {
    location.setStreetID(key.key)
  };
  const getStreets = async id => {
   await location.getStreets(id);
  form.resetFields(['street', 'house', 'apartment']);
  };
  const handleChangeHouse = async (value) => {
    await location.getHouses(value);
  };
  const onFinish = async values => {
    values.tashkent = profile.profile.is_tashkent;
    values.street_id = location.street_id ? location.street_id : profile.profile.street_id;
    const error = await profile.changeAddress(values);
    if(error){
      error.map(item => (
        message.error(item)
      ));
    }else{
      notification.success({
        message:t('Вы успешно поменяли Адрес')
      })
    }
  }
  return(
    <>
      <div className={'profile-header-title'}>
        <h3><Trans>Изменить адрес</Trans></h3>
      </div>
      {
        profile.profile.is_oneid ?
        <>
          <Alert
            message={t("Напоминалка")}
            style={{marginBottom:15}}
            description={t("Вы уже поменяли свои данные через Единую систему идентификации. Ваши данные актуальны.")}
            type="info"
            closable
            showIcon
          />
          <ul className="profile-list">
            <li>{profile.profile.address}</li>
          </ul>
        </> :
        <Form
        form={form}
        layout={'vertical'}
        fields={profile.profileValues}
        onFinish={onFinish}
        className={`change-password-form ${profile.profile.is_tashkent ? 'tashkent-form' : ''}`}
        >
        <Form.Item label={t('Регион')} name={'region_id'} className={'select'}>
        <Select placeholder={t('Введите Регион')} onChange={getRegionID}>
        {
          location.region.map((item, idx) => {
            return item.title !== '' && <Option key={idx} value={item.id}>{item[`title_${i18n.language}`]}</Option>
          })
        }
        </Select>
        </Form.Item>
        {
          profile.profile.is_tashkent ?
            <>
              <Form.Item label={t('Район')} name={'district_id'} className={'select'}>
                <Select placeholder={t('Введите Район') }  disabled={districtDisabled} onChange={getStreets}>
                  {
                    location.districtRegistry.map((item, idx) => {
                      return <Option key={idx} value={item.id}>{item[`name_${currentLang()}`]}</Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item name={'type'} >
                <div className={'street-type'}>
                  <Radio.Group
                    defaultValue={type}
                    onChange={onChange}
                    optionType="button"
                  >
                    <Radio.Button value="all"><Trans>Все параметры</Trans></Radio.Button>
                    <Radio.Button value="street"><Trans>Улица</Trans></Radio.Button>
                    <Radio.Button value="massif"><Trans>Массив</Trans></Radio.Button>
                    <Radio.Button value="quarter"><Trans>Квартал</Trans></Radio.Button>
                  </Radio.Group>
                </div>
              </Form.Item>
              <Form.Item name={'street'}>
                <Select showSearch
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSuggestion}
                        onChange={handleSelect}
                        notFoundContent={false}
                >
                  {
                    location.streets.map((item, idx) => {
                      return <Option value={item[`full_name_${currentLang()}`]} key={item.id}>{item[`full_name_${currentLang()}`]}</Option>
                    })
                  }
                </Select>
              </Form.Item>
              <div className={'d-flex apartment'}>
                <Form.Item label={t('Дом')}  name={'house'}>
                  <Select showSearch
                          showArrow={false}
                          filterOption={false}
                          onSearch={handleChangeHouse}
                          notFoundContent={false}
                  >
                    {
                      location.houses.map((item) => {
                        return <Option value={item.number} key={item.id}>{item.number}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item label={t('Квартира')}  name={'apartment'}>
                  <Input/>
                </Form.Item>
              </div>

            </>
            :
            <div className={'d-flex'}>
              <Form.Item label={t('Район (Область)')}  disabled={districtDisabled} name={'district_id'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <Select placeholder={t('Введите Район (город)')}>
                  {
                    location.district.map((item, idx) => {
                      return <Option key={idx} value={item.id}>{item.title}</Option>
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item label={t('Адрес')} name={'address'}>
                <Input/>
              </Form.Item>
            </div>
        }
        <Button type={'primary'} htmlType={'submit'} className={'btn save primary'}><Trans>Сохранить</Trans></Button>
        </Form>
      }

    </>
  )
};

export default observer(ChangeAddress)