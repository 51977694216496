import { Alert, Button,   DatePicker, Form, Input,  Select } from "antd";
import React, { useState } from "react";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";
import Registration from "../../store/modules/Registration";
import ruRu from "antd/lib/locale/ru_RU";
import enEn from "antd/lib/locale/en_US";
import ConfigProvider from "antd/lib/config-provider";
import Rules from "../../components/rule";
import { currentLang } from "../../config";

const Step1Registration = ({...props}) => {
  const {location, Modals} = useStores()
  const {Option} = Select;
  const {t, i18n} = useTranslation()
  const { onFinish, onFinishFailed, error, form, loading} = props;
  const [value, setValue] = useState(undefined);
  const [disable, setDisable] = useState(true);
  const [tashkent, setTashkent] = useState(false);
  const [district, setDistrict] = useState(false);
  const [street, setStreet] = useState(true);

  const [house, setHouse] = useState(true);
  const getRegionID = async (value, key) => {
    if(value === 10){
      const error = await location.getDistrictRegistry();
      if(!error){
        setDistrict(false)
        setTashkent(true)
        form.resetFields(['district_id', 'street', 'house'])
      }
    }else{
      const error = await location.district2({pm_gov:1, region_id:key.key});
      if(!error){
        setDisable(false);
        setTashkent(false)
        form.resetFields(['district_id'])
      }
    }

  };
  const getStreets = async (id, values) => {
    const error = await location.getStreets(id);
    if(!error){
      setStreet(false)
      props.setStreetName(values.children);
      setValue(undefined)
      form.resetFields(['street', 'house'])
    }
  };
  const handleChange = async q => {
    const error = await location.suggestion(q);
    if(!error){
      setValue(null)
    }
  };
  const validatePassword = (rule, value, callback) => {
    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,14}$/.test(value)) {
      callback(t("Пароль должен состоять минимум из 8 символов, может содержать латинские буквы, цифры и специальные знаки."));
    } else {
      callback();
    }
  }
  const handleSelect = value => {
    location.setStreetID(value);
    setHouse(false);
    setValue(null)
    form.resetFields(['house'])
  };
  const handleChangeHouse = async (value) => {
    await location.getHouses(value);
  };

  return (
    <div className={`registration-wrapper auth-box animate__animated animate__fadeIn`}>
      <h3><Trans>Регистрация</Trans></h3>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      {error && <Alert message={error} type="error" style={{marginBottom:10}}/>}
      <Form
        layout={'vertical'}
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {
          Registration.step === 3 ?
            <>
              <strong><Trans>Как к вам обращаться?</Trans></strong>
              <Form.Item
                label={<Trans>Введите имя</Trans>}
                name="last_name"
                rules={[{ required: true, message: <Trans>Введите имя</Trans> }]}
              >
                <Input autoComplete="off" maxLength={30} placeholder={t('Введите имя')}/>
              </Form.Item>
              <Form.Item
                label={<Trans>Введите Фамилию</Trans>}
                name="first_name"
                rules={[{ required: true, message: <Trans>Введите Фамилию</Trans> }]}
              >
                <Input autoComplete="off" maxLength={30} placeholder={t('Введите Фамилию')}/>
              </Form.Item>
              <Form.Item
                label={<Trans>Введите отчество</Trans>}
                name="patronymic"
              >
                <Input autoComplete="off" maxLength={30} placeholder={t('Введите отчество')}/>
              </Form.Item>
              <ConfigProvider locale={currentLang() === 'en' ? enEn : ruRu}>
              <Form.Item
                label={<Trans>Введите дату рождения</Trans>}
                name="birth_date"
                rules={[{ required: true, message:<Trans>Введите дату рождения</Trans> }]}
              >
                  <DatePicker  placeholder={t('Введите дату рождения')} format={'DD.MM.YYYY'}/>
              </Form.Item>
              </ConfigProvider>
              <Form.Item
                label={<Trans>Введите Пол</Trans>}
                name="gender"
                rules={[{ required: true, message: <Trans>Введите Пол</Trans> }]}
              >
                <Select placeholder={t('Введите Пол')}>
                  <Option value="female">{<Trans>Женский</Trans>}</Option>
                  <Option value="male"><Trans>Мужской</Trans></Option>
                </Select>
              </Form.Item>
            </> :
            Registration.step === 4 ?
              <>
                <strong><Trans>Откуда вы</Trans>?</strong>
                <Form.Item
                  label={<Trans>Регион</Trans>}
                  name="region_id"
                  rules={[{ required: true, message: <Trans>Введите регион</Trans> }]}
                >
                  <Select onDropdownVisibleChange={(open)=> open && location.getRegion({pm_gov:1})} onChange={getRegionID} placeholder={t('Введите регион')}>
                    {
                      location.region.map((item, idx) => {
                        return item.title !== '' && <Option value={item.id} key={item.pm_gov_id}>{item[`title_${i18n.language}`]}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
                {
                  !tashkent
                    ?
                    <Form.Item label={<Trans>Район (Область)</Trans>} name={'district_id'}
                               rules={[{ required: true, message: <Trans>Обязательное поле</Trans>  }]}>
                      <Select placeholder={t('Введите Район (город)')} disabled={disable}>
                        {
                          location.district && location.district.map((item, idx) => {
                            return <Option value={item.id} key={idx}>{item.title}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                    :
                    <>
                      <Form.Item label={<Trans>Район (город)</Trans>} name={'district_id'}
                                 rules={[{ required: true, message: <Trans>Обязательное поле</Trans> }]}>
                        <Select placeholder={t('Введите Район (город)')} disabled={district} onChange={getStreets}>
                          {
                            location.districtRegistry.map((item, idx) => {
                              return <Option value={item.id} key={idx}>{item[`name_${currentLang()}`]}</Option>
                            })
                          }
                        </Select>
                      </Form.Item>
                      <Form.Item label={<Trans>Улица</Trans>} name={'street'} >
                        <Select
                          value={value}
                          showSearch
                          defaultActiveFirstOption={true}
                          showArrow={false}
                          filterOption={false}
                          onSearch={handleChange}
                          placeholder={t('Введите улицу')}
                          onChange={handleSelect}
                          disabled={street}
                          notFoundContent={null}
                        >
                          {
                            location.streets.map((item, idx) => {
                              return <Option key={idx} value={item.id}>{item[`full_name_${currentLang()}`]}</Option>
                            })
                          }
                        </Select>
                      </Form.Item>
                      <Form.Item label={<Trans>Дом</Trans>} name={'house'}>
                        <Select showSearch
                                showArrow={false}
                                value={value}
                                placeholder={t('Введите дом')}
                                filterOption={false}
                                onSearch={handleChangeHouse}
                                notFoundContent={false}
                                disabled={house}>
                          {
                            location.houses && location.houses.map((item, idx) => {
                              return <Option value={item.number} key={idx}>{item.number}</Option>
                            })
                          }
                        </Select>
                      </Form.Item>
                    </>
                }
              </> :
              Registration.step === 5 &&
              <>
                <strong><Trans>Еще чуть-чуть и все</Trans></strong>
                <Form.Item
                  label={<Trans>Введите Email</Trans>}
                  name="email"
                  type={'email'}
                  readonly
                  rules={[
                    { required: true, message:<Trans>Введите Email</Trans> },
                    {type:'email', message:<Trans>Введите корректный Email</Trans>}
                  ]}
                >
                  <Input autoComplete="new-email"  placeholder={t('Введите Email')}/>
                </Form.Item>
                <Form.Item
                  label={<Trans>Введите пароль</Trans>}
                  name="password"
                  hasFeedback
                  rules={
                    [
                    { required: true, message: <Trans>Введите пароль</Trans> },
                    {
                      validator:validatePassword
                    },
                    ]}
                >
                  <Input.Password autoComplete="off"  placeholder={t('Введите пароль')}/>
                </Form.Item>
                <Form.Item
                  label={<Trans>Повторите пароль</Trans>}
                  name="password2"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: <Trans>Повторите пароль</Trans>,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('Пароль не совбадает')));
                      },
                    }),
                  ]}
                >
                  <Input.Password autoComplete="off" placeholder={t('Введите пароль')}/>
                </Form.Item>
                <Rules/>
              </>
        }
        <Form.Item >
          <Button type="primary" loading={loading} htmlType="submit" className={'btn success'}>
            <Trans>{Registration.step === 3 || Registration.step === 4 ? t('Далее шаг') : t('Готово')}</Trans>
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
};
export default observer(Step1Registration)