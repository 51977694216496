import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Select, Space, Spin, Table } from "antd";
import { useStores } from "../../store";
import { NavLink, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";

const ExportDetail = () => {
  const [form] = Form.useForm();
  const { app, profile, location} = useStores()
  const { Option } = Select;
  const {id, type} = useParams()
  const {t, i18n} = useTranslation()
  const { TextArea } = Input;
  const [regionID, setRegionId] = useState(null);
  const [, setMahalla] = useState(null);
  const [visible, setVisible] = useState(false);
  const [phone, setPhone] = useState(false);
  const [form_id, setForm_id] = useState(false);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [closeed, setClosed] = useState(false);
  const [mahhallaPM, setMahallaPM] = useState(null);
  useEffect(()=>{
    profile.exportDetail({type, id})
  }, [profile, id, type])

  const {result = [], title = ''} = profile.exportsDetail;
  const downloadFile = (e, file) => {
    e.preventDefault()
    fetch(file, {
      headers:{
        Authorization:`token ${app.token}`
      }
    }).then(res => res.blob()).then(blob => {
      const fileName = file.split('/').slice(-1);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', fileName)
      a.setAttribute('target', '_blank')
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
  };
  const acceptRow = async (record) => {
    const error = await profile.sendAccept({status:'accepted', phone:record[t('Телефон')], form_id:record.id});
    if(error.status !== 200){
      return message.error(error.data.detail)
    }
    message.success('Смс отправлен на текуший номер')
  }
  let data =  result.map((item, index) => ({
    key:index,
    ...item,
    id:item.id.toString() ?? ''

  }));
  const columns = [];
  let obj = [];
  result.forEach((item) => {
    Object.keys(item).forEach((keys, i) => {
      if(!obj[keys] && keys !== 'id'){
        obj[keys] = [];
        columns.push({
          title:keys,
          dataIndex:keys,
          render:text =>(
            (text && text.match('/upload')) ? <div className="ant-upload-list-text-container">
              <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
                <div className="ant-upload-list-item-info">
                  <span className="ant-upload-span">
                    <div className="ant-upload-text-icon">
                      <span role="img" aria-label="paper-clip" className="anticon anticon-paper-clip">
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                          <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"/>
                        </svg>
                      </span>
                    </div>
                    <a onClick={(e)=>downloadFile(e, text)} rel="noopener noreferrer" className="ant-upload-list-item-name" title={text.split('/').slice(-1)} href={text}>{text.split('/').slice(-1)}</a>
                  </span>
                </div>
              </div>
            </div> : text
          )
        })
        if(i >= Object.keys(item).length - 1){
          columns.push({
            title: 'Действие',
            key: 'action',
            render: (text, record) => {
              return (
                <Space size="middle">
                  <Button htmlType={'button'} type={'primary'} onClick={()=>acceptRow(record)}>Принять</Button>
                  <Button htmlType={'button'} type={'primary'} onClick={()=>{setVisible(true); setForm_id(record.id); setPhone(record[t('Телефон')])}} danger>Отказать</Button>
                </Space>
              )
            }
          })
        }

      }
    })

  });

  const getRegionID = async (value, key) => {
    await location.resetFields();
    const error = await location.getPMDistrict(value);
    setRegionId(key.key)
    if (!error) {
      setDisable(false);
      form.resetFields(['district_id', 'mahalla_id'])
    }
  };
  const getCommynityID = async (id, key) => {
    setMahalla(key.key);
    setMahallaPM(regionID);
    const mahallaID = key.value;
    const error = await location.getCommunityPM({ mahallaID, regionID });

    if (!error) {
      setDisable(false);
    }

  };
  const handleGetCommunity = async q => {
    const error = await location.getCommunityPM2(q, mahhallaPM);
    if (!error) {
    }
  };
  const onFinish = async (values) => {
    setLoading(true);
    await profile.setParamsExport({...values, type, id});
    await profile.exportDetail()
    setLoading(false)
    setClosed(true)
  }
  const onFinish1 = async (values) => {
    values.status = 'rejected';
    const error = await profile.sendAccept({...values, phone, form_id});
    if(error.status !== 200){
      return message.error(error.data.detail)
    }
    setVisible(false)
  }
  const exportItem = async () => {

    const error = await profile.exportItem({type, id, ...form.getFieldsValue(true)})
    if(error){
      message.error(error)
    }
  }

  return  !Object.keys(profile.exportsDetail).length ? <div style={{textAlign:'center'}}><Spin size={'large'} spinning/></div> :
    <>
      <h3 className={'bordered'}>
        <NavLink to={`/${i18n.language}/export`}>
          <svg aria-hidden="true" focusable="false" viewBox="0 0 64 64" width="64" height="64" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="30.5" stroke="#0E1724" strokeWidth="3"/>
            <path d="M29.333 41.333L20 32m0 0l9.333-9.333M20 32h24" stroke="#000" strokeWidth="3" strokeLinecap="round"
                  strokeLinejoin="round"/>
          </svg>
        </NavLink>
        {title}
      </h3>
      <div className={'d-flex justify-end  export-action'}>
        <button onClick={() => exportItem()} className={'btn exportButtons btn-primary small'}>Экспорт</button>
      </div>
      <div className={'filters  mb-40'}>
        <Form form={form} onFinish={onFinish}>
          <div className={'buttons align-center'}>
            <Form.Item name={'region_id'}>
              <Select placeholder={t('Введите Регион')} placement={'bottomLeft'} dropdownMatchSelectWidth={false}
                      onDropdownVisibleChange={(open) => open && location.getPMRegion()} onChange={getRegionID}>
                {
                  location.region.map((item) => {
                    return (item.title !== '' && item.pm_gov_id !== 9999) &&
                      <Option value={item.id} key={item.pm_gov_id}>{item.title}</Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item name={'district_id'}>
              <Select placement={'bottomLeft'} dropdownMatchSelectWidth={false}
                      placeholder={t('Введите Район (город)')} onChange={getCommynityID} disabled={disable}>
                {
                  location.district.map(item => (
                    <Option value={item.pm_gov_id} key={item.id}>{item.title}</Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item name={'mahalla_id'}>
              <Select
                showSearch
                filterOption={false}
                onSearch={handleGetCommunity}
                notFoundContent={false}
                showArrow={false}
                placement={'bottomLeft'}
                placeholder={t('Махалля')}
                disabled={disable}
              >
                {
                  location.community.map((item, idx) => {
                    return <Option value={item.id} key={idx}>{item.title}</Option>
                  })
                }
              </Select>
            </Form.Item>
            <Button className={'btn btn-primary small'} htmlType={'submit'}
                    style={{ width: 'auto' }}>Фильтровать</Button>
            {
              closeed && <Button type="primary" danger onClick={() => {
                form.resetFields();
                onFinish().then(r => setClosed(false))
              }} shape="circle" icon={<CloseOutlined/>} size={'small'}/>

            }
          </div>
        </Form>
      </div>
      <Table loading={loading} bordered pagination={false} columns={columns} dataSource={data} scroll={{x:'100%'}}/>
      <Modal
        title="Причина отказа"
        visible={visible}
        onOk={null}
        onCancel={()=>setVisible(false)}
        footer={null}
      >
        <Form onFinish={onFinish1} style={{ textAlign: 'center' }}>
          <Form.Item name={'reason'} rules={[{ required: true, message: 'Обязательное поле' }]}>
            <TextArea/>
          </Form.Item>
          <Space>
            <Button htmlType={'button'} onClick={() => setVisible(false)} type={'primary'}
                    className={'btn default'}>Отмена</Button>
            <Button htmlType={'submit'} danger type={'primary'} className={'btn btn-error'}>Отказать</Button>
          </Space>
        </Form>
      </Modal>
    </>
};

export default observer(ExportDetail)