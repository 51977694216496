import React, { useEffect, useState } from 'react';
import { Alert, Button, Col,  Form, Input, message, notification, Row, Select} from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { Redirect, useLocation } from "react-router-dom";
import { currentLang, formatDateClient, formatDateServer } from "../../config";
import InfoText from "../../components/alert";
import { useMediaQuery } from "react-responsive";
import DatePickerPortal from "../../components/datepicker";
import InputMask from "react-input-mask";
const Cabinet = () => {
  const { t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const {profile} = useStores();
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({});
  const location = useLocation()
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const {Option} = Select;
  useEffect(()=>{
    forceUpdate({});
  }, [])
  const onFinish = async values => {
    values.birth_date = formatDateServer(values.birth_date)
    const error = await profile.edit(values);
    if(!error){
      notification.success({
        message:t('Успех'),
        description:t('Вы успешно поменяли свои учетные данные.')
      })
    }
  };
  // const changeByGCP = async () => {
  //   const error = await profile.edit({...profile.profile, gcp_verified:true});
  //   if(!error){
  //     notification.success({
  //       message:t('Успех'),
  //       description:t('Вы успешно поменяли свои учетные данные.')
  //     })
  //   }
  //   setLoading(false)
  // };

  if(profile.profile.role){
    return <Redirect from={location.pathname} noThrow to={`/${currentLang}/pm`}/>
  }
  const getPersonalInfo = async values => {
    setLoading(true)
    values.document = `${values.series.toUpperCase()}${values.number}`;
    const date = values.birthdate.split('.');
    values.birthdate = `${date[2]}-${date[1]}-${date[0]}`
    delete values.series;
    delete values.number;
    const error = await profile.getProfileByGCP2(values);
    if(error){
      message.error(error)
    }
    setLoading(false)

  }
  const validateNumber = (rule, value, callback) => {
    const reg = /[^\d]+/;
    if(reg.test(value)){
      value.replace(/[^\d]+/g, '')
      callback('Введите только цифры')
    }else{
      callback()
    }
  };
  const validateSeries = (rule, value, callback) => {
    const reg = /[^a-zA-z]+/;
    if(reg.test(value)){
      value.replace(reg, '')
      callback('Введите только латинские буквы')
    }else{
      callback()
    }
  };
  return (
    <>
      <div className={'profile-header-title'}>
        <h3><Trans>Личные данные</Trans></h3>
      </div>
      {
        (profile.profile.is_oneid || profile.profile.gcp_verified) && <Alert
          message={t("Напоминалка")}
          style={{marginBottom:15}}
          description={t("Вы уже поменяли свои данные через Единую систему идентификации. Ваши данные актуальны")}
          type="info"
          closable
          showIcon
        />
      }
      {
        !(profile.profile.is_oneid || profile.profile.gcp_verified) ?
          <>
            <InfoText text={<Trans>Паспортные данные нужны для использования возможностей нашего портала по отправке обращений в государственные органы по вопросам мол одежи</Trans>}/>

            <Form form={form} layout={'vertical'} onFinish={getPersonalInfo} className={'form-gcp'}>
              <div>
                <Form.Item nam={'document'} label={t('Серия и номер паспорта или ID карты')}>
                  <Form.Item name={'series'} rules={[
                    { required: true, message: t('Обязательное поле') },
                    {max: 2, message: t('Обязательное поле') },
                    {
                      validator: validateSeries,
                    }
                  ]}>
                    <Input className={'input-seria'} maxLength={2}/>
                  </Form.Item>
                  <Form.Item  rules={[
                    { required: true, message: t('Обязательное поле') },
                    {max: 7, message: t('Минимальное кол-во 7 цифр') },
                    {
                      validator: validateNumber,
                    }
                  ]} name={'number'}>
                    <Input className={'input-number'}  maxLength={7}/>
                  </Form.Item>
                </Form.Item>
                <Form.Item label={t('Дата рождения')} rules={[
                  { required: true, message: t('Обязательное поле') },
                  {max: 10, message: t('Минимальное кол-во 8 цифр') }
                ]} name={'birthdate'}>
                  <InputMask className={'ant-input ant-picker'}  type="tel" maskChar={''}  mask="99.99.9999"  placeholder="DD.MM.YYYY">
                    {(inputProps) => <Input type="tel"  {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </div>
              <Form.Item shouldUpdate>
                {() => (
                  <Button disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  } loading={loading} type={'primary'} htmlType={'submit'} className={'btn primary'}><Trans>Проверить</Trans></Button>
                )}
              </Form.Item>

            </Form>

            <Form
              className={'profile-form'}
              layout={'vertical'}
              onFinish={onFinish}
              fields={profile.profileValues}
            >
              <Row gutter={[24, 0]}>
                <Col className={'animate__animated animate__fadeInUp'} span={isMobile ? 24 : 12}>
                  <Form.Item
                    label={t('Имя')}
                    name={'last_name'}
                    rules={[{required:true, message:t('Введите имя')}]}
                  >
                    <Input autoComplete="off" maxLength={30} placeholder={t('Введите имя')}/>
                  </Form.Item>
                </Col>
                <Col className={'animate__animated animate__fadeInUp'} span={isMobile ? 24 : 12}>
                  <Form.Item
                    label={t('Фамилия')}
                    name={'first_name'}
                    rules={[{required:true, message:t('Введите Фамилию')}]}
                  >
                    <Input autoComplete="off" maxLength={30} placeholder={t('Введите Фамилию')}/>
                  </Form.Item>
                </Col>
                <Col className={'animate__animated animate__fadeInUp'} span={isMobile ? 24 : 12}>
                  <Form.Item
                    label={t('Отчество')}
                    name={'patronymic'}
                    rules={[{required:true, message:t('Введите Отчество')}]}
                  >
                    <Input autoComplete="off" maxLength={30} placeholder={t('Введите Отчество')}/>
                  </Form.Item>
                </Col>
                <Col className={'animate__animated animate__fadeInUp'} span={isMobile ? 24 : 12}>
                  <DatePickerPortal label={t('Дата рождения')} name={'birth_date'}/>
                </Col>
                <Col className={'animate__animated animate__fadeInUp'} span={isMobile ? 24 : 12}>
                  <Form.Item
                    label={t('Пол')}
                    name={'gender'}
                    rules={[{required:true, message:t('Введите Пол')}]}
                  >
                    <Select  placeholder={'Введите Пол'}>
                      <Option value="female"><Trans>Женский</Trans></Option>
                      <Option value="male"><Trans>Мужской</Trans></Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Button type={'primary'} htmlType={'submit'} className={'btn save primary'}><Trans>Сохранить</Trans></Button>
            </Form>
          </> : null
      }
      {
        (profile.profile.is_oneid || profile.profile.gcp_verified) ?
          <>
            <ul className={'profile-list'}>
              <li>
                <strong><Trans>Имя</Trans></strong>
                <span>{profile.profile.first_name}</span>
              </li>
              <li>
                <strong><Trans>Фамилия</Trans></strong>
                <span>{profile.profile.last_name}</span>
              </li>
              <li>
                <strong><Trans>Отчество</Trans></strong>
                <span>{profile.profile.patronymic}</span>
              </li>
              <li>
                <strong><Trans>Дата рождения</Trans></strong>
                <span>{formatDateClient(profile.profile.birth_date)}</span>
              </li>
              <li>
                <strong><Trans>Пол</Trans></strong>
                <span>{profile.profile.gender ==='male' ? t('Мужской') : t('Женский')}</span>
              </li>
            </ul>
            {/*<Button type={'primary'} htmlType={'button'} onClick={()=>changeByGCP()} className={'btn save primary'}><Trans>Сохранить</Trans></Button>*/}
          </> : null
      }
    </>
  )
};
export default observer(Cabinet)