import {observable, makeObservable, action, flow} from "mobx";
import api from '../../api';
class Magazine {
  @observable list = {
    results:[],
    count:0,
  };
  @observable start = 1;
  @observable searchText = null;
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable category = [];
  @observable single = null;
  @observable currentCategory = null;
  @observable loading = true;

  constructor() {
    makeObservable(this)
  }
  @action setCurrentCategory = (id) => {
    this.start = 1;
    this.loading = true;
    this.list.results = [];
    this.pagination = {
      limit:20,
      offset:0
    };
    this.category.forEach(item => item.active = false);
    if(id.type){
      const current = this.category.find(item => item.id === id.type);
      current.active = true;
      this.currentCategory = id;
    }else{
      this.currentCategory = null;
    }

  }
  @action reset = () => {
    this.single = null;
  }
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @action download = (file, index) => {
    if(index !== null){
      this.list.results[index].loading = true;
    }else{
      this.single.loading = true;
    }
    fetch(file, {
      method:'GET'
    }).then((res) => res.blob()).then((blob)=>{
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.split('/').pop();
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

      }, 0);
      if(index !== null){
        this.list.results[index].loading = false;
      }else{
        this.single.loading = false;
      }
    })
  }
  @action search = (values) => {
    this.start = 1;
    this.loading = true;
    this.list.results = [];
    this.pagination = {
      limit:20,
      offset:0
    };
    this.searchText = values;
  };
  @flow * getList () {
    const response = yield api.magazine.list({...this.searchText, ...this.currentCategory, ...this.pagination});
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.count = response.data.count;
        this.list.results = [...this.list.results, ...response.data.results];
        this.start++;
      }else{
        this.list = response.data;
      }
      this.list.results = this.list.results.map(item => ({
          loading:false,
        ...item
      }));
      this.loading = false;

    }
  }
  @flow * getCategory () {
    const response = yield api.magazine.category();
    if(!response.detail){
      this.category = response.data.results.map(item => (
        {
          active:false,
          ...item
        }
      ));
    }
  }
  @flow * getSingle (id) {
    const response = yield api.magazine.single(id);
    if(!response.detail){
      this.single = response.data;
      this.single['loading'] = false;
    }
  }
}
export default new Magazine()