import React from 'react';
import { useStores } from "../../store";
import { observer } from "mobx-react";
import IdeasVote from "../../components/ideasLike";

const IdeasDetail = () => {
  const {ideas } = useStores();
  const {idea} = ideas;

  return (
    <div className={'ideas-items'}>
      <IdeasVote {...idea} truncate={0}/>
    </div>
  )
};
export default observer(IdeasDetail)