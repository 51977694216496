import React, { useState } from 'react'
import { Trans } from "react-i18next";
import ShareComponent from "../share-buttons";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import icon from '../../assets/icon.svg'
import { observer } from "mobx-react";
const MapDetail = (props) => {
  const [show, setShow] = useState(false);
  return (
    <div className={'location-panel'}>

      <div className={'d-flex align-center justify-spacebetween'}>
        {
          (props.latitude && props.longitude) &&  <button onClick={()=>setShow(!show)} className={'btn text location'}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0C7.34756 0 3.5625 3.78506 3.5625 8.4375C3.5625 10.0094 3.99792 11.5434 4.82198 12.8743L11.5197 23.6676C11.648 23.8744 11.874 24 12.1171 24C12.119 24 12.1208 24 12.1227 24C12.3679 23.9981 12.5944 23.8686 12.7204 23.6582L19.2474 12.7603C20.026 11.4576 20.4375 9.96277 20.4375 8.4375C20.4375 3.78506 16.6524 0 12 0ZM18.0406 12.0383L12.1065 21.9462L6.0172 12.1334C5.33128 11.0257 4.95938 9.74766 4.95938 8.4375C4.95938 4.56047 8.12297 1.39687 12 1.39687C15.877 1.39687 19.0359 4.56047 19.0359 8.4375C19.0359 9.7088 18.6885 10.9541 18.0406 12.0383Z" fill="#44D370"/>
              <path d="M12 4.21875C9.67378 4.21875 7.78125 6.11128 7.78125 8.4375C7.78125 10.7489 9.64298 12.6562 12 12.6562C14.3861 12.6562 16.2188 10.7235 16.2188 8.4375C16.2188 6.11128 14.3262 4.21875 12 4.21875ZM12 11.2594C10.4411 11.2594 9.17813 9.9922 9.17813 8.4375C9.17813 6.88669 10.4492 5.61563 12 5.61563C13.5508 5.61563 14.8172 6.88669 14.8172 8.4375C14.8172 9.96952 13.5836 11.2594 12 11.2594Z" fill="#44D370"/>
            </svg>
            <Trans>Геолокация</Trans>
          </button>
        }
        {
          props.children
        }
        <ShareComponent title={props.title}/>
      </div>

      {
        show && <div className={'map'}>
          {
           <YMaps>
              <div>
                <Map defaultState={{ center: [ +props.longitude, +props.latitude], zoom: 13, }}
                     width={'100%'} height={420}>
                  <Placemark
                    geometry={[+props.longitude,+props.latitude]}
                    options={{
                      iconLayout: 'default#image',
                      iconImageHref: icon,
                      iconImageSize: [43, 43],
                      iconImageOffset: [-3, -42]
                    }}
                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}

                  />
                </Map>
              </div>
            </YMaps>
          }

        </div>
      }

    </div>
  )
};
export default observer(MapDetail);