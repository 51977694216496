import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row } from "antd";
import { useStores } from "../../store";
import InputMask from "react-input-mask";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useMediaQuery } from "react-responsive";

const AuthComp = () => {
  const { profile, appeals } = useStores();
  const [form] = Form.useForm();
  const {t, i18n} = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const [, forceUpdate] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    forceUpdate({});
  }, [])
  const onFinish = async values => {
    setLoading(true)
    values.document = `${values.series.toUpperCase()}${values.number}`;
    const date = values.birthdate.split('.');
    values.birthdate = `${date[2]}-${date[1]}-${date[0]}`
    delete values.series;
    delete values.number;
    const error = await profile.getProfileByGCP2(values);
    if (error) {
      message.error(error);
      setLoading(false)
    }
  }
  const validateNumber = (rule, value, callback) => {
    const reg = /[^\d]+/;
    if(reg.test(value)){
      value.replace(/[^\d]+/g, '')
      callback('Введите только цифры')
    }else{
      callback()
    }
  };
  const validateSeries = (rule, value, callback) => {
    const reg = /[^a-zA-z]+/;
    if(reg.test(value)){
      value.replace(reg, '')
      callback('Введите только латинские буквы')
    }else{
      callback()
    }
  };
  return (
    <div className="over-bottom" style={{ position: "relative" }}>
     <span>
        <div className="send-appeal-form preliminary-form">
          <h4>{appeals.title[`title_${i18n.language}`]}</h4>
          <Form form={form} onFinish={onFinish} layout={'vertical'} className="card-block ">
            <Row gutter={[48, 24]}>
              <Col span={isMobile ? 24 : 10} className={'note'}>
               <p className="note-warning"><Trans>Просим ввести данные, удостоверяющие Вашу личность</Trans>. <Trans>Введенная персональная информация не будет разглашена и распространена третьим лицам!</Trans></p>
               <p className="note-warning"><Trans>Введение данных, удостоверяющих личность предоставляет следующие удобства</Trans>:</p>
               <ul className="note-list">
                  <li className="note-list-item"><span><Trans>часть формы отправки обращения будет заполнена автоматически</Trans></span></li>
                  <li className="note-list-item"><span><Trans>отправленное обращение будет сохранено на Ваше имя и будет доступно в любое время при входе в систему через сервис id.egov.uz</Trans></span></li>
                  <li className="note-list-item"><span><Trans>предотвращаются случаи необоснованного оставления обращения без рассмотрения</Trans></span></li>
               </ul>
              </Col>
              <Col span={isMobile ? 24 : 14}>
                <strong className="preliminary-form-title"><Trans>Данные документа, удостоверяющего личность</Trans></strong>
                <Row gutter={isMobile ? [15,0] : [24, 0]}>
                  <Col span={isMobile ? 8 : 6}>
                    <Form.Item  label={t('Серия')} rules={[
                      { required: true, message: t('Обязательное поле') },
                      {min: 2, message: t('Минимальное кол-во 2 буквы') },
                      {
                      validator: validateSeries,
                    }
                    ]} name="series">
                      <Input maxLength={2} autoComplete='off' style={{ textTransform: 'uppercase' }}/>
                    </Form.Item>
                  </Col>
                  <Col span={isMobile ? 16 : 18}>
                     <Form.Item label={t('Номер')} rules={[{ required: true, message: t('Обязательное поле') },{min: 7, message: t('Минимальное кол-во 7 цифр') },{
                       validator: validateNumber,
                     }]} name="number">
                        <Input maxLength={7} autoComplete='off'/>
                      </Form.Item>
                  </Col>

                </Row>
                <div className="helper-text mt-2 mb-2">
                  <span>*<Trans>В качестве документа, удостоверяющего личность можно использовать паспорт гражданина, ID-карту и загранпаспорт гражданина</Trans></span>
                </div>
               <div className="align-items-end col-xs-10 mb-2">
                  <div className="col-md-9">
                    <Form.Item label={t('Дата рождения')} rules={[
                      { required: true, message: t('Обязательное поле') },
                      {max: 10, message: t('Минимальное кол-во 8 цифр') }
                              ]} name={'birthdate'}>
                      <InputMask className={'ant-input'}  type="tel" maskChar={''}  mask="99.99.9999"  placeholder="DD.MM.YYYY">
                        {(inputProps) => <Input type="tel"  {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </div>
               </div>
               <div className="form-bottom">
                 <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      loading={loading}
                      className={'btn'}
                      type={'primary'}
                      htmlType="submit"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                      }
                    >
                      <Trans>Отправить</Trans>
                    </Button>
                  )}
                </Form.Item>
               </div>
              </Col>
            </Row>
          </Form>
        </div>
     </span>
    </div>
  )
};
export default observer(AuthComp)