import React, { useEffect} from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Badge, Button, Form, Input, Select, Spin } from "antd";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../../../store";
import Card from "./card";
import ExecutorDetail from "./detail";
import InfiniteScroll from "react-infinite-scroll-component";

const ExecutorPMInProgress = () => {
  let { id } = useParams();
  const [form] = Form.useForm();
  const { executor, profile} = useStores()
  const { Option } = Select;
  const {t} = useTranslation()
  useEffect(() => {
    if (id) {
      executor.detailAppeal(id);
    }else{
      executor.getPmAppeals({status:'processing'})
    }
    return () => {
      executor.reset()
    }
  }, [id, profile, executor]);

  const fetchData = () => {
    executor.setPagination()
    executor.getPmAppeals({status:'open'});
  }
  const changeHandler = async () => {
    await executor.setFilter(form.getFieldValue());
    executor.getPmAppeals({status:'processing'})
  }
  if(id){
    return <ExecutorDetail id={id} />
  }
  return <>
    <h3 className={'bordered'}><Trans>Открытые заявки</Trans></h3>
    <div className={'filters  mb-40'}>
      <Form form={form}  onChange={changeHandler}>
        <div className={'form progress'}>
          <Form.Item name={'id'}>
            <Input placeholder={t('Поиск по ID')}/>
          </Form.Item>
          <Button htmlType={'submit'}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </Button>
        </div>
        <div className={'buttons'}>
          <Form.Item name={'created_at'}>
            <Select placeholder={'Сортировать по дате'} onChange={changeHandler}>
              <Option value={'y'}><Trans>Свежие</Trans></Option>
              <Option value={null}><Trans>Старые</Trans></Option>
            </Select>
          </Form.Item>
          <Form.Item name={'status'}>
            <Select placeholder={'Статус'}  onChange={changeHandler}>
              <Option value={'is_any_news'}><Trans>Есть новости</Trans></Option>
              <Option value={'expired'}><Trans>Просроченные</Trans></Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    </div>
    <InfiniteScroll
      dataLength={executor.appeals.count}
      next={fetchData}
      hasMore={executor.appeals.count > executor.appeals.results.length}
      pullDownToRefreshThreshold={300}
      style={{overflowAnchor: 'auto', overflow: 'inherit'}}
      useWindow={true}
    >
      <Spin spinning={executor.loading}  size="large">
        <div className={'profile-appeals-box'}>
          {
            executor.appeals.results.map((item, index) => {
              return item.is_any_news ? <Badge.Ribbon  key={index}  text={t("Есть новости")} color="#44D370">
                <Card type={'processing'} {...item}/>
              </Badge.Ribbon> :
                <Card type={'processing'} key={index} {...item}/>
            })
          }
        </div>
      </Spin>
    </InfiniteScroll>
  </>
};

export default observer(ExecutorPMInProgress)