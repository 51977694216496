import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import {  formatDateClient, truncateString } from "../../../config";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "antd";

const Card = (item) => {
  const {t, i18n} = useTranslation()
  const [loading, setLoading] = useState(false);

  const acceptItem = async () => {
    setLoading(true);
    const error = await item.onAppoint(item);
    if(error){
      setLoading(false);
    }

  }
  return (
    <div  className={`appeals-item ${moment(item.deadline) < moment() ? 'expired' : item.status}`}>
      <NavLink key={item.index} to={`/${i18n.language}/to-agency/${item.id}`} >
        <ul>
          <li>
            <div>
              <span><Trans>ID и дата</Trans></span>
              <strong>№ {item.request_no} от {formatDateClient(item.created_at)}</strong>
            </div>
            <div>
              <span><Trans>Категория и сфера</Trans></span>
              <strong>{truncateString(item.category, 130)}</strong>
            </div>
          </li>
          <li>
            <div>
              <span><Trans>Исполнитель</Trans></span>
              <strong>{item.last_name} {item.first_name} {item.middle_name}</strong>
            </div>
            <div>
              <div
                className={`status  ${moment.duration(moment(item.deadline).diff(moment())).days() <= 0 ? 'expired' : item.status}`}>
                {item.status === 'processing' ? t('На рассмотрении') : item.status === 'answered' ? t('Есть ответ') : t('Закрытая')}
              </div>
            </div>
          </li>
          <li>
            <div>
              <span><Trans>Срок исполнения до</Trans></span>
              <strong>{formatDateClient(item.deadline)}</strong>
            </div>
            <div>
              <span><Trans>Осталось</Trans></span>
              <strong>{moment.duration(moment(item.deadline).diff(moment())).days() <= 0 ? 0 : moment.duration(moment(item.deadline).diff(moment())).days()} <Trans>дней</Trans></strong>
            </div>
          </li>
          <li className={'full'}>
            <div>
              <span><Trans>Текст обращения</Trans></span>
              <p>{truncateString(item.description, 100)}</p>
            </div>
          </li>
        </ul>
      </NavLink>
      <div className={`deadline ${moment.duration(moment(item.deadline).diff(moment())).days() <= 0 ? 'expired' : item.status}`}>
        <div className={'date-panel'}>
          {
            item.status === 'closed' ?
              <>
                <strong><Trans>Дата закрытие</Trans> {formatDateClient(item.updated_at)}</strong>
              </> :
              <>
                <div className={'btn-panel'}>

                  <Button className={'appoint-btn pt-0'} loading={loading} onClick={()=>acceptItem()}><Trans>Принять</Trans></Button>
                  <Button className={'appoint-btn  pt-0 reject-btn'}  onClick={()=>item.visibleHandler(item)}><Trans>Перенаправить</Trans></Button>
                </div>

              </>
          }
        </div>

      </div>
    </div>
  )
}
export default observer(Card)