import { makeObservable,action, observable, flow } from "mobx";
import api from '../../api';
import app from '../app';
class Registration {
  @observable step = 1;
  @observable form = {};
  constructor() {
    makeObservable(this)
  }
  @action setForm = (values) => {
    for(let val in values){
      this.form[val] = values[val]
    }
    this.step +=1;
  }
  @action reset = () => {
    this.step = 1;
  }
  @flow * signupCode(values){
    const response = yield api.app.signupCode(values);
    if(response.detail){
      return response.detail
    }else{
      this.step = 2
    }
  }

  @flow * confirmCode(values){
    const response = yield api.app.confirmCode(values);
    if(response.status !== 200){
      return response
    }else{
      this.step = 3
    }
  }
  @flow * registration(values){
    const response = yield api.app.registration({...values, ...this.form});
    if(response.data.detail || response.status === 500){
      let error = [];
      for(let key in response.data.detail){
        error.push(response.data.detail[key])
      }
      debugger
      return error.map(item => item)
    }
   else{
      app.access = true;
      localStorage.setItem('token', response.data.token);
      api.apiClient.defaults.headers.Authorization = `token ${response.data.token}`;
      app.token = `token ${response.data.token}`;
    }
  }


};

export default new Registration();