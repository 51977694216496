import { action,  makeObservable, observable } from "mobx";

class Modals{
  @observable modal = {
    authType:false,
    contest:false,
    burger:false,
    changePeriod:false,
    auth:false,
    registration:false,
    success:false,
    successForm:false,
    executor:false,
    filterPopup:false,
    library:false,
    vacancy:false,
    createIdea:false,
    howReg:false,
    rule:false,
    successIdeas:false,
    policy:false,
    ideasDetail:false,
    recoverPassword:false,
    denyReasonModal:false,
    reviewdModal:false,
    setExecutorModal:false,
  };
  @observable showPopup = false;
  @observable showMemberPopup = false;
  @observable showIdeasModal = true;

  constructor() {
    makeObservable(this);
  }

  @action hide = () => {
    this.showPopup = false;
    Object.keys(this.modal).forEach(item => this.modal[item] = false);
  }

  @action show = (value) =>{
    if(value !== 'howReg' && value !== 'rule'){
      this.hide()
    }
    if(value !== 'burger' && value !== 'howReg' && value !== 'rule' && value !== 'policy'){
      this.showPopup = true;
    }
    this.modal[value] = !this.modal[value];
  }
}
export default new Modals();