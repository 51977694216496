import React from 'react';
import { observer } from "mobx-react";
import { useStores } from "../../store";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Turizm from "./turizm";
import School from "./school";
import Band from "./band";
import MV from "./mv";
import OTV from "./otv";

const YouthIndices = () =>{
  const {i18n} = useTranslation()
  const {indices} = useStores();
  const changeFrame = index => {
    indices.changeFrame(index)
  }

  const content = {turizm:<Turizm/>,OTV:<OTV/>,MV:<MV/>,band:<Band/>,school:<School/>}
  return (
    <div className={'a-routeFadeIn indices-page '}>
      <Helmet>
        <title>Yoshlar Portali - Индекс молодежи</title>
      </Helmet>
      <div className={'header-title bordered'}>
        <h1><Trans>Индекс молодежи</Trans></h1>
      </div>
      <div className={'buttong'}>
        <ul>
          {
            indices.list.map((item,index) => {
              return <li key={index}><button onClick={()=>changeFrame(index)}  className={item.active ? 'active' : ''}><Trans>{item[`title_${i18n.language}`]}</Trans></button></li>
            })
          }
        </ul>
      </div>
      {
        indices.list.map((item,index) => {
          const {comp, active} = item;
          return active && content[comp]
        })
      }
    </div>
  )
};
export default observer(YouthIndices)