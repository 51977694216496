import React from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { useStores } from "../../store";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

const MobileNavigation = (props) => {
  const {Modals, app, profile} = useStores();
  const {i18n} = useTranslation();
  const history = useHistory()
  return (
    <div className={'d-flex align-center justify-spacebetween  mobile-navigation'}>
      <button className={'back'} onClick={()=>history.goBack()}>
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.09527 0.7618L8.33347 0L0.333496 7.99998L8.33347 16L9.09527 15.2382L1.8571 7.99998L9.09527 0.7618Z" fill="#2D2D2D"/>
        </svg>
      </button>
      <strong>{props.title}</strong>
      <div className={'d-flex align-center'}>
        {
          !app.access ? <button className={'user'}  onClick={() => Modals.show('authType')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.05025 16.0503C8.36301 14.7375 10.1435 14 12 14C13.8565 14 15.637 14.7375 16.9497 16.0503C18.2625 17.363 19 19.1435 19 21H5C5 19.1435 5.7375 17.363 7.05025 16.0503Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
          </button>
            :
          <NavLink className={'user'} to={profile.profile.role ? `/${i18n.language}/executor/new` : `/${i18n.language}/profile/cabinet`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.05025 16.0503C8.36301 14.7375 10.1435 14 12 14C13.8565 14 15.637 14.7375 16.9497 16.0503C18.2625 17.363 19 19.1435 19 21H5C5 19.1435 5.7375 17.363 7.05025 16.0503Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </NavLink>
        }

        <div className={'burger'} onClick={()=>Modals.show('burger')} >
          <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33203 2H22.6654M1.33203 10H22.6654M1.33203 18H22.6654" stroke="#2D2D2D" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  )
};

export default observer(MobileNavigation)
