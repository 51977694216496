import React, { useEffect} from 'react';
import { Button, Form, Input, Spin } from "antd";
import { useStores } from "../../store";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import banner from "../../assets/lib.jpg";
import { baseUrl, currentLang, truncateString } from "../../config";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink } from "react-router-dom";
const Magazine = () => {
  const {magazine} = useStores()
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  useEffect(()=>{
    magazine.getList()
    magazine.getCategory()
  }, [magazine]);
  const setCurrentCategory = async (id) => {
    magazine.setCurrentCategory({type:id});
    magazine.getList()
  }

  const onFinish = async (values) => {
    await magazine.search(values)
    magazine.getList()
  }
  const fetchData = () => {
    magazine.setPagination()
    magazine.getList()
  }
  return (
   <>
     {
       isMobile && <MobileNavigation title={<Trans>Библиотека</Trans>}/>
     }
     <Helmet>
       <title>Yoshlar Portali - Библиотека</title>
     </Helmet>
     <div className={'banner  animate__animated animate__fadeIn'} style={{background:`url(${banner}) no-repeat center center / cover`}}>
       <div className={'center-content'}>
         <h1><Trans>Библиотека</Trans></h1>
         <p><Trans>Литература — это самый приятный способ погрузиться в мир историй. Чтение – одно из самых больших удовольствий, которое может нам предложить свободное время. Все книги библиотеки молодёжного портала абсолютно бесплатны и доступны к скачиванию</Trans> </p>
       </div>
     </div>
     <div className={'center-content  animate__animated animate__fadeIn magazine-page'}>
      <div className={'d-flex filters align-center justify-spacebetween'}>
        <div className={isMobile ? ' mobile-filter-btn' : ''}>
          <button  className={null === magazine.currentCategory ? 'filter-btn active' : 'filter-btn'}
                   onClick={() => null !== magazine.currentCategory ? setCurrentCategory(null) : null}><Trans>Все виды</Trans></button>
          {
            magazine.category.map((item, index) => {
              return <button onClick={() => !item.active ? setCurrentCategory(item.id) : null}  key={index} className={`filter-btn ${item.active ? 'active' : ''}`}>{item[`name_${currentLang()}`]}</button>
            })
          }
        </div>
        <Form  onFinish={onFinish}>
          <Form.Item name={'search_text'}>
            <Input  placeholder={t('Поиск')}/>
          </Form.Item>
          <Button htmlType={'submit'} >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </Button>
        </Form>
      </div>
       <InfiniteScroll
         dataLength={magazine.list.results}
         next={fetchData}
         hasMore={magazine.list.count > magazine.list.results.length}
         pullDownToRefreshThreshold={300}
         style={{overflowAnchor: 'auto', overflow: 'inherit'}}
         useWindow={true}
       >
         <Spin spinning={magazine.loading} size={'large'}>
           <div className={'library-items'}>
             {
               magazine.list.results.map((item, index) => {
                 return <div key={index} className={'item'}>
                   <NavLink className={'img'} to={`/${i18n.language}/magazine/${item.id}`}>
                     <img width={720} height={1021} src={baseUrl+item.cover} alt={item[`title_${currentLang()}`]}/>
                   </NavLink>

                   <div className={'caption'}>
                     <div>
                       <span className={'author'}>{item.author[`name_${currentLang()}`]}</span>
                       <NavLink className={'library-link'} to={`/${i18n.language}/magazine/${item.id}`}>{item[`title_${currentLang()}`]} </NavLink>
                       <p>{truncateString(item[`description_${currentLang()}`].replace(/<[^>]+>/g, ''), 140)} </p>
                       <span className={'date'}><Trans>Год выпуска</Trans> {item.year} {currentLang() === 'ru' && 'г'}</span>
                     </div>
                     <Button loading={item.loading} onClick={()=>magazine.download(item.file, index)}><Trans>Скачать pdf</Trans></Button>
                   </div>
                 </div>
               })
             }
           </div>
         </Spin>
       </InfiniteScroll>
     </div>
   </>
  )
};

export default observer(Magazine)