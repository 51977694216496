import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Alert, Button, Form, Input, notification } from "antd";
import { useStores } from "../../store";

const ChangeEmail = () => {
  const { t} = useTranslation();
  const {profile} = useStores()
  const [error, ] = useState({show:false, message:''});
  const onFinish = async values => {
    const error = await profile.changeEmail(values);
   if(!error){
     notification.success({
       message:t('Вы успешно поменяли Email')
     })
   }
  }
  return (
    <>
      <div className={'profile-header-title'}>
        <h3><Trans>Изменить Email</Trans></h3>
      </div>
      {
        error.show &&
        error.message.map((item, idx) => {
          return <Alert banner closable showIcon style={{marginBottom:10}} key={idx} message={item} type="error"/>
        })
      }
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={{email:profile.profile.email}}
        className={'change-password-form'}
      >
        <Form.Item
          label={t('Введите новый Email:')}
          name={'email'}
          rules={[{
            type: 'email',
            message: 'Введите кооректный email!',
          },
            {required:true, message:t('Введите email')}]}
        >
          <Input autoComplete="off" placeholder={t('Введите email')}/>
        </Form.Item>
        <Button type={'primary'} htmlType={'submit'} className={'btn save primary'}><Trans>Сохранить</Trans></Button>
      </Form>
    </>
  )
};

export default ChangeEmail