import React from 'react';
import { Result } from "antd";
import {NavLink} from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const E404Token = () => {
  const {i18n} = useTranslation()
  return (
    <Result
      status="500"
      title="Временные работы на сервере попробуйте позже или напишите нам"
      subTitle="Ой! что то пошло не так"
      extra={
        <>
          <NavLink to={`/${i18n.language}`} className="btn success"><Trans>На главную</Trans></NavLink>
        </>
      }
    />
  )
};
export default E404Token