import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'mobx-react';
import store from './store';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './i18n';
import './scss/animate.scss';
import './scss/style.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spin } from "antd";
ReactDOM.render(<Provider {...store}>
  <Router>
    <Suspense fallback={<Spin spinning={true} size={'large'}/>}>
      <App />
    </Suspense>
  </Router>

</Provider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
