import React from 'react';
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";
import { Button, notification } from "antd";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import { useStores } from "../../store";
import { truncateString } from "../../config";

const IdeasVote = (item) => {
  const {truncate} = item;
  const {t} = useTranslation()
  const {ideas, Modals, app } = useStores();
  const showAuth = (key) => {
    Modals.show('auth');
    notification.close(key);
  };

  const showDetail = (item) => {
    ideas.detail(item);
    Modals.show('ideasDetail');
  }

  const voteHandler = async (id, type, ideasType = null) => {
    if (!app.access) {
      const key = 1;
      const btn = (
        <Button className={'primary'} size="small" onClick={()=>showAuth(key)}>
          <Trans>Авторизоваться</Trans>
        </Button>
      );
      notification.open({
        message: t('Внимание!'),
        description:
          t('Для подачи идеи вам нужно авторизоваться.'),
        btn,
        icon: <SmileOutlined style={{ color: '#faad14' }} />,
        key,
      });
    } else {
      const error = await ideas.votes({ id, type, ideasType });
      if (!error) {

      }
    }
  }
  return (
    <div className={'idea-item'}>
      {
        Modals.modal.ideasDetail && <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      }

      <div>
        <div className={'d-flex align-center justify-spacebetween'}>
          <span className={'id'}>ID: {item.id}</span>
          {
            item.likes >= 500 && <strong className={'popular'}>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.200195 13.8002C0.200195 13.485 0.262273 13.1729 0.382885 12.8818C0.503496 12.5906 0.680279 12.326 0.903139 12.1031C1.126 11.8803 1.39057 11.7035 1.68176 11.5829C1.97294 11.4623 2.28502 11.4002 2.6002 11.4002C2.91537 11.4002 3.22745 11.4623 3.51864 11.5829C3.80982 11.7035 4.07439 11.8803 4.29725 12.1031C4.52011 12.326 4.6969 12.5906 4.81751 12.8818C4.93812 13.1729 5.0002 13.485 5.0002 13.8002V23.4002C5.0002 24.0367 4.74734 24.6472 4.29725 25.0972C3.84716 25.5473 3.23671 25.8002 2.6002 25.8002C1.96368 25.8002 1.35323 25.5473 0.903139 25.0972C0.453052 24.6472 0.200195 24.0367 0.200195 23.4002V13.8002ZM6.6002 13.533V22.221C6.59992 22.8157 6.76535 23.3986 7.07792 23.9045C7.3905 24.4104 7.83785 24.8192 8.3698 25.085L8.4498 25.125C9.33761 25.5687 10.3165 25.7999 11.309 25.8002H19.9746C20.7146 25.8005 21.4319 25.5443 22.0043 25.0752C22.5767 24.6061 22.9687 23.9531 23.1138 23.2274L25.0338 13.6274C25.1266 13.1632 25.1152 12.6842 25.0005 12.2249C24.8858 11.7656 24.6707 11.3375 24.3705 10.9714C24.0704 10.6053 23.6928 10.3104 23.2649 10.1079C22.8371 9.90532 22.3696 9.80024 21.8962 9.80019H16.2002V3.4002C16.2002 2.5515 15.8631 1.73757 15.2629 1.13745C14.6628 0.537337 13.8489 0.200195 13.0002 0.200195C12.5758 0.200195 12.1689 0.368766 11.8688 0.668824C11.5688 0.968883 11.4002 1.37585 11.4002 1.8002V2.8674C11.4002 4.25216 10.9511 5.59958 10.1202 6.70739L7.8802 9.693C7.04933 10.8008 6.6002 12.1482 6.6002 13.533Z" fill="#5768FA"/>
              </svg>
              <Trans>Лучшие идеи</Trans>
            </strong>
          }

        </div>
        <strong tabIndex={1} onClick={() => showDetail(item)}>{truncate ? truncateString(item.title, 60) : item.title}</strong>
        <p>{truncate ? truncateString(item.description, 180) : item.description}</p>
      </div>
      <div className={'d-flex align-center justify-spacebetween'}>
        <div className={'idea-author'}>
          <Trans>Автор</Trans>:{item.user ? <strong>{item.user}</strong> : item.author && <strong>{item.author}</strong>}
        </div>
        <div className={'rating-box'}>
          <span className={item.user_reaction === 'like' ? 'active' : ''}
                onClick={() => voteHandler(item.id, 'like')}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.200195 13.8002C0.200195 13.485 0.262273 13.1729 0.382885 12.8818C0.503496 12.5906 0.680279 12.326 0.903139 12.1031C1.126 11.8803 1.39057 11.7035 1.68176 11.5829C1.97294 11.4623 2.28502 11.4002 2.6002 11.4002C2.91537 11.4002 3.22745 11.4623 3.51864 11.5829C3.80982 11.7035 4.07439 11.8803 4.29725 12.1031C4.52011 12.326 4.6969 12.5906 4.81751 12.8818C4.93812 13.1729 5.0002 13.485 5.0002 13.8002V23.4002C5.0002 24.0367 4.74734 24.6472 4.29725 25.0972C3.84716 25.5473 3.23671 25.8002 2.6002 25.8002C1.96368 25.8002 1.35323 25.5473 0.903139 25.0972C0.453052 24.6472 0.200195 24.0367 0.200195 23.4002V13.8002ZM6.6002 13.533V22.221C6.59992 22.8157 6.76535 23.3986 7.07792 23.9045C7.3905 24.4104 7.83785 24.8192 8.3698 25.085L8.4498 25.125C9.33761 25.5687 10.3165 25.7999 11.309 25.8002H19.9746C20.7146 25.8005 21.4319 25.5443 22.0043 25.0752C22.5767 24.6061 22.9687 23.9531 23.1138 23.2274L25.0338 13.6274C25.1266 13.1632 25.1152 12.6842 25.0005 12.2249C24.8858 11.7656 24.6707 11.3375 24.3705 10.9714C24.0704 10.6053 23.6928 10.3104 23.2649 10.1079C22.8371 9.90532 22.3696 9.80024 21.8962 9.80019H16.2002V3.4002C16.2002 2.5515 15.8631 1.73757 15.2629 1.13745C14.6628 0.537337 13.8489 0.200195 13.0002 0.200195C12.5758 0.200195 12.1689 0.368766 11.8688 0.668824C11.5688 0.968883 11.4002 1.37585 11.4002 1.8002V2.8674C11.4002 4.25216 10.9511 5.59958 10.1202 6.70739L7.8802 9.693C7.04933 10.8008 6.6002 12.1482 6.6002 13.533Z" fill="#5768FA"/>
                </svg>
            {item.likes}
          </span>
          <span className={item.user_reaction === 'dislike' ? 'active' : ''}
                onClick={() => voteHandler(item.id, 'dislike')}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.7957 12.1992C25.7957 12.8357 25.5428 13.4462 25.0928 13.8963C24.6427 14.3464 24.0322 14.5992 23.3957 14.5992C22.7592 14.5992 22.1487 14.3464 21.6986 13.8963C21.2486 13.4462 20.9957 12.8357 20.9957 12.1992V2.59922C20.9957 1.9627 21.2486 1.35225 21.6986 0.902163C22.1487 0.452076 22.7592 0.199219 23.3957 0.199219C24.0322 0.199219 24.6427 0.452076 25.0928 0.902163C25.5428 1.35225 25.7957 1.9627 25.7957 2.59922V12.1992ZM19.3957 12.4664V3.77842C19.3961 3.18393 19.2309 2.60107 18.9187 2.0952C18.6064 1.58933 18.1594 1.18046 17.6277 0.914419L17.5477 0.874419C16.6584 0.429966 15.6779 0.19879 14.6837 0.199219H6.0197C5.27966 0.198926 4.56239 0.455142 3.99002 0.924243C3.41764 1.39334 3.02555 2.04633 2.88051 2.77202L0.960505 12.372C0.867695 12.8364 0.879092 13.3155 0.993874 13.7749C1.10866 14.2343 1.32396 14.6625 1.62426 15.0286C1.92457 15.3947 2.30238 15.6896 2.73045 15.8921C3.15852 16.0945 3.62618 16.1994 4.09971 16.1992H9.79571V22.5992C9.79571 23.4479 10.1328 24.2618 10.733 24.862C11.3331 25.4621 12.147 25.7992 12.9957 25.7992C13.4201 25.7992 13.827 25.6306 14.1271 25.3306C14.4271 25.0305 14.5957 24.6236 14.5957 24.1992V23.132C14.5957 21.7472 15.0448 20.3998 15.8757 19.292L18.1157 16.3064C18.9466 15.1986 19.3957 13.8512 19.3957 12.4664Z" fill="#BCBCBC"/>
            </svg>
            {item.dislikes}
          </span>
        </div>
      </div>
    </div>
  )
};

export default observer(IdeasVote)