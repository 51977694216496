import React from 'react';
import { observer } from "mobx-react";
import { formatDateClient } from "../../../config";
const ExecutorDetail = (props) => {
  return (
   <>
     <h3>Обращения ID {props.detail && props.detail.id}</h3>
     {
       props.detail &&
       <>
         <ul  className={'animate__animated animate__fadeIn profile-list'}>
           <li>
             <strong>ФИО:</strong>
             <span><span>{props.detail.first_name} {props.detail.last_name} {props.detail.patronymic}</span></span>
           </li>
           <li>
             <strong>Номер телефона:</strong>
             <span>+{props.detail.phone}</span>
           </li>
           <li>
             <strong>Адрес:</strong>
             <span>{props.detail.current_address}</span>
           </li>
           <li>
             <strong>Номер обращений:</strong>
             <span>{props.detail.request_no}</span>
           </li>
           <li>
             <strong>Дата обращения:</strong>
             <span>{formatDateClient(props.detail.user.created_at)}</span>
           </li>
           <li>
             <strong>Категории:</strong>
             <span>{props.detail.category}</span>
           </li>
           <li>
             <strong>Сфера:</strong>
             <span>{props.detail.category}</span>
           </li>
           <li className={'full'}>
             <strong>Текст обращения</strong>
             <p>{props.detail.description}</p>
           </li>
         </ul>

       </>
     }
   </>
  )
};

export default observer(ExecutorDetail)