import React from 'react';
import i18n from "../../i18n";
import { Link,  useLocation } from "react-router-dom";
import pathToRegexp, { compile } from "path-to-regexp";
import { useMediaQuery } from "react-responsive";
import { setBaseUrl } from "../../api";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import 'moment/locale/uz';
import 'moment/locale/uz-latn';
import { currentLang } from "../../config";
const LangSwitcher = () => {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const generateLanguage = (locale) => {
    const ROUTE = "/:locale/:path*";
    const definePath = compile(ROUTE);
    const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);
    let subPaths = null;
    if (routeComponents && routeComponents[2]) {
      subPaths = routeComponents[2].split("/");
    }

    return definePath({
      locale,
      path: subPaths
    });
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setBaseUrl(lng);
    currentLang(lng);
    moment.locale(lng === 'uz' ? 'uz' : lng === 'oz' ? 'uz-Latn' : lng === 'en' ? 'en-gb' : 'ru' )

  };
  return (
    <div className={'lang-switcher'}>
      <ul>
        <li>
          <span>{i18n.language  === 'uz' ? 'Ўз' : i18n.language === 'oz' ? "O'z" : i18n.language === 'kp' ? "Kp" : i18n.language === 'ru' ? "Ру" : i18n.language}</span>
          {
            isMobile && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2902 9.29006C16.9002 8.90006 16.2702 8.90006 15.8802 9.29006L12.0002 13.1701L8.12022 9.29006C7.73022 8.90006 7.10022 8.90006 6.71022 9.29006C6.32022 9.68006 6.32022 10.3101 6.71022 10.7001L11.3002 15.2901C11.6902 15.6801 12.3202 15.6801 12.7102 15.2901L17.3002 10.7001C17.6802 10.3201 17.6802 9.68006 17.2902 9.29006Z" fill="#323232"/>
            </svg>
          }
          <ul className={location.pathname.length > 4 ? 'white' : ''}>
            {
              i18n.options.lngs.map((item, index) => {
                return item !== i18n.language &&
                  <li key={index}>
                    <Link to={generateLanguage(item)}>
                                <span onClick={() => changeLanguage(item)}>
                                  {item  === 'uz' ? 'Ўз' : item === 'oz' ? "O'z" : item === 'kp' ? "Kp" : item === 'ru' ? "Ру" :  item}
                                </span>
                    </Link>
                  </li>
              })
            }
          </ul>
        </li>
      </ul>
    </div>
  )
};

export default LangSwitcher
