import React from 'react';
import { Checkbox, Form } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";


const Rules = () => {
  const {Modals} = useStores()
  const {t} = useTranslation();
  const getRule = e => {
    e.preventDefault();
    Modals.show('rule')
  }
  return (
    <Form.Item name={'agreement'} valuePropName="checked" rules={[{
      validator: (_, checked) =>
        checked ? Promise.resolve() : Promise.reject(new Error(t('Обязательное поле'))),
    }]}>
      <Checkbox>
        <Trans i18nKey="Ознакомлен">
          Ознакомлен <a href={'#'} onClick={getRule} className={'link'}>об ответственности</a>
        </Trans>
      </Checkbox>
    </Form.Item>
  )
};
export default Rules