import React from 'react';
import {Table} from 'antd'
import { useTranslation } from "react-i18next";
const Band =  () => {
  const {i18n} = useTranslation()
  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index === 0) {
      obj.props.colSpan = 0;
    }
    return obj;
  };
  let columns = [];
  let data = [];

  if(i18n.language === 'ru'){
     columns = [
      {
        title:'п/н',
        dataIndex:'key',
        width:60,
        render: renderContent
      },
      {
        title:'название региона',
        dataIndex:'region',
        width:'20%',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        }
      },
      {
        title:'Трудоустроенны за рубежом в организованном порядке*',
        dataIndex:'has_work',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      },
      {
        title:'Количество занятой молодежи, включенной в Единую национальную систему труда **',
        dataIndex:'all_employee',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      },
      {
        title:'Уровень безработицы (%)',
        dataIndex:'not_work',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      }
    ];
     data = [
      {
        key:0,
        region:'Всего:',
        has_work:'3 212',
        all_employee:'1 240 397',
        not_work:'15.2'
      },
      {
        key:1,
        region:"Республика Каракалпакстан",
        has_work:'778',
        all_employee:'61 230',
        not_work:'15.7'
      },
      {
        key:2,
        region:'Андижанская область',
        has_work:'214',
        all_employee:'89 694',
        not_work:'15.9'
      },
      {
        key:3,
        region:'Бухарская область',
        has_work:'39',
        all_employee:'76 631',
        not_work:'16.3'
      },
      {
        key:4,
        region:'Джизакская область',
        has_work:'62',
        all_employee:'43 677',
        not_work:'15.7'
      },
      {
        key:5,
        region:'Кашкадарьинская область',
        has_work:'242',
        all_employee:'82 808',
        not_work:'16'
      },
      {
        key:6,
        region:'Навоийская область',
        has_work:'5',
        all_employee:'58 067',
        not_work:'13.6'
      },
      {
        key:7,
        region:'Наманганская область',
        has_work:'371',
        all_employee:'69 900',
        not_work:'15.3'
      },
      {
        key:8,
        region:'Самаркандская область',
        has_work:'545',
        all_employee:'111 784',
        not_work:'15.3'
      },
      {
        key:9,
        region:'Сурхандарьинская область',
        has_work:'156',
        all_employee:'53 463',
        not_work:'15.6'
      },
      {
        key:10,
        region:'Сырдарьинская область',
        has_work:'14',
        all_employee:'31 038',
        not_work:'15.4'
      },
      {
        key:11,
        region:'Ташкентская область',
        has_work:'33',
        all_employee:'129 843',
        not_work:'14.8'
      },
      {
        key:12,
        region:"'Ферганская область",
        has_work:'408',
        all_employee:'113 774',
        not_work:'15.7'
      },
      {
        key:13,
        region:'Хорезмская область',
        has_work:'333',
        all_employee:'50 118',
        not_work:'15.1'
      },
      {
        key:14,
        region:'Город Ташкент',
        has_work:'12',
        all_employee:'268 370',
        not_work:'12'
      }
    ];
  }
  if(i18n.language === 'uz'){
     columns = [
      {
        title:'т/р',
        dataIndex:'key',
        width:60,
        render: renderContent
      },
      {
        title:'ҳудуд номи',
        dataIndex:'region',
        width:'20%',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        }
      },
      {
        title:'Ташкиллаштирилган тартибда чет элга ишлашга жойлаштирилганлар*',
        dataIndex:'has_work',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      },
      {
        title:'Ягона миллий меҳнат тизимида киритилган банд ёшлар сони**',
        dataIndex:'all_employee',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      },
      {
        title:'Ишсизлик даражаси (%)',
        dataIndex:'not_work',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      }
    ];
     data = [
      {
        key:0,
        region:'Жами:',
        has_work:'3 212',
        all_employee:'1 240 397',
        not_work:'15.2'
      },
      {
        key:1,
        region:"Қорақалпоғистон Республикаси",
        has_work:'778',
        all_employee:'61 230',
        not_work:'15.7'
      },
      {
        key:2,
        region:'Андижон вилояти',
        has_work:'214',
        all_employee:'89 694',
        not_work:'15.9'
      },
      {
        key:3,
        region:'Бухоро вилояти',
        has_work:'39',
        all_employee:'76 631',
        not_work:'16.3'
      },
      {
        key:4,
        region:'Жиззах вилояти',
        has_work:'62',
        all_employee:'43 677',
        not_work:'15.7'
      },
      {
        key:5,
        region:'Қашқадарё вилояти',
        has_work:'242',
        all_employee:'82 808',
        not_work:'16'
      },
      {
        key:6,
        region:'Навоий вилояти',
        has_work:'5',
        all_employee:'58 067',
        not_work:'13.6'
      },
      {
        key:7,
        region:'Наманган вилояти',
        has_work:'371',
        all_employee:'69 900',
        not_work:'15.3'
      },
      {
        key:8,
        region:'Самарқанд вилояти',
        has_work:'545',
        all_employee:'111 784',
        not_work:'15.3'
      },
      {
        key:9,
        region:'Сурхондарё вилояти',
        has_work:'156',
        all_employee:'53 463',
        not_work:'15.6'
      },
      {
        key:10,
        region:'Сирдарё вилояти',
        has_work:'14',
        all_employee:'31 038',
        not_work:'15.4'
      },
      {
        key:11,
        region:'Тошкент вилояти',
        has_work:'33',
        all_employee:'129 843',
        not_work:'14.8'
      },
      {
        key:12,
        region:"'Фарғона вилояти",
        has_work:'408',
        all_employee:'113 774',
        not_work:'15.7'
      },
      {
        key:13,
        region:'Хоразм вилояти',
        has_work:'333',
        all_employee:'50 118',
        not_work:'15.1'
      },
      {
        key:14,
        region:'Тошкент шаҳри',
        has_work:'12',
        all_employee:'268 370',
        not_work:'12'
      }
    ];
  }
  if(i18n.language === 'oz'){
     columns = [
      {
        title:'T/r',
        dataIndex:'key',
        width:60,
        render: renderContent
      },
      {
        title:'Hudud nomi',
        dataIndex:'region',
        width:'20%',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        }
      },
      {
        title:'Tashkillashtirilgan tartibda chet elga ishlashga joylashtirilganlar*',
        dataIndex:'has_work',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      },
      {
        title:'Yagona milliy mehnat tizimida kiritilgan band yoshlar soni**',
        dataIndex:'all_employee',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      },
      {
        title:'Ishsizlik darajasi (%)',
        dataIndex:'not_work',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>
          };
        }
      }
    ];
     data = [
      {
        key:0,
        region:'Jami:',
        has_work:'3 212',
        all_employee:'1 240 397',
        not_work:'15.2'
      },
      {
        key:1,
        region:"Qoraqalpog'iston Respublikasi",
        has_work:'778',
        all_employee:'61 230',
        not_work:'15.7'
      },
      {
        key:2,
        region:'Andijon viloyati',
        has_work:'214',
        all_employee:'89 694',
        not_work:'15.9'
      },
      {
        key:3,
        region:'Buxoro viloyati',
        has_work:'39',
        all_employee:'76 631',
        not_work:'16.3'
      },
      {
        key:4,
        region:'Jizzax viloyati',
        has_work:'62',
        all_employee:'43 677',
        not_work:'15.7'
      },
      {
        key:5,
        region:'Qashqadaryo viloyati',
        has_work:'242',
        all_employee:'82 808',
        not_work:'16'
      },
      {
        key:6,
        region:'Navoiy viloyati',
        has_work:'5',
        all_employee:'58 067',
        not_work:'13.6'
      },
      {
        key:7,
        region:'Namangan viloyati',
        has_work:'371',
        all_employee:'69 900',
        not_work:'15.3'
      },
      {
        key:8,
        region:'Samarqand viloyati',
        has_work:'545',
        all_employee:'111 784',
        not_work:'15.3'
      },
      {
        key:9,
        region:'Surhxondaryo viloyati',
        has_work:'156',
        all_employee:'53 463',
        not_work:'15.6'
      },
      {
        key:10,
        region:'Sirdaryo viloyati',
        has_work:'14',
        all_employee:'31 038',
        not_work:'15.4'
      },
      {
        key:11,
        region:'Toshkent viloyati',
        has_work:'33',
        all_employee:'129 843',
        not_work:'14.8'
      },
      {
        key:12,
        region:"'Farg'ona viloyati",
        has_work:'408',
        all_employee:'113 774',
        not_work:'15.7'
      },
      {
        key:13,
        region:'Xorazm viloyati',
        has_work:'333',
        all_employee:'50 118',
        not_work:'15.1'
      },
      {
        key:14,
        region:'Toshkent shahri',
        has_work:'12',
        all_employee:'268 370',
        not_work:'12'
      }
    ];
  }
  return(
    <div className={'table-center'}>
      <h3>
        {
          i18n.language === 'oz' && 'Bandlik va mehnat munosabatlari vazirligi tomonidan Yoshlar siyosatini baholash milliy indeksi mezonlari'
        }
        {
          i18n.language === 'uz' && 'Бандлик ва меҳнат муносабатлари вазирлиги томонидан Ёшлар сиёсатини баҳолаш миллий индекси мезонлари'
        }
        {
          i18n.language === 'ru' && 'Критерии национального индекса оценки молодежной политики со стороны Министерства занятости и трудовых отношений'
        }
      </h3>
      <Table
        pagination={false}
        sticky
        columns={columns}
        dataSource={data}
        bordered
        size="large"
      />
      {
        i18n.language === 'oz' && <em>* Tashqi mehnat migratsiyasi orqali chet elga ishga joylashtirilgan yoshlar soni</em>
      }
      {
        i18n.language === 'uz' && <em>* Ташқи меҳнат миграцияси агентлиги орқали чет элга ишга жойлаштирилган ёшлар сони</em>
      }
      {
        i18n.language === 'ru' && <em>* Количество молодых людей, трудоустроенных за рубежом со стороны Агенства по внешней трудовой миграции</em>
      }
      {
        i18n.language === 'oz' && <em>**YMMTda yakka tartibda tadbirkorlik faoliyati bilan mug'ullanuvchi fuqarolar, o'zini o'zi band qilganlar hamda harbiylashgan tuzilmalarda ishlovchilar soni aks etiirilmaydi</em>
      }
      {
        i18n.language === 'uz' && <em>**ЯММТда якка тартибда тадбиркорлик фаолияти билан шуғулланувчи фуқаролар, ўзини ўзи банд қилганлар ҳамда ҳарбийлашган тузилмаларда ишловчилар сони акс эттирилмайди</em>
      }
      {
        i18n.language === 'ru' && <em>** В ЕНСТ не отражается количество граждан, занимающихся индивидуальной предпринимательской деятельностью, самозанятых и сотрудников военизированных структур.</em>
      }


    </div>
  )
};

export default Band