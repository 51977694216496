import React from 'react';

const YoshlarLogo = () => {
  return <svg className={'logo-y'} width="52" height="40" viewBox="0 0 52 40" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.89923 15.3128C8.23265 12.5964 10.3016 10.3086 12.8707 8.70971C15.4399 7.11081 18.4062 6.26492 21.4322 6.26825H33.7458V18.5819C33.7492 21.6079 32.9033 24.5742 31.3044 27.1434C29.7055 29.7125 27.4177 31.7814 24.7013 33.1149V35.797C24.6946 37.2035 24.567 38.6068 24.32 39.9915C28.8779 38.5601 32.859 35.7094 35.6824 31.8556C38.5057 28.0017 40.0234 23.3464 40.014 18.569V4.31976e-05H21.4322C16.6548 -0.00933463 11.9995 1.5084 8.14565 4.33173C4.29179 7.15507 1.44115 11.1362 0.00976562 15.6941C1.39448 15.4475 2.79779 15.3199 4.20428 15.3128H6.89923Z"
      fill="#009B00"/>
    <path
      d="M24.3281 39.9702L27.3273 31.4697C27.0074 31.7125 26.6789 31.941 26.3418 32.1552L26.2947 32.1852C26.0091 32.3652 25.7234 32.5366 25.4378 32.6994L25.0522 32.9093C24.9408 32.9693 24.8294 33.0336 24.7137 33.0936V35.7756C24.707 37.1821 24.5794 38.5855 24.3324 39.9702H24.3281Z"
      fill="#077207"/>
    <path
      d="M4.21562 15.3119H6.89771C6.94913 15.2091 7.00911 15.1105 7.06052 15.012C7.16764 14.8106 7.27046 14.6093 7.38614 14.4122C7.50183 14.2151 7.63465 13.9837 7.76318 13.7909C7.89172 13.5981 7.94741 13.5039 8.04596 13.3625C8.20877 13.1225 8.38015 12.8869 8.5601 12.6513C8.60294 12.5913 8.6415 12.5313 8.68863 12.4756L0.0253906 15.6932C1.4087 15.4468 2.81056 15.3192 4.21562 15.3119Z"
      fill="#077207"/>
    <path d="M18.4275 21.6502V21.5859H18.3633L18.4275 21.6502Z" fill="#FBB03B"/>
    <path d="M18.1152 29.8164C18.2181 29.9407 18.3295 30.0563 18.428 30.1806L18.1152 29.8164Z"
          fill="#FFDF00"/>
    <path
      d="M24.7001 35.7967V15.3125H4.216C2.8095 15.3196 1.4062 15.4471 0.0214844 15.6938L2.82783 18.5002L5.85696 21.5464L10.7156 21.5807H18.4277V30.1797L18.4534 34.1514L21.4954 37.1934L24.3017 39.9997C24.555 38.6128 24.6883 37.2065 24.7001 35.7967Z"
      fill="#FFDF00"/>
  </svg>
};

export default YoshlarLogo