import React, {  useState } from 'react';
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { useMediaQuery } from "react-responsive";
import ClickAwayListener from "react-click-away-listener";

const Aside = (props) => {
  const [menu, setMenu] = useState(false)
  const { profile,appeals } = useStores();
  const {i18n} = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })


  return (
    <aside>
      {
        props.executor ?
          <ClickAwayListener onClickAway={()=>setMenu(false)}>
            <div>
            <div className={'profile-info'}>
              <div className={'profile-pic'}>
                <div className={'img'}>
                  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.9987 5.33398C17.2787 5.33398 5.33203 17.2807 5.33203 32.0007C5.33203 46.7207 17.2787 58.6673 31.9987 58.6673C46.7187 58.6673 58.6654 46.7207 58.6654 32.0007C58.6654 17.2807 46.7187 5.33398 31.9987 5.33398ZM31.9987 13.334C36.4254 13.334 39.9987 16.9073 39.9987 21.334C39.9987 25.7607 36.4254 29.334 31.9987 29.334C27.572 29.334 23.9987 25.7607 23.9987 21.334C23.9987 16.9073 27.572 13.334 31.9987 13.334ZM31.9987 51.2007C25.332 51.2007 19.4387 47.7873 15.9987 42.614C16.0787 37.3073 26.6654 34.4007 31.9987 34.4007C37.3054 34.4007 47.9187 37.3073 47.9987 42.614C44.5587 47.7873 38.6654 51.2007 31.9987 51.2007Z" fill="#2D2D2D"/>
                  </svg>
                </div>
                <strong>{profile.profile && profile.profile.last_name} {profile.profile && profile.profile.first_name}</strong>
              </div>
            </div>
            {/*<strong className={'title'}><Trans>Заявки по льготам</Trans></strong>*/}
            {/*<ul>*/}
            {/*  <li><NavLink to={`/${i18n.language}/executor/new`}><Trans>Новые заявки</Trans></NavLink></li>*/}
            {/*  <li><NavLink to={`/${i18n.language}/executor/processing`}><Trans>Открытые заявки</Trans></NavLink></li>*/}
            {/*  <li><NavLink to={`/${i18n.language}/executor/closed`}><Trans>Закрытие заявки</Trans></NavLink></li>*/}
            {/*</ul>*/}
            <strong className={'title mt-12'}><Trans>Заявки гос органам</Trans></strong>
            <ul>
              {/*<li><NavLink to={`/${i18n.language}/pm/new`}><Trans>Новые заявки</Trans></NavLink></li>*/}
              <li><NavLink to={`/${i18n.language}/pm/processing`}><Trans>Открытые заявки</Trans></NavLink></li>
              {/*<li><NavLink to={`/${currentLang}/pm/closed`}><Trans>Закрытие заявки</Trans></NavLink></li>*/}
              {/*<li className={'mr-10'}><NavLink to={`/${currentLang}/pm/redirect-task`}><Trans>Результат рассмотрения обращения</Trans></NavLink></li>*/}
              {/*<li className={'mr-10'}><NavLink to={`/${currentLang}/pm/redirect-task`}><Trans>Переназначение задач</Trans></NavLink></li>*/}
              {/*<li className={'mr-10'}><NavLink to={`/${currentLang}/pm/change-deadline`}><Trans>Изменение срока</Trans></NavLink></li>*/}
              {/*<li className={'mr-10'}><NavLink to={`/${currentLang}/pm/change-category`}><Trans>Изменение категории</Trans></NavLink></li>*/}
              {/*<li className={'mr-10'}><NavLink to={`/${currentLang}/pm/change-period`}><Trans>Изменение ограничения по задач</Trans></NavLink></li>*/}
              <li><NavLink to={`/${i18n.language}/pm/closed`}><Trans>Закрытие обращения</Trans></NavLink></li>
              <li><NavLink to={`/${i18n.language}/to-agency`}><Trans>Обращения в Портал молодёжи</Trans></NavLink></li>
              <li><NavLink to={`/${i18n.language}/export`}><Trans>Запросы на участие в Портал молодёжи</Trans></NavLink></li>
            </ul>
          </div>
          </ClickAwayListener>
          :
          <>
            <ClickAwayListener onClickAway={()=>setMenu(false)}>
              <div>
                {
                  isMobile && <div className={'profile-pic'} onClick={()=>setMenu(!menu)}>
                    <div className={'img'}>
                      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.9987 5.33398C17.2787 5.33398 5.33203 17.2807 5.33203 32.0007C5.33203 46.7207 17.2787 58.6673 31.9987 58.6673C46.7187 58.6673 58.6654 46.7207 58.6654 32.0007C58.6654 17.2807 46.7187 5.33398 31.9987 5.33398ZM31.9987 13.334C36.4254 13.334 39.9987 16.9073 39.9987 21.334C39.9987 25.7607 36.4254 29.334 31.9987 29.334C27.572 29.334 23.9987 25.7607 23.9987 21.334C23.9987 16.9073 27.572 13.334 31.9987 13.334ZM31.9987 51.2007C25.332 51.2007 19.4387 47.7873 15.9987 42.614C16.0787 37.3073 26.6654 34.4007 31.9987 34.4007C37.3054 34.4007 47.9187 37.3073 47.9987 42.614C44.5587 47.7873 38.6654 51.2007 31.9987 51.2007Z" fill="#2D2D2D"/>
                      </svg>
                    </div>
                    <strong>{profile.profile && profile.profile.last_name} {profile.profile && profile.profile.first_name}</strong>
                  </div>
                }
                <div className={`mobile-profile-navigation ${menu ? 'show' : null}`}>
                  {
                    !isMobile && <div className={'profile-info'}>
                     <div className={'profile-pic'}>
                        <div className={'img'}>
                          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.9987 5.33398C17.2787 5.33398 5.33203 17.2807 5.33203 32.0007C5.33203 46.7207 17.2787 58.6673 31.9987 58.6673C46.7187 58.6673 58.6654 46.7207 58.6654 32.0007C58.6654 17.2807 46.7187 5.33398 31.9987 5.33398ZM31.9987 13.334C36.4254 13.334 39.9987 16.9073 39.9987 21.334C39.9987 25.7607 36.4254 29.334 31.9987 29.334C27.572 29.334 23.9987 25.7607 23.9987 21.334C23.9987 16.9073 27.572 13.334 31.9987 13.334ZM31.9987 51.2007C25.332 51.2007 19.4387 47.7873 15.9987 42.614C16.0787 37.3073 26.6654 34.4007 31.9987 34.4007C37.3054 34.4007 47.9187 37.3073 47.9987 42.614C44.5587 47.7873 38.6654 51.2007 31.9987 51.2007Z" fill="#2D2D2D"/>
                          </svg>
                        </div>
                        <strong>{profile.profile && profile.profile.last_name} {profile.profile && profile.profile.first_name}</strong>
                      </div>
                    {/*<div className={'profile-score'}>*/}
                    {/*  <div className={'score'}>*/}
                    {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*      <g>*/}
                    {/*        <path d="M21.2186 7.40215L14.9495 6.82071L12.4592 1.03806C12.2848 0.633148 11.7107 0.633148 11.5364 1.03806L9.0461 6.82066L2.77698 7.40215C2.338 7.44283 2.16062 7.98884 2.49179 8.27979L7.22186 12.4351L5.8376 18.5771C5.74066 19.0072 6.20514 19.3446 6.58418 19.1195L11.9978 15.905L17.4114 19.1195C17.7905 19.3446 18.2549 19.0072 18.158 18.5771L16.7737 12.4351L21.5038 8.27979C21.8349 7.98884 21.6575 7.44283 21.2186 7.40215Z" fill="#FFD854"/>*/}
                    {/*        <path d="M5.59162 0.841484C5.91122 1.28539 6.81328 3.1918 7.329 4.30283C7.4101 4.47749 7.19096 4.63527 7.05103 4.50294C6.16102 3.66144 4.63922 2.20119 4.31962 1.75724C4.06673 1.406 4.14647 0.91625 4.49775 0.663359C4.84898 0.410467 5.33873 0.490249 5.59162 0.841484Z" fill="#FFEF7A"/>*/}
                    {/*        <path d="M18.4072 0.841484C18.0876 1.28539 17.1856 3.1918 16.6699 4.30283C16.5888 4.47749 16.8079 4.63527 16.9478 4.50294C17.8378 3.66144 19.3596 2.20114 19.6792 1.75724C19.9321 1.406 19.8524 0.91625 19.5011 0.663359C19.1499 0.410467 18.6601 0.490249 18.4072 0.841484Z" fill="#FFEF7A"/>*/}
                    {/*        <path d="M23.4601 13.859C22.9401 13.6894 20.8497 13.4093 19.6345 13.2555C19.4435 13.2313 19.3598 13.4881 19.5283 13.5811C20.6005 14.1734 22.4541 15.1795 22.9741 15.3491C23.3856 15.4833 23.8279 15.2586 23.9621 14.847C24.0963 14.4355 23.8716 13.9932 23.4601 13.859Z" fill="#FFEF7A"/>*/}
                    {/*        <path d="M0.54087 13.859C1.0609 13.6894 3.1513 13.4093 4.36644 13.2555C4.55746 13.2313 4.64118 13.4881 4.47266 13.5811C3.40049 14.1734 1.5469 15.1795 1.02687 15.3491C0.615402 15.4833 0.173042 15.2586 0.0388382 14.847C-0.0953652 14.4355 0.129401 13.9932 0.54087 13.859Z" fill="#FFEF7A"/>*/}
                    {/*        <path d="M11.2383 22.7043C11.2383 22.1573 11.6201 20.0831 11.8507 18.8801C11.8869 18.691 12.157 18.691 12.1932 18.8801C12.4238 20.0831 12.8056 22.1573 12.8056 22.7043C12.8056 23.137 12.4548 23.4879 12.022 23.4879C11.5891 23.488 11.2383 23.137 11.2383 22.7043Z" fill="#FFEF7A"/>*/}
                    {/*        <path d="M13.3647 3.14087L12.4591 1.03806C12.2847 0.633148 11.7107 0.633148 11.5363 1.03806L9.04605 6.82066L2.77698 7.40215C2.338 7.44283 2.16062 7.98884 2.49179 8.27979L7.22186 12.4351L5.83759 18.5771C5.74065 19.0072 6.20514 19.3446 6.58417 19.1195L7.30469 18.6917C8.48589 11.0454 11.8066 5.4325 13.3647 3.14087Z" fill="#FEC851"/>*/}
                    {/*      </g>*/}
                    {/*      <defs>*/}
                    {/*        <clipPath id="clip0">*/}
                    {/*          <rect width="24" height="24" fill="white"/>*/}
                    {/*        </clipPath>*/}
                    {/*      </defs>*/}
                    {/*    </svg>*/}
                    {/*    <span><Trans>Баллы</Trans>:</span>*/}
                    {/*    <strong>1000</strong>*/}
                    {/*  </div>*/}
                    {/*  <div className={'score'}>*/}
                    {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*      <path d="M10.9049 7.31456C10.7744 7.1041 10.5318 6.98328 10.2786 7.00187L4.83512 7.40803C4.28401 7.44918 3.7931 7.72818 3.48818 8.17358L1.20376 11.511C0.972566 11.8488 0.935769 12.2715 1.10538 12.6417C1.27494 13.012 1.62349 13.27 2.03768 13.332L6.45157 13.9923C6.48591 13.9975 6.52021 14 6.55424 14C6.78034 14 6.99424 13.8892 7.11852 13.7014L10.8946 7.99544C11.0314 7.78869 11.0353 7.52505 10.9049 7.31456Z" fill="#FA5948"/>*/}
                    {/*      <path d="M16.6854 13.0952C16.4749 12.9647 16.2112 12.9686 16.0045 13.1054L10.2986 16.8815C10.0825 17.0245 9.96842 17.2861 10.0076 17.5485L10.6679 21.9623C10.7299 22.3766 10.988 22.7251 11.3583 22.8947C11.5123 22.9652 11.6753 23 11.8376 23C12.0654 23 12.2917 22.9313 12.4889 22.7963L15.8264 20.5118C16.2718 20.207 16.5508 19.7161 16.5919 19.1649L16.9981 13.7215C17.017 13.4682 16.8959 13.2257 16.6854 13.0952Z" fill="#FA5948"/>*/}
                    {/*      <path d="M9.32284 18.669C9.57869 18.9249 9.94225 19.0446 10.2991 18.9849C15.5325 18.1098 19.6737 13.7768 21.2503 10.7287C23.001 7.3441 23.0658 3.76742 22.9743 2.09C22.943 1.5152 22.4848 1.05701 21.91 1.02564C20.2326 0.934183 16.6559 0.999026 13.2713 2.74969C10.2232 4.32628 5.89015 8.46751 5.01509 13.7009C4.95543 14.0577 5.07509 14.4213 5.33095 14.6772L9.32284 18.669Z" fill="#F1F1FB"/>*/}
                    {/*      <path d="M22.6386 1L7 16.6264L9.03757 18.6624C9.29877 18.9234 9.66991 19.0455 10.0342 18.9846C15.3767 18.092 19.6044 13.672 21.2138 10.5628C23.001 7.11028 23.0672 3.46184 22.9738 1.75075C22.9578 1.45759 22.8328 1.19415 22.6386 1Z" fill="#D7D6FB"/>*/}
                    {/*      <path d="M16 11C15.2317 11 14.4636 10.7075 13.8787 10.1223C13.3121 9.55544 13 8.80169 13 8C13 7.1983 13.3121 6.44455 13.8787 5.87769C15.0484 4.70746 16.9516 4.70742 18.1213 5.87769C18.688 6.44455 19 7.1983 19 8C19 8.80169 18.688 9.55544 18.1213 10.1223C17.5366 10.7074 16.7682 10.9999 16 11ZM14.303 6.30216H14.3034H14.303Z" fill="#466288"/>*/}
                    {/*      <path d="M5.01589 13.4187C4.95306 13.7946 5.07909 14.1775 5.34857 14.447L9.55302 18.6514C9.8225 18.9209 10.2054 19.0469 10.5813 18.9841C11.7807 18.7836 12.9255 18.4104 14 17.9165L6.08353 10C5.58957 11.0745 5.21645 12.2193 5.01589 13.4187Z" fill="#466288"/>*/}
                    {/*      <path d="M1.5698 19C1.7156 19 1.86148 18.9443 1.97271 18.8331L3.8331 16.9727C4.05563 16.7502 4.05563 16.3894 3.8331 16.1669C3.6106 15.9444 3.24983 15.9444 3.02726 16.1669L1.1669 18.0273C0.944366 18.2497 0.944366 18.6106 1.1669 18.8331C1.27817 18.9444 1.42397 19 1.5698 19Z" fill="#FDB43A"/>*/}
                    {/*      <path d="M5.82306 18.1769C5.58719 17.941 5.20468 17.941 4.96876 18.1769L1.17694 21.9688C0.941021 22.2046 0.941021 22.5872 1.17694 22.8231C1.2949 22.941 1.4495 23 1.60411 23C1.75871 23 1.91332 22.941 2.03124 22.823L5.82306 19.0312C6.05898 18.7953 6.05898 18.4128 5.82306 18.1769Z" fill="#FDB43A"/>*/}
                    {/*      <path d="M7.8331 20.1669C7.6106 19.9444 7.24979 19.9444 7.02726 20.1669L5.1669 22.0272C4.94437 22.2497 4.94437 22.6105 5.1669 22.8331C5.27817 22.9443 5.424 23 5.5698 23C5.7156 23 5.86148 22.9443 5.97271 22.8331L7.8331 20.9727C8.05563 20.7502 8.05563 20.3894 7.8331 20.1669Z" fill="#F57A21"/>*/}
                    {/*      <path d="M1 22.8166C1.12229 22.9389 1.28256 23 1.44284 23C1.60312 23 1.7634 22.9388 1.88564 22.8166L5.81657 18.8856C6.06114 18.6411 6.06114 18.2446 5.81657 18L1 22.8166Z" fill="#F57A21"/>*/}
                    {/*      <path d="M7 16.5695L9.24692 18.6543C9.53495 18.9216 9.94423 19.0466 10.346 18.9842C11.6279 18.7853 12.8515 18.4153 14 17.9254L9.76933 14L7 16.5695Z" fill="#354A67"/>*/}
                    {/*      <path d="M14 10.1433C14.5711 10.7145 15.3209 11.0001 16.0711 11C16.821 10.9999 17.5712 10.7143 18.1421 10.1433C18.6953 9.5899 19 8.85418 19 8.07163C19 7.28908 18.6953 6.55333 18.1421 6L14 10.1433Z" fill="#354A67"/>*/}
                    {/*      <path d="M16 6C15.4879 6 14.9757 6.19503 14.5858 6.58513C14.2081 6.96305 14 7.46551 14 8C14 8.53448 14.2081 9.03695 14.5858 9.41486C15.3656 10.195 16.6345 10.1951 17.4142 9.41486C17.792 9.0369 18 8.53444 18 7.99995C18 7.46547 17.792 6.963 17.4142 6.58509C17.0243 6.19503 16.5122 6 16 6Z" fill="#4BBEFD"/>*/}
                    {/*      <path d="M17.4853 7L15 9.48596C15.6852 10.1713 16.8001 10.1714 17.4853 9.48596C17.8172 9.15392 18 8.7125 18 8.24296C18 7.77342 17.8172 7.332 17.4853 7Z" fill="#0590FB"/>*/}
                    {/*    </svg>*/}
                    {/*    <span><Trans>Статус</Trans>:</span>*/}
                    {/*    <strong>Новичок</strong>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                      }
                  <ul>
                    <li><strong><Trans>Мои обращения</Trans></strong></li>
                    <li><NavLink to={`/${i18n.language}/profile/benefits`}><Trans>Обращения по возможностям</Trans>  ({appeals.statistic.total_benefit_count})</NavLink></li>
                    <li><NavLink to={`/${i18n.language}/profile/pm-appeals`}><Trans>Обращения  молодежи</Trans> ({appeals.statistic.total_request_count})</NavLink></li>
                  </ul>
                  <ul>
                    <li><strong><Trans>Мои данные</Trans></strong></li>
                    <li><NavLink to={`/${i18n.language}/profile/cabinet`}><Trans>Личные данные</Trans></NavLink></li>
                    <li><NavLink to={`/${i18n.language}/profile/change-password`}><Trans>Изменить пароль</Trans></NavLink></li>
                    <li><NavLink to={`/${i18n.language}/profile/change-address`}><Trans>Изменить адрес</Trans></NavLink></li>
                    <li><NavLink to={`/${i18n.language}/profile/change-email`}><Trans>Изменить Email</Trans></NavLink></li>
                  </ul>
                  <ul>
                    <li><strong><Trans>Полезные ссылки</Trans></strong></li>
                    <li><a href={'https://t.me/yoshlarmurojaati_bot'} rel="noreferrer" target={'_blank'}><Trans>Телеграм бот</Trans> (@yoshlarmurojaati_bot)</a></li>
                    <li><a href={'https://t.me/yoshlaragentligi'} rel="noreferrer" target={'_blank'}><Trans>Телеграм канал</Trans> (@yoshlaragentligi)</a></li>
                  </ul>
                  {/*<ul>*/}
                  {/*  /!*<li><strong><Trans>Полезные ссылки</Trans></strong></li>*!/*/}
                  {/*  <li><p><Trans>Сообщить разработчикам об ошибке или о своем предложении по улучшению</Trans></p></li>*/}
                  {/*</ul>*/}
                </div>
              </div>
            </ClickAwayListener>
          </>
      }
    </aside>
  )
};

export default observer(Aside)