import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react";
import { useStores } from "../../store";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {  truncateString, currentLang } from "../../config";
import {  Form, Radio, Select, Slider, Spin, Tag } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import {gsap} from 'gsap';
import { ScrollToPlugin } from 'gsap/all'
import MobileNavigation from "../../components/navigation";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import ClickAwayListener from "react-click-away-listener";
gsap.registerPlugin(ScrollToPlugin)
const Opportunities = () => {
  const {Home, opportunities, location} = useStores();
  const [form] = Form.useForm()
  const [dropdown, setDropDownHandler] = useState(false)
  const { CheckableTag } = Tag;
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const [age, setAge] = useState(3);
  let [formValues, setFormValues] = useState(null);
  let [firstChanged, setChanged] = useState(false);
  const [tags, setTags] = useState([]);
  const {i18n, t} = useTranslation()
  const Option = Select.Option;
  const ref = useRef(null);
  const {data, scopes,  loading, setValuesForm} = opportunities;
  useEffect(()=>{
    opportunities.getScopes();
    opportunities.getData();
    return () => {
      opportunities.reset()
    }
  }, [Home, opportunities]);
  const marks = {
    3: {
      label: <strong><Trans>от 3 лет</Trans></strong>,
    },
    30: {
      label: <strong><Trans>до 30 лет</Trans></strong>,
    },
  };
  const onChange = async () => {
    formValues = {age:firstChanged ? age : null, ...form.getFieldValue(), ...tags.map(item => item.id).join(',')}
    setFormValues({...formValues})
    await setValuesForm(formValues)
    opportunities.getData();
    setDropDownHandler(false)
  };
  const handleChange = async (tag, checked) => {
    if(tags.length >= 3) {
      tags.shift();
    }
    const nextSelectedTags = checked ? [...tags, tag] : tags.filter(t => t !== tag);
    let scope = nextSelectedTags.map(item => item.id).join(',');
    formValues = {age:firstChanged ? age : null, scope}
    setFormValues({...formValues})
    await setValuesForm(formValues);
    setTags(nextSelectedTags);
    opportunities.getData();
    setDropDownHandler(false)
  }
  const getAge = async age => {
    setAge(age);
    setChanged(true)
  }
  const resetForm = async () => {
    form.resetFields();
    setTags([]);
    setAge(3);
    setFormValues(null);
    opportunities.reset()
    opportunities.getData();
    setDropDownHandler(false)
  }
  const fetchData = () => {
    opportunities.setPagination()
    opportunities.getData();
  }
  return(
    <>
      <Helmet>
        <title>Yoshlar Portali - Возможности для молодежи</title>
      </Helmet>
      {
        isMobile && <MobileNavigation title={<Trans>Возможности для молодежи</Trans>}/>
      }

      <div className={' possibility-page'} ref={ref}>
        <div className={'header-title bordered'}>
          <h1><Trans>Возможности для молодежи</Trans></h1>
        </div>
        <div className={'benefits'} >
          <div className={'center-content '}>
            {
              !isMobile && <div className={`right-side ${dropdown ? 'show' : ''}`}>
                <div className={'sticky'} id={'area'}>
                  <h3><Trans>Расскажите о себе</Trans></h3>
                  <Form layout={'vertical'} form={form}>
                    <Form.Item label={t('Пол')} name={'gender'}>
                      <Radio.Group onChange={onChange} >
                        <Radio value={'male'}><Trans>Мужчина</Trans></Radio>
                        <Radio value={'female'}><Trans>Женщина</Trans></Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={t('Возраст')} name={'age'} >
                      <div className={'slider-range'}>
                        <Slider
                          max={30}
                          onChange={getAge}
                          onAfterChange={onChange}
                          min={3}
                          marks={marks}
                        />
                        {/*<strong>{age} <Trans>лет</Trans></strong>*/}
                      </div>

                    </Form.Item>
                    <Form.Item label={t('Наличии детей')} name={'kid'}>
                      <Radio.Group  onChange={onChange}>
                        <Radio value={'yes'}><Trans>есть</Trans></Radio>
                        <Radio value={'no'}><Trans>нет</Trans></Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={t('Семейное положение')} name={'status'}>
                      <Select  onChange={onChange} getPopupContainer={() => document.getElementById('area')} placeholder={t('Семейное положение')}>
                        <Option value={'divorced'}><Trans>в разводе</Trans></Option>
                        <Option value={'young_family'}><Trans>молодая семья</Trans></Option>
                        <Option value={'family'}><Trans>семейный</Trans></Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label={t('Местоположения')} name={'region_id'}>
                      <Select  onChange={onChange}  placeholder={t('Введите Регион')} getPopupContainer={() => document.getElementById('area')} onDropdownVisibleChange={(open) =>open && location.getPMRegion()} >
                        {
                          location.region.map((item, idx) => {
                            return item.title !== '' && <Option key={idx} value={item.id}>{item[`title_${i18n.language}`]}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item label={t('Дафтар')}  name={'notebook'}>
                      <Radio.Group  onChange={onChange}>
                        <Radio value={'iron'}><Trans>железная тетрадь</Trans></Radio>
                        <Radio value={'youth'}><Trans>тетрадь молодежи</Trans></Radio>
                        <Radio value={'women'}><Trans>женская тетрадь</Trans></Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={t('Инвалидность')} name={'disability'}>
                      <Radio.Group  onChange={onChange} >
                        <Radio value={'yes'}><Trans>есть</Trans></Radio>
                        <Radio value={'no'}><Trans>нет</Trans></Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label={t('Сферы')} name={'scope'}>
                      {scopes.results.map((tag, index) => (
                        <CheckableTag
                          key={index}
                          checked={tags.indexOf(tag) > -1}
                          onChange={checked => handleChange(tag, checked)}
                        >
                          {tag[`title_${i18n.language}`]}
                        </CheckableTag>
                      ))}
                      <span style={{display:isMobile ? 'block' : null}}>{tags.length }/ 3</span>
                    </Form.Item>

                  </Form>
                  {
                    formValues && <button className={'btn primary error'} onClick={resetForm}><Trans>Сбросить</Trans></button>
                  }

                </div>
              </div>
            }
            <div className={'left-side'} >
              {
                data.count > 0 ?
                  <h3>{t('возможностей для вас', { name: data.count })}</h3> : loading ? null
                  :
                  <h3>{t('возможностей для вас нет')}</h3>
              }
              {
                isMobile && <ClickAwayListener onClickAway={()=>setDropDownHandler(false)}>
                  <div className={'right-side'}>
                    {
                      isMobile && <button onClick={()=>setDropDownHandler(!dropdown)} className={'d-flex align-center filter-btn'}>
                        <Trans>Фильтр</Trans>
                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.12274 3.6001C6.8563 4.6352 5.91668 5.40006 4.79839 5.40006C3.47292 5.40006 2.39844 4.32558 2.39844 3.00011C2.39844 1.67464 3.47292 0.600159 4.79839 0.600159C5.91668 0.600159 6.8563 1.36502 7.12274 2.40012H23.3984C23.7296 2.40012 23.9984 2.66871 23.9984 3.00011C23.9984 3.33151 23.7296 3.6001 23.3984 3.6001H7.12274ZM5.99836 3.00011C5.99836 3.66291 5.46106 4.20009 4.79839 4.20009C4.13572 4.20009 3.59841 3.66278 3.59841 3.00011C3.59841 2.33744 4.13572 1.80013 4.79839 1.80013C5.46106 1.80013 5.99836 2.33731 5.99836 3.00011Z" fill="white"/>
                          <path d="M0.599987 3.60001H2.47555C2.42616 3.40829 2.39995 3.2072 2.39995 3.00003C2.39995 2.79285 2.42616 2.59176 2.47555 2.40004H0.599987C0.268589 2.40004 0 2.66863 0 3.00003C0 3.33142 0.268589 3.60001 0.599987 3.60001Z" fill="white"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M14.3243 14.4001C14.0579 13.365 13.1182 12.6002 12 12.6002C10.8818 12.6002 9.94196 13.365 9.67565 14.4001H0.599987C0.268589 14.4001 0 14.6688 0 15.0001C0 15.3314 0.268589 15.6001 0.599987 15.6001H9.67552C9.94196 16.6352 10.8817 17.4001 11.9999 17.4001C13.118 17.4001 14.0579 16.6352 14.3242 15.6001H23.3998C23.731 15.6001 23.9997 15.3314 23.9997 15.0001C23.9997 14.6688 23.731 14.4001 23.3998 14.4001H14.3243ZM12 16.2001C12.6628 16.2001 13.2 15.6629 13.2 15.0001C13.2 14.3373 12.6628 13.8001 12 13.8001C11.3372 13.8001 10.8 14.3373 10.8 15.0001C10.8 15.6629 11.3372 16.2001 12 16.2001Z" fill="white"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M21.5243 8.40009C21.2579 7.36499 20.3181 6.60013 19.2 6.60013C18.0818 6.60013 17.1419 7.36499 16.8756 8.40009H0.599987C0.268589 8.40009 0 8.66881 0 9.00008C0 9.33135 0.268589 9.60007 0.599987 9.60007H16.8756C17.1421 10.6352 18.0818 11.4 19.2 11.4C20.3181 11.4 21.258 10.6352 21.5243 9.60007H23.3999C23.7312 9.60007 23.9999 9.33135 23.9999 9.00008C23.9999 8.66881 23.7312 8.40009 23.3999 8.40009H21.5243ZM19.2 10.2001C19.8628 10.2001 20.3999 9.66288 20.3999 9.00008C20.3999 8.33728 19.8626 7.8001 19.2 7.8001C18.5372 7.8001 18 8.33728 18 9.00008C18 9.66288 18.5372 10.2001 19.2 10.2001Z" fill="white"/>
                        </svg>
                      </button>
                    }
                    <div className={`filter ${dropdown ? 'show' : ''}`}>
                      <div className={'sticky'} id={'area'}>
                        <h3><Trans>Расскажите о себе</Trans></h3>
                        <Form form={form}>
                          <Form.Item label={t('Пол')} name={'gender'}>
                            <Radio.Group onChange={onChange} >
                              <Radio value={'male'}><Trans>Мужчина</Trans></Radio>
                              <Radio value={'female'}><Trans>Женщина</Trans></Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label={t('Возраст')} name={'age'} >
                            <div className={'slider-range'}>
                              <Slider
                                max={30}
                                onChange={getAge}
                                onAfterChange={onChange}
                                min={3}
                                marks={marks}
                              />
                              <strong>{age} <Trans>лет</Trans></strong>
                            </div>

                          </Form.Item>
                          <Form.Item label={t('Наличии детей')} name={'kid'}>
                            <Radio.Group  onChange={onChange}>
                              <Radio value={'yes'}><Trans>есть</Trans></Radio>
                              <Radio value={'no'}><Trans>нет</Trans></Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label={t('Семейное положение')} name={'status'}>
                            <Select  onChange={onChange} getPopupContainer={() => document.getElementById('area')} placeholder={t('Семейное положение')}>
                              <Option value={'divorced'}><Trans>в разводе</Trans></Option>
                              <Option value={'young_family'}><Trans>молодая семья</Trans></Option>
                              <Option value={'family'}><Trans>семейный</Trans></Option>
                            </Select>
                          </Form.Item>
                          <Form.Item label={t('Местоположения')} name={'region_id'}>
                            <Select  onChange={onChange}  placeholder={t('Введите Регион')} getPopupContainer={() => document.getElementById('area')} onDropdownVisibleChange={() => location.getPMRegion()} >
                              {
                                location.region.map((item, idx) => {
                                  return item.title !== '' && <Option key={idx} value={item.id}>{item[`title_${currentLang()}`]}</Option>
                                })
                              }
                            </Select>
                          </Form.Item>
                          <Form.Item label={t('Дафтар')}  name={'notebook'}>
                            <Radio.Group  onChange={onChange}>
                              <Radio value={'iron'}><Trans>железная тетрадь</Trans></Radio>
                              <Radio value={'youth'}><Trans>тетрадь молодежи</Trans></Radio>
                              <Radio value={'women'}><Trans>женская тетрадь</Trans></Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label={t('Инвалидность')} name={'disability'}>
                            <Radio.Group  onChange={onChange} >
                              <Radio value={'yes'}><Trans>есть</Trans></Radio>
                              <Radio value={'no'}><Trans>нет</Trans></Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label={t('Сферы')} name={'scope'}>
                            {scopes.results.map((tag, index) => (
                              <CheckableTag
                                key={index}
                                checked={tags.indexOf(tag) > -1}
                                onChange={checked => handleChange(tag, checked)}
                              >
                                {tag[`title_${i18n.language}`]}
                              </CheckableTag>
                            ))}
                            <span style={{display:isMobile ? 'block' : null}}>{tags.length }/ 3</span>
                          </Form.Item>

                        </Form>
                        {
                          formValues && <button className={'btn primary error'} onClick={resetForm}><Trans>Сбросить</Trans></button>
                        }

                      </div>
                    </div>
                  </div>
                </ClickAwayListener>
              }
              <InfiniteScroll
                dataLength={opportunities.data.results.length}
                next={fetchData}
                pullDownToRefreshThreshold={isMobile ? 600 : 300}
                hasMore={opportunities.data.count > opportunities.data.results.length}
                initialLoad={false}
                style={{overflowAnchor: 'auto', overflow: 'inherit'}}
                useWindow={true}
              >
                <Spin spinning={loading} >
                  <div className={'cards'}>
                    {
                      data.results.map((item, index) => {
                        return  <div key={index} className={'benefit  animate__animated animate__fadeIn '}>
                          <div>
                            <div className={'tags'}>
                              {
                                item.scope.map((scope,index) => {
                                  return <span key={index}>{scope}</span>
                                })
                              }
                            </div>
                            <NavLink to={`/${i18n.language}/opportunities/${item.id}`}> <strong>{truncateString(item[`title_${currentLang()}`], 90)}</strong></NavLink>
                            <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(item[`content_${currentLang()}`].replace(/<[^>]+>/g, ''), 120)}}/>
                          </div>
                          <NavLink className={'more'} to={`/${i18n.language}/opportunities/${item.id}`}><Trans>Хочу знать больше</Trans></NavLink>
                        </div>
                      })
                    }

                  </div>
                </Spin>
              </InfiniteScroll>
            </div>
          </div>
        </div>

      </div>
    </>
  )
};

export default observer(Opportunities)