import React, { useEffect, useState } from 'react';
import { Button,  Col, Form, Input, message, Row } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { observer } from "mobx-react";

const IdeasModal = () => {
  const {t} = useTranslation()
  const { TextArea } = Input;
  const { form } = Form.useForm();
  const { ideas, Modals } = useStores();
  const fileInput = React.createRef();
  const [fileName, setFileName] = useState('');
  const [loading, setloading] = useState(false);
  const [, setFileType] = useState('');
  const [files, setFiles] = useState(null);
  const { profile } = useStores()
  useEffect(() => {
    profile.getProfile()
  }, [profile]);

  const getFile = event => {
    setFileName(event.target.files[0].name)
    setFileType(event.target.files[0].name.split('.')[1])
    setFiles(event.target.files[0])
  }

  const onFinish = async values => {
    values.file = files;
    values.user = profile.profile.id;
    setloading(true);
    const error = await ideas.create(values);
    if (error) {
      setloading(false);
      return error.map(item => message.error(item))
    }
    setloading(false);
    Modals.hide();
    Modals.show('successIdeas')
  }
  return (
      <div>
        <div className={'popup-header'}>
          <h3><Trans>Отправить идею</Trans></h3>
        </div>
        <div className={'close-popup'} onClick={()=>Modals.hide()}/>
        <Form layout={'vertical'} onFinish={onFinish}  form={form}>
          <Row gutter={[40, 32]}>
            <Col span={24}>
              <Form.Item label={t('Названия идеи')} name={'title'}
                         rules={[
                           { required: true, message: t('Обязательное поле') },
                           {
                             pattern: !/^[a-zA-ZА-яА-Я]{0,255}$/,
                             message: "Максимальное значение название 255 символов",
                           }
                         ]
                         }>
                <Input/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('Прикрепить файл')} name={'file'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <div className={'file-upload'}>
                  <div className={'input'}>
                    <span className={'file-name'}>{fileName}</span>
                  </div>
                  <button>
                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.2297 6.33225L7.44835 15.1136C7.19366 15.3596 6.99051 15.6538 6.85075 15.9792C6.71099 16.3045 6.63743 16.6544 6.63435 17.0085C6.63127 17.3626 6.69874 17.7137 6.83283 18.0415C6.96691 18.3692 7.16492 18.6669 7.4153 18.9173C7.66568 19.1677 7.96342 19.3657 8.29114 19.4998C8.61886 19.6339 8.97001 19.7013 9.32409 19.6983C9.67817 19.6952 10.0281 19.6216 10.3534 19.4819C10.6788 19.3421 10.973 19.1389 11.219 18.8842L19.771 10.1029C20.7425 9.09704 21.2801 7.74983 21.2679 6.35144C21.2558 4.95306 20.6949 3.61539 19.706 2.62655C18.7172 1.63771 17.3795 1.07681 15.9812 1.06465C14.5828 1.0525 13.2356 1.59007 12.2297 2.56158L3.67635 11.3416C2.17593 12.842 1.33301 14.877 1.33301 16.9989C1.33301 19.1208 2.17593 21.1558 3.67635 22.6562C5.17677 24.1567 7.21177 24.9996 9.33369 24.9996C11.4556 24.9996 13.4906 24.1567 14.991 22.6562L23.3337 14.3322" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <input type="file" ref={fileInput} onChange={getFile}
                           accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"/>
                  </button>
                </div>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={t('Описание идеи')} name={'description'} style={{ marginBottom: 0 }}
                         rules={[{ required: true, message: t('Обязательное поле')}]}>
                <TextArea/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button loading={loading} className={'btn primary'} type={'primary'}
                      htmlType={'submit'}><Trans>Отправить</Trans></Button>
            </Col>
          </Row>
        </Form>
      </div>
  )
};

export default  observer(IdeasModal)

