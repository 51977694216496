import React from 'react';
import moment from "moment";
import { formatDateClient, truncateString } from "../../config";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";

const Appeal = (props) => {
  const {t} = useTranslation()
  return (
    <>
      <ul>
        <li>
          <div>
            <span><Trans>ID и дата</Trans></span>
            <strong>{t('Дата и обращения', {id:props.request_no, date:formatDateClient(props.created_at)})}</strong>
          </div>
          <div>
            <span><Trans>Категория и сфера</Trans></span>
            <strong>{props.category} </strong>
          </div>
        </li>
        <li>
          <div>
            <span><Trans>Исполнитель</Trans></span>
            <strong>{props.user.first_name} {props.user.last_name}</strong>
          </div>
          <div>
            <div
              className={`status ${moment(props.deadline) < moment() ? 'expired' : props.status}`}>
              {props.status === 'processing' ? t('На рассмотрении') : props.status === 'answered' ? t('Есть ответ') : t('Закрытая')}</div>
          </div>
        </li>
        <li className={'full'}>
          <div>
            <span><Trans>Текст обращения</Trans></span>
            <p>{truncateString(props.description, 100)}</p>
          </div>
        </li>
      </ul>
      {
        props.status === 'processing' ?
          <>
            <div className={`deadline ${moment(props.deadline) < moment() ? 'expired' : props.status}`}>
              <strong><Trans>Срок исполнения до</Trans> {formatDateClient(props.deadline)}</strong>
              <span><Trans>осталось</Trans> {moment(props.deadline) < moment() ? 0 : moment(props.deadline).diff(moment(), 'days')} <Trans>дней</Trans></span>
            </div>
          </> :
          <div className={`deadline ${moment(props.deadline) < moment() ? 'expired' : props.status}`}>
            <strong><Trans>Дата закрытие</Trans> {formatDateClient(props.updated_at)}</strong>
          </div>

      }
    </>

  )
};

export default observer(Appeal)