import React, { useEffect,  useState } from 'react';
import { Image, notification, Spin } from "antd";
import { NavLink, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { baseUrl, currentLang, formatDateClient, formatNum, truncateString } from "../../config";
import { observer } from "mobx-react";
import MemberForm from "../../components/memberForm";
import ReactDOM from "react-dom";
import MapDetail from "../../components/map";
import E404 from "../../components/e404/404";
import MobileNavigation from "../../components/navigation";
import { useMediaQuery } from "react-responsive";
import DetailForm from "../../components/form";
import FormButton from "../../components/form/button";
import FilesDownload from "../../components/filesUpload";

const ProjectsDetail = () => {
  const {id} = useParams();
  const {t, i18n} = useTranslation()
  const [showPopup, setShowPopup] = useState(false);
  const {projects} = useStores();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const [classes, setClasses] = useState('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(()=>{
    projects.detail(id);
    // projects.getForm({project_id:id});
    return () => {
      projects.reset()
    }
  }, [projects, id]);
  const onFinish = async values => {
    setLoading(true)
    values.name = values.first_name;
    values.project = +id;
    const error = await projects.membership(values);
    if (!error) {
      setShowPopup(false);
      notification.success({
        message: t('Успешно'),
        description: t('В скорем времени с вами свяжуться наши модераторы.')
      })
    }
  };
  const handleClickAway = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      setClasses('animate__animated animate__bounceOut');
      setTimeout(() => {
        setShowPopup(false);
      }, 600)
    }
  };
  if(projects.error){
    return <E404/>
  }

  return (
   <>
     {
       projects.project.object ?
         <>
           <Helmet>
             <title>{projects.project.object[`title_${currentLang()}`]}</title>
             <meta property="og:url" content={window.location.href} />
             <meta property="og:type" content={'website'} />
             <meta property="og:title" content={projects.project.object[`title_${currentLang()}`]} />
             <meta property="og:description" content={projects.project.object[`content_${currentLang()}`]} />
             <meta property="og:image" content={baseUrl+projects.project.object.image} />
           </Helmet>
           {
             isMobile && <MobileNavigation title={<Trans>Наши Проекты</Trans>}/>
           }
           <div className={'center-content animate__animated animate__fadeIn'}>
             <div className={'article-detail'}>
               <div className={'detail-content'}>
                 <div className={' header'}>
                   <h1>{projects.project.object[`title_${currentLang()}`]}</h1>
                   <div className={'d-flex'}>
                     <span className={'date'}>{formatDateClient(projects.project.object.created_at)}</span>
                     <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                            stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                          <path
                            d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                            stroke="#313B61" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                       {formatNum(projects.project.object.views_count)}
                      </span>
                   </div>
                 </div>
                 <Image
                   src={`${projects.project.object.image}`}
                 />
                 <div className={'project-detail text'}>
                   {/*<strong><Trans>Описание</Trans></strong>*/}
                   <div dangerouslySetInnerHTML={{__html:projects.project.object[`content_${currentLang()}`]}}/>
                 </div>
                 <FilesDownload files={[{title:projects.project.object[`file_title1_${currentLang()}`],file:projects.project.object.file1},{title:projects.project.object[`file_title2_${currentLang()}`],file:projects.project.object.file2},{title:projects.project.object[`file_title3_${currentLang()}`],file:projects.project.object.file3}]}/>
                 <MapDetail>
                   {
                     projects.project.object.has_form && <FormButton id={id} setVisible={setVisible} store={projects}/>
                   }
                 </MapDetail>
                 {
                   visible && <DetailForm setVisible={setVisible} store={projects} id={projects.form.form} forms={projects.form}/>
                 }

               </div>
               <div className={'popular'}>
                 <h3><Trans>В тему</Trans></h3>
                 {
                   projects.project.similar && projects.project.similar.map((item, idx)=>{
                     return  <div key={idx} className={'event'}>
                       <NavLink to={`/${i18n.language}/projects/${item.id}`} className={'img'}>
                         <img src={item.preview} alt={''}/>
                       </NavLink>
                       <NavLink to={`/${i18n.language}/projects/${item.id}`} className={'description'}>
                         <div >
                           <strong>{item[`title_${currentLang()}`]}</strong>
                           <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(item[`content_${currentLang()}`].replace(/<[^>]+>/g, ''), 100)}}/>
                         </div>
                         <div className={'tags'}>
                           {
                             item[`address_${currentLang()}`] && <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#5768FA"/>
                                </svg>
                               {
                                 truncateString(item[`address_${currentLang()}`], 50)
                               }
                                </span>
                           }

                           <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19V9H19V19ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11Z" fill="#5768FA"/>
                        </svg>
                             {formatDateClient(item.created_at)}
                      </span>
                         </div>
                       </NavLink>
                     </div>
                   })
                 }
               </div>
             </div>

           </div>
         </> :
       projects.loading ? <div className={'d-flex justify-center'}><Spin spinning={true} size={'large'}/></div> : null
     }

     {
       showPopup && <MemberForm onFinish={onFinish} loading={loading} showPopup={showPopup} classes={classes}
                                handleClickAway={handleClickAway}/>
     }

   </>
  )
};
export default observer(ProjectsDetail)
