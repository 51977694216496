import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { useStores } from "../../store";
import { currentLang, formatDateClient, truncateString } from "../../config";
import banner from '../../assets/event.jpg'
import { Button,  Form, Radio, Spin } from "antd";
import ClickAwayListener from "react-click-away-listener";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
const Events = () => {
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const [dropdown, setDropDownHandler] = useState(false)
  const {Events} = useStores();
  const {i18n} = useTranslation()
  useEffect(()=>{
    Events.getData();
    return ()=>{
      Events.reset()
    }
  }, [Events]);
  const onChange = async () => {
    await Events.setFromValue(form.getFieldValue())
    Events.getData();
    setDropDownHandler(false)
  }
  const filterHandler = async (value) => {
    await Events.buttonsValue(value)
    Events.getData();
  }
  const fetchData = () => {
    Events.setPagination()
    Events.getData();
  }
  return (
    <>
      <Helmet>
        <title>Yoshlar Portali - Событие для молодежи</title>
      </Helmet>
      {
        isMobile && <MobileNavigation title={<Trans>События</Trans>}/>
      }
      <div className={'banner  animate__animated animate__fadeIn'} >
        <img src={banner} alt=""/>
        <div className={'center-content'}>
          <h1><Trans>События</Trans></h1>
          <p><Trans>Раздел событий — это подборка концертов, выставок, спектаклей, фестивалей и конференций с описанием и ссылкой на регистрацию</Trans> </p>
        </div>
      </div>
      <div className={'center-content'}>


        <div className={'course-box animate__animated animate__fadeIn'}>
          <div className={'filters d-flex align-center justify-spacebetween'}>
            <ul>
              <li><button className={Events.params === null ? 'active' : ''} onClick={() => Events.params !== null ? filterHandler(null) : null}><Trans>Новые события</Trans></button></li>
              <li><button className={Events.params !== null ? 'active' : ''} onClick={() => Events.params === null ? filterHandler({actual:true}) : null}><Trans>Актуальные события</Trans></button></li>
            </ul>

            <ClickAwayListener onClickAway={()=>setDropDownHandler(false)}>
              <ul>
                <li>
                  <button onClick={()=>setDropDownHandler(!dropdown)} className={'d-flex align-center filter-btn'}>
                    <Trans>Фильтр</Trans>
                    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.12274 3.6001C6.8563 4.6352 5.91668 5.40006 4.79839 5.40006C3.47292 5.40006 2.39844 4.32558 2.39844 3.00011C2.39844 1.67464 3.47292 0.600159 4.79839 0.600159C5.91668 0.600159 6.8563 1.36502 7.12274 2.40012H23.3984C23.7296 2.40012 23.9984 2.66871 23.9984 3.00011C23.9984 3.33151 23.7296 3.6001 23.3984 3.6001H7.12274ZM5.99836 3.00011C5.99836 3.66291 5.46106 4.20009 4.79839 4.20009C4.13572 4.20009 3.59841 3.66278 3.59841 3.00011C3.59841 2.33744 4.13572 1.80013 4.79839 1.80013C5.46106 1.80013 5.99836 2.33731 5.99836 3.00011Z" fill="white"/>
                      <path d="M0.599987 3.60001H2.47555C2.42616 3.40829 2.39995 3.2072 2.39995 3.00003C2.39995 2.79285 2.42616 2.59176 2.47555 2.40004H0.599987C0.268589 2.40004 0 2.66863 0 3.00003C0 3.33142 0.268589 3.60001 0.599987 3.60001Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.3243 14.4001C14.0579 13.365 13.1182 12.6002 12 12.6002C10.8818 12.6002 9.94196 13.365 9.67565 14.4001H0.599987C0.268589 14.4001 0 14.6688 0 15.0001C0 15.3314 0.268589 15.6001 0.599987 15.6001H9.67552C9.94196 16.6352 10.8817 17.4001 11.9999 17.4001C13.118 17.4001 14.0579 16.6352 14.3242 15.6001H23.3998C23.731 15.6001 23.9997 15.3314 23.9997 15.0001C23.9997 14.6688 23.731 14.4001 23.3998 14.4001H14.3243ZM12 16.2001C12.6628 16.2001 13.2 15.6629 13.2 15.0001C13.2 14.3373 12.6628 13.8001 12 13.8001C11.3372 13.8001 10.8 14.3373 10.8 15.0001C10.8 15.6629 11.3372 16.2001 12 16.2001Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M21.5243 8.40009C21.2579 7.36499 20.3181 6.60013 19.2 6.60013C18.0818 6.60013 17.1419 7.36499 16.8756 8.40009H0.599987C0.268589 8.40009 0 8.66881 0 9.00008C0 9.33135 0.268589 9.60007 0.599987 9.60007H16.8756C17.1421 10.6352 18.0818 11.4 19.2 11.4C20.3181 11.4 21.258 10.6352 21.5243 9.60007H23.3999C23.7312 9.60007 23.9999 9.33135 23.9999 9.00008C23.9999 8.66881 23.7312 8.40009 23.3999 8.40009H21.5243ZM19.2 10.2001C19.8628 10.2001 20.3999 9.66288 20.3999 9.00008C20.3999 8.33728 19.8626 7.8001 19.2 7.8001C18.5372 7.8001 18 8.33728 18 9.00008C18 9.66288 18.5372 10.2001 19.2 10.2001Z" fill="white"/>
                    </svg>
                  </button>
                  <ul className={`dropdown ${dropdown ? 'open' : ''}`}>
                    <li>
                      <Form form={form} layout={'vertical'}>
                        <Form.Item name={'period'} label={<Trans>Период</Trans>}>
                          <Radio.Group style={{ width: '100%' }} onChange={onChange}>
                            <Radio value="week"><Trans>За эту неделю</Trans></Radio>
                            <Radio value="month"><Trans>За этот месяц</Trans></Radio>
                            <Radio value="half_year"><Trans>За полгода</Trans></Radio>
                            <Radio value="year"><Trans>За год</Trans></Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Button onClick={()=> {form.resetFields(); setDropDownHandler(false); Events.setFromValue(form.getFieldValue())}} className={'btn-reset'}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.6 8C1.6 6.09305 2.416 4.38253 3.696 3.18762L4.92 4.43982C5.168 4.69354 5.6 4.51348 5.6 4.14519V0.634114C5.6 0.404953 5.424 0.224898 5.2 0.224898H1.768C1.408 0.224898 1.232 0.666851 1.488 0.920565L2.56 2.02545C0.992 3.51499 0 5.63473 0 8C0 11.8876 2.656 15.1449 6.208 15.9797C6.712 16.0943 7.2 15.7096 7.2 15.1776C7.2 14.793 6.936 14.4656 6.568 14.3756C3.728 13.7127 1.6 11.11 1.6 8ZM16 8C16 4.11245 13.344 0.85509 9.792 0.0202898C9.288 -0.0942906 8.8 0.290372 8.8 0.822353C8.8 1.20702 9.064 1.53439 9.432 1.62442C12.272 2.28735 14.4 4.88996 14.4 8C14.4 9.90695 13.584 11.6175 12.304 12.8124L11.08 11.5602C10.832 11.3065 10.4 11.4865 10.4 11.8548V15.3659C10.4 15.595 10.576 15.7751 10.8 15.7751H14.232C14.592 15.7751 14.768 15.3331 14.512 15.0794L13.44 13.9746C15.008 12.485 16 10.3653 16 8Z" fill="#00264D"/>
                          </svg>
                          <Trans>Сбросить</Trans>
                        </Button>
                      </Form>
                    </li>
                  </ul>
                </li>
              </ul>
            </ClickAwayListener>
          </div>
          <Spin spinning={Events.loading} size={'large'}>
            <InfiniteScroll
              dataLength={20}
              next={fetchData}
              hasMore={Events.list.next}
              pullDownToRefreshThreshold={300}
              style={{overflowAnchor: 'auto', overflow: 'inherit'}}
              useWindow={true}
            >
              <div className={'events-items'}>
                <div className={'line'}/>
                <div className={'events-block'}>
                  {
                    Object.keys(Events.list.results).map((item, index) => {
                      return <div key={index} className={'animate__animated animate__fadeIn'}>
                        <span className={'date'}>{item}</span>
                        <div className={'column'}>
                          {
                            Events.list.results[item].map((event, indx) => {
                              return <div key={indx} className={'event'}>
                                <NavLink to={`/${i18n.language}/events/${event.id}`} className={'img'}>
                                  <img src={event.preview} alt={''}/>
                                </NavLink>
                                <NavLink to={`/${i18n.language}/events/${event.id}`} className={'description'}>
                                  <div className={'top'}>
                                    <strong>{event[`title_${currentLang()}`]}</strong>
                                    <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(event[`content_${currentLang()}`].replace(/<(?!br\s*\/?)[^>]+>/g, ''), 205)}}/>
                                  </div>
                                  <div className={'tags'}>
                                    {
                                      event[`address_${currentLang()}`] && <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#5768FA"/>
                                    </svg>
                                        {event[`address_${currentLang()}`]}
                                  </span>
                                    }
                                    {
                                      event.date && <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19V9H19V19ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11Z" fill="#5768FA"/>
                                    </svg>
                                        {formatDateClient(event.date)}
                                  </span>
                                    }
                                  </div>
                                </NavLink>
                              </div>
                            })
                          }
                        </div>
                      </div>
                    })
                  }
                </div>
              </div>
            </InfiniteScroll>
          </Spin>

        </div>
      </div>
    </>
  )
};

export default observer(Events)