import { action, computed, flow, makeObservable, observable, toJS } from "mobx";
import moment from 'moment';
import api from "../../api";
import location from './location';

class Profile {
  @observable exportTypeButtons = [
    {
      title:'Наши проекты',
      type:'project',
      active:true
    },
    {
      title:'Актуальные гранты',
      type:'grant',
      active:false
    },
    {
      title:'Актуальные конкурсы',
      type:'contest',
      active:false
    },
    {
      title:'События',
      type:'event',
      active:false
    },
    {
      title:'Учебные курсы',
      type:'course',
      active:false
    },
  ]
  @observable profile = {
    is_tashkent:false,
    region_id:null,
    district_id:null,
    address:null,
    street_id:null,
    birth_date:moment()
  };
  @observable benefits = [];
  @observable exports = {
    results:[]
  };
  @observable exportsDetail = {};
  @observable params = {type:'project'};
  constructor() {
    makeObservable(this)
  }
  @action setTashkent = (value) => {
    this.profile.is_tashkent = value
  }
  @flow *
  getProfile(){
    const response = yield api.app.profile();
    if(!response.detail){
      this.profile = response.data;
    }
  }

  @flow *
  getProfileByGCP(values){
    const response = yield api.app.profileGCP(values);
    if(!response.detail){
      this.profile = response.data.user_data;
      this.profile.gcp_verified = true
    }else
      return response.detail
  }
  @flow *
  getProfileByGCP2(values){
    const response = yield api.app.profileGCP2(values);
    if(!response.detail){
      this.profile = response.data.user_data;
      this.profile.gcp_verified = true;
    }else
      return response.detail
  }
  @flow *
  getProfileByGCP3(values){
    const response = yield api.app.profileGCP3(values);
    return response
  }

  @flow *
  getBenefits(values){
    const response = yield api.app.benefits(values);
    if(!response.detail){
      this.benefits = response.data;
    }
  }
  @flow *
  edit(values){
    const response = yield api.app.editProfile(values);
    if(response.detail){
      return response.detail
    }
    this.profile = values
  }
  @flow * changePassword(values){
    const response =  yield api.app.changePassword(values);
    if(response.detail){
      let data = {};
      for(let type in response.detail){
        data = response.detail[type];
      }
      return data.map((item) => item)
    }else{
      return {
        status:'success',
        text:response.data.detail
      }
    }
  }
  @flow * changeEmail(values){
    const response =  yield api.app.changeEmail(values);
    if(response.detail){

    }
  }
  @flow * changeAddress(values){
    const response =  yield api.app.changeAddress(values);
    if(response.detail){

    }
  }
  @computed get profileValues(){
    const profile = toJS(this.profile);
    profile.birth_date =  moment(profile.birth_date);
    profile.phone_number = profile.phone;
    profile.region_id = profile.region && profile.region.id;
    profile.district_id = (profile.district && profile.is_tashkent) ? profile.district.addr_register_id : profile.district && profile.district.id;
    profile.address = profile.regional_address;
    if(profile.region_id === 10){
      location.getDistrictRegistry(profile.region && profile.region.id)
    }else{
      location.getPMDistrict(profile.region && profile.region.id)
    }
   return Object.keys(profile).map(k => ({
      name: k,
      value: profile[k]
    }))
  }
  @computed get getProfileValue(){
    const profile = toJS(this.profile);
    profile.street_id = null;
    return Object.keys(profile).map((key) => ({
      name: key,
      value: profile[key]
    }))

  }
  @action isActiveButton = (item) => {
    this.exportTypeButtons.forEach(item => item.active = false)
    item.active = true;

  }
  @action setParamsExport = (values) => {
    this.params = values;
  }
  @flow * getExportList(){
    const response = yield api.exports.getList(this.params);
    if(!response.detail){
      this.exports = response.data;
    }
  }
  @flow * sendAccept(values){
   return yield api.exports.send(values);
  }
  @flow * exportDetail(id){
    const response = yield api.exports.exportDetail({...this.params, ...id});
    if(!response.detail){
      this.exportsDetail = response.data;
    }
  }
  @flow * exportItem(values){
    const response = yield api.exports.exportItem(values);
    if(response.status === 200){
      const a = document.createElement('a');
      a.href = URL.createObjectURL(response.data);
      a.setAttribute('download', `export.xlsx`)
      a.setAttribute('target', '_blank')
      document.body.appendChild(a)
      a.click()
      a.remove()
    }else{
      return  'Что-то пошло не так попробуйте позже'
    }
  }
};

export default new Profile();