import React from 'react';
import { Trans } from "react-i18next";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

const ShareComponent = (props) => {
  const shareUrl = '/';
  return (
    <div className={'dropdown-share-button'}>
      <Trans>Поделиться</Trans>:
      <ul>
        <li>
          <TelegramShareButton className={'tg'} url={shareUrl} title={props.title}>
            <svg width="22" height="20  " viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.5562 20.2393L12.0269 27.6846C12.7842 27.6846 13.1122 27.3593 13.5056 26.9686L17.0562 23.5753L24.4136 28.9633C25.7629 29.7153 26.7136 29.3193 27.0776 27.7219L31.9069 5.0926L31.9082 5.09127C32.3362 3.0966 31.1869 2.3166 29.8722 2.80594L1.48557 13.6739C-0.451763 14.4259 -0.42243 15.5059 1.15624 15.9953L8.41357 18.2526L25.2709 7.7046C26.0642 7.17927 26.7856 7.46994 26.1922 7.99527L12.5562 20.2393Z"
                fill="#419FD9"/>
            </svg>
          </TelegramShareButton>
        </li>
        <li>
          <FacebookShareButton className={'fb'} url={shareUrl} >
            <svg width="23" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0)">
                <path
                  d="M21.3295 5.31333H24.2508V0.225333C23.7468 0.156 22.0135 0 19.9948 0C15.7828 0 12.8975 2.64933 12.8975 7.51867V12H8.24951V17.688H12.8975V32H18.5962V17.6893H23.0562L23.7642 12.0013H18.5948V8.08267C18.5962 6.43867 19.0388 5.31333 21.3295 5.31333Z"
                  fill="#4267B2"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="32" height="32" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton className={'in'} url={shareUrl}  title={props.title}>
            <svg width="21" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0)">
                <path
                  d="M32 6.078C30.81 6.6 29.542 6.946 28.22 7.114C29.58 6.302 30.618 5.026 31.106 3.488C29.838 4.244 28.438 4.778 26.946 5.076C25.742 3.794 24.026 3 22.154 3C18.522 3 15.598 5.948 15.598 9.562C15.598 10.082 15.642 10.582 15.75 11.058C10.296 10.792 5.47 8.178 2.228 4.196C1.662 5.178 1.33 6.302 1.33 7.512C1.33 9.784 2.5 11.798 4.244 12.964C3.19 12.944 2.156 12.638 1.28 12.156C1.28 12.176 1.28 12.202 1.28 12.228C1.28 15.416 3.554 18.064 6.536 18.674C6.002 18.82 5.42 18.89 4.816 18.89C4.396 18.89 3.972 18.866 3.574 18.778C4.424 21.376 6.836 23.286 9.704 23.348C7.472 25.094 4.638 26.146 1.57 26.146C1.032 26.146 0.516 26.122 0 26.056C2.906 27.93 6.35 29 10.064 29C22.136 29 28.736 19 28.736 10.332C28.736 10.042 28.726 9.762 28.712 9.484C30.014 8.56 31.108 7.406 32 6.078Z"
                  fill="#1DA1F2"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="32" height="32" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </TwitterShareButton>
        </li>
      </ul>
    </div>
  )
};
export default ShareComponent;