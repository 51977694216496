import React, { useState } from 'react';
import { Alert, Button, Statistic } from "antd";
import api from '../../api';
import { Trans } from "react-i18next";

const CounterDown = () => {
  const {Countdown} = Statistic;
  const [error, setError] = useState({ show: false, message: '' });
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(Date.now() + 120000);

  const renderer = () => {
    setDisabled(false)

  };
  const retryCode = async () => {
    const error = await api.app.retryCode();
    if (error.data.detail) {
      setError({
        show: true,
        message: error.data.detail
      });
      setTimer(Date.now() + 120000);
      setDisabled(true);
    }
  }
  return (
    <>
      <div className={'recover-code d-flex align-center animate__animated animate__fadeInUp'}>
        <Button disabled={disabled} type={'text'} style={{paddingLeft: 0}} onClick={retryCode}><Trans>Отправить код еще раз</Trans></Button>

        <Countdown title="Countdown" value={timer} format="mm:ss" onFinish={renderer} />

      </div>
      {
        error.show && <Alert message={error.message} style={{textAlign:'left'}} type={'warning'}/>
      }
    </>
  )
};
export default CounterDown