import React from 'react';
import { DatePicker, Form } from "antd";
import { useTranslation } from "react-i18next";
import en from "antd/lib/locale/en_US";
import ru from "./translate/ru";
import uz_Latn from "./translate/uz";
import o_zOz from "./translate/oz";

const DatePickerPortal = (props) => {
  const {t, i18n} = useTranslation();
  const languages = {
    uz:uz_Latn,
    ru:ru,
    en:en,
    oz:o_zOz
  };
  return  <Form.Item label={props.label} name={props.name}>
    <DatePicker locale={languages[i18n.language]} placeholder={props.label ? t('Выберите дату') : t('Дата отправки')}  format={'DD.MM.YYYY'}/>
  </Form.Item>
};

export default DatePickerPortal;