import React from 'react';
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";


const FormButton = (props) => {
  const {store,  id,  setVisible} = props;
  const {t} = useTranslation()
  const getForm = async () => {
    const error = await store.getForm(id);
    if(!error){
      setVisible(true);
    }
  };
  return (
    <Button type={'primary'} className={'btn success btn-success'} onClick={()=>getForm()}>{t('Стать участником')}</Button>
  )
}
export default observer(FormButton)