import React, { useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Alert, Button, Form, Input, message } from "antd";
import { useStores } from "../../store";

const ChangePassword = () => {
  const { t} = useTranslation();
  const {profile} = useStores();
  const [error, setError] = useState({show:false, message:''});
  const onFinish = async values => {
    setError({show:false, message:''});
    const error = await profile.changePassword(values);
    if(!error.status){
      setError({
        show:true,
        message:error
      })
    }else{
      message.success(error.text)
    }
  };
  return (
    <>
      <div className={'profile-header-title'}>
        <h3><Trans>Изменить пароль</Trans></h3>
      </div>
      {
        error.show &&
        error.message.map((item, idx) => {
          return <Alert banner closable showIcon style={{marginBottom:10}} key={idx} message={item} type="error"/>
        })
      }
      <Form
        layout={'vertical'}
        onFinish={onFinish}
        className={'change-password-form'}
      >
        <Form.Item
          label={t('Старый пароль')}
          name={'old_password'}
          rules={[{required:true, message:t('Введите старый пароль')}]}
        >
          <Input.Password autoComplete="off" placeholder={t('Введите старый пароль')}/>
        </Form.Item>
        <div className={'d-flex'}>
          <Form.Item
            label={t('Новый пароль')}
            name={'new_password1'}
            rules={
              [
                {required:true, message:t('Введите новый пароль')},
                {
                  validator: (rule, value, callback) => {
                    if (!/^[a-z0-9]{8,14}$/.test(value)) {
                      callback(t('Введеный пароль слишком короткий'));
                    } else {
                      callback();
                    }
                  }
                }
              ]
            }
          >
            <Input.Password autoComplete="off" placeholder={t('Введите новый пароль')}/>
          </Form.Item>
          <Form.Item
            label={t('Повторить новый пароль')}
            name={'new_password2'}
            rules={
              [
                {required:true, message:t('Повторите пароль')},
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password1') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('Пароль не совбадает')));
                  },
                })
              ]
            }
          >
            <Input.Password autoComplete="off" placeholder={t('Повторить новый пароль')}/>
          </Form.Item>
        </div>
        <Button type={'primary'} htmlType={'submit'} className={'btn save primary'}><Trans>Сохранить</Trans></Button>
      </Form>
    </>
  )
};

export default ChangePassword