import React, { useEffect, useRef } from 'react';
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import 'aos/dist/aos.css';
import { useStores } from "../../store";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Spin } from "antd";
import MainHeader from "../../components/header";
import {  currentLang, formatDateClient, truncateString } from "../../config";
import Slider from "react-slick";
import { toJS } from "mobx";
import video from '../../5tashabbusday.mp4'
import {gsap} from 'gsap';
import { useMediaQuery } from "react-responsive";
import { useSpring, animated } from 'react-spring'
import AppealPage from "../appeal";
const MainScreen = () => {
  const {t} = useTranslation()
  const isBigScreen = useMediaQuery({ query: '(min-width: 1100px)' })
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    if(isBigScreen){
      gsap.fromTo(
        element.querySelector(".animate-logo"),
        {
          scale: 1,
          y: 0
        },
        {
          x: document.querySelector('.dark .curlogo').getBoundingClientRect().left - element.querySelector(".animate-logo").getBoundingClientRect().left - 215,
          y: document.querySelector('.dark .curlogo').getBoundingClientRect().top - element.querySelector(".animate-logo").getBoundingClientRect().top - 50,
          scale: document.querySelector('.dark .curlogo').getBoundingClientRect().width / element.querySelector(".animate-logo").getBoundingClientRect().width,
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: element,
            start: "top top",
            end: "bottom top",
            scrub: true
          }
        }
      );
    }
  }, [isBigScreen]);

  return <div className={'home-section video animate__animated animate__fadeIn'} ref={ref}>
    {
      isBigScreen && <MainHeader classes={'light'}/>
    }

    <video  autoPlay loop muted playsInline src={video}/>
    <div className={'banner-text'}>
      <div className={'animate-logo desktop'}>
        <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.89923 15.3128C8.23265 12.5964 10.3016 10.3086 12.8707 8.70971C15.4399 7.11081 18.4062 6.26492 21.4322 6.26825H33.7458V18.5819C33.7492 21.6079 32.9033 24.5742 31.3044 27.1434C29.7055 29.7125 27.4177 31.7814 24.7013 33.1149V35.797C24.6946 37.2035 24.567 38.6068 24.32 39.9915C28.8779 38.5601 32.859 35.7094 35.6824 31.8556C38.5057 28.0017 40.0234 23.3464 40.014 18.569V4.31976e-05H21.4322C16.6548 -0.00933463 11.9995 1.5084 8.14565 4.33173C4.29179 7.15507 1.44115 11.1362 0.00976562 15.6941C1.39448 15.4475 2.79779 15.3199 4.20428 15.3128H6.89923Z" fill="#009B00"/><path d="M24.3281 39.9702L27.3273 31.4697C27.0074 31.7125 26.6789 31.941 26.3418 32.1552L26.2947 32.1852C26.0091 32.3652 25.7234 32.5366 25.4378 32.6994L25.0522 32.9093C24.9408 32.9693 24.8294 33.0336 24.7137 33.0936V35.7756C24.707 37.1821 24.5794 38.5855 24.3324 39.9702H24.3281Z" fill="#077207"></path><path d="M4.21562 15.3119H6.89771C6.94913 15.2091 7.00911 15.1105 7.06052 15.012C7.16764 14.8106 7.27046 14.6093 7.38614 14.4122C7.50183 14.2151 7.63465 13.9837 7.76318 13.7909C7.89172 13.5981 7.94741 13.5039 8.04596 13.3625C8.20877 13.1225 8.38015 12.8869 8.5601 12.6513C8.60294 12.5913 8.6415 12.5313 8.68863 12.4756L0.0253906 15.6932C1.4087 15.4468 2.81056 15.3192 4.21562 15.3119Z" fill="#077207"></path><path d="M18.4275 21.6502V21.5859H18.3633L18.4275 21.6502Z" fill="#FBB03B"></path><path d="M18.1152 29.8164C18.2181 29.9407 18.3295 30.0563 18.428 30.1806L18.1152 29.8164Z" fill="#FFDF00"></path><path d="M24.7001 35.7967V15.3125H4.216C2.8095 15.3196 1.4062 15.4471 0.0214844 15.6938L2.82783 18.5002L5.85696 21.5464L10.7156 21.5807H18.4277V30.1797L18.4534 34.1514L21.4954 37.1934L24.3017 39.9997C24.555 38.6128 24.6883 37.2065 24.7001 35.7967Z" fill="#FFDF00"></path>
        </svg>
        <span>{t('Молодёжный портал')}</span>
      </div>
      <i>{t('Мне очень приятно видеть, что у нас много молодых людей с широким кругозором, пытливым и прогрессивным мышлением.')}</i>
      <p><i>{t('Ш. Мирзиёев')}</i></p>
    </div>
  </div>
}

const Index = () =>{
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  const isBigScreen = useMediaQuery({ query: '(min-width: 1100px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const element = useRef(null);
  const {i18n, t} = useTranslation()
  const { Home, app, appeals, Grants} = useStores();
  const { resetActive, setActive} = Home;

  useEffect(()=>{
    Home.getData();
    const header = element.current;
    if(isBigScreen){
      gsap.fromTo(
        header.querySelector('.dark .logo'),
        {
          opacity: 0,
          y:0
        },
        {
          opacity:1,
          y:0,
          duration: 1,
          scrollTrigger: {
            trigger: header.querySelector('.dark .logo'),
            start: "-100px top",
            end: "bottom top",
            scrub: true
          }
        }
      );
    }
    return () => {
      Home.reset()
    }
  }, [ Home, app, isBigScreen, appeals]);


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay:false,
    duration:1500,
    slidesToShow: 3,
    fade:false,
    draggable:false,
    slidesToScroll: 1,
    responsive:[
      {
        breakpoint:1024,
        settings: "unslick"
      }
    ]
  };

  const handleChange = (index) => {
    resetActive(index);
    setActive(index);
  };
  const filterGrants = async value => {
    Grants.buttonsValue(value)
    Grants.getData()
  }
  return (

    <>
      <Helmet>
        <title>Yoshlar Portali - Главная</title>
      </Helmet>
      <div className={'home'}  ref={element}>
        <MainScreen/>
        {
          isBigScreen && <MainHeader classes={'dark'} />
        }

        <div className={'home-section benefits animate__animated animate__fadeIn'}>
          <div className={'center-content'}>
            <div className={'header-title'}>
              <NavLink to={`/${i18n.language}/opportunities`}><h1>{t('Возможности')}</h1></NavLink>
            </div>
            <div className={'cards'}>
              {
                Home.data.benefits.map((item, index) => {
                  return  <div key={index} className={'benefit'}>
                    <div>

                      <div className={'tags'}>
                        {
                          item.scope.slice(0, 3).map((scope,index) => {
                            return <span key={index}>{scope}</span>
                          })
                        }
                      </div>
                      <NavLink to={`/${i18n.language}/opportunities/${item.id}`}><strong>{truncateString(item[`title_${currentLang()}`], 50)}</strong></NavLink>
                      <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(item[`content_${currentLang()}`].replace(/<(?!br\s*\/?)[^>]+>/g, ''), 150)}}/>
                    </div>
                    <NavLink className={'more'} to={`/${i18n.language}/opportunities/${item.id}`}>{t('Хочу знать больше')}</NavLink>
                  </div>
                })
              }

              <div className={'last'}>
                <NavLink to={`/${i18n.language}/opportunities`}>{t('Узнайте, чем молодёжный портал полезн именно вам')}</NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className={'home-section grants'}>
          <div className={'center-content'}>
            <div className={'header-title'}>
             <NavLink to={`/${i18n.language}/grants`}><h1>{t('Актуальные гранты')}</h1></NavLink>
              <div className={'filters'}>
                <ul>
                  <li><button className={Grants.params === null ? 'active' : ''} onClick={() =>filterGrants(null)}>{t('Новые')}</button></li>
                  <li><button className={Grants.params !== null ? 'active' : ''} onClick={() => filterGrants({hot:true})}>{t('Горячие')}</button></li>
                </ul>
              </div>
            </div>
            <Spin spinning={Grants.loading} size={'large'}>
              <div className={'cards'}>
                {
                  Home.data.grants.map((item, index)=>{
                    return  <NavLink to={`/${i18n.language}/grants/${item.id}`} key={index} className={'grant'}>
                      <div className={'img'}>
                        <img src={item.preview} alt={''}/>
                      </div>
                      <div>
                        <span>{formatDateClient(item.deadline)}</span>
                        {
                          item[`address_${currentLang()}`] && <span>{truncateString(item[`address_${currentLang()}`], 20)}</span>
                        }
                        {
                          item.program[`name_${currentLang()}`] && <span>{truncateString(item.program[`name_${currentLang()}`], 20)}</span>
                        }
                      </div>
                      <p>{isMobile ? truncateString(item[`title_${currentLang()}`], 50) : item[`title_${currentLang()}`]}</p>
                    </NavLink>
                  })
                }
              </div>
            </Spin>

          </div>
        </div>
        <div className={'home-section projects'}>
          <div className={'center-content'}>
            <div className={'header-title'}>
              <NavLink to={`/${i18n.language}/projects`}><h1><Trans>Наши проекты</Trans></h1></NavLink>
            </div>
            <div className={'project'}>
              {
                Home.data.projects.slice(0, 3).map((item, index) => {
                  return <NavLink to={`/${i18n.language}/projects/${item.id}`} key={index} className={'card'}>
                    <img src={item.preview} alt={''}/>
                    <strong>{item[`title_${currentLang()}`]}</strong>
                    <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(item[`content_${currentLang()}`].replace(/<[^>]+>/g, ''), 150)}}/>
                  </NavLink>
                })
              }
            </div>
          </div>
        </div>
        <div className={'home-section events'}>
          <div className={'center-content'}>
            <div className={'header-title'}>
              <NavLink to={`/${i18n.language}/events`}><h1>{t('События')}</h1></NavLink>
            </div>

            <div className={'cards'}>
              <Slider {...settings}>
                {
                  toJS(Home.data.events).map((item, index) => {
                    return <div key={index} className={'slide'}>
                      <div className={'event'}>
                        <NavLink title={item[`title_${currentLang()}`]} to={`/${i18n.language}/events/${item.id}`} className={'img'}>
                          <img src={item.preview} alt={''}/>
                        </NavLink>
                        <NavLink to={`/${i18n.language}/events/${item.id}`} className={'description'}>
                          <strong>{truncateString(item[`title_${currentLang()}`], 60)}</strong>
                          <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(item[`content_${currentLang()}`].replace(/<(?!br\s*\/?)[^>]+>/g, ''), 100)}}/>
                          <div className={'tags'}>
                            {
                              item[`address_${currentLang()}`] && <span>
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12 2C8.13 2 5 5.13 5 9C5 13.17 9.42 18.92 11.24 21.11C11.64 21.59 12.37 21.59 12.77 21.11C14.58 18.92 19 13.17 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#5768FA"/>
                                      </svg>
                                {item[`address_${currentLang()}`]}
                                    </span>
                            }
                            {
                              item.date && <span>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19V9H19V19ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11Z" fill="#5768FA"/>
                                  </svg>
                                {formatDateClient(item.date)}
                                  </span>
                            }
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  })
                }
              </Slider>
            </div>
            <div className={'more d-flex align-center justify-center'}>
              <NavLink to={`/${i18n.language}/events`} className={'btn more'}>{t('Все событии')}</NavLink>
            </div>
          </div>
        </div>
        <div className={'home-section achievements'} onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <div className={'center-content'}>
            <div className={'header-title'}>
              <NavLink to={`/${i18n.language}/achievements`}><h1>{t('Доска почета')}</h1></NavLink>
            </div>
            <div className={'cards'}>

              {
                toJS(Home.fameSlide).map((item, index) => {
                  return item.active && <div key={index} className={'description'}>
                    <NavLink to={`/${i18n.language}/achievements/${item.id}`}>{item[`last_name_${currentLang()}`]} {item[`name_${currentLang()}`]}</NavLink>
                    <div style={{position:'relative'}} dangerouslySetInnerHTML={{ __html: item[`achievement_${currentLang()}`] }}/>
                  </div>
                })
              }
              <div className={'persons'}>
                {
                  toJS(Home.fameSlide).map((item, index) => {
                    return  item.active ?
                      <div className={'slide'} key={index} >
                        <NavLink title={'person'} to={`/${i18n.language}/achievements/${item.id}`}>
                          <img src={item.image} alt={''}/>
                        </NavLink>
                      </div>
                       :
                      <button aria-label={`person`} onClick={()=>handleChange(index)} key={index} className={item.active ? 'active' : ''}>
                      <img src={item.image} alt={''}/>
                    </button>
                  })
                }
              </div>

            </div>
          </div>
          <div className={'icon-2 icon'}/>
          <animated.div className={'icon-1 icon'} style={{ transform: props.xy.interpolate(trans1) }}/>
          <div className={'icon-3 icon'}/>
          <animated.div className={'icon-4 icon'} style={{ transform: props.xy.interpolate(trans1) }}/>
          <div className={'icon-5 icon'}/>
          <animated.div className={'icon-6 icon'} style={{ transform: props.xy.interpolate(trans1) }}/>
          <div className={'icon-7 icon'}/>
          <div className={'icon-8 icon'}/>
          <animated.div className={'icon-9 icon'} style={{ transform: props.xy.interpolate(trans1) }}/>
        </div>
        <AppealPage/>
      </div>
    </>
  )
};

export default observer(Index)