import React, { useEffect, useState } from 'react';
import { useStores } from "../../store";
import { observer } from "mobx-react";
import { formatDateServer } from "../../config";
import Step1Registration from './step_1'
import Step2Registration from './step_2'
import { notification, Form, message } from "antd";
import { Trans } from "react-i18next";
const RegistrationModal = ({...props}) => {
  const [form] = Form.useForm();
  const { Registration, Modals } = useStores();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [streetName, setStreetName] = useState('');
  useEffect(() => {
    Registration.reset()
  }, [Registration]);
  const { handleClickAway } = props;

  const onFinish = async values => {
    setError(null)
    setLoading(true);
    if (Registration.step === 1) {
      const error = await Registration.signupCode({ phone: values.phone });
      if (error) {
        setLoading(false);
        setError(error);
      } else {
        setLoading(false);
      }
    } else if (Registration.step === 2) {
      delete values.phone
      const error = await Registration.confirmCode(values);
      if (error) {
        setError(error.data.detail);
        setLoading(false);
        form.resetFields()
      }else{
        setLoading(false);
      }

      debugger
    } else if (Registration.step === 3 || Registration.step === 4) {
      values.street = streetName;
      values.birth_date = formatDateServer(values.birth_date)
      await Registration.setForm(values);
      setLoading(false);
      form.resetFields()
    } else {
      const error = await Registration.registration(values);
      if (!error) {
        setLoading(false);
        Modals.hide();
        notification.success({
          message: <Trans>Добро пожаловать в "Портал молодежи"</Trans>,
          description:
            <Trans>Поздравляем вы успешно зарегистрировались</Trans>,
        });
      }else{
        debugger
        error.map((item)=> {
          return message.error(item)
        });
        setLoading(false);
      }
    }
  };
  const onFinishFailed = values => {
    setLoading(false);
  };
  if (Registration.step === 1 || Registration.step === 2) {
    return <Step2Registration onFinish={onFinish} loading={loading} error={error} onFinishFailed={onFinishFailed}
                              handleClickAway={handleClickAway}/>
  } else {
    return <Step1Registration onFinish={onFinish} form={form} setStreetName={setStreetName} loading={loading} error={error}
                              onFinishFailed={onFinishFailed} handleClickAway={handleClickAway}/>
  }
}
export default observer(RegistrationModal)