import React, { useEffect } from 'react';
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { CSSTransition } from 'react-transition-group'
import { useStores } from "../store";
import MainHeader from "../components/header";
import MainFooter from "../components/footer";
import RegistrationModal from "../page/registration";
import Auth from '../page/auth';
import AuthType from '../page/auth/auth-type';
import RecoverPassword from "../page/recover-password";
import {  useTranslation} from "react-i18next";
import { Drawer, notification } from "antd";
import IdeasModal from "../page/ideas/modal";
import IdeasDetail from "../page/ideas/detail";
import { SuccessModalForm, SuccessModal } from "../page/ideas/sussessModal";
import FilterPopup from "../page/grants/modal";
import VacancyModal from "../page/vacancy/modal";
import LibraryModal from "../page/magazine/modal";
import FilterContestPopup from "../page/contests/modal";
import { useMediaQuery } from 'react-responsive'
import LangSwitcher from "../components/lang-switcher";
import FocusTrap from "focus-trap-react";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import RuleModal from "../components/rule/modal";
import PolicyModal from "../components/rule/policy";
const MainLayout = ({children}) => {
  const {Modals, app, profile} = useStores();
  const history = useHistory()
  const location = useLocation()
  const {t, i18n} = useTranslation()
  const isBigScreen = useMediaQuery({ query: '(min-width: 1100px)' })
  const isMobile = useMediaQuery({ query: '(max-width:1099px)' })
  useEffect(()=>{
    Modals.hide();
    notification.close(1);
  }, [Modals]);
  const logoutHandler = () => {
    const err = app.logout();
    if (!err) {
      history.push(`/${i18n.language}/`);
      notification.success({
        message: t('Так быстро'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
        description:t('Мы будем рады снова видеть вас в Портале молодежи возвращайтесь скорее')
      });
    }
  };
  return (
    <>
      <div className="badge">Beta</div>
      {
        ((location.pathname.length > 4 && isBigScreen) || (isMobile && location.pathname.length < 5))  && <MainHeader classes={'default'}/>
      }
      <div className="site-layout-background" style={{ position:'relative' }}>
        <CSSTransition
          key={location.pathname}
          timeout={{ enter: 1000, exit: 200 }}
          classNames="fade"
        >
          {children}
        </CSSTransition>
      </div>

      <MainFooter/>
      {
        Modals.showPopup && <div className={`popup  show ${ 
          Modals.modal.success || Modals.modal.successIdeas ? 'success' : 
          Modals.modal.vacancy ? 'vacancy' : 
          Modals.modal.library ? 'library' : ''}`}>
          <div className={'overlay'} onClick={() =>  Modals.modal.success && Modals.hide() }/>
          <FocusTrap focusTrapOptions={{
              allowOutsideClick(event) {
              return event.target.className.includes('.popup-content') || event.target.className.includes('ant-') || event.target.className.includes('btn') || event.target.className.includes("close-popup");
            },
          }} active={Modals.showPopup} >
            <div  className={`popup-content  ${Modals.showPopup ? 'animate__animated animate__bounceInDown' : 'animate__animated animate__bounceOut'}`}>
              {
                Modals.modal.authType ?
                  <AuthType /> :
                Modals.modal.auth ?
                  <Auth/> :
                Modals.modal.registration ?
                  <RegistrationModal/> :
                Modals.modal.recoverPassword ?
                  <RecoverPassword/> :
                Modals.modal.createIdea ?
                  <IdeasModal /> :
                Modals.modal.ideasDetail ?
                  <IdeasDetail/> :
                Modals.modal.successIdeas ?
                  <SuccessModal/> :
                Modals.modal.filterPopup ?
                  <FilterPopup/> :
                Modals.modal.vacancy ?
                  <VacancyModal/> :
                Modals.modal.library ?
                  <LibraryModal/> :
                Modals.modal.contest ?
                  <FilterContestPopup/> :
                Modals.modal.success ?
                  <SuccessModal/> :
                Modals.modal.successForm &&
                  <SuccessModalForm/>
              }
            </div>
          </FocusTrap>
        </div>
      }

      {
        Modals.modal.howReg && <div className={'popup auth-type show'}>
          <div className={'overlay'}/>
          <div className={`popup-content `}>
            <div className={'close-popup'} onClick={()=>{Modals.hide(); Modals.show('authType')}}/>
            <div className={'popup-header'}>
              <h3 className={'reg'}>{t('Регистрация')}</h3>
            </div>
            <p className={'small'}>{t('Это необходимо для использования всех возможностей портала (участие в событиях,конкурсах, направления обращений и т.д.)')}</p>
            <div className={'popup-buttons'}>
              <span className={'or'}>{t('Выберите способ регистрации')}</span>
              <button className={'btn success'} onClick={()=>{Modals.show('registration')}}>{t('По номеру телефона')}</button>
              <a href={`/${i18n.language}/api/idgovuz-auth/authorize`} className={'btn oneidBtn'}>{t('По ONE ID')}</a>
            </div>
          </div>
        </div>
      }

      {
        Modals.modal.rule && <div className={'popup rule show'}>
          <div className={'overlay'}/>
          <div className={`popup-content `}>
              <RuleModal/>
          </div>
        </div>
      }

      {
        Modals.modal.policy && <div className={'popup rule show'}>
          <div className={'overlay'}/>
          <div className={`popup-content `}>
              <PolicyModal/>
          </div>
        </div>
      }

      <Drawer
        title="Basic Drawer"
        placement={'left'}
        closable={true}
        width={500}
        onClose={()=>Modals.hide()}
        visible={Modals.modal.burger}
        key={'left'}
      >
        <div className={'menu'}>
          {/*<Form>*/}
          {/*  <Form.Item>*/}
          {/*    <Input placeholder={'Поиск'}/>*/}
          {/*  </Form.Item>*/}
          {/*  <Button>*/}
          {/*    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
          {/*      <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
          {/*    </svg>*/}
          {/*  </Button>*/}
          {/*</Form>*/}
          {
            isMobile &&
            <div className={'mobile-drawer-header'}>
              <div className={'logo'}>
                <NavLink to={(profile.profile.role === 'central_office' || profile.profile.role === 'regional') ? `/${i18n.language}/executor/new/` : `/${i18n.language}/`}>
                  <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.89923 15.3128C8.23265 12.5964 10.3016 10.3086 12.8707 8.70971C15.4399 7.11081 18.4062 6.26492 21.4322 6.26825H33.7458V18.5819C33.7492 21.6079 32.9033 24.5742 31.3044 27.1434C29.7055 29.7125 27.4177 31.7814 24.7013 33.1149V35.797C24.6946 37.2035 24.567 38.6068 24.32 39.9915C28.8779 38.5601 32.859 35.7094 35.6824 31.8556C38.5057 28.0017 40.0234 23.3464 40.014 18.569V4.31976e-05H21.4322C16.6548 -0.00933463 11.9995 1.5084 8.14565 4.33173C4.29179 7.15507 1.44115 11.1362 0.00976562 15.6941C1.39448 15.4475 2.79779 15.3199 4.20428 15.3128H6.89923Z" fill="#009B00"/><path d="M24.3281 39.9702L27.3273 31.4697C27.0074 31.7125 26.6789 31.941 26.3418 32.1552L26.2947 32.1852C26.0091 32.3652 25.7234 32.5366 25.4378 32.6994L25.0522 32.9093C24.9408 32.9693 24.8294 33.0336 24.7137 33.0936V35.7756C24.707 37.1821 24.5794 38.5855 24.3324 39.9702H24.3281Z" fill="#077207"></path><path d="M4.21562 15.3119H6.89771C6.94913 15.2091 7.00911 15.1105 7.06052 15.012C7.16764 14.8106 7.27046 14.6093 7.38614 14.4122C7.50183 14.2151 7.63465 13.9837 7.76318 13.7909C7.89172 13.5981 7.94741 13.5039 8.04596 13.3625C8.20877 13.1225 8.38015 12.8869 8.5601 12.6513C8.60294 12.5913 8.6415 12.5313 8.68863 12.4756L0.0253906 15.6932C1.4087 15.4468 2.81056 15.3192 4.21562 15.3119Z" fill="#077207"></path><path d="M18.4275 21.6502V21.5859H18.3633L18.4275 21.6502Z" fill="#FBB03B"></path><path d="M18.1152 29.8164C18.2181 29.9407 18.3295 30.0563 18.428 30.1806L18.1152 29.8164Z" fill="#FFDF00"></path><path d="M24.7001 35.7967V15.3125H4.216C2.8095 15.3196 1.4062 15.4471 0.0214844 15.6938L2.82783 18.5002L5.85696 21.5464L10.7156 21.5807H18.4277V30.1797L18.4534 34.1514L21.4954 37.1934L24.3017 39.9997C24.555 38.6128 24.6883 37.2065 24.7001 35.7967Z" fill="#FFDF00"></path>
                  </svg>
                  <span>{t('Молодёжный портал')}</span>
                </NavLink>
                <div className={'beta'}>Beta</div>

              </div>
              <div className={'right-side'}>
                <LangSwitcher/>
                {
                  app.access && <button className={'exit'} onClick={()=>logoutHandler()}>
                    <svg height="512pt" viewBox="0 0 511 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m361.5 392v40c0 44.113281-35.886719 80-80 80h-201c-44.113281 0-80-35.886719-80-80v-352c0-44.113281 35.886719-80 80-80h201c44.113281 0 80 35.886719 80 80v40c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-40c0-22.054688-17.945312-40-40-40h-201c-22.054688 0-40 17.945312-40 40v352c0 22.054688 17.945312 40 40 40h201c22.054688 0 40-17.945312 40-40v-40c0-11.046875 8.953125-20 20-20s20 8.953125 20 20zm136.355469-170.355469-44.785157-44.785156c-7.8125-7.8125-20.476562-7.8125-28.285156 0-7.8125 7.808594-7.8125 20.472656 0 28.28125l31.855469 31.859375h-240.140625c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h240.140625l-31.855469 31.859375c-7.8125 7.808594-7.8125 20.472656 0 28.28125 3.90625 3.90625 9.023438 5.859375 14.140625 5.859375 5.121094 0 10.238281-1.953125 14.144531-5.859375l44.785157-44.785156c19.496093-19.496094 19.496093-51.214844 0-70.710938zm0 0"/>
                    </svg>
                  </button>
                }
                <div className={'close-burger animate__animated animate__fadeInRight'} onClick={()=>Modals.hide()}/>
              </div>
            </div>
          }

          {
            isBigScreen && <div className={'close-burger animate__animated animate__fadeInRight'} onClick={()=>Modals.hide()}/>
          }

          <ul className={'menu-navigation'}>
            {
              isMobile &&
              <>
                <li><NavLink to={`/${i18n.language}/opportunities`}>{t('Возможности')}</NavLink></li>
                <li><NavLink to={`/${i18n.language}/events`}>{t('События')}</NavLink></li>
                <li><NavLink to={`/${i18n.language}/ideas`}>{t('Идеи')}</NavLink></li>
              </>
            }
            <li><NavLink to={`/${i18n.language}/news`}>{t('Новости')}</NavLink></li>
            {
              isMobile && <li><NavLink to={`/${i18n.language}/about-us`}>{t('О нас')}</NavLink></li>
            }
            <li><NavLink to={`/${i18n.language}/grants`}>{t('Актуальные гранты')}</NavLink></li>
            <li><NavLink to={`/${i18n.language}/contests`}>{t('Актуальные конкурсы')}</NavLink></li>
            <li><NavLink to={`/${i18n.language}/achievements`}>{t('Доска почета')}</NavLink></li>

            <li><NavLink to={`/${i18n.language}/courses`}>{t('Учебные курсы')}</NavLink></li>
            <li><NavLink to={`/${i18n.language}/vacancy`}>{t('Вакансии')}</NavLink></li>

            {
              isBigScreen ?  <li><NavLink to={`/${i18n.language}/events`}>{t('События')}</NavLink></li> : <li><NavLink to={`/${i18n.language}/projects`}>{t('Наши проекты')}</NavLink></li>
            }
            <li><NavLink to={`/${i18n.language}/magazine`}>{t('Библиотека')}</NavLink></li>
            {
              isBigScreen && <>
                <li><NavLink to={`/${i18n.language}/youth-indices`} >{t('Индекс молодежи')}</NavLink></li>
                {/*<li><NavLink  className={'btn btn-primary-link'} to={`/${i18n.language}/projects/82`}>{t('Предложение по закону')}</NavLink></li>*/}
                {/*<li><a target={'_blank'} href={`https://beshtashabbus.uz/`} rel="noreferrer">{t('5 Ташаббус')}</a></li>*/}
              </>
            }
            {/*{*/}
            {/*  isMobile && <li><a target={'_blank'} href={`https://beshtashabbus.uz/`} rel="noreferrer">{t('5 Ташаббус')}</a></li>*/}
            {/*}*/}
            {
              isMobile && <li><NavLink  className={'btn btn-primary-link'} to={`/${i18n.language}/projects/66`}>Volontyorlik</NavLink></li>
            }
            {
              isMobile && <li><NavLink style={{color:'#fff', display:'inline-block'}} className={'btn success'} to={`/${i18n.language}/appeal`}>{t('Отправить обращения')}</NavLink></li>
            }

          </ul>
          <span>&copy; Yoshlar Portali {new Date().getFullYear()}. {t('Все права защищены')}</span>
        </div>
      </Drawer>
    </>
  )
};
export default observer(MainLayout)
