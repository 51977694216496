import React from 'react';
import { Trans } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../store";
import { currentLang } from "../../config";


const LibraryModal = () => {
  const {Modals, magazine} = useStores();
  const {single} = magazine;
  return (
    <>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      <div className={'library-content'}>
        <div className={'d-flex align-center library-info'}>
          <img src={single.cover} alt={''}/>
          <div className={'caption'}>
            <div>
              <strong>{single[`title_${currentLang()}`]}</strong>
              <ul>
                <li>
                  <Trans>Автор</Trans>
                  <span>{single.author[`name_${currentLang()}`]}</span>
                </li>
                <li>
                  <Trans>Год выпуска</Trans>
                  <span>{single.year} г</span>
                </li>
              </ul>
            </div>
            <button className={'btn success'} onClick={()=>magazine.download(single.file, null)}><Trans>Скачать pdf</Trans></button>
          </div>
        </div>
        <p>{single[`description_${currentLang()}`]}</p>
      </div>
    </>
  )
};

export default observer(LibraryModal)