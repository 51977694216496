import React from 'react';
import {Table} from 'antd'
import { useTranslation } from "react-i18next";
const OTV =  () => {
  const {i18n} = useTranslation();
  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index === 0) {
      obj.props.colSpan = 0;
    }
    return obj;
  };
  let columns = [];
  let data = [];
  if(i18n.language === 'ru'){
     columns = [
      {
        title:'п/н',
        dataIndex:'key',
      },
      {
        title:"Показатели оценки",
        dataIndex:'title',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        }
      },
      {
        title:"Показатель 2021 года",
        dataIndex:'value',
        render: renderContent
      },
    ];
     data = [
      {
        key:'5.2',
        title:"Уровень обеспечения образовательных учреждений ИКТ ",
        value:''
      },
      {
        key:'5.2.1',
        title:"Количество ИКТ (количество компьютеров, приобретенных за последние 5 лет",
        value:'33290 шт'
      },
      {
        key:'5.2.2.',
        title:"Количество специалистов",
        value:'18000 шт'
      },
      {
        key:'5.2.3.',
        title:"выделенный лимит для Интернета",
        value:'от 100 Мбит/с до 1 Гбит/с'
      }
    ]
  }
  if(i18n.language === 'oz'){
     columns = [
      {
        title:'T/r',
        dataIndex:'key',
      },
      {
        title:"Baholash ko'rsatkichlari",
        dataIndex:'title',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        }
      },
      {
        title:"2021-yil ko'rsatkichi",
        dataIndex:'value',
        render: renderContent
      },
    ];
     data = [
      {
        key:'5.2',
        title:"Ta'lim muassasalarining AKT bilan ta'minlanganlik darajasi",
        value:'33290 ta'
      },
      {
        key:'5.2.1',
        title:"AKT soni (so'nggi 5 yilda xarid qilingan kompyuterlar soni",
        value:'33290 ta'
      },
      {
        key:'5.2.2.',
        title:"Mutaxassislar soni",
        value:'18000 ta'
      },
      {
        key:'5.2.3.',
        title:"Internet uchun ajratilgan limit",
        value:'100 mbit/s dan 1 gbit/s gacha'
      }
    ]
  }
  if(i18n.language === 'uz'){
     columns = [
      {
        title:'т/р',
        dataIndex:'key',
      },
      {
        title:"Баҳолаш кўрсаткичлари",
        dataIndex:'title',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <strong>{text}</strong>,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        }
      },
      {
        title:"2021 йил кўрсаткичи",
        dataIndex:'value',
        render: renderContent
      },
    ];
     data = [
      {
        key:'5.2',
        title:"Таълим муассасаларининг АКТ билан таъминланганлик даражаси",
        value:''
      },
      {
        key:'5.2.1',
        title:"АКТ сони (сўнгги 5 йилда харид қилинган компьютерлар сони)",
        value:'33290 та'
      },
      {
        key:'5.2.2.',
        title:"Мутахассислар сони",
        value:'18000 та'
      },
      {
        key:'5.2.3.',
        title:"Интернет учун ажратилган лимит",
        value:'100 Мбит/с дан 1 Гбит/с гача'
      }
    ]
  }
  return(
    <div  className={'table-center'}>
      <h3>
        {
          i18n.language === 'oz' && 'Yoshlar siyosatini baholash milliy indeksi mezonlari (14 yoshdan 30 yoshgacha bo\'lgan fuqarolar bo\'yicha)'
        }
        {
          i18n.language === 'ru' && 'Критерии национального индекса оценки молодежной политики (для граждан от 14 до 30 лет)'
        }
        {
          i18n.language === 'uz' && 'Ёшлар сиёсатини баҳолаш миллий индекси мезонлари (14 ёшдан 30 ёшгача бўлган фуқаролар бўйича)'
        }
      </h3>
      <Table
        pagination={false}
        sticky
        columns={columns}
        dataSource={data}
        bordered
        size="large"
      />
    </div>
  )
};

export default OTV