import {observable, makeObservable,action, flow} from "mobx";
import api from '../../api';
class Courses {
  @observable start = 1;
  @observable list = {
    count:0,
    results:[]
  };
  @observable category = {
    results:[]
  };
  @observable subcategory = {
    results:[]
  };
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable forms = {
    fields:[]
  };
  @observable params = null;
  @observable form = null;
  @observable loading = true;
  @observable error = false;
  @observable course = {
    object:null,
    similar:[],
    next:{},
    prev:{}
  };

  constructor() {
    makeObservable(this)
  }
  @action setFromValue = (values) => {
    this.start = 1;
    this.list.results = [];
    this.pagination = {limit:20, offset: 0};
    this.loading = true;
    this.form = values
  }
  @action buttonsValue = (values) => {
    this.start = 1;
    this.list.results = [];
    this.pagination = {limit:20, offset: 0};
    this.params = values;
  }

  @action reset = () => {
    this.list.results = [];
    this.category.results = [];
    this.pagination = {limit:20, offset: 0};
    this.params = null;
    this.start = 1;
    this.form = null;
    this.loading = true
  }
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @flow *
  getData(){
    const response = yield api.courses.data({...this.pagination, ...this.form, ...!this.params ? {new:true} : this.params});
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.results = [...this.list.results, ...response.data.results];
        this.start++;
      }else{
        this.list = response.data;
      }
      this.loading = false;
    }
  }
  @flow *
  getCategory(){
    const response = yield api.courses.category();
    if(!response.detail){
      this.category = response.data;
    }
  }
  @flow *
  getSubCategory(values){
    const response = yield api.courses.subcategory(values);
    if(!response.detail){
      this.subcategory = response.data;
    }
  }
  @flow *
  detail(id){
    this.loading = true;
    const response = yield api.courses.detail(id);
    if(!response.detail){
      this.course = response.data;
      this.loading = false;
    }
    else{
      this.error = true;
      this.loading = false;
    }
  }
  @flow *
  membership(values){
    yield api.courses.membership(values);

  }
  @flow * sendForm(values){
    let form = new FormData();
    for(let type in values){
      form.append(type, values[type])
    }
    const response = yield api.courses.sendForm(form);
    if(!response.detail){

    }

  }
  @flow *
  getForm(values){
    const response = yield api.courses.getForm(values);
    if(!response.detail){
      this.forms = response.data;
    }
  }
};

export default new Courses();