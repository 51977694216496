import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import { Trans, useTranslation } from "react-i18next";
import ShareComponent from "../../components/share-buttons";
import { Helmet } from "react-helmet";
import { useStores } from "../../store";
import { baseUrl, currentLang, formatNum, truncateString } from "../../config";
import { observer } from "mobx-react";
import moment from 'moment'
import E404 from "../../components/e404/404";
import MobileNavigation from "../../components/navigation";
import { useMediaQuery } from "react-responsive";
import icon from "../../assets/icon.svg";
const VacancyDetail = () => {
  const {id} = useParams();
  const {i18n} = useTranslation()
  const {vacancy} = useStores();
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const [show, setShow] = useState(false);
  useEffect(()=>{
    vacancy.detailVacancy(id)
  }, [vacancy, id]);
  if(vacancy.error){
    return <E404/>
  }
  const coords = vacancy.detail ? [vacancy.detail.vacancy.organization.geoposition.split(',')[0], vacancy.detail.vacancy.organization.geoposition.split(',')[1]] : []
  return (
    <>
      {
        isMobile && <MobileNavigation title={<Trans>Вакансии для молодёжи</Trans>}/>
      }
      <Helmet>
        <title>{vacancy.vacancy && vacancy.vacancy[`vacancy_name_${currentLang()}`]}</title>
        <meta property="og:url" content={baseUrl} />
        <meta property="og:type" content={'website'} />
        <meta property="og:title" content={vacancy.vacancy && vacancy.vacancy[`vacancy_name_${currentLang()}`]} />
      </Helmet>
      <div className={'center-content vacancy-box-detail '}>
        {
          vacancy.detail &&
          <>
            <div className={'detail'}>
              <div className={'animate__animated animate__fadeIn'}>
                <h2>{vacancy.detail.vacancy[`vacancy_name_${currentLang()}`]}</h2>
                <span>{vacancy.detail.vacancy.sphere[`name_${currentLang()}`]}</span>
                <ul className={'title'}>
                  <li>
                    <span><Trans>Зарплата</Trans>:</span>.
                    <p>{vacancy.detail.vacancy.salary_from} - {vacancy.detail.vacancy.salary_to} сум</p>
                  </li>
                  <li>
                    <span><Trans>Компания</Trans>:</span>.
                    <p>{vacancy.detail.vacancy.organization.name}</p>
                  </li>
                  <li>
                    <span><Trans>Адрес</Trans>:</span>.
                    <p>{vacancy.detail.vacancy.organization[`organization_address_${currentLang()}`]}</p>
                  </li>
                  <li>
                    <span><Trans>Занятость</Trans>:</span>.
                    <p>{vacancy.detail.vacancy.employment_type[`name_${currentLang()}`]}</p>
                  </li>
                </ul>
                <strong><Trans>Обязанности</Trans>:</strong>
                <div className={'text'} dangerouslySetInnerHTML={{ __html: vacancy.detail.vacancy[`responsibilities_${currentLang()}`] }} />
                <strong><Trans>Требования</Trans>:</strong>
                <div className={'text'} dangerouslySetInnerHTML={{ __html: vacancy.detail.vacancy[`requirements_${currentLang()}`] }} />
                <strong><Trans>Навыки</Trans>:</strong>
                <div className={'text'} dangerouslySetInnerHTML={{ __html: vacancy.detail.vacancy[`skills_${currentLang()}`] }} />
                <strong><Trans>Контактная информация</Trans>:</strong>
                <div className={'contacts d-flex align-start'}>
                  <div className={'phones'}>
                    <a href={`tel:${vacancy.detail.vacancy.organization.phone_number1}`}>{vacancy.detail.vacancy.organization.phone_number1}</a>
                    <a href={`tel:${vacancy.detail.vacancy.organization.phone_number2}`}>{vacancy.detail.vacancy.organization.phone_number2}</a>
                  </div>
                </div>
                <div className={'location-panel'}>
                  <div className={'d-flex align-center justify-spacebetween'}>
                    <button className={'btn text location'}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0C7.34756 0 3.5625 3.78506 3.5625 8.4375C3.5625 10.0094 3.99792 11.5434 4.82198 12.8743L11.5197 23.6676C11.648 23.8744 11.874 24 12.1171 24C12.119 24 12.1208 24 12.1227 24C12.3679 23.9981 12.5944 23.8686 12.7204 23.6582L19.2474 12.7603C20.026 11.4576 20.4375 9.96277 20.4375 8.4375C20.4375 3.78506 16.6524 0 12 0ZM18.0406 12.0383L12.1065 21.9462L6.0172 12.1334C5.33128 11.0257 4.95938 9.74766 4.95938 8.4375C4.95938 4.56047 8.12297 1.39687 12 1.39687C15.877 1.39687 19.0359 4.56047 19.0359 8.4375C19.0359 9.7088 18.6885 10.9541 18.0406 12.0383Z" fill="#44D370"/>
                        <path d="M12 4.21875C9.67378 4.21875 7.78125 6.11128 7.78125 8.4375C7.78125 10.7489 9.64298 12.6562 12 12.6562C14.3861 12.6562 16.2188 10.7235 16.2188 8.4375C16.2188 6.11128 14.3262 4.21875 12 4.21875ZM12 11.2594C10.4411 11.2594 9.17813 9.9922 9.17813 8.4375C9.17813 6.88669 10.4492 5.61563 12 5.61563C13.5508 5.61563 14.8172 6.88669 14.8172 8.4375C14.8172 9.96952 13.5836 11.2594 12 11.2594Z" fill="#44D370"/>
                      </svg>
                      <Trans>Геолокация</Trans>
                    </button>
                    <div className={'d-flex align-center header'}>
                    <span className={'d-flex align-center'}>
                      {
                        !isMobile &&  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4.66667 3.83333V0.5M11.3333 3.83333V0.5M3.83333 7.16667H12.1667M2.16667 15.5H13.8333C14.2754 15.5 14.6993 15.3244 15.0118 15.0118C15.3244 14.6993 15.5 14.2754 15.5 13.8333V3.83333C15.5 3.39131 15.3244 2.96738 15.0118 2.65482C14.6993 2.34226 14.2754 2.16667 13.8333 2.16667H2.16667C1.72464 2.16667 1.30072 2.34226 0.988155 2.65482C0.675595 2.96738 0.5 3.39131 0.5 3.83333V13.8333C0.5 14.2754 0.675595 14.6993 0.988155 15.0118C1.30072 15.3244 1.72464 15.5 2.16667 15.5Z"
                            stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      }
                      {moment(vacancy.detail.vacancy.created_at).fromNow()}
                    </span>
                      <span className={'d-flex align-center'}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                        stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                      <path
                        d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                        stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                        {formatNum(vacancy.detail.vacancy.views_count)}
                      </span>
                    </div>
                  </div>
                  <div className={'map'}>
                    <YMaps>
                      <div>
                        <Map defaultState={{ center: coords, zoom: 17, controls: [] }}
                             width={'100%'} height={288}>
                          <Placemark
                            geometry={coords}
                            options={{
                              iconLayout: 'default#image',
                              iconImageHref: icon,
                              iconImageSize: [43, 43],
                              iconImageOffset: [-3, -42]
                            }}
                            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}

                          />
                        </Map>
                      </div>
                    </YMaps>
                  </div>
                </div>
                <div className={'d-flex justify-center'}>
                  <ShareComponent isVisible={show} setVisible={setShow} title={vacancy.vacancy && vacancy.vacancy[`vacancy_name_${currentLang()}`]}/>
                </div>
              </div>


            </div>
            <div className={'popular'}>
              <h3><Trans>Похожие вакансии</Trans></h3>
              <div className={'vacancy-popular'}>
                {
                  vacancy.detail.random && vacancy.detail.random.map((randomItem, idx)=>{
                    return <div key={idx} className={'vacancy-item'}>
                      <NavLink to={`/${i18n.language}/vacancy/${randomItem.id}`} key={idx} className={'popular-vacancy-link'}>
                        <strong>{randomItem[`vacancy_name_${currentLang()}`]}</strong>
                        <span className={'salary'}>до {randomItem.salary_to} сум</span>
                        <div className={'text'} dangerouslySetInnerHTML={{__html:truncateString(randomItem[`description_${currentLang()}`].replace(/<[^>]+>/g, ''), 100)}}/>
                        <div className={'location d-flex align-center'}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.7148 13.8806L11.179 17.4164C11.0244 17.5712 10.8407 17.694 10.6386 17.7778C10.4365 17.8616 10.2199 17.9047 10.0011 17.9047C9.78228 17.9047 9.56564 17.8616 9.36352 17.7778C9.16141 17.694 8.97779 17.5712 8.82315 17.4164L5.28649 13.8806C4.35417 12.9482 3.71927 11.7603 3.46206 10.4671C3.20485 9.17395 3.3369 7.83352 3.84149 6.61536C4.34608 5.39721 5.20057 4.35604 6.29689 3.62351C7.39321 2.89098 8.68213 2.5 10.0007 2.5C11.3192 2.5 12.6081 2.89098 13.7044 3.62351C14.8007 4.35604 15.6552 5.39721 16.1598 6.61536C16.6644 7.83352 16.7965 9.17395 16.5392 10.4671C16.282 11.7603 15.6471 12.9482 14.7148 13.8806V13.8806Z" stroke="#44D370" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.7678 10.9348C12.2366 10.4659 12.5 9.83003 12.5 9.16699C12.5 8.50395 12.2366 7.86807 11.7678 7.39922C11.2989 6.93038 10.663 6.66699 10 6.66699C9.33696 6.66699 8.70107 6.93038 8.23223 7.39922C7.76339 7.86807 7.5 8.50395 7.5 9.16699C7.5 9.83003 7.76339 10.4659 8.23223 10.9348C8.70107 11.4036 9.33696 11.667 10 11.667C10.663 11.667 11.2989 11.4036 11.7678 10.9348Z" stroke="#44D370" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                          {randomItem.region[`title_${i18n.language}`]}
                        </div>
                      </NavLink>
                    </div>
                  })
                }
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
};

export default observer(VacancyDetail)