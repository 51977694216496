import React from 'react';
import { useStores } from "../../store";
import { Trans, useTranslation } from "react-i18next";

const AuthType = () => {
  const {Modals} = useStores();
  const {i18n} = useTranslation()
  return (
    <div className={'auth-wrapper auth-box'}>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      <h3><Trans>Авторизация</Trans></h3>
      <p><Trans>Выберите способ авторизации</Trans>:</p>
      <button className={'btn success'} onClick={() => Modals.show('auth')}><Trans>По номеру телефона</Trans></button>
      <span className={'or'}><Trans>или</Trans></span>
      <a href={`/${i18n.language}/api/idgovuz-auth/authorize`} className={'btn oneidBtn mb-64 '}><Trans>По ONE ID</Trans></a>
      <button className={'btn default'}  onClick={() => Modals.show('howReg')}><Trans>Регистрация</Trans></button>
    </div>
  )
};

export default AuthType