import React, { useEffect, useState } from 'react';
import {Button,  Col, message, Form, Input, Radio, Row, Select, notification } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../store";
import { observer } from "mobx-react";
import {useParams} from 'react-router-dom';
import { formatDateServer, currentLang } from "../../config";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import { useMediaQuery } from "react-responsive";
import Rules from "../rule";

const AppealForm = () => {
  const { t } = useTranslation();
  const {id} = useParams()
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const { TextArea } = Input;
  const { Option } = Select;
  const isMobile = useMediaQuery({ query: '(max-width:1099px)' })
  const { profile, appeals, location, app, opportunities,Modals } = useStores();
  const [regionID, setRegionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [mahhalla, setMahalla] = useState(null);
  const [tashkent, setTashkent] = useState(false);
  const [district, setDistrict] = useState(false);
  const [street, setStreet] = useState(true);
  const [community, setCommunity] = useState(true);
  const [, setHouse] = useState(true);
  const [file, setFile] = useState(null);
  const { access } = app;
  useEffect(() => {
    forceUpdate({});
    opportunities.reset()

  }, [opportunities, profile]);
  const setFileHandler = event => {
    setFile(event.target.files)
  }
  const getRegionID = async (value, key) => {
    if (value === 10) {
      await location.resetFields();
      await location.getPMDistrict2(value);
      const error = await location.getDistrictRegistry();
      if (!error) {
        setDistrict(false)
        setTashkent(true)
        form.resetFields(['district_id', 'street_id', 'house_id', 'mahalla_id', 'apartment'])

      }
    } else {
      await location.resetFields();
      const error = await location.getPMDistrict(value);
      setRegionId(key.key)
      if (!error) {
        setDisable(false);
        setTashkent(false)
        form.resetFields(['district_id', 'mahalla_id', 'current_address'])
      }
    }

  };
  const getCommynityID = async (id, key) => {
    setMahalla(key.key);
    const mahallaID = key.value;
    const error = await location.getCommunityPM({ mahallaID, regionID });

    if (!error) {
      setDisable(false);
      setTashkent(false)
    }

  };
  const getStreets = async id => {
    const error = await location.getStreets(id);
    if (!error) {
      setStreet(false)
      form.resetFields(['street_id', 'house', 'apartment'])
    }
  };
  const handleSearch = async q => {
    const error = await location.suggestion(q);
    if (!error) {
      setHouse(false)
    }
  };
  const handleSelect = value => {
    location.setStreetID(value)
    setCommunity(false);
  };
  // const handleChangeHouse = async (value) => {
  //   await location.getHouses(value);
  // };
  const showAuth = (key) => {
    Modals.show('auth');
    notification.close(key);
  };
  const onFinish = async (values) => {
    if (!access) {
      const key = 1;
      const btn = (
        <Button className={'btn primary'} size="small" onClick={() => showAuth(key)}>
          <Trans>Авторизоваться</Trans>
        </Button>
      );
      notification.open({
        message: t('Внимание!'),
        description:
          t('Для подачи заявки вам нужно авторизоваться.'),
        btn,
        icon: <SmileOutlined style={{ color: '#faad14' }}/>,
        duration: 0,
        key,
      });
    }
    else{
      setLoading(true);
      let data;
      if(id){
        if (tashkent){
          values.district_id = location.district2List.find(item => item.addr_register_id === form.getFieldValue('district_id')).id
        }else{
          values.district_id = mahhalla;
        }
        data = {
          is_benefit:true,
          recipient_type_id:1,
          phone:profile.profile.phone.split('+')[1],
          middle_name:profile.profile.patronymic,
          birthday:formatDateServer(profile.profile.birth_date),
          authority_id:appeals.detail.authority,
          // authority_id:5494,
          gender:profile.profile.gender.substr(0, 1),
          first_name: profile.profile.first_name,
          last_name: profile.profile.last_name,
          file:file && file[0],
          email: profile.profile.email,
          classification_id:appeals.detail.classification,
          // classification_id:1235,
          ...values
        }
      }
      else{
        values.recipient_type_id = 1;
        values.phone = values.phone.split('+')[1];
        // values.middle_name = values.patronymic;
        values.birthday = formatDateServer(values.birth_date);
        // values.authority_id = +id;
        values.authority_id = 5494;
        values.gender = values.gender.substr(0, 1);
        values.district_id = mahhalla;
        delete values.patronymic
        delete values.birth_date;
        values.file = file && file[0];
        data = values;
      }
      Object.keys(data).forEach((k) => data[k] == null && delete data[k]);
      const error = await appeals.submit(data);
      if (error) {
        error.map((item)=> message.error(item))
        setLoading(false);
      }else{
        Modals.show('success')
        setLoading(false);
        form.resetFields()
        appeals.reset()
      }
    }

  };
  // const getClassification2 = async (id, key) => {
  //   await appeals.getClassification2(key.key)
  // }
  // const getClassification3 = async (id) => {
  //   await appeals.getClassification3(id)
  // }
  const handleGetCommunity = async q => {
    const error = await location.getCommunityRegistry({q});
    if (!error) {
      setHouse(false)
    }
  };
  return (
    <Form layout={'vertical'} form={form}

          onFinish={onFinish}
    >
      <Row gutter={isMobile ? [0, 0] : [24, 0]}>
        <Col span={isMobile? 24 : 12}>
          <Row gutter={[24,0]}>
            <Col span={isMobile? 24 : 12}>
              <Form.Item label={t('Статус')} name={'employment_id'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <Select placeholder={t('Введите Статус')}>
                  <Option value={1}><Trans>Занят-работает</Trans></Option>
                  <Option value={2}><Trans>Безработный</Trans></Option>
                  <Option value={4}><Trans>Студент</Trans></Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={isMobile? 24 : 12}>
              <Form.Item label={t('Местоположения')} name={'region_id'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <Select placeholder={t('Введите Регион')} onDropdownVisibleChange={(open) => open && location.getPMRegion()}
                        onChange={getRegionID}>
                  {
                    location.region.map((item, idx) => {
                      return item.title !== '' && <Option  value={item.id} key={item.pm_gov_id}>{item.title}</Option>
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24,0]}>
            {
              !tashkent
                ?
                <>
                  <Col span={isMobile? 24 :12}>
                    <Form.Item label={t('Район (Область)')} name={'district_id'}
                               rules={[{ required: true, message: t('Обязательное поле') }]}>
                      <Select placeholder={t('Введите Район (город)')} onChange={getCommynityID} disabled={disable}>
                        {
                          location.district.map(item => (
                            <Option value={item.pm_gov_id} key={item.id}>{item.title}</Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={isMobile? 24 :12}>
                    <Form.Item label={t('Махалля')} name={'mahalla_id'}
                               rules={[{ required: true, message: t('Обязательное поле') }]}>
                      <Select placeholder={t('Махалля')} disabled={disable}>
                        {
                          location.community.map((item, idx) => {
                            return <Option value={item.id} key={idx}>{item.title}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </>
                :
                <>
                  <Col span={isMobile? 24 : 12}>
                    <Form.Item label={t('Район (город)')} name={'district_id'}
                               rules={[{ required: true, message: t('Обязательное поле') }]}>
                      <Select placeholder={t('Введите Район (город)')} disabled={district} onChange={getStreets}>
                        {
                          location.districtRegistry.map((item, idx) => {
                            return <Option value={item.id} key={idx}>{item[`name_${currentLang() === 'en' ? 'uz' : currentLang()}`]}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={isMobile? 24 :12 }>
                    <Form.Item label={t('Улица')} name={'street_id'}>
                      <Select
                        placeholder={t('Введите улицу')}
                        disabled={street}
                        showSearch
                        showArrow={true}
                        filterOption={false}
                        onSearch={handleSearch}
                        onChange={handleSelect}
                        notFoundContent={false}
                      >
                        {
                          location.streets.map(item => {
                            return <Option key={item.id} value={item.id}>{item[`full_name_${currentLang() === 'en' ? 'uz' : currentLang()}`]}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={isMobile? 24 :12 }>
                    <Form.Item label={t('Махалля')} name={'mahalla_id'} rules={[{ required: true, message: t('Обязательное поле') }]}>
                      <Select
                        placeholder={t('Махалля')}
                        disabled={community}
                        showSearch
                        showArrow={true}
                        filterOption={false}
                        onSearch={handleGetCommunity}
                        notFoundContent={false}
                      >
                        {
                          location.community.map((item, idx) => {
                            return <Option value={item.id} key={idx}>{item[`name_${currentLang() === 'en' ? 'uz' : currentLang()}`]}</Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </>
            }
            <Col span={isMobile ? 24 : !access ? 12 : 12}>
              <Form.Item label={t('Адрес')} name={'current_address'}
                         rules={[{ required: true, message: t('Обязательное поле') }]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col  span={isMobile? 24 :12}>
              <Form.Item
                rules={[{ required: true, message: t('Обязательное поле') }]} label={t('Открытость обращения')}
                help={t('Открытость информации об обращении и заявителе')}
                name={'is_public'}>
                <Radio.Group checked={true}  name={'is_public'} value={1}>
                  <Radio value={1}><Trans>Да</Trans></Radio>
                  <Radio value={0}><Trans>Нет</Trans></Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={isMobile? 24 :12}>
              <Form.Item label={t('Загрузить файл')}>
                <div className={'file-content'}>
                  <input type={'file'} required={false} value={file && file.fileName} onChange={setFileHandler}
                         accept="application/pdf, .xls, .doc, .docx, .ppt, .txt"/>
                  <span>{file ? file[0].name : <Trans>Файл не выбран</Trans>}</span>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={isMobile? 24 :12}>
          <Form.Item label={t('Текст обращения')} name={'description'}
                     rules={[{ required: true, message: t('Обязательное поле') }]} style={{ marginBottom: 0 }}>
            <TextArea placeholder={t('Введите Текст обращения')}/>
          </Form.Item>
          <Rules/>
        </Col>
      </Row>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            loading={loading}
            type="primary"
            className={'btn primary'}
            htmlType="submit"
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            <Trans>Отправить</Trans>
          </Button>
        )}
      </Form.Item>
    </Form>
  )
};

export default observer(AppealForm)