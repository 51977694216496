import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import ShareComponent from "../../components/share-buttons";
import { NavLink, useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import { useStores } from "../../store";
import { useMediaQuery } from "react-responsive";
import MobileNavigation from "../../components/navigation";
import { Helmet } from "react-helmet";
import { baseUrl, currentLang } from "../../config";

const AchievementDetail = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1099px)' })
  const {achievements} = useStores();
  const {id} = useParams();
  useEffect(()=>{
    achievements.detail(id);
  }, [achievements, id])
  return (
    <>
      <Helmet>
        <title>Доска почета</title>
        <meta property="og:url" content={baseUrl} />
        <meta property="og:type" content={'website'} />
        <meta property="og:title" content={[achievements.single.result && achievements.single.result.last_name, achievements.single.result && achievements.single.result.name]} />
        <meta property="og:description" content={achievements.single.result.category && achievements.single.result.category[`name_${currentLang()}`]} />
        <meta property="og:image" content={achievements.single.result && achievements.single.result.image} />
      </Helmet>
      {
        isMobile && <MobileNavigation title={<Trans>Доска почета</Trans>}/>
      }
      <div className={'center-content achievementDetail animate__animated animate__fadeIn'}>
        <div className={'header-title'}>
          <h1><Trans>Доска почета</Trans></h1>
        </div>
        <div className={'achievementBanner'}>
          {
            isMobile && <>
              <div className={'mobile-user-info'}>
                <p>{achievements.single.result.category && achievements.single.result.category[`name_${currentLang()}`]}</p>
                <h3>{achievements.single.result && achievements.single.result[`last_name_${currentLang()}`]} {achievements.single.result && achievements.single.result[`name_${currentLang()}`]}</h3>
              </div>
            </>
          }
          <div>
            {
              !isMobile && <>
                <p>{achievements.single.result.category && achievements.single.result.category[`name_${currentLang()}`]}</p>
                <h3>{achievements.single.result && achievements.single.result[`last_name_${currentLang()}`]} {achievements.single.result && achievements.single.result[`name_${currentLang()}`]}</h3>
              </>
            }
            <div dangerouslySetInnerHTML={{ __html: achievements.single.result && achievements.single.result[`achievement_${currentLang()}`] }}/>
          </div>
          <div className={'icon-1 icon'}/>
          <div className={'icon-2 icon'}/>
          <div className={'icon-3 icon'}/>
          <div className={'icon-4 icon'}/>
          <div className={'icon-5 icon'}/>
          <div className={'icon-6 icon'}/>
          <img src={achievements.single.result && achievements.single.result.image} alt={''}/>
        </div>
        <div className={'caption'}>
          <strong className={'title'}><Trans>Дополнительная информация</Trans></strong>
          <div className={'text'} dangerouslySetInnerHTML={{__html:achievements.single.result && achievements.single.result[`description_${currentLang()}`]}}/>
          <div className={'d-flex justify-end'}>
            <ShareComponent title={[achievements.single.result && achievements.single.result.last_name, achievements.single.result && achievements.single.result.name]}/>
          </div>
        </div>
        <div className={'popular'}>
          <h3><Trans>Другие</Trans></h3>
          <div className={'achievements-items'}>
            {
              achievements.single.random.map((item, index) => {
                return <NavLink key={index} to={`/${currentLang()}/achievements/${item.id}`} className={`achievements-item ${isMobile ? 'active' : null}`}>
                  <div className={'img'}>
                    <img src={item.image} alt={''}/>
                  </div>
                  <div className={'description'}>
                    <strong>{item.last_name} {item.name}</strong>
                    <p>{item.category.name}</p>
                  </div>
                </NavLink>
              })
            }
          </div>
        </div>
      </div>
    </>
  )
};

export default observer(AchievementDetail);