import { flow, makeObservable, observable, action } from "mobx";
import api from  '../api';
import profile from './modules/profile'
import { currentLang } from "../config";
class App {
  @observable role = 1;
  @observable error = false;
  @observable rules = null;
  @observable access = !process.env.IS_SERVER ? !!localStorage.getItem('token') : null;
  @observable token = !process.env.IS_SERVER ? localStorage.getItem('token') || '' : null;




  constructor() {
    makeObservable(this)
    if (!process.env.IS_SERVER) {
      this.access = !!localStorage.getItem('token');
      this.token = localStorage.getItem('token') || '';
    }
  }

  @action reset(){
    this.loading = true;
    this.rules = null
  }
  @flow *
  auth(values){
    const response = yield api.app.auth(values);
    if(response.message) return response.message;
    this.access = true;
    localStorage.setItem('token', response.data.token);
    api.apiClient.defaults.headers.Authorization = `token ${response.data.token}`;
    this.token = `token ${response.data.token}`;
  }
  @flow *
  authIDGov(){
    const response = yield api.app.authIDGov();
    if(response.status === 500 || response.status === 404){
      this.error = true;
    }else{
      this.access = true;
      localStorage.setItem('token', response.data.token);
      api.apiClient.defaults.headers.Authorization = `token ${response.data.token}`;
      this.token = `token ${response.data.token}`;
      profile.getProfile();
    }
  }

  @flow * getRules(){
    const response = yield api.app.rule();
    if(!response.detail){
      this.rules = response.data.results[0][`text_${currentLang()}`] ? response.data.results[0][`text_${currentLang()}`] : null;
    }
  }
  @flow * getPolicy(){
    const response = yield api.app.policy();
    if(!response.detail){
      this.rules = response.data.results[0][`text_${currentLang()}`] ? response.data.results[0][`text_${currentLang()}`] : null;
    }
  }


  @action logout = () => {
    this.access = false;
    this.token = '';
    api.apiClient.defaults.headers.Authorization = '';
    localStorage.clear();
    profile.profile = {}
  };
};

export default new App()