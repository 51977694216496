import React from 'react';
import { observer } from "mobx-react";
import { useStores } from "../../../store";
import { currentLang, formatDateClient } from "../../../config";
import { Trans, useTranslation } from "react-i18next";
const ExecutorDetail = () => {
  const {appeals} = useStores();
  const {t} = useTranslation()
  return (
   <>
     <h3>{t('Обращения  молодежи ID', {id:appeals.detail && appeals.detail.request_no})}</h3>
     {
       appeals.detail &&
       <>
         <ul className={'animate__animated animate__fadeIn profile-list'}>
           <li>
             <strong><Trans>ID номер</Trans>:</strong>
             <span>№ {appeals.detail.request_no}</span>
           </li>
           <li>
             <strong><Trans>Дата</Trans>:</strong>
             <span>{t('от', {date:formatDateClient(appeals.detail.created_at)})}</span>
           </li>
           <li>
             <strong><Trans>Категория</Trans>:</strong>
             <span>{appeals.detail.category}</span>
           </li>
           <li>
             <strong><Trans>Статус  обращения</Trans>:</strong>
             <span className={`status ${appeals.detail.status}`}>{appeals.detail.status === 'new' ? t('Новая заявка') : appeals.detail.status === 'processing' ? t('На рассмотрении') : appeals.detail.status === 'answered' ? t('Есть ответ') : t('Закрытая')}</span>

           </li>
           <li>
             <strong><Trans>Исполнитель</Trans>:</strong>
             <span>{appeals.detail.category}</span>
           </li>
           <li>
             <strong><Trans>Срок исполнения</Trans>:</strong>
             <span>{t('до', {date:formatDateClient(appeals.detail && appeals.detail.deadline)})}</span>
           </li>
           <li className={'full'}>
             <strong ><Trans>Текст обращения</Trans></strong>
             <p>{appeals.detail.description}</p>
           </li>
         </ul>
         {
           appeals.detail.result.answer_text !== '' &&
           <>
             <h3><Trans>Результат обращения</Trans></h3>
             <ul  className={'animate__animated animate__fadeIn profile-list'}>
               <li>
                 <strong><Trans>Номер обращений</Trans>:</strong>
                 <span>{appeals.detail.request_no}</span>
               </li>
               <li>
                 <strong><Trans>Дата обращения</Trans>:</strong>
                 <span>{formatDateClient(appeals.detail.created_at)}</span>
               </li>
               <li>
                 <strong><Trans>Результат</Trans>:</strong>
                 <span className={
                   (
                     appeals.detail.result.result_type.title === 'Отклонено' ||
                     appeals.detail.result.result_type.title === 'Не рассмотрено' ||
                     appeals.detail.result.result_type.title === 'Рассмотрение прекращено') ? 'text-error' : 'text-success'}
                 >
                   {appeals.detail.result.result_type[`title_${currentLang}`]}
                 </span>
               </li>
               {
                 (appeals.detail.result.result_type.title === 'Отклонено' || appeals.detail.result.result_type.title === 'Не рассмотрено' || appeals.detail.result.result_type.title === 'Рассмотрение прекращено') ?
                   <>
                     <li className={'full'}>
                       <strong><Trans>Ответ</Trans></strong>
                       <p>{appeals.detail.result.result_type.title === 'Отклонено' ? 'Нет ответа' : appeals.detail.result.reasons_reject_text}</p>
                     </li>
                   </> :
                   <>
                     <li>
                       <strong><Trans>Ижро учун масъул ходим</Trans>:</strong>
                       <span>{appeals.detail.result.responsible_person}</span>
                     </li>
                     <li>
                       <strong><Trans>Жавоб хатини имзолаган масъул шахс ҳақида маълумотлар</Trans>:</strong>
                       <span>{appeals.detail.result.executor_info}</span>
                     </li>

                     <li className={'full'}>
                       <strong><Trans>Ответ</Trans></strong>
                       <p>{appeals.detail.result.answer_text}</p>
                     </li>
                   </>
               }
               <li className={'full'}>
                 <strong><Trans>Коментарии</Trans></strong>
                 <p>{appeals.detail.result.comment}</p>
               </li>
             </ul>
             {
               appeals.detail.result.file && <li>
                 <strong><Trans>Файл</Trans>:</strong>
                 <button style={{background:'#44D370', flex:1}} className={'btn text'}>Скачать</button>
               </li>
             }
           </>
         }
       </>
     }
   </>
  )
};

export default observer(ExecutorDetail)