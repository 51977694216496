import { NavLink } from "react-router-dom";
import React from "react";
import { currentLang, formatDate, formatNum, truncateString } from "../../config";
import { useTranslation } from "react-i18next";

const OtherNews = (item) => {
  const {i18n} = useTranslation()
  return (
    <div
      className={'news-blue '}
    >
      <NavLink to={`/${i18n.language}/news/${item.id}`}>
        <img src={item.preview ? item.preview : item.image} alt={''}/>
        <div className={'caption'}>
          <div className={'news-header d-flex'}>
            <span className={'date'}>
              {formatDate(item.created_at)}
            </span>
            <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678Z"
                stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
              <path
                d="M2.04834 10.0003C3.11001 6.61949 6.26917 4.16699 10 4.16699C13.7317 4.16699 16.89 6.61949 17.9517 10.0003C16.89 13.3812 13.7317 15.8337 10 15.8337C6.26917 15.8337 3.11001 13.3812 2.04834 10.0003Z"
                stroke="#8F8F8F" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              {formatNum(item.views_count)}
            </span>
          </div>
          <p>{truncateString(item[`title_${currentLang()}`], 200)}</p>
        </div>
      </NavLink>
    </div>

  )
};
export default OtherNews