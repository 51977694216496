import React from 'react';
import {Table} from 'antd'
import { useTranslation } from "react-i18next";
const MV =  () => {
  const {i18n} = useTranslation()
  let columns = [];
  let data = [];
  if(i18n.language === 'ru'){
    columns = [
      {
        title:'п/н',
        dataIndex:'key',
        width:60,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0) {
            obj.props.colSpan = 0;
          }
          if (index === 1) {
            obj.props.rowSpan = 2;
            obj.props.align = 'center';
          }
          // These two are merged into above cell
          if (index === 2) {
            obj.props.rowSpan = 0;
          }
          return obj;
        },
      },
      {
        title:'Название регионов',
        width:'20%',
        dataIndex:'title',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <em>{text}</em>,
            props: {
              colSpan: 2,
            },
          };
        },
      },
      {
        title:'2017 год',
        children:[
          {
            dataIndex:'value_2017_half',
            title:'за 6 месяцев'
          },
          {
            dataIndex:'value_2017_full',
            title:'за год'
          }
        ]
      },
      {
        title:'2018 год',
        children:[
          {
            dataIndex:'value_2018_half',
            title:'за 6 месяцев'
          },
          {
            dataIndex:'value_2018_full',
            title:'за год'
          }
        ]
      },
      {
        title:'2019 год',
        children:[
          {
            dataIndex:'value_2019_half',
            title:'за 6 месяцев'
          },
          {
            dataIndex:'value_2019_full',
            title:'за год'
          }
        ]
      },
      {
        title:'2020 год',
        children:[
          {
            dataIndex:'value_2020_half',
            title:'за 6 месяцев'
          },
          {
            dataIndex:'value_2020_full',
            title:'yillik'
          }
        ]
      },
    ];
    data = [
      {
        key:'0',
        title:'Финансирование государственной молодежной политики',
        value_2017_half:'-',
        value_2017_full:'-',
        value_2018_half:'240',
        value_2018_full:'497.4',
        value_2019_half:'241.7',
        value_2019_full:'614.5',
        value_2020_half:'192.4',
        value_2020_full:'399.2',
      },
      {
        key:1,
        title:'Центральный аппарат Агентства по делам молодежи и системные организации',
        value_2017_half:'0',
        value_2017_full:'0',
        value_2018_half:'240',
        value_2018_full:'480',
        value_2019_half:'241.7',
        value_2019_full:'483.3',
        value_2020_half:'192.4',
        value_2020_full:'384.8',
      },
      {
        key:2,
        title:'Валовой региональный продукт — доля в (контрольных показателях) ВВП ',
        value_2017_half:'0%',
        value_2017_full:'0%',
        value_2018_half:'14.%',
        value_2018_full:'12%',
        value_2019_half:'11%',
        value_2019_full:'9%',
        value_2020_half:'8%',
        value_2020_full:'7%',
      }
    ];
  }
  if(i18n.language === 'oz'){
    columns = [
      {
        title:'T/r',
        dataIndex:'key',
        width:60,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0) {
            obj.props.colSpan = 0;
          }
          if (index === 1) {
            obj.props.rowSpan = 2;
            obj.props.align = 'center';
          }
          // These two are merged into above cell
          if (index === 2) {
            obj.props.rowSpan = 0;
          }
          return obj;
        },
      },
      {
        title:'Hududlar nomi',
        width:'20%',
        dataIndex:'title',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <em>{text}</em>,
            props: {
              colSpan: 2,
            },
          };
        },
      },
      {
        title:'2017-yil',
        children:[
          {
            dataIndex:'value_2017_half',
            title:'6 oylik'
          },
          {
            dataIndex:'value_2017_full',
            title:'yillik'
          }
        ]
      },
      {
        title:'2018-yil',
        children:[
          {
            dataIndex:'value_2018_half',
            title:'6 oylik'
          },
          {
            dataIndex:'value_2018_full',
            title:'yillik'
          }
        ]
      },
      {
        title:'2019-yil',
        children:[
          {
            dataIndex:'value_2019_half',
            title:'6 oylik'
          },
          {
            dataIndex:'value_2019_full',
            title:'yillik'
          }
        ]
      },
      {
        title:'2020-yil',
        children:[
          {
            dataIndex:'value_2020_half',
            title:'6 oylik'
          },
          {
            dataIndex:'value_2020_full',
            title:'yillik'
          }
        ]
      },
    ];
    data = [
      {
        key:'0',
        title:'Yoshlarga oid davlat siyosatining moliyalashtirilishi',
        value_2017_half:'-',
        value_2017_full:'-',
        value_2018_half:'240',
        value_2018_full:'497.4',
        value_2019_half:'241.7',
        value_2019_full:'614.5',
        value_2020_half:'192.4',
        value_2020_full:'399.2',
      },
      {
        key:1,
        title:'Yoshlar ishlari agentligi markaziy apparati va tizim tashkilotlari',
        value_2017_half:'0',
        value_2017_full:'0',
        value_2018_half:'240',
        value_2018_full:'480',
        value_2019_half:'241.7',
        value_2019_full:'483.3',
        value_2020_half:'192.4',
        value_2020_full:'384.8',
      },
      {
        key:2,
        title:'Yalpi hududiy mahsulot - YIM (nazorat ko\'rsatkichlari)dagi ulishi',
        value_2017_half:'0%',
        value_2017_full:'0%',
        value_2018_half:'14.%',
        value_2018_full:'12%',
        value_2019_half:'11%',
        value_2019_full:'9%',
        value_2020_half:'8%',
        value_2020_full:'7%',
      }
    ];
  }
  if(i18n.language === 'uz'){
    columns = [
      {
        title:'т/р',
        dataIndex:'key',
        width:60,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0) {
            obj.props.colSpan = 0;
          }
          if (index === 1) {
            obj.props.rowSpan = 2;
            obj.props.align = 'center';
          }
          // These two are merged into above cell
          if (index === 2) {
            obj.props.rowSpan = 0;
          }
          return obj;
        },
      },
      {
        title:'Ҳудудлар номи',
        width:'20%',
        dataIndex:'title',
        render: (text, row, index) => {
          if (index > 0) {
            return text;
          }
          return {
            children: <em>{text}</em>,
            props: {
              colSpan: 2,
            },
          };
        },
      },
      {
        title:'2017 йил',
        children:[
          {
            dataIndex:'value_2017_half',
            title:'6 ойлик'
          },
          {
            dataIndex:'value_2017_full',
            title:'йиллик'
          }
        ]
      },
      {
        title:'2018 йил',
        children:[
          {
            dataIndex:'value_2018_half',
            title:'6 ойлик'
          },
          {
            dataIndex:'value_2018_full',
            title:'йиллик'
          }
        ]
      },
      {
        title:'2019 йил',
        children:[
          {
            dataIndex:'value_2019_half',
            title:'6 ойлик'
          },
          {
            dataIndex:'value_2019_full',
            title:'йиллик'
          }
        ]
      },
      {
        title:'2020 йил',
        children:[
          {
            dataIndex:'value_2020_half',
            title:'6 ойлик'
          },
          {
            dataIndex:'value_2020_full',
            title:'йиллик'
          }
        ]
      },
    ];
    data = [
      {
        key:'0',
        title:'Ёшларга оид давлат сиёсатининг молиялаштирилиши',
        value_2017_half:'-',
        value_2017_full:'-',
        value_2018_half:'240',
        value_2018_full:'497.4',
        value_2019_half:'241.7',
        value_2019_full:'614.5',
        value_2020_half:'192.4',
        value_2020_full:'399.2',
      },
      {
        key:1,
        title:'Ёшлар ишлари агентлиги марказий аппарати ва тизим ташкилотлари',
        value_2017_half:'0',
        value_2017_full:'0',
        value_2018_half:'240',
        value_2018_full:'480',
        value_2019_half:'241.7',
        value_2019_full:'483.3',
        value_2020_half:'192.4',
        value_2020_full:'384.8',
      },
      {
        key:2,
        title:'Ялпи ҳудудий маҳсулот — ЯИМ (назорат кўрсаткичлари)даги улуши',
        value_2017_half:'0%',
        value_2017_full:'0%',
        value_2018_half:'14.%',
        value_2018_full:'12%',
        value_2019_half:'11%',
        value_2019_full:'9%',
        value_2020_half:'8%',
        value_2020_full:'7%',
      }
    ];
  }
  return(
    <div className={'table-center'}>
      <h3>
        {
          i18n.language === 'ru' && "Критерии национального индекса оценки молодежной политики в разрезе Министерства финансов (Для граждан от 14 до 30 лет по районам (городам)) Финансирование государственной молодежной политики"
        }
        {
          i18n.language === 'oz' && "Moliya vazirligi kesimida Yoshlar siyosatini baholash milliy indeksi mezonlari (Tumanlar (shaharlar) kesimida 14 yoshdan 30 yoshgacha bo‘lgan fuqarolar bo‘yicha) Yoshlarga oid davlat siyosatining moliyalashtirilishi"
        }
        {
          i18n.language === 'uz' && 'Молия вазирлиги кесимида Ёшлар сиёсатини баҳолаш миллий индекси мезонлари (Туманлар (шаҳарлар) кесимида 14 ёшдан 30 ёшгача бўлган фуқаролар бўйича) Ёшларга оид давлат сиёсатининг молиялаштирилиши"'
        }

      </h3>
      <Table
        pagination={false}
        sticky
        columns={columns}
        dataSource={data}
        bordered
        size="large"
      />
    </div>
  )
};

export default MV