import React, { useEffect, Suspense } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import MainLayout from "./layout";
import Index from './page/home'

import Token from './page/token'
import About from './page/about/about'
import NewsPage from './page/news'
import NewsDetail from './page/news/newsDetail'
import Opportunities from './page/youth_opportunities/youth_opportunities'
import YouthIndices from './page/youth_indices/youth_indices';
import YouthAppeals from './page/youth_appeals/youth_appeals';
import { observer } from "mobx-react";
import ProfileAppealsList from "./page/profile/appeals";
import ProfilePrivilegeDetail from "./page/profile/privilege-detail";
import Cabinet from "./page/profile/cabinet";
import { useStores } from "./store";
import Events from "./page/events";
import CoursesPage from "./page/courses";
import Projects from "./page/projects";
import ProjectsDetail from "./page/projects/detail";
import Agency from "./page/agency";
import Grants from "./page/grants";
import CoursesDetail from "./page/courses/detail";
import EventsDetail from "./page/events/detail";
import Vacancy from "./page/vacancy";
import VacancyDetail from "./page/vacancy/detail";
import GrantDetail from "./page/grants/detail";
import Contests from "./page/contests";
import E404 from "./components/e404/404";
import ContestsDetail from "./page/contests/detail";
import Achievements from "./page/achievements";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import Ideas from "./page/ideas";
import ScrollToTop from "./components/scrollTop";
import Loading from "./components/loading";
import ExecutorConfirmed from "./page/executor/benefits/confirmed";
import ExecutorInProgress from "./page/executor/benefits/in_progress";
import ExecutorPending from "./page/executor/benefits/pending";
import Magazine from "./page/magazine";
import MagazineDetail from "./page/magazine/detail";
import ChangePassword from "./page/profile/change-password";
import ChangeEmail from "./page/profile/change-email";
import ChangeAddress from "./page/profile/change-adress";
import AchievementDetail from "./page/achievements/detail";
import ExecutorPMInProgress from "./page/executor/pm/in_progress";
import ExecutorPMConfirmed from "./page/executor/pm/confirmed";
import ExecutorPMPending from "./page/executor/pm/pending";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AppealPage from "./page/appeal";
import ProfilePM from "./page/profile/pm";
import MainProfile from "./page/profile";
import ExecutorPMRedirectTask from "./page/executor/pm/redirect-task";
import ExecutorPMChangeDeadline from "./page/executor/pm/change-deadline";
import ExecutorPMChangeCategory from "./page/executor/pm/change-category";
import ExecutorPMChangePeriod from "./page/executor/pm/change-period";
import ToAgency from "./page/executor/agency";
import ExportUsers from "./page/export";
import ExportDetail from "./page/export/detail";
import Helmet from "react-helmet";

const App = () => {
  const { Modals, app } = useStores();
  const { access } = app;
  const { profile } = useStores();

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if(!process.env.IS_SERVER){
      if(!!localStorage.getItem('token')){
        profile.getProfile();
      }
    }
  }, [profile]);

  if (!profile.profile) return <Loading/>;

  return (
      <>
        <Helmet>
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="512"/>
          <meta property="og:image:height" content="512"/>
          <meta property="og:image" content="%PUBLIC_URL%/icon/android-chrome-512x512.png" />
          <meta property="og:title" content="Yoshlar portali" />
          <meta property="og:description" content="Молодежный портал – это уникальная платформа, которая дает молодежи возможность быстро овладеть информацией о грантах, конкурсах и различных мероприятиях. Он также рекомендует качественные учебные центры, чтобы максимизировать потенциал подрастающего поколения. Для юношей и девушек в качестве примера приводятся достижения сверстников, дающие мотивацию к достижению поставленных целей." />
          <meta name="description" content="Молодежный портал – это уникальная платформа, которая дает молодежи возможность быстро овладеть информацией о грантах, конкурсах и различных мероприятиях. Он также рекомендует качественные учебные центры, чтобы максимизировать потенциал подрастающего поколения. Для юношей и девушек в качестве примера приводятся достижения сверстников, дающие мотивацию к достижению поставленных целей."/>
        </Helmet>
        <I18nextProvider i18n={i18n}>
          <div className={Modals.showPopup ? 'App show' : 'App'}>

            <MainLayout>
              <ScrollToTop>
                <Switch>
                  <Redirect exact from="/" to={`/oz/`}/>
                  <NoAccessRoute Cmp={Index} path={`/:lang/`} exact/>
                  <NoAccessRoute Cmp={About} path={`/:lang/about-us`} exact/>
                  <NoAccessRoute Cmp={Magazine} path={`/:lang/magazine`} exact/>
                  <NoAccessRoute Cmp={MagazineDetail} path={`/:lang/magazine/:id`} exact/>
                  <NoAccessRoute Cmp={Achievements} path={`/:lang/achievements`} exact/>
                  <NoAccessRoute Cmp={AchievementDetail} path={`/:lang/achievements/:id`} exact/>
                  <NoAccessRoute Cmp={Ideas} path={`/:lang/ideas`} exact/>
                  <NoAccessRoute Cmp={AppealPage} path={`/:lang/appeal`} exact/>
                  <NoAccessRoute Cmp={Vacancy} path={`/:lang/vacancy`} exact/>
                  <NoAccessRoute Cmp={VacancyDetail} path={`/:lang/vacancy/:id`} exact/>
                  <NoAccessRoute Cmp={Contests} path={`/:lang/contests`} exact/>
                  <NoAccessRoute Cmp={ContestsDetail} path={`/:lang/contests/:id`} exact/>
                  <NoAccessRoute Cmp={Events} path={`/:lang/events`} exact/>
                  <NoAccessRoute Cmp={EventsDetail} path={`/:lang/events/:id`} exact/>
                  <NoAccessRoute Cmp={CoursesPage} path={`/:lang/courses`} exact/>
                  <NoAccessRoute Cmp={CoursesDetail} path={`/:lang/courses/:id`} exact/>
                  <NoAccessRoute Cmp={Projects} path={`/:lang/projects`} exact/>
                  <NoAccessRoute Cmp={ProjectsDetail} path={`/:lang/projects/:id`} exact/>
                  <NoAccessRoute Cmp={Agency} path={`/:lang/agency`} exact/>
                  <NoAccessRoute Cmp={Grants} path={`/:lang/grants`} exact/>
                  <NoAccessRoute Cmp={GrantDetail} path={`/:lang/grants/:id`} exact/>
                  <NoAccessRoute Cmp={NewsPage} path={`/:lang/news`} exact/>
                  <NoAccessRoute Cmp={NewsDetail} path={`/:lang/news/:id`} exact/>
                  <NoAccessRoute Cmp={Opportunities} path={`/:lang/opportunities`} exact/>
                  <NoAccessRoute Cmp={ProfilePrivilegeDetail} path={`/:lang/opportunities/:id`} exact/>
                  <NoAccessRoute Cmp={YouthIndices} path={`/:lang/youth-indices`} exact/>
                  <NoAccessRoute Cmp={YouthAppeals} path={`/:lang/youth-appeals`} exact/>
                  <NoAccessRoute Cmp={Token} path={`/:lang/oauth/idgovuz`} exact/>
                  <Route exact path={
                    [
                      `/:lang/executor/new/:id?`,
                      `/:lang/executor/processing/:id?`,
                      `/:lang/executor/closed/:id?`,
                      `/:lang/pm/new/:id?`,
                      `/:lang/pm/processing/:id?`,
                      `/:lang/pm/closed/:id?`,
                      `/:lang/to-agency/:id?`,
                      `/:lang/pm/redirect-task/:id?`,
                      `/:lang/pm/change-deadline/:id?`,
                      `/:lang/pm/change-category/:id?`,
                      `/:lang/pm/change-period/:id?`,
                      `/:lang/profile/benefits/:id?`,
                      `/:lang/profile/pm-appeals/:id?`,
                      `/:lang/profile/cabinet`,
                      `/:lang/profile/change-password`,
                      `/:lang/profile/change-email`,
                      `/:lang/profile/change-address`,
                      `/:lang/export`,
                      `/:lang/export/:type/:id`
                    ]
                  }>
                    <MainProfile>
                      <Switch>
                        <Private  access={access} Cmp={ExecutorPending} path={`/:lang/executor/new/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorInProgress} path={`/:lang/executor/processing/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorConfirmed} path={`/:lang/executor/closed/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorPMPending} path={`/:lang/pm/new/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorPMInProgress} path={`/:lang/pm/processing/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorPMConfirmed} path={`/:lang/pm/closed/:id?`} exact/>
                        <Private  access={access} Cmp={ToAgency} path={`/:lang/to-agency/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorPMRedirectTask} path={`/:lang/pm/redirect-task/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorPMChangeDeadline} path={`/:lang/pm/change-deadline/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorPMChangeCategory} path={`/:lang/pm/change-category/:id?`} exact/>
                        <Private  access={access} Cmp={ExecutorPMChangePeriod} path={`/:lang/pm/change-period/:id?`} exact/>
                        <Private  exact Cmp={ProfileAppealsList} path={`/:lang/profile/benefits/:id?`} access={access}/>
                        <Private  exact Cmp={ProfilePM} path={`/:lang/profile/pm-appeals/:id?`} access={access}/>
                        <Private  exact Cmp={Cabinet} path={`/:lang/profile/cabinet`} access={access}/>
                        <Private  exact Cmp={ChangePassword} path={`/:lang/profile/change-password`} access={access}/>
                        <Private  exact Cmp={ChangeEmail} path={`/:lang/profile/change-email`} access={access}/>
                        <Private  exact Cmp={ChangeAddress} path={`/:lang/profile/change-address`} access={access}/>
                        <Private  exact Cmp={ExportUsers} path={`/:lang/export`} access={access}/>
                        <Private  exact Cmp={ExportDetail} path={`/:lang/export/:type/:id`} access={access}/>
                      </Switch>
                    </MainProfile>
                  </Route>

                  <Route>
                    <E404/>
                  </Route>
                </Switch>
              </ScrollToTop>
            </MainLayout>
          </div>
        </I18nextProvider>

</>

  );
};
const Private = ({ Cmp, access, ...rest }) => {
  if (access) return <Route {...rest}>
    <Cmp/>
  </Route>
  return <Redirect to={`/`} from={rest.path} noThrow/>;
};
const NoAccessRoute = ({ Cmp, access, ...rest }) => {
  if (!access) return <Route {...rest}>
    <Cmp/>
  </Route>
  return <Redirect to={`/`} from={rest.path} noThrow/>
};

export default observer(App);
