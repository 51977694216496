import React from 'react';
import { observer } from "mobx-react";
import { currentLang, formatDateClient } from "../../../config";
import { useStores } from "../../../store";
import { Trans } from "react-i18next";
import ExecutorPMChangeCategory from "./change-category";
import ExecutorPMChangeDeadline from "./change-deadline";
import ExecutorPMChangePeriod from "./change-period";
import ExecutorPMRedirectTask from "./redirect-task";
import ExecutorPMResult from "./result";
const ExecutorDetail = (id) => {
  const {executor} = useStores();
  return (
   <>
     <h3>Обращения ID {executor.detail && executor.detail.request_no}</h3>
     {
       executor.detail &&
       <>
         <ul  className={'animate__animated animate__fadeIn profile-list'}>
           <li>
             <strong><Trans>ФИО</Trans>:</strong>
             <span><span>{executor.detail.first_name} {executor.detail.last_name} {executor.detail.patronymic}</span></span>
           </li>
           <li>
             <strong><Trans>Номер телефона</Trans>:</strong>
             <span>+{executor.detail.phone}</span>
           </li>
           <li>
             <strong><Trans>Адрес</Trans>:</strong>
             <span>{executor.detail.current_address}</span>
           </li>
           <li>
             <strong><Trans>Номер обращений</Trans>:</strong>
             <span>{executor.detail.request_no}</span>
           </li>
           <li>
             <strong><Trans>Дата обращения</Trans>:</strong>
             <span>{formatDateClient(executor.detail.user.created_at)}</span>
           </li>
           {
             executor.detail.status === 'closed' && <li>
               <strong><Trans>Дата закрытие</Trans>:</strong>
               <span>{formatDateClient(executor.detail.updated_at)}</span>
             </li>
           }
           <li>
             <strong><Trans>Категории</Trans>:</strong>
             <span>{executor.detail.category}</span>
           </li>
           <li className={'full'}>
             <strong><Trans>Текст обращения</Trans></strong>
             <p>{executor.detail.description}</p>
           </li>
         </ul>
         {
           executor.detail.status !== 'closed' &&
             <>
               <ExecutorPMResult  id={id}/>
               <ExecutorPMChangeCategory id={id}/>
               <ExecutorPMChangeDeadline  id={id}/>
               <ExecutorPMChangePeriod  id={id}/>
               <ExecutorPMRedirectTask  id={id}/>
             </>
         }

       </>
     }
     {
       executor.detail && executor.detail.status === 'closed' &&
       <>
         <h3><Trans>Результат обращения</Trans></h3>
         <ul  className={'animate__animated animate__fadeIn profile-list'}>
           <li>
             <strong><Trans>Номер обращений</Trans>:</strong>
             <span>{executor.detail.request_no}</span>
           </li>
           <li>
             <strong><Trans>Дата обращения</Trans>:</strong>
             <span>{formatDateClient(executor.detail.created_at)}</span>
           </li>

           <li>
             <strong><Trans>Результат</Trans>:</strong>
             <span className={
               (
                 executor.detail.result.result_type.title === 'Отклонено' ||
                 executor.detail.result.result_type.title === 'Не рассмотрено' ||
                 executor.detail.result.result_type.title === 'Рассмотрение прекращено') ? 'text-error' : 'text-success'}
             >
                   {executor.detail.result.result_type[`title_${currentLang()}`]}
                 </span>
           </li>
           {
             (executor.detail.result.result_type.title === 'Отклонено' || executor.detail.result.result_type.title === 'Не рассмотрено' || executor.detail.result.result_type.title === 'Рассмотрение прекращено') ?
               <>
                 <li className={'full'}>
                   <strong><Trans>Ответ</Trans></strong>
                   <p>{executor.detail.result.result_type.title === 'Отклонено' ? 'Нет ответа' : executor.detail.result.reasons_reject_text}</p>
                 </li>
               </> :
               <>
                 <li>
                   <strong><Trans>Ижро учун масъул ходим</Trans>:</strong>
                   <span>{executor.detail.result.responsible_person}</span>
                 </li>
                 <li>
                   <strong><Trans>Жавоб хатини имзолаган масъул шахс ҳақида маълумотлар</Trans>:</strong>
                   <span>{executor.detail.result.executor_info}</span>
                 </li>

                 <li className={'full'}>
                   <strong><Trans>Ответ</Trans></strong>
                   <p>{executor.detail.result.answer_text}</p>
                 </li>
               </>
           }
           <li className={'full'}>
             <strong><Trans>Коментарии</Trans></strong>
             <p>{executor.detail.result.comment}</p>
           </li>

           {
             executor.detail.result.file && <li>
               <strong><Trans>Файл</Trans>:</strong>
               <button style={{background:'#44D370', flex:1}} className={'btn text'}>Скачать</button>
             </li>
           }
         </ul>
       </>
     }
   </>
  )
};

export default observer(ExecutorDetail)