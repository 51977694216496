import { observable, makeObservable, flow, action } from "mobx";
import api from '../../api';
import i18n from '../../i18n';
class Vacancy {
  @observable list = {
    results:[],
    count:0
  };
  @observable schedule = [];
  @observable sphere = [];
  @observable form = {
    title:null,
    region:null
  };
  @observable employment = [];
  @observable detail = null;
  @observable error = false;
  @observable loading = true;
  @observable pagination = {
    limit:20,
    offset:0
  };
  @observable start = 1;

  constructor() {
    makeObservable(this);
  }
  @action setPagination = () => {
    this.pagination.offset = this.start * 20;
  }
  @action filterRegion = (values) => {
    this.start = 1;
    this.list.results = [];
    if(values){
      this.form.title = values.children;
      this.form.region = values.value;
    }else{
      this.form.title = null;
      this.form.region = null;
    }
  }
  @action setForm = (values) => {
    this.start = 1;
    this.list.results = [];
    for(let val in values) {
      if(val === 'sphere' || val === 'region_id' || val === 'search_text'){
        this.form[val] = values[val]
      }else{
        this.form[val] = values[val].join(',');
      }
    }
  }
  @action reset = () =>{
    this.start = 1;
    this.list.results = [];
    this.form = {
      title:null,
      region:null
    }
  }
  @flow *
    getData(){
    const response = yield api.vacancy.data({...this.pagination, ...this.form});
    if(!response.detail){
      if(this.list.results.length > 0) {
        this.list.count = response.data.count;
        this.list.results = [...this.list.results, ...response.data.results];
        this.start++;
      }else{
        this.list = response.data;
      }
      this.loading = false;
    }
  }
  @flow *
  getSpheres(){
    const response = yield api.vacancy.spheres();
    if(!response.detail){
      this.sphere = response.data.results;
    }
  }
  @flow *
  getSchedule(){
    const response = yield api.vacancy.schedule();
    if(!response.detail){
      this.schedule = response.data.results.map((item) => ({
        label:item.name,
        value:item.id
      }));
    }
  }
  @flow *
  getEmployment(){
    const response = yield api.vacancy.employment();
    if(!response.detail){
      this.employment = response.data.results.map((item) => ({
        label:item[`name_${i18n.language}`],
        value:item.id
      }));
    }
  }
  @flow *
    detailVacancy(id){
    const response = yield api.vacancy.detail(id);
    if(!response.detail){
      this.detail = response.data;
    }else{
      this.error = true;
      this.loading = false;
    }
  }
};

export default new Vacancy();