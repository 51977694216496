import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "../../../store";
import { Button, Form, Input, InputNumber, message, Modal, notification, Select } from "antd";
import { observer } from "mobx-react";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import { currentLang, formatDateClient } from "../../../config";

const ExecutorPMRedirectTask = (props) => {
  const {id} = props;
  const {executor, appeals} = useStores()
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const {t} = useTranslation()
  const Option = Select;
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visibleReject, setVisibleReject] = useState(false)
  useEffect(()=>{
    executor.redirectTask({request_id:id.id})
    return () => {
      executor.reset()
    }
  }, [executor, id])
  const onFinish = async values => {
    setLoading(true);
    executor.setValues({...values, status:'REJECTED', deadline:15, url:'/set-status-on-redirect-task'});
    const error = await executor.acceptedItem();
    if(!error){
      notification.success({
        message: t('Заявка отправлено'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
      });
      form.resetFields()
      setLoading(false);
      setVisible(false);
      executor.redirectTask({request_id:id.id})
    }else{
      setLoading(false);
      error.map(item => message.error(item))
    }
  }
  const handleChange = async q => {
    await appeals.suggestion(q);

  }
  const visibleHandler = (item) => {
    executor.setValues(
      {
        action_id:item.id,
        request_pm_gov_id:item.request_pm_gov_id,
        task_pm_gov_id:item.task.pm_gov_id,
        status:'ACCEPTED'
      }
    );
    setVisible(true)
  }
  const visibleRejectHandler = (item) => {
    executor.setValues({url:'/set-status-on-redirect-task', action_id:item.id, request_pm_gov_id:item.request_pm_gov_id, task_pm_gov_id:item.task_pm_gov_id, status:'REJECTED'});
    setVisibleReject(true)
  }
  const rejectedHandler = async (values) => {
    setLoading(true);
    executor.setValues({...values});
    const error = await executor.acceptedItem();
    if(!error){
      notification.success({
        message: t('Заявка отправлено'),
        icon: <SmileOutlined style={{ color: '#108ee9' }}/>,
      });
      form.resetFields()
      setLoading(false);
      setVisibleReject(false)
      executor.redirectTask({request_id:id.id})
    }else{
      error.map(item => message.error(item))
      setLoading(false);
    }
  }
  const changeHandler = async id => {
    await appeals.getPMSubcategories(id);
  }
  return(
    <>
      {
        executor.task.results.length > 0 &&
          <>
            <h3><Trans>Переназначение задач</Trans></h3>
            {
              executor.task.results.map(item => {
                return <div key={item.id}>
                  <ul  className={'animate__animated animate__fadeIn profile-list'}>
                    <li>
                      <strong>Номер обращений:</strong>
                      <span>{item.request.request_no}</span>
                    </li>
                    <li>
                      <strong>Дата обращения:</strong>
                      <span>{formatDateClient(item.created_at)}</span>
                    </li>
                    <li>
                      <strong>Организация:</strong>
                      <span>{item.task.dst_authority[`title_${currentLang()}`]}</span>
                    </li>
                    <li>
                      <strong>Категории:</strong>
                      <span>{item.task.classification[`title_${currentLang()}`]}</span>
                    </li>
                    {
                      (item.status === 'ACCEPTED' || item.status === 'REJECTED') &&
                      <>
                        <li>
                          <strong><Trans>Статус</Trans></strong>
                          <span className={item.status === 'ACCEPTED' ? 'text-success' : 'text-error'}>{item.status === 'ACCEPTED' ? 'Сиз мурожатни кабул килдингиз' : item.status === 'REJECTED' && 'Сиз мурожатни рад килдингиз'}</span>
                        </li>
                      </>
                    }
                    <li className={'full'}>
                      <strong>Коментарии</strong>
                      <p>{item.comment}</p>
                    </li>
                  </ul>
                  <div className={'btn-panel'}>
                    {
                      item.status === 'PROCESSING' &&
                      <>
                        <button className={'btn success'} onClick={()=>visibleHandler(item)}><span><Trans>Принять</Trans></span></button>
                        <button className={'btn error'} onClick={()=>visibleRejectHandler(item)}><span><Trans>Отказать</Trans></span></button>
                      </>
                    }
                  </div>
                </div>
              })
            }
          </>
      }
      <Modal maskClosable={true} onCancel={()=>setVisible(false)} title={t('Переназначение задач')} visible={visible} footer={false}>
        <Form  form={form} layout={'vertical'} onFinish={onFinish}>
          <Form.Item rules={[{required:true, message:t('Обьязательное поля')}]} label={t('Выберите организацию')}>
            <Select
              onDropdownVisibleChange={(open)=>open && appeals.getPMCategory()}
              onChange={changeHandler}>
              {
                appeals.pm.categories.map((item) => {
                  return <Option value={item.pm_gov_id}>{item[`title_${currentLang()}`]}</Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item rules={[{required:true, message:'Обьязательное поля'}]} name={'dst_authority_pm_gov_id'} label={'Выберите министерства'}>
            <Select disabled={appeals.pm.subcategories.length <= 0}>
              {
                appeals.pm.subcategories.map((item) => {
                  return <Option value={item.pm_gov_id}>{item[`title_${currentLang()}`]}</Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item name={'classification_pm_gov_id'} label={t('Категорий второго уровня')}>
            <Select
              showSearch
              defaultActiveFirstOption={true}
              showArrow={false}
              filterOption={false}
              onSearch={handleChange}
              placeholder={t('Введите классификацию')}
              notFoundContent={null}
            >
              {
                appeals.pm.classificationsText.map((item, idx) => {
                    return <Option value={item.pm_gov_id} key={idx}>{item[`title_${currentLang() === 'en' ? 'ru' : currentLang()}`]}</Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item name={'depth_level'} label={t('Уровень подведомственной организации')}>
            <InputNumber/>
          </Form.Item>
          <Form.Item rules={[{required:true, message:'Обьязательное поля'}]} name={'comment'} label={t('Причина')}>
            <TextArea/>
          </Form.Item>
          <Button loading={loading} htmlType={'submit'} className={'btn success'}><Trans>Отправить</Trans></Button>
          <Button className={'btn close'} onClick={()=>setVisible(false)}><Trans>Закрыть</Trans></Button>
        </Form>
      </Modal>
      <Modal maskClosable={true} onCancel={()=>setVisibleReject(false)} title={t("Переназначение задач")} visible={visibleReject} footer={false}>
        <Form  form={form} layout={'vertical'} onFinish={rejectedHandler}>
          <Form.Item rules={[{required:true, message:t('Обьязательное поля')}]} name={'comment'} label={t('Причина')}>
            <TextArea/>
          </Form.Item>
          <Button loading={loading} htmlType={'submit'} className={'btn success'}><Trans>Отправить</Trans></Button>
          <Button className={'btn close'} onClick={()=>setVisible(false)}><Trans>Закрыть</Trans></Button>
        </Form>
      </Modal>
    </>
  )
};

export default observer(ExecutorPMRedirectTask)