  import { makeObservable, observable, flow, action } from "mobx";
import api from '../../api';
class Opportunities{
  @observable step = 1;
  @observable position = null;
  @observable start = 1;
  @observable paginations = {
    limit:20,
    offset:0
  }
  @observable data = {
    count:0,
    results:[]
  };
  @observable scopes = {
    results:[]
  };
  @observable loading = true;
  @observable form = {};

  constructor() {
    makeObservable(this);
  }

  @action setValuesForm = values => {
    this.start = 1;
    this.data.results = [];
    this.paginations = {limit:20, offset: 0};
    this.form = {...this.form, ...values};
    for(let type in this.form){
      if(this.form[type] === null || this.form[type] === ''){
        delete this.form[type];
      }
    }
  }
  @action setPosition = (position) => {
    this.position = position;
  }
  @action reset = () => {
    this.step = 1;
    this.loading = true;
    this.data.results = [];
    this.form = {};
    this.paginations = {
      limit:20,
      offset:0
    }
  }
  @action setPagination = () => {
    this.paginations.offset = this.start * 20;
  }

  @flow * getData(){
    const response = yield api.opportunities.data({...this.paginations, ...this.form})
    if(!response.detail){
      if(this.data.results.length > 0) {
        this.data.count = response.data.count;
        this.data.results = [...this.data.results, ...response.data.results];
        this.start++;
      }else{
        this.data = response.data;
      }
      this.loading = false;
    }
  }
  @flow * getScopes(){
    const response = yield api.opportunities.scopes()
    if(!response.detail){
      this.scopes = response.data;
    }
  }
  @flow * submit(value){
    const response = yield api.opportunities.send(value)
    if(!response.detail){
      this.step = 2;
    }
  }
  @flow * emailPm(value){
    const response = yield api.appeals.send(value)
    if(!response.detail){
    }
  }
}
export default new Opportunities();