import React from 'react';
import {Table} from 'antd'
import { useTranslation } from "react-i18next";
const School =  () => {
  const {i18n} = useTranslation()
  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index >= 14) {
      obj.props.colSpan = 0;
    }
    return obj;
  };
  let columns = [];
  let data = [];
  if(i18n.language === 'ru'){
    columns = [
      {
        title:'п/н',
        dataIndex:'key',
        render: renderContent
      },
      {
        title:'Регионы',
        dataIndex:'region',
        render: (text, row, index) => {

          if (index < 14) {
            return text;
          }
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
              props: {
                align:'center',
                colSpan: 2,
              },
            };
          }

          return {
            children: text,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        },

      },
      {
        title:'Общее количество школ',
        dataIndex:'schools',
        render: (text, row, index) => {
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
            };
          }
          return text
        }
      },
      {
        title:'Общее количество учеников',
        dataIndex:'students',
        render: (text, row, index) => {
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
            };
          }
          return text
        }
      }
    ];
    data = [
      {
        key:1,
        region:"Республика Каракалпакстан",
        schools:'727',
        students:'352 476'
      },
      {
        key:2,
        region:"Андижанская область",
        schools:'764',
        students:'568 008'
      },
      {
        key:3,
        region:"Бухарская область",
        schools:'536',
        students:'329 787'
      },
      {
        key:4,
        region:"Джизакская область",
        schools:'550',
        students:'259 009'
      },
      {
        key:5,
        region:"Кашкадарьинская область",
        schools:'1157',
        students:'638 119'
      },
      {
        key:6,
        region:"Навоийская область",
        schools:'365',
        students:'181 223'
      },
      {
        key:7,
        region:"Наманганская область",
        schools:'711',
        students:'506 897'
      },
      {
        key:8,
        region:"Самаркандская область",
        schools:'1255',
        students:'734 398'
      },
      {
        key:9,
        region:"Сурхандарьинская область",
        schools:'927',
        students:'503 033'
      },
      {
        key:10,
        region:"Сырдарьинская область",
        schools:'313',
        students:'157 601'
      },
      {
        key:11,
        region:"Ташкентская область",
        schools:'891',
        students:'516 838'
      },
      {
        key:12,
        region:"Ферганская область",
        schools:'948',
        students:'675 697'
      },
      {
        key:13,
        region:"Хорезмская область",
        schools:'546',
        students:'355 216'
      },
      {
        key:14,
        region:"Город Ташкент",
        schools:'312',
        students:'449 044'
      },
      {
        key:15,
        region:"Всего:",
        schools:'10002',
        students:'6 227 346'
      },
      {
        key:16,
        region:"Школы под управлением МНО",
        schools:'6',
        students:'9405'
      },
      {
        key:17,
        region:"Всего по республике:",
        schools:'10008',
        students:'6 236 751'
      }
    ];
  }
  if(i18n.language === 'oz'){
    columns = [
      {
        title:'T/r',
        dataIndex:'key',
        render: renderContent
      },
      {
        title:'Hudud nomi',
        dataIndex:'region',
        render: (text, row, index) => {

          if (index < 14) {
            return text;
          }
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
              props: {
                align:'center',
                colSpan: 2,
              },
            };
          }

          return {
            children: text,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        },

      },
      {
        title:'Jami maktablar soni',
        dataIndex:'schools',
        render: (text, row, index) => {
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
            };
          }
          return text
        }
      },
      {
        title:'Jami o‘quvchilar soni',
        dataIndex:'students',
        render: (text, row, index) => {
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
            };
          }
          return text
        }
      }
    ];
    data = [
      {
        key:1,
        region:"Qoraqalpog‘iston Respublikasi",
        schools:'727',
        students:'352 476'
      },
      {
        key:2,
        region:"Andijon viloyati",
        schools:'764',
        students:'568 008'
      },
      {
        key:3,
        region:"Buxoro viloyati",
        schools:'536',
        students:'329 787'
      },
      {
        key:4,
        region:"Jizzax viloyati",
        schools:'550',
        students:'259 009'
      },
      {
        key:5,
        region:"Qashqadaryo viloyati",
        schools:'1157',
        students:'638 119'
      },
      {
        key:6,
        region:"Navoiy viloyati",
        schools:'365',
        students:'181 223'
      },
      {
        key:7,
        region:"Namangan viloyati",
        schools:'711',
        students:'506 897'
      },
      {
        key:8,
        region:"Samarqand viloyati",
        schools:'1255',
        students:'734 398'
      },
      {
        key:9,
        region:"Surxondaryo viloyati",
        schools:'927',
        students:'503 033'
      },
      {
        key:10,
        region:"Sirdaryo viloyati",
        schools:'313',
        students:'157 601'
      },
      {
        key:11,
        region:"Toshkent viloyati",
        schools:'891',
        students:'516 838'
      },
      {
        key:12,
        region:"Farg‘ona viloyati",
        schools:'948',
        students:'675 697'
      },
      {
        key:13,
        region:"Xorazm viloyati",
        schools:'546',
        students:'355 216'
      },
      {
        key:14,
        region:"Toshkent shahri",
        schools:'312',
        students:'449 044'
      },
      {
        key:15,
        region:"Jami:",
        schools:'10002',
        students:'6 227 346'
      },
      {
        key:16,
        region:"XTV tasarrufidagi maktablar",
        schools:'6',
        students:'9405'
      },
      {
        key:17,
        region:"Respublika bo‘yicha jami:",
        schools:'10008',
        students:'6 236 751'
      }
    ];
  }
  if(i18n.language === 'uz'){
    columns = [
      {
        title:'т/р',
        dataIndex:'key',
        render: renderContent
      },
      {
        title:'Ҳудудлар',
        dataIndex:'region',
        render: (text, row, index) => {

          if (index < 14) {
            return text;
          }
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
              props: {
                align:'center',
                colSpan: 2,
              },
            };
          }

          return {
            children: text,
            props: {
              align:'center',
              colSpan: 2,
            },
          };
        },

      },
      {
        title:'Жами мактаблар сони',
        dataIndex:'schools',
        render: (text, row, index) => {
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
            };
          }
          return text
        }
      },
      {
        title:'Жами ўқувчилар сони',
        dataIndex:'students',
        render: (text, row, index) => {
          if (index === 14 || index === 16) {
            return {
              children: <strong>{text}</strong>,
            };
          }
          return text
        }
      }
    ];
    data = [
      {
        key:1,
        region:"Қорақалпоғистон Республикаси",
        schools:'727',
        students:'352 476'
      },
      {
        key:2,
        region:"Андижон вилояти",
        schools:'764',
        students:'568 008'
      },
      {
        key:3,
        region:"Бухоро вилояти",
        schools:'536',
        students:'329 787'
      },
      {
        key:4,
        region:"Жиззах вилояти",
        schools:'550',
        students:'259 009'
      },
      {
        key:5,
        region:"Қашқадарё вилояти",
        schools:'1157',
        students:'638 119'
      },
      {
        key:6,
        region:"Навоий вилояти",
        schools:'365',
        students:'181 223'
      },
      {
        key:7,
        region:"Наманган вилояти",
        schools:'711',
        students:'506 897'
      },
      {
        key:8,
        region:"Самарқанд вилояти",
        schools:'1255',
        students:'734 398'
      },
      {
        key:9,
        region:"Сурхондарё вилояти",
        schools:'927',
        students:'503 033'
      },
      {
        key:10,
        region:"Сирдарё вилояти",
        schools:'313',
        students:'157 601'
      },
      {
        key:11,
        region:"Тошкент вилояти",
        schools:'891',
        students:'516 838'
      },
      {
        key:12,
        region:"Фарғона вилояти",
        schools:'948',
        students:'675 697'
      },
      {
        key:13,
        region:"Хоразм вилояти",
        schools:'546',
        students:'355 216'
      },
      {
        key:14,
        region:"Тошкент шаҳри",
        schools:'312',
        students:'449 044'
      },
      {
        key:15,
        region:"Жами:",
        schools:'10002',
        students:'6 227 346'
      },
      {
        key:16,
        region:"ХТВ тасарруфидаги мактаблар",
        schools:'6',
        students:'9405'
      },
      {
        key:17,
        region:"Республика бўйича жами:",
        schools:'10008',
        students:'6 236 751'
      }
    ];
  }
  return(
    <div className={'table-center'}>
      <h3>
        {
          i18n.language === 'oz' && "2020-2021-o‘quv yilida xalq ta’limi tizimidagi umumiy o‘rta ta’lim maktablari koeffitsiyenti to‘g‘risida ma’lumot"
        }
        {
          i18n.language === 'uz' && '2020-2021-ўқув йилида халқ таълими тизимидаги умумий ўрта таълим мактаблари коэффициенти тўғрисида маълумот'
        }
        {
          i18n.language === 'ru' && 'Информация о коэффициенте средних общеобразовательных школ в системе народного образования в 2020-2021 учебном году '
        }
        </h3>
      <Table
        pagination={false}
        sticky
        columns={columns}
        dataSource={data}
        bordered
        size="large"
      />
    </div>
  )
};

export default School