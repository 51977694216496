import { makeObservable, observable, action, flow } from "mobx";
import api from "../../api";
import Grants from './grants';
class Home {
  @observable loading = true;
  @observable fameSlide = [];
  @observable data = {
    events:[],
    benefits:[],
    projects:[],
    achievers:[],
    grants:[]
  };
  constructor() {
    makeObservable(this)
  }

  @action resetActive = () => {
    this.fameSlide.forEach(item =>{ item.active = false});
  }

  @action setActive = (index) => {
    this.fameSlide[index].active = true;
  }
  @action reset = () => {
    this.data = {
      events:[],
      benefits:[],
      projects:[],
      achievers:[],
      grants:[]
    }
    this.loading = true
  }

  @flow * getData(){
    const response = yield api.app.data();
    if(!response.detail){
      this.data = response.data;
      this.data.events = response.data.events.map((item, index) => ({
        active:false,
        classes:[],
        ...item
      }));
      this.fameSlide = response.data.achievers.map((item, index) => ({
        active:(index === 3) && true,
        defaultActive:(index === 3) && true,
        style:'',
        ...item
      }));
      this.loading = false;
      Grants.loading = false;
    }
  }

};

export default new Home()