import React from 'react';
import { Trans } from "react-i18next";
import { Helmet } from "react-helmet";

const Agency = () => {
  return (
    <div className={'center-content'}>
      <Helmet>
        <title>Yoshlar Portali - Агентство молодежи</title>
      </Helmet>
      <h1><Trans>Агентство молодежи</Trans></h1>
    </div>
  )
};

export default Agency