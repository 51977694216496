import React from 'react';
import { Alert, Button, Form, Input } from "antd";
import CounterDown from "../../components/counter-down";
import { useStores } from "../../store";
import { Trans, useTranslation } from "react-i18next";
import PhoneMask from "../../components/mask";


const Step2Registration = ({...props}) => {
  const [form] = Form.useForm();
  const {t,i18n} = useTranslation()
  const {Registration, Modals} = useStores();
  const validateCode = (rule, value, callback) => {
    if(!/^[0-9]{6}/.test(value)){
        callback(<Trans>Введите корректный код</Trans>)
    }
    else {
      callback();
    }
  };
  const { onFinish, onFinishFailed, loading, error} = props;
  return (
    <div className={'auth-box auth-wrapper animate__animated animate__fadeIn'}>
      <h3><Trans>Регистрация</Trans></h3>
      <div className={'close-popup'} onClick={()=>Modals.hide()}/>
      {error && <Alert message={error} type="error" style={{marginBottom:10}}/>}
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <PhoneMask label={<Trans>Введите номер телефона</Trans>}/>
        {
          Registration.step === 2 &&
          <div
            className={'animate__animated animate__fadeIn'}
            style={{marginTop:15}}
          >
            <Form.Item label={<Trans>Введите код</Trans>} name="code"  style={{marginBottom:0}} rules={[
              { required: true, message: <Trans>Введите код</Trans> },
              {
                validator: validateCode,
              }
            ]}>
              <Input placeholder={t('Код активации')} maxLength={6}/>
            </Form.Item>
            <CounterDown/>
          </div>
        }
        {
          error ? <a href={`/${i18n.language}/api/idgovuz-auth/authorize`} className={'btn oneidBtn'}><Trans>Вход по ONE ID</Trans></a> :
            <Form.Item style={{marginTop:25}}>
              <Button type="primary"
                      loading={loading}
                      htmlType="submit"
                      className={'btn success'}
              >
                <Trans>Подтвердить</Trans>
              </Button>
            </Form.Item>
        }
      </Form>
    </div>
  )
};

export default Step2Registration