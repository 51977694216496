import axios from 'axios';
import { stringify } from "qs";
import i18n from './i18n';
import { baseUrl } from "./config";
import app from './store/app'
const client = axios.create({
  withCredentials: true,
  baseURL: `${baseUrl}/${i18n.language}/api`,
  headers:{
    Authorization: !process.env.IS_SERVER && !!localStorage.getItem('token') ? `token ${localStorage.getItem('token')}` : ''
  },
  paramsSerializer: function (params) {
    return stringify(params);
  },
});
export function setBaseUrl (lang){
  client.defaults.baseURL =`${baseUrl}/${lang}/api`;
}
const responseSuccessHandler = response => {
  return response;
};

const responseErrorHandler = error => {
  if (error.response.status === 401 && error.request.status === 401) {
    localStorage.clear();
    client.defaults.headers.Authorization = null;
    app.access = false;
  }
  return Promise.reject(error);
};

client.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);


const urls ={
  apiClient: client,
  app: {
    data: () => client.get(`/data`),
    rule: () => client.get(`/agreement/user_agreement/`),
    policy: () => client.get(`/agreement/privacy_policy/`),
    filterScopes: (id) => client.get(`/appeals/category-scopes`, {params:{category_id:id}}),
    filterAppeals: (values) => client.get(`/appeals/benefits`, {params:values}),
    registration: (data) => client.post(`/users/signup`, data).catch((error) => {
      return error.response
    }),
    signupCode: (data) => client.post(`/users/signup-code`, data).catch((error) => {
      return error.response.data
    }),
    confirmCode: (data) => client.post(`/users/signup-confirm`, data).catch(err => {
      return err.response;
    }),
    retryCode: () => client.post(`/users/retry-signup-code`),
    auth: (data) => client.post(`/users/signin`, data).catch((error) => {
      return error.response.data
    }),
    authIDGov: (data) => client.get(`/idgovuz-auth/get-token`, data).catch((error) => {
      return error.response
    }),
    profile: () => client.get(`/users/personal-info`).catch(err => {
      return err.response.data;
    }),
    profileGCP: (values) => client.post(`/users/data-from-gcp`, values).catch(err => {
      return err.response.data;
    }),
    profileGCP2: (values) => client.post(`/users/verify-with-gcp`, values).catch(err => {
      return err.response.data;
    }),
    profileGCP3: (values) => client.post(`/users/data-from-gcp-datedoc`, values).catch(err => {
      return err.response;
    }),
    benefits:(values) => client.get(`/appeals/benefits`, {params:values}),
    recoverCode: (data) => client.post(`/users/recover-code`, data).catch((error) => {
      return error.response.data
    }),
    confirmCodeRecover: (data) => client.post(`/users/recover-code-validation`, data).catch((error) => {
      return error.response.data
    }),
    recoverPassword: (data) => client.post(`/users/recover-password`, data).catch((error) => {
      return error.response.data
    }),
    editProfile: (data) => client.post(`/users/personal-info`, data).catch((error) => {
      return error.response.data
    }),
    changePassword: (data) => client.post(`/users/change-password`, data).catch((error) => {
      return error.response.data
    }),
    changeEmail: (data) => client.post(`/users/user-email-change`, data).catch((error) => {
      return error.response.data
    }),
    changeAddress: (data) => client.post(`/users/user-address-change`, data).catch((error) => {
      return error.response.data
    }),
  },
  exports:{
    getList:(values) => client.get('/dynamic-register-forms/register-form-list', {params:values}),
    exportDetail:(values) => client.get('/dynamic-register-forms/table', {params:values}),
    send:(values) => client.post('/dynamic-register-forms/form-decision', values),
    exportItem:(values) => client.get('/dynamic-register-forms/export', {responseType:'blob',params:values})
  },
  location:{
    region:(values) => client.get('/locations/region', {params:values}),
    regionALl:(id) => client.get(`/pm-gov/district/${id}`),
    regionPM:() => client.get('/pm-gov/region'),
    districtPM:(id) => client.get(`/pm-gov/district/${id}`),
    communityPM:(id) => client.get(`/pm-gov/mahalla/${id}`),
    district:(id) => client.get('/locations/district', {params:{region_id:id}}),
    district2:(values) => client.get('/locations/district', {params:values}),
    districtRegistry:() => client.get(`/locations/address-registry/district`),
    communityRegistry:(values) => client.get(`/locations/address-registry/community`, {params:{...values}}),
    getCommunityPM2:(values, id) => client.get(`/pm-gov/mahalla/${id}`, {params:{q:values}}),
    streets:(id) => client.get(`/locations/address-registry/street`, {params:{district_id:id}}),
    suggestion:(values) => client.get(`/locations/address-registry/street`, {params:{...values}}),
    houses:(value) => client.get(`/locations/address-registry/house`, {params:{...value}})
  },
  pm:{
    categories:() => client.get('/pm-gov/authority-level-1'),
    search:(values) => client.get('pm-gov/search', {params:values}),
    suggestion:(q) => client.get('/pm-gov/classification-search', {params:q}),
    getClassification:() => client.get('/pm-gov/classification-level-1'),
    getClassification2:(id) => client.get(`/pm-gov/classification-level-2/${id}`),
    getClassification3:(id) => client.get(`/pm-gov/classification-level-3/${id}`),
    create:(values) => client.post(`/pm-gov/create-request`, values).catch(e => {
      return e.response
    }),
    // create:(values) => client.post(`/appeals/pm-gov-uz-send-email`, values).catch(e => {
    //   return e.response
    // }),
    subcategories:(id) => client.get(`/pm-gov/authority-level-2/${id}`)
  },
  ideas:{
    create:(values)=> client.post('/ideas/create', values).catch((error) => {
      return error.response
    }),
    list:(values)=> client.get('/ideas/all', {params:values}),
    votes:(values)=> client.get('/ideas/vote_idea', {params:{...values}}),
    popular:()=> client.get('/ideas/popular')
  },
  appeals:{
    list:(values)=> client.get('/pm-gov/benefit-request-list', {params:values}),
    detail:(id)=> client.get(`/pm-gov/request/${id}`),
    benefit:(id)=> client.get(`appeals/benefits/${id}`),
    scopes:()=> client.get(`appeals/scopes`),
    statistic:()=> client.get(`pm-gov/user-request-count`),
    send:(values)=> client.post(`appeals/pm-gov-uz-send-email`, values),
  },
  about:{
    about:()=> client.get('/about/')
  },
  executor:{
    onAppoint:(values) => client.post(`/pm-gov/set-request-processing`, values),
    agencyRedirect:(values) => client.post(`/pm-gov/create-request`, values).catch((error) => {
      return error.response
    }),
    detail:(id) => client.get(`/pm-gov/request/${id}`),
    republic:{
      redirectTask:(values) => client.get(`/pm-gov/redirect-request-list`, {params:values}),
      changeDeadline:(values) => client.get(`/pm-gov/deadline-request-list`, {params:values}),
      changePeriod:(values) => client.get(`/pm-gov/depth-level-request-list`, {params:values}),
      changeCategory:(values) => client.get(`/pm-gov/classification-request-list`, {params:values}),
      accepted:(values) => client.post(`/pm-gov${values.url}`, values.values).catch(e => e.response),
      result:(values) => client.get(`/pm-gov/result/${values.id}`),
      getPmAppeals:(values)=> client.get(`/pm-gov/request-list`, {params:values}),
      confirmed:(values) => client.post(`/executor/republic/confirm`, values),
    },
    executor:{
      getBenefitAppeals:(values)=> client.get(`/pm-gov/benefit-request-list`, {params:values}),
    }
  },
  magazine:{
    list:(values) => client.get(`/library/book/`, {params:values}),
    category:() => client.get(`/library/type/`),
    single:(id) => client.get(`/library/book/${id}/`)
  },
  opportunities:{
    data:(values) => client.get(`/appeals/benefits`, {params:values}),
    scopes:() => client.get(`/appeals/scopes`),
    send:(values) => client.post(`/pm-gov/create-request`, values)
    // send:(values) => client.post(`/appeals/pm-gov-uz-send-email`, values)
  },
  events:{
    data:(values)=> client.get(`/events/event/`, {params:values}),
    detail:(id)=> client.get(`/events/event/${id}/`).catch(err => {return err.response}),
    getForm:(id)=> client.get(`/events/form/${id}/`),
    sendForm:(values)=> client.post(`/events/form/`, values),
  },
  projects:{
    data:(params)=> client.get(`/our-projects/project/`, {params}),
    category:()=> client.get(`/our-projects/category/`),
    detail:(id)=> client.get(`/our-projects/project/${id}/`).catch(err => {return err.response.data}),
    membership:(values)=> client.get(`/our-projects/membership/`, values),
    getForm:(id)=> client.get(`/our-projects/form/${id}/`),
    sendForm:(values)=> client.post(`/our-projects/form/`, values),
  },
  grants:{
    data:(values)=> client.get(`/grants/grant/`, {params:values}),
    university:()=> client.get(`/grants/university/`),
    programs:()=> client.get(`/grants/program/`),
    country:()=> client.get(`/grants/country/`),
    detail:(id)=> client.get(`/grants/grant/${id}/`).catch(err => {return err.response.data}),
    getForm:(id)=> client.get(`/grants/form/${id}/`),
    sendForm:(values)=> client.post(`/grants/form/`, values),
  },
  news:{
    category:()=> client.get('/news/category/'),
    list:(values)=> client.get('/news/article/', {params:values}),
    detail:(id)=> client.get(`/news/article/${id}/`).catch(err => {return err.response.data})
  },
  contests:{
    list:(values)=> client.get('/contests/contest/', {params:values}),
    direction:()=> client.get('/contests/form1-direction/'),
    detail:(id)=> client.get(`/contests/contest/${id}/`).catch(err => {return err.response.data}),
    membership:(values)=> client.post(`/contests/membership/`, values),
    form1:(values)=> client.post(`/contests/form1/`, values),
    sportList:(values)=> client.get(`/contests/form2-sport-type/`, {params:values}),
    form2:(values)=> client.post(`/contests/form2/`, values),
    getForm:(id)=> client.get(`/contests/form/${id}/`),
    sendForm:(values)=> client.post(`/our-projects/form/`, values),
  },
  courses:{
    data:(values)=> client.get('/courses/course/', {params:values}),
    category:()=> client.get('/courses/category/' ),
    subcategory:(value)=> client.get('/courses/subcategory/', {params:value} ),
    detail:(id)=> client.get(`/courses/course/${id}/`).catch(err => {return err.response.data}),
    membership:(values)=> client.get(`/courses/membership/`, values),
    getForm:(id)=> client.get(`/courses/form/${id}/`),
    sendForm:(values)=> client.post(`/courses/form/`, values),
  },
  vacancy:{
    data:(values)=> client.get('/vacancies/all/', {params:values}),
    spheres:()=> client.get('vacancies/sphere/'),
    schedule:()=> client.get('vacancies/workschedule/'),
    employment:()=> client.get('vacancies/employmenttype/'),
    detail:(id)=> client.get(`/vacancies/all/${id}/`).catch(err => {return err.response.data})
  },
  achievements:{
    person:(values)=> client.get(`/achievements/achievement/`, {params:values}),
    category:()=> client.get(`/achievements/category/`),
    detail:(id)=> client.get(`/achievements/achievement/${id}/`).catch(err => {return err.response.data})
  }
};

export default urls
