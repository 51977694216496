import { action, flow, makeObservable, observable } from "mobx";
import api from '../../api';

class RecoverPassword {
  @observable step = 1;
  @observable loading = false;
  constructor() {
    makeObservable(this)
  }
  @action submit = async (values) => {
    if(this.step === 1){
      const error = await this.getCode(values);
      if(error) return [error];
    }
    else if(this.step === 2){
      const error = await this.confirmCode(values);
      if(error) return [{error}];
    }
    else if(this.step === 3){
      const error = await this.recoverPassword(values);
      if(error) return error.map((item) => item);
    }

  }
  @flow * getCode(values){
    this.loading = true;
    const response =  yield api.app.recoverCode(values);
    if(response.detail){
      this.loading = false;
      return response.detail
    }else{
      this.step = 2;
    }
    this.loading = false;
  }
  @flow * confirmCode(values){
    this.loading = true;
    const response =  yield api.app.confirmCodeRecover(values);
    if(response.detail){
      this.loading = false;
      return response.detail
    }else{
      this.loading = false;
      this.step = 3
    }
  }
  @flow * recoverPassword(values){
    this.loading = true;
    const response =  yield api.app.recoverPassword(values);
    if(response.detail){
      this.loading = false;
      return response.detail.password2
    }else{
      this.step = 4
      this.loading = false;
    }
  }
  @action reset() {
    this.step = 1;
  }
}
export default new RecoverPassword()