import React, { useState } from 'react';
import InputMask from "react-input-mask";
import { Form, Input } from 'antd';
import { Trans } from "react-i18next";

const PhoneMask = (props) => {
  const [phone, setValue] = useState(props.values ? props.values : null)
  const validatePhone = (rule, value, callback) => {
    if(value && !/\+998\s[0-9]{0,2}\s[0-9]{0,3}\s[0-9]{0,2}\s[0-9]{0,2}$/.test(value) && !phone){
      console.log(rule)
      console.log(callback())
      callback(<Trans>Введите корректный номер телефона</Trans>);
    }
    else {
      callback();
    }
  };

  const valueHandler = (e) => {
    setValue(e.target.value)
  }
  return (
    <Form.Item
      label={props.label}
      name="phone"
      rules={[{ required: true, message: <Trans>Введите корректный номер телефона</Trans>},
        {
          validator:validatePhone
        }
       ]}
    >
      <InputMask className={'ant-input'} disabled={props.disabled} value={phone} onChange={valueHandler}  mask="+\9\98 99 999 99 99"  placeholder="+998">
        {(inputProps) => <Input type="tel"  disabled={props.disabled}  {...inputProps} />}
      </InputMask>

    </Form.Item>
  )
};

export default PhoneMask
